import React from 'react';
import _t from 'counterpart';
import { CLink, CTooltip } from '@coreui/react';
import moment from 'moment';

interface Props {
	accountId: string;
	displayName: string;
	comment: string;
	image: string;
	createdAt: string;
}

const CustomerComment = React.memo(({ accountId, displayName, comment, image, createdAt }: Props) => {
	const locale = _t.getLocale();
	return (
		<div className="d-flex flex-column border-bottom mt-3">
			<div className="d-flex">
				<CLink href={accountId} target="_blank">
					<img src={image} className="comments-avatar" alt="profile" />
				</CLink>
				<div className="d-flex flex-column">
					<div className="d-flex">
						<CLink href={accountId} target="_blank">
							<span className="font-weight-bold">{displayName}</span>
						</CLink>
						<CTooltip content={moment(createdAt).locale(locale).toString()}>
							<CLink className="comments-datetime ml-2">
								<small>{moment(createdAt).fromNow()}</small>
							</CLink>
						</CTooltip>
					</div>
					<div className="comments-comment">
						<p>{comment}</p>
					</div>
				</div>
			</div>
		</div>
	);
});

export default CustomerComment;
