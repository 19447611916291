import React, { useMemo, useState, useEffect } from 'react';
import { CButton, CModalHeader, CModalTitle, CModalBody, CModal } from '@coreui/react';
import _t from 'counterpart';
import { formatDateTime, getIconNameForCountry, parseOrderBy } from '../../helpers';
import CIcon from '@coreui/icons-react';
import { useQuery } from 'react-query';
import { loadAssignableCustomers } from '../../services/BackendService';
import PaginationTable from '../../components/PaginationTable';

interface IOrderBy {
	column: string;
	asc: boolean;
}

interface IProps {
	show: boolean;
	onClose: () => void;
	queryKey: string;
	action: (id: string, role?: SalesAgentRoles) => void;
	roles: Array<string> | null;
	assignSalesAgent: boolean;
	assignForLeads?: boolean;
}

export enum SalesAgentRoles {
	CONVERSIONAGENT = 'conversionAgent',
	RETENTIONAGENT = 'retentionAgent',
}

const AssignableCustomers = ({
	queryKey,
	show,
	onClose,
	action,
	roles,
	assignSalesAgent = false,
	assignForLeads = false,
}: IProps) => {
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [sorter, setSorter] = useState<IOrderBy>({ column: 'createdAt', asc: false });
	const [activePage, setActivePage] = useState<number>(1);
	const [offset, setOffset] = useState<number>(0);

	const limit = 10;

	useEffect(() => {
		setOffset(Math.max(activePage * limit - limit, 0));
	}, [activePage]);

	const { column, asc } = sorter;
	const sorterValue = parseOrderBy(column, asc);

	const { isLoading, data } = useQuery<any, Error>([queryKey, searchFilter, sorter, offset], async () => {
		const data = await loadAssignableCustomers(searchFilter, limit, offset, sorterValue, roles);
		return data;
	});

	const salesAgentsFields = useMemo(
		() => [
			{
				key: 'actionConversionAgent',
				label: _t('customers.actions.assign-as-conversion-agent'),
				width: '40%',
				sorter: false,
			},
			{
				key: 'actionRetentionAgent',
				label: _t('customers.actions.assign-as-retention-agent'),
				width: '40%',
				sorter: false,
			},
		],
		[]
	);

	const referrerFields = useMemo(
		() => ({ key: 'action', label: _t('customers.actions.assign-as-referrer'), width: '40%', sorter: false }),
		[]
	);

	const leadSalesAgentsFields = useMemo(
		() => ({ key: 'action', label: _t('customers.actions.assign-as-salesperson'), width: '40%', sorter: false }),
		[]
	);

	const tableFields = useMemo(() => {
		const fields = [
			{ key: 'name', label: _t('customers.columns.name'), width: '40%' },
			{ key: 'accountId', label: _t('customers.columns.accountId'), width: '40%', sorter: false },
			{ key: 'country', label: _t('customers.columns.country'), width: '40%' },
			{ key: 'createdAt', label: _t('customers.columns.createdAt'), width: '40%' },
		];
		if (assignForLeads) {
			fields.push(leadSalesAgentsFields);
		} else if (assignSalesAgent) {
			fields.push(...salesAgentsFields);
		} else {
			fields.push(referrerFields);
		}

		return fields;
	}, [assignForLeads, leadSalesAgentsFields, assignSalesAgent, referrerFields, salesAgentsFields]);

	const scopedSlots = useMemo(
		() => ({
			country: (user: any) => (
				<td>
					{getIconNameForCountry(user.country.toLowerCase()) ? (
						<CIcon name={getIconNameForCountry(user.country.toLowerCase())!} size="xl" title={user.country} />
					) : (
						user.country
					)}
				</td>
			),
			createdAt: (user: any) => <td>{formatDateTime(user.createdAt)}</td>,
			action: (item: any) => (
				<td className="py-2">
					<CButton color="dark" size="sm" onClick={() => action(item.id)}>
						{_t('action.assign')}
					</CButton>
				</td>
			),
			actionRetentionAgent: (item: any) => (
				<td className="py-2">
					<CButton color="dark" size="sm" onClick={() => action(item.id, SalesAgentRoles.RETENTIONAGENT)}>
						{_t('action.assign')}
					</CButton>
				</td>
			),
			actionConversionAgent: (item: any) => (
				<td className="py-2">
					<CButton color="dark" size="sm" onClick={() => action(item.id, SalesAgentRoles.CONVERSIONAGENT)}>
						{_t('action.assign')}
					</CButton>
				</td>
			),
		}),
		[action]
	);

	const onSorterChanged = (value: any) => {
		setSorter(value);
	};

	return (
		<CModal show={show} onClose={onClose} size="xl" scrollable>
			<CModalHeader closeButton>
				<CModalTitle>{_t('customer.customers-list')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<PaginationTable
					tableFields={tableFields}
					scopedSlots={scopedSlots}
					data={data?.customers || []}
					loading={isLoading}
					pages={data?.pages || 0}
					showFilter
					search={searchFilter || ''}
					onSearchValueChanged={setSearchFilter}
					onSorterChanged={onSorterChanged}
					sorter={sorter}
					activePage={activePage}
					onPageChanged={setActivePage}
					pagination
				/>
			</CModalBody>
		</CModal>
	);
};

export default AssignableCustomers;
