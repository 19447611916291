import { CCard, CCardBody, CCol, CFormGroup, CFormText, CInput, CRow, CSwitch } from '@coreui/react';
import React, { FormEvent, useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { coinsSettings, updateCoinsSettings } from '../../services/BackendService';
import { useMutation, useQuery } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { ICoinsSettings } from './types';
import toast from 'react-hot-toast';

const CoinsSettingsPage = () => {
	const [enabled, setEnabled] = useState<boolean>(false);
	const [dollarPerCoin, setDollarPerCoin] = useState<string>();

	const toggleIsEnabled = () => {
		setEnabled(!enabled);
	};

	const coinsSettingsQuery = useQuery(['coins-settings'], () => coinsSettings(), {
		onError: (e) => {
			toast.error(extractErrorMessage(e));
		},
		refetchOnWindowFocus: false,
	});

	const coinsSettingsMutation = useMutation(
		({ coinsEnabled, dollarsPerCoin }: ICoinsSettings) => updateCoinsSettings(coinsEnabled, dollarsPerCoin),
		{
			onSuccess: () => {
				toast.success(_t('coins.coins-settings-updated'));
			},
			onError: (e) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	useEffect(() => {
		if (coinsSettingsQuery.data) {
			const { coinsEnabled, dollarsPerCoin } = coinsSettingsQuery.data;
			setEnabled(coinsEnabled);
			setDollarPerCoin(dollarsPerCoin.toString());
		}
	}, [coinsSettingsQuery.data]);

	const handleDollarPerCoin = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setDollarPerCoin(value);
	};

	const applyMutation = () => {
		coinsSettingsMutation.mutate({ coinsEnabled: enabled, dollarsPerCoin: Number(dollarPerCoin) });
	};

	const renderSettings = !coinsSettingsQuery.isLoading && !coinsSettingsQuery.isError;

	return (
		<PageLayout title={_t('sidebar.coins-settings')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							{coinsSettingsQuery.isLoading && <Loading />}
							{coinsSettingsQuery.isError && <Error onRetry={coinsSettingsQuery.refetch} />}
							{renderSettings && (
								<>
									<CFormGroup>
										<InputHeaderNoButton labelText={_t('coins.enable-disable-coins')} />
										<div className="d-flex align-items-center mb-3 mt-2">
											<CSwitch
												id="send-all"
												color="primary"
												variant="3d"
												onChange={toggleIsEnabled}
												checked={enabled}
											/>
											<span className="ml-2">{enabled ? _t('global.enabled') : _t('global.disabled')}</span>
										</div>
									</CFormGroup>
									<CFormGroup>
										<InputHeaderNoButton labelText={_t('coins.dollars-per-coin')} />
										<CInput
											type="number"
											value={dollarPerCoin}
											onChange={handleDollarPerCoin}
											disabled={!enabled}
											placeholder="0.001"
										/>
										<CFormText className="help-block">{_t('coins.dollar-per-coin-form-text')}</CFormText>
									</CFormGroup>
									<CRow>
										<CCol md={12} className="d-flex justify-content-end">
											<ButtonWithLoader
												isLoading={coinsSettingsMutation.isLoading}
												onClick={applyMutation}
												buttonColor="primary"
												spinnerColor="secondary"
												title={_t('action.save')}
												className="btn-md"
												showText={false}
											/>
										</CCol>
									</CRow>
								</>
							)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(CoinsSettingsPage);
