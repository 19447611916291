import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CDataTable,
	CCardTitle,
	CInput,
	CLabel,
	CCardHeader,
	CCardFooter,
	CFormGroup,
	CInputCheckbox,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
} from '@coreui/react';
import PageLayout from '../../components/PageLayout';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { loadGroupWithExtraData, updateGroupWithMarkups } from '../../services/BackendService';
import { extractErrorMessage, onlyPositiveInteger, findErrorFromValidation } from '../../helpers';
import { IGroupWithExtraData, IMarkup } from '../customers/types';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { OrderRoutingAdapterSelector } from '../customers/CustomerWalletPopup';
import toast from 'react-hot-toast';
import { ITradeCommission, IGroupRowData } from './types';

const GroupPage = () => {
	const params = useParams();
	const [groupData, setGroupData] = useState<Array<IGroupRowData>>([]);
	const [leverage, setLeverage] = useState<string>();
	const [marginCallLevel, setMarginCallLevel] = useState<string>();
	const [stopOutLevel, setStopOutLevel] = useState<string>();
	const [adapterName, setAdapterName] = useState<string>('');
	const [disableSwapCharges, setDisableSwapCharges] = useState<boolean>(false);

	const { id } = params as any;

	const loadGroupWithExtraDataQuery = useQuery(['load-group-with-markups', id], () => loadGroupWithExtraData(id), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const groupWithExtraData = loadGroupWithExtraDataQuery.data;
	const loading = loadGroupWithExtraDataQuery.isLoading;
	const history = useHistory();
	const name = groupWithExtraData?.name;

	const combineData = (markups: Array<IMarkup>, commissions: Array<ITradeCommission>) => {
		const max = Math.max(markups.length, commissions.length);
		const rowData: Array<IGroupRowData> = [];

		for (let i = 0; i < max; i++) {
			const markup = markups[i];
			const commission = commissions[i];

			const groupRowData: IGroupRowData = {
				...markup,
				...commission,
				symbol: markup ? markup.instrumentSymbol : commission.symbol,
			};

			rowData.push(groupRowData);
		}

		return rowData;
	};

	useEffect(() => {
		if (groupWithExtraData && loadGroupWithExtraDataQuery.isSuccess) {
			const markups = groupWithExtraData.markups;
			const commissions = groupWithExtraData.commissions;

			const rowData = combineData(markups, commissions);
			setGroupData(rowData);

			setLeverage(groupWithExtraData.leverage.toString());
			setMarginCallLevel(groupWithExtraData.marginCallLevel.toString());
			setStopOutLevel(groupWithExtraData.stopOutLevel.toString());
			setAdapterName(groupWithExtraData.routingAdapterName);
			setDisableSwapCharges(groupWithExtraData.disableSwapCharges);
		}
	}, [groupWithExtraData, loadGroupWithExtraDataQuery.isSuccess, setLeverage]);

	const updateGroupWithMarkupsMutation = useMutation(
		(updatedGroup: IGroupWithExtraData) =>
			updateGroupWithMarkups(
				updatedGroup.id,
				updatedGroup.leverage,
				updatedGroup.marginCallLevel,
				updatedGroup.stopOutLevel,
				updatedGroup.routingAdapterName,
				updatedGroup.markups,
				updatedGroup.commissions,
				updatedGroup.disableSwapCharges
			),
		{
			onSuccess: () => {
				loadGroupWithExtraDataQuery.refetch();
				toast.success(_t('groups.group-successfully-update'));
				history.push('/groups');
			},
			onError: (e: any) => {
				const error = extractErrorMessage(e);
				toast.error(error);
			},
		}
	);

	const handleInputChange = useCallback(
		(symbol: string, field: string, value: string) => {
			const updateRows = groupData.map((item) => {
				if (item.symbol === symbol) {
					return { ...item, [field]: value };
				}
				return item;
			});

			setGroupData(updateRows);
		},
		[groupData]
	);

	const setRoutingAdapterName = (adapterName: string) => {
		setAdapterName(adapterName);
	};

	const fields = useMemo(
		() => [
			{ key: 'symbol', label: _t('groups.symbol'), sorter: false },
			{
				key: 'bid',
				label: _t('groups.markup-buy'),
				sorter: false,
			},
			{ key: 'ask', label: _t('groups.markup-sell'), sorter: false },
			{ key: 'open', label: _t('groups.commission-open'), sorter: false },
			{ key: 'close', label: _t('groups.commission-close'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			symbol: (row: IGroupRowData) => <td>{row.symbol}</td>,
			bid: (row: IGroupRowData) => (
				<td className="pl-0">
					<CInput
						value={row.bid}
						type="number"
						onChange={(e) => handleInputChange(row.symbol, 'bid', (e.target as HTMLInputElement).value)}
					/>
				</td>
			),
			ask: (row: IGroupRowData) => (
				<td className="pl-0">
					<CInput
						value={row.ask}
						type="number"
						onChange={(e) => handleInputChange(row.symbol, 'ask', (e.target as HTMLInputElement).value)}
					/>
				</td>
			),
			open: (row: IGroupRowData) => (
				<td className="pl-0">
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput
							value={row.open}
							type="number"
							onChange={(e) => handleInputChange(row.symbol, 'open', (e.target as HTMLInputElement).value)}
						/>
					</CInputGroup>
				</td>
			),
			close: (row: IGroupRowData) => (
				<td className="pl-0">
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput
							value={row.close}
							type="number"
							onChange={(e) => handleInputChange(row.symbol, 'close', (e.target as HTMLInputElement).value)}
						/>
					</CInputGroup>
				</td>
			),
		}),
		[handleInputChange]
	);

	const updateGroup = () => {
		if (groupWithExtraData) {
			const markups: Array<IMarkup> = groupData.map((row) => {
				return {
					groupId: groupWithExtraData.id.toString(),
					instrumentSymbol: row.symbol,
					ask: Number(row.ask),
					bid: Number(row.bid),
				};
			});

			const commissions: Array<ITradeCommission> = groupData.map((row) => {
				return {
					groupId: groupWithExtraData.id.toString(),
					symbol: row.symbol,
					open: Number(row.open),
					close: Number(row.close),
				};
			});

			const updateGroup: IGroupWithExtraData = {
				...groupWithExtraData,
				id: groupWithExtraData?.id,
				leverage: Number(leverage),
				marginCallLevel: Number(marginCallLevel),
				stopOutLevel: Number(stopOutLevel),
				routingAdapterName: adapterName,
				markups,
				commissions,
			};
			updateGroupWithMarkupsMutation.mutate(updateGroup);
		}
	};

	const onSetLeverage = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setLeverage(value);
		}
	};

	const onSetStopoutLevel = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setStopOutLevel(value);
		}
	};

	const toggleDisableSwapCharges = () => {
		setDisableSwapCharges(!disableSwapCharges);
	};

	const onMarginLevel = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setMarginCallLevel(value);
		}
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateGroupWithMarkupsMutation.error, paramName);
	};

	return (
		<PageLayout title={name || ''}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('groups.trading-and-risk')}</CCardTitle>
							<CRow>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.default-leverage')}</CLabel>
										<CInput value={leverage} onChange={onSetLeverage} />
									</CFormGroup>
								</CCol>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.stop-out-level')}</CLabel>
										<CInput value={stopOutLevel} onChange={onSetStopoutLevel} />
									</CFormGroup>
								</CCol>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.margin-call-level')}</CLabel>
										<CInput value={marginCallLevel} onChange={onMarginLevel} />
									</CFormGroup>
								</CCol>
							</CRow>
							<CFormGroup>
								<CLabel htmlFor="nf-type">{_t('wallet-modal.order-routing-adapter')}</CLabel>
								<OrderRoutingAdapterSelector onChange={setRoutingAdapterName} value={adapterName} />
								{findError('adapterName') && <CLabel className="text-danger">{findError('adapterName')}</CLabel>}
							</CFormGroup>
							<CFormGroup>
								<div className="form-check form-check-inline">
									<CInputCheckbox
										className="form-check-input"
										type="checkbox"
										id="is-demo"
										onChange={toggleDisableSwapCharges}
										checked={disableSwapCharges}
									/>
									<span>{_t('groups.disable-swap-charges')}</span>
								</div>
							</CFormGroup>
						</CCardHeader>
						<CCardBody>
							<CCardTitle style={{ display: 'block', clear: 'left' }}>{_t('instruments.title')}</CCardTitle>
							<div className="table-remove-border">
								<CDataTable loading={loading} fields={fields} scopedSlots={scopedSlots} items={groupData} />
							</div>
						</CCardBody>
						<CCardFooter>
							<CRow>
								<CCol>
									<ButtonWithLoader
										isLoading={updateGroupWithMarkupsMutation.isLoading}
										onClick={updateGroup}
										buttonColor="primary"
										spinnerColor="secondary"
										title={_t('action.save')}
										className="group-page-save-button"
									/>
								</CCol>
							</CRow>
						</CCardFooter>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default GroupPage;
