import React from 'react';
import { CInputGroup, CLabel, CButton } from '@coreui/react';
import CustomSelect from '../../components/CustomSelect';
import { ITradingHoursRange } from './types';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';

const DAYS_OF_WEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
const MINUTES: Array<number> = [];
for (let i = 0; i < 60; i += 1) {
	MINUTES.push(i);
}

interface IProps {
	index: number;
	onRemove: (index: number) => void;
	onClone: (index: number) => void;
	onChange: (index: number, tradingHoursRange: ITradingHoursRange) => void;
	tradingHoursRange: ITradingHoursRange;
}

const FormTradingHoursRange = (props: IProps) => {
	const { index, onRemove, onClone, onChange, tradingHoursRange } = props;

	const { startTime, endTime } = tradingHoursRange;

	const days = DAYS_OF_WEEK.map((day) => {
		return { value: day, label: day };
	});

	const hours = new Array(24)
		.fill(1, 0, 24)
		.map((v, i) => {
			return i;
		})
		.map((hour) => {
			return { value: hour, label: hour.toString() };
		});

	const minutes = MINUTES.map((minute) => {
		return { value: minute, label: minute.toString() };
	});

	// TODO move this 0 workaround directly to CSelect
	const getTimeValue = (value: any) => {
		if (value === 0) {
			return '0';
		}
		if (value === '') {
			return null;
		}
		return value;
	};

	const handleSelectChange = (select: any, action: any) => {
		const { value } = select;
		const { name } = action;
		const first = name.indexOf('-');
		const last = name.lastIndexOf('-');

		const startOrEnd = name.substring(first + 1, last);
		const dayPart = name.substring(last + 1, name.length);

		const spreadObject: any = tradingHoursRange;

		const toSpread = spreadObject[startOrEnd];
		const range = {
			...tradingHoursRange,
			[startOrEnd]: {
				...toSpread,
				[dayPart]: getTimeValue(value),
			},
		};

		onChange(index, range);
	};

	const startHour = getTimeValue(startTime.hour);
	const endHour = getTimeValue(endTime.hour);
	const startMinute = getTimeValue(startTime.minute);
	const endMinute = getTimeValue(endTime.minute);

	return (
		<CInputGroup className="d-flex align-items-center">
			<div>
				<div className="trading-hours-elements trading-hours-start">
					<div>
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.start-day')}
						</CLabel>
						<CustomSelect
							value={startTime.day && { value: startTime.day.toString(), label: startTime.day.toString() }}
							onChange={handleSelectChange}
							options={days}
							whiteBackground
							name={`${index}-startTime-day`}
							className="input-days"
							placeholder="MONDAY"
						/>
					</div>

					<div className="m-2">
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.hour')}
						</CLabel>
						<CustomSelect
							value={startHour != null && { value: startHour, label: startHour }}
							onChange={handleSelectChange}
							options={hours}
							whiteBackground
							name={`${index}-startTime-hour`}
							className="input-day-subunit"
							placeholder="14"
						/>
					</div>

					<div className="m-2">
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.minute')}
						</CLabel>
						<CustomSelect
							value={startMinute && { value: startTime.minute.toString(), label: startMinute.toString() }}
							onChange={handleSelectChange}
							options={minutes}
							whiteBackground
							name={`${index}-startTime-minute`}
							className="input-day-subunit"
							placeholder="45"
						/>
					</div>
				</div>
			</div>

			<div className="d-flex flex-wrap">
				<div className="trading-hours-elements">
					<div>
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.end-day')}
						</CLabel>
						<CustomSelect
							value={endTime.day && { value: endTime.day.toString(), label: endTime.day.toString() }}
							onChange={handleSelectChange}
							options={days}
							whiteBackground
							name={`${index}-endTime-day`}
							className="input-days"
							placeholder="MONDAY"
						/>
					</div>

					<div className="m-2">
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.hour')}
						</CLabel>
						<CustomSelect
							value={endHour != null && { value: endHour, label: endHour }}
							onChange={handleSelectChange}
							options={hours}
							whiteBackground
							name={`${index}-endTime-hour`}
							className="input-day-subunit"
							placeholder="14"
						/>
					</div>

					<div className="m-2">
						<CLabel className="m-0" htmlFor="regdate">
							{_t('instruments.minute')}
						</CLabel>
						<CustomSelect
							value={endMinute && { value: endTime.minute.toString(), label: endMinute.toString() }}
							onChange={handleSelectChange}
							options={minutes}
							whiteBackground
							name={`${index}-endTime-minute`}
							className="input-day-subunit"
							placeholder="45"
						/>
					</div>
				</div>

				<div>
					<CButton className="mr-2 trading-hour-button" color="danger" onClick={() => onRemove(index)}>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
					<CButton
						className="mr-2 trading-hour-button"
						color="primary"
						type="button"
						onClick={() => onClone(index)}
						title="Clone trading hours"
					>
						<CIcon name="cil-clone" size="sm" />
					</CButton>
				</div>
			</div>
		</CInputGroup>
	);
};

export default FormTradingHoursRange;
