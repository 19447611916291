import React, { useEffect } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import {
	CCard,
	CCardBody,
	CCol,
	CForm,
	CFormGroup,
	CFormText,
	CInputGroup,
	CLabel,
	CRow,
	CSwitch,
	CTextarea,
} from '@coreui/react';
import { useLegacyState } from '../../helpers/customHooks';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation } from 'react-query';
import { sendMessageToAllCustomers, sendMessageToCustomers } from '../../services/BackendService';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';

interface IState {
	message: string;
	accounts: string;
	sendToAll: boolean;
	subject: string;
}

const MessagingPage = () => {
	const initialState = {
		message: '',
		accounts: '',
		sendToAll: false,
		subject: '',
	};

	const { state: queryState } = useLocation<{ customersIds: string }>();

	const [state, setState] = useLegacyState<IState>(initialState);

	const { accounts, message, sendToAll, subject } = state;

	const resetState = () => {
		setState(initialState);
	};

	const sendMessageMutation = useMutation(
		({ accounts, message, subject }: { accounts: string; message: string; subject: string }) =>
			sendMessageToCustomers(accounts, message, subject),
		{
			onSuccess: ({ messageRecipients }) => {
				resetState();
				toast.success(
					_t('messaging.message-sent-to-customers', {
						sent: messageRecipients,
					})
				);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					toast.error(msg);
				}
			},
		}
	);

	const sendMessageAllMutation = useMutation(
		({ message, subject }: { message: string; subject: string }) => sendMessageToAllCustomers(message, subject),
		{
			onSuccess: ({ messageRecipients }) => {
				resetState();
				toast.success(
					_t('messaging.message-sent-to-customers', {
						sent: messageRecipients,
					})
				);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					toast.error(msg);
				}
			},
		}
	);

	const handleMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			message: event.currentTarget.value,
		});
	};

	const handleSubjectChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			subject: event.currentTarget.value,
		});
	};

	const handleAccountsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			accounts: event.currentTarget.value,
		});
	};

	const toggleSendToAll = () => {
		setState({
			sendToAll: !sendToAll,
		});
	};

	const sendMessage = async (e: React.FormEvent) => {
		e.preventDefault();
		if (sendToAll) {
			sendMessageAllMutation.mutate({ message, subject });
		} else {
			sendMessageMutation.mutate({ accounts, message, subject });
		}
	};

	const findError = (paramName: string) => {
		if (sendMessageAllMutation.error) {
			return findErrorFromValidation(sendMessageAllMutation.error, paramName);
		}
		return findErrorFromValidation(sendMessageMutation.error, paramName);
	};

	useEffect(() => {
		if (!queryState?.customersIds || queryState?.customersIds.length === 0) return;

		setState({
			...state,
			accounts: queryState?.customersIds,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryState]);

	return (
		<PageLayout title={_t.translate('sidebar.messaging')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<CForm onSubmit={sendMessage}>
								<div className="d-flex align-items-center mb-3">
									<CSwitch id="send-all" color="primary" variant="3d" onChange={toggleSendToAll} checked={sendToAll} />
									<span className="ml-2">{_t.translate('messaging.send-to-all')}</span>
								</div>
								<CFormGroup>
									<CLabel htmlFor="nf-accounts">{_t('messaging.user-ids')}</CLabel>
									<CInputGroup>
										<CTextarea
											style={{ height: 120 }}
											id="nf-accounts"
											name="nf-accounts"
											value={accounts || ''}
											onChange={handleAccountsChanged}
											disabled={sendToAll}
										/>
									</CInputGroup>
									<CFormText className="help-block">{_t('messaging.user-ids-comment')}</CFormText>
									{findError('customers') && <CLabel className="text-danger">{findError('customers')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-subject">{_t('emails.subject')}</CLabel>
									<CInputGroup>
										<CTextarea
											style={{ height: 40 }}
											id="nf-subject"
											name="nf-subject"
											value={subject || ''}
											onChange={handleSubjectChanged}
										/>
									</CInputGroup>
									{findError('message') && <CLabel className="text-danger">{findError('subject')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-message">{_t('messaging.message')}</CLabel>
									<CInputGroup>
										<CTextarea
											style={{ height: 120 }}
											id="nf-message"
											name="nf-message"
											value={message || ''}
											onChange={handleMessageChanged}
										/>
									</CInputGroup>
									{findError('message') && <CLabel className="text-danger">{findError('message')}</CLabel>}
								</CFormGroup>

								<ButtonWithLoader
									isLoading={sendMessageMutation.isLoading}
									type="submit"
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.send')}
									className="mr-2"
								/>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(MessagingPage);
