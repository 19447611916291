import React from 'react';
import { connect } from 'react-redux';
import {
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CHeader,
	CHeaderBrand,
	CHeaderNav,
	CImg,
	CToggler,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { AppState } from '../reducers';
import {
	requestChangeLanguage,
	toggleSidebar,
	toggleSidebarMobile,
	requestStopImpersonation,
	oAuthLogoutRequested,
} from '../actions';
import incognito from '../images/incognito.png';
import { capitalize, getIconNameForLanguage } from '../helpers';
import logo from '../images/logo';
import { supportedLocales } from '../locales';
import { useAppSelector, useHeartbeat } from '../helpers/customHooks';
import styles from './ActvityDot.module.scss';

interface IAppHeaderProps {
	userName: string;
	avatarUrl: string;
	isImpersonated: boolean;
	connected: boolean;
	logout: () => void;
	stopImpersonation: () => void;
	toggleSidebar: () => void;
	toggleSidebarMobile: () => void;
	changeLanguage: (language: string) => void;
}

interface ILanguageDropdownProps {
	changeLanguage: (language: string) => void;
}

const LanguageDropdown = ({ changeLanguage }: ILanguageDropdownProps) => {
	const currentLanguage = useAppSelector((state) => state.core.language);
	const setLanguage = async (language: string) => {
		changeLanguage(language);
	};

	useHeartbeat();

	return (
		<CDropdown inNav className="c-header-nav-items">
			<CDropdownToggle className="c-header-nav-link dropdown-toggle" caret={false}>
				{getIconNameForLanguage(currentLanguage) && (
					<CIcon name={getIconNameForLanguage(currentLanguage)!} size="xl" className="d-lg-none" />
				)}
				<p className="px-2 mb-0 d-md-down-none">{capitalize(_t('global.language'))}</p>
			</CDropdownToggle>
			<CDropdownMenu className="py-0" placement="bottom-end">
				{supportedLocales.map((language) => (
					<button
						type="button"
						onClick={() => setLanguage(language)}
						className="wrapper-button"
						key={`lang-${language}`}
					>
						<CDropdownItem active={currentLanguage === language}>
							{getIconNameForLanguage(language) && (
								<CIcon name={getIconNameForLanguage(language)!} size="custom-size" title={language} />
							)}
							<span>{_t(`languages.${language}`)}</span>
						</CDropdownItem>
					</button>
				))}
			</CDropdownMenu>
		</CDropdown>
	);
};

const AppHeader = ({
	changeLanguage,
	userName,
	avatarUrl,
	isImpersonated,
	connected,
	logout,
	stopImpersonation,
	toggleSidebar,
	toggleSidebarMobile,
}: IAppHeaderProps) => {
	return (
		<CHeader>
			<CToggler inHeader className="d-lg-none" onClick={toggleSidebarMobile} />
			<CToggler inHeader className="d-md-down-none" onClick={toggleSidebar} />
			<CHeaderBrand className="d-lg-none" to="/">
				<CImg src={logo} height="48" />
			</CHeaderBrand>

			<CHeaderNav className="ml-auto mr-3">
				<CTooltip
					content={connected ? _t('header.connected-to-live-services') : _t('header.not-connected-to-live-services')}
					placement="left"
				>
					<div className={connected ? styles.dotActive : styles.dotInactive} />
				</CTooltip>
				<LanguageDropdown changeLanguage={changeLanguage} />

				<CDropdown inNav className="c-header-nav-items">
					<CDropdownToggle className="c-header-nav-link dropdown-toggle" caret={false}>
						<div className="c-avatar">
							<CImg
								src={isImpersonated ? incognito : avatarUrl}
								className="c-avatar-img"
								style={{ overflow: 'hidden' }}
								alt={userName}
							/>
						</div>
						<p className={`px-2 mb-0 d-none${isImpersonated ? ' text-danger' : ''}`}>{userName}</p>
					</CDropdownToggle>
					<CDropdownMenu className="py-0" placement="bottom-end">
						<CDropdownItem href="/profile">
							<CIcon name="cil-user" className="mfe-2" />
							{_t('header.profile')}
						</CDropdownItem>
						{!isImpersonated && (
							<CDropdownItem onClick={logout}>
								<CIcon name="cil-account-logout" className="mfe-2" />
								{_t('header.logout')}
							</CDropdownItem>
						)}
						{isImpersonated && (
							<CDropdownItem onClick={stopImpersonation} color="danger">
								<CIcon name="cil-account-logout" className="mfe-2 text-white" />
								<span className="text-white">{_t('header.stop-impersonation')}</span>
							</CDropdownItem>
						)}
					</CDropdownMenu>
				</CDropdown>
			</CHeaderNav>
		</CHeader>
	);
};

const mapStateToProps = (state: AppState) => {
	return {
		userName: state.user.name!,
		avatarUrl: state.user.avatarUrl!,
		connected: state.core.socketConnected,
		isImpersonated: Boolean(state.user.impersonation),
	};
};

const mapDispatchToProps = {
	logout: oAuthLogoutRequested,
	toggleSidebarMobile,
	toggleSidebar,
	stopImpersonation: requestStopImpersonation,
	changeLanguage: requestChangeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
