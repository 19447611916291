import { CRow, CCol, CCard, CCardBody, CCardHeader, CCardTitle, CBadge } from '@coreui/react';
import { timeFormat } from 'd3-time-format';
import React from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ResponsiveContainer, AreaChart, XAxis, Label, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import { extractErrorMessage } from '../../helpers';
import { loadMarketData } from '../../services/HistoryService';
import _t from 'counterpart';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { isNumber } from 'lodash';

const MarketDataChart = ({ decimals, ask, bid }: { decimals: string; ask: number; bid: number }) => {
	const { id } = useParams<{ id: string }>();
	const unescapedId = decodeURIComponent(id);

	const end = new Date();
	const start = new Date(end.getTime() - 16 * 60 * 1000);

	const marketDataQuery = useQuery(
		['market-data', unescapedId],
		() => loadMarketData(unescapedId, start.toString(), end.toString(), '1', undefined),
		{
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
			retry: false,
			refetchInterval: 60000,
		}
	);

	const tickFormatter = (tick: string) => {
		if (!isNumber(tick)) {
			return "";
		}
		return timeFormat('%I:%M')(new Date(tick));
	};

	const priceFormatter = (tick: string) => {
		const symbolDecimals = decimals === undefined ? 2 : decimals;

		return Number(tick).toFixed(Number(symbolDecimals));
	};

	const dataToChartData = (data: Array<any>) => {
		return data.map((quote) => {
			const { start, end, open, close } = quote;
			const x = (Number(start) + Number(end)) / 2;
			const y = (Number(open) + Number(close)) / 2;

			return {
				x: x,
				y: y,
			};
		});
	};

	const renderTooltip = (test: any) => {
		const { payload } = test;

		if (!payload || !payload[0]?.payload) {
			return null;
		}

		const { x, y } = payload[0].payload;

		return (
			<div className="bg-white p-2 text-dark border">
				<span>{tickFormatter(x)}</span>
				<div>
					<span>{_t('instruments.price')}</span>
					<span className="text-success ml-1">{priceFormatter(y)}</span>
				</div>
			</div>
		);
	};

	const { isLoading, data, isError, refetch } = marketDataQuery;

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const { noData } = data;

	return (
		<CRow>
			<CCol md={12}>
				<CCard>
					<CCardHeader className="d-flex justify-content-between align-items-center">
						<CCardTitle>{_t('instruments.data-and-analytics')}</CCardTitle>
						<CBadge color="light" className="d-flex justify-content-between p-2 text-dark" style={{ width: '200px' }}>
							<div>
								<span>{_t('instruments.ask')}:</span>
								<span className="font-weight-light"> {ask}</span>
							</div>
							<div>
								<span>{_t('instruments.bid')}:</span>
								<span className="font-weight-light"> {bid}</span>
							</div>
						</CBadge>
						<div>{_t('instruments.last-15-minutes')}</div>
					</CCardHeader>
					<CCardBody className="pb-4">
						{noData && (
							<CBadge className="position-absolute" color="danger" style={{ width: '200px', top: '250px', left: '50%', transform: 'translate(-100px)' }}>
								No market data
							</CBadge>
						)}
						<ResponsiveContainer width="100%" height={400}>
							<AreaChart data={dataToChartData(data?.candles ?? [])}>
								<XAxis dataKey="x" allowDecimals domain={['dataMin', 'dataMax']} tickFormatter={tickFormatter}>
									<Label value={_t('instruments.instrument-price')} offset={0} position="insideBottom" />
								</XAxis>
								<YAxis dataKey="y" allowDecimals domain={['dataMin', 'dataMax']} tickFormatter={priceFormatter} />
								<defs>
									<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
										<stop offset="100%" stopColor="var(--success)" stopOpacity={0.5} />
									</linearGradient>
								</defs>
								<CartesianGrid strokeDasharray="3 3" />
								<Tooltip content={renderTooltip} />
								<Area
									type="linear"
									label="test"
									dataKey="y"
									stroke="var(--success)"
									fillOpacity={1}
									fill="url(#colorUv)"
								/>
							</AreaChart>
						</ResponsiveContainer>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	);
};

export default MarketDataChart;
