import React, { createContext, useRef, ReactNode, useCallback, useState, useEffect } from 'react';
import { store } from '../store';
import { setSocketConnected } from '../actions';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import _t from 'counterpart';
import storeRegistry from '../helpers/storeRegistry';
import { loadUserSelf } from './BackendService';

interface WebSocketContextType {
	addListener: (channel: ListenersEnum, callback: ListenerFunction) => void;
	removeListener: (channel: ListenersEnum, callback: ListenerFunction) => void;
	subscribe: (subscriptionTopic: SubscriptionTopicEnum, token?: string) => void;
	unsubscribe: (subscriptionTopic: SubscriptionTopicEnum) => void;
	sendPing: (token: string) => void;
}

export enum SubscriptionTopicEnum {
	POSITIONS = 'positions',
	ACCOUNTS = 'accounts',
	ORDERS = 'orders',
	PRICE = 'price',
}

export enum ListenersEnum {
	POSITIONS = 'POSITIONS',
	ACCOUNTS = 'ACCOUNTS',
	ORDERS = 'ORDERS',
	PRICE = 'PRICE',
	DASHBOARD = 'DASHBOARD',
}

export const WebSocketContext = createContext<WebSocketContextType | null>(null);

interface IWebSocketProviderProps {
	children: ReactNode;
}

type ListenerFunction = (event: any) => void;

const RECONNECTION_LIMIT = 3;

export const WebSocketProvider = ({ children }: IWebSocketProviderProps) => {
	const client = useRef<WebSocket | null>(null);
	const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
	const [receivedHeartbeatTime, setReceivedHeartbeatTime] = useState<Date | null>(null);

	const positionListeners = useRef<Array<ListenerFunction>>([]);
	const accountListeners = useRef<Array<ListenerFunction>>([]);
	const priceListeners = useRef<Array<ListenerFunction>>([]);
	const dashboardListeners = useRef<Array<ListenerFunction>>([]);

	const currentlySubscribedTopic = useRef<SubscriptionTopicEnum | null>(null);

	const connect = useCallback(() => {
		if (!process.env.REACT_APP_REPORTING_WS_ROOT) {
			throw new Error('REACT_APP_REPORTING_WS_ROOT ENV var is required');
		}
		client.current = new WebSocket(process.env.REACT_APP_REPORTING_WS_ROOT);
		client.current.onopen = onOpen;
		client.current.onclose = onClose;
		client.current.onerror = onError;
		client.current.onmessage = onMessage;
	}, []);

	const resubscribeAfterLostConnection = () => {
		//Calling loadUserSelf to ensure token is refreshed before resubscribing
		(async () => {
			try {
				await loadUserSelf();
			} catch (e) {
				Sentry.captureException(e);
			}
		})();
		const { token } = storeRegistry.getStore().getState().user;
		if (currentlySubscribedTopic.current) {
			subscribe(currentlySubscribedTopic.current, token);
		}
	};

	useEffect(() => {
		connect();
	}, [connect]);

	const waitForConnection = useCallback((callback: Function, interval: number) => {
		if (client.current?.readyState === 1) {
			callback();
		} else {
			setTimeout(() => {
				waitForConnection(callback, interval);
			}, interval);
		}
	}, []);

	const send = useCallback(
		(data: any) => {
			waitForConnection(() => {
				client.current?.send(data);
			}, 1000);
		},
		[waitForConnection]
	);

	const sendPing = (token: string) => {
		sendJSON({
			messageType: 'Ping',
			topic: 'heartbeat',
			auth: token,
		});
	};

	const sendJSON = useCallback(
		(data: Object) => {
			send(JSON.stringify(data));
		},
		[send]
	);

	const closeConnection = useCallback(() => {
		client.current?.close();
	}, []);

	const onOpen = useCallback(() => {
		store.dispatch(setSocketConnected(true));
		checkHeartbeatDiff();
	}, []);

	const checkHeartbeatDiff = useCallback(() => {
		setInterval(() => {
			if (receivedHeartbeatTime !== null) {
				const seconds = (new Date().getTime() - receivedHeartbeatTime.getTime()) / 1000;
				if (seconds > 9) {
					store.dispatch(setSocketConnected(false));
				} else {
					store.dispatch(setSocketConnected(true));
				}
			}
		}, 1000);
	}, [receivedHeartbeatTime]);

	const onClose = useCallback(
		(event: CloseEvent) => {
			if (!event.wasClean && reconnectionAttempts < RECONNECTION_LIMIT) {
				setTimeout(() => {
					const reconnAttempts = reconnectionAttempts + 1;
					setReconnectionAttempts(reconnAttempts);
					connect();
					resubscribeAfterLostConnection();
				}, 200);
			}

			if (reconnectionAttempts >= RECONNECTION_LIMIT) {
				toast.error(_t('errors.live-updates-unavailable'));
			}
			store.dispatch(setSocketConnected(false));
		},
		[reconnectionAttempts, connect]
	);

	const onError = useCallback(() => {
		closeConnection();
	}, [closeConnection]);

	const onMessage = useCallback((event: MessageEvent) => {
		if (event.data === 'Pong') {
			setReceivedHeartbeatTime(new Date());
		}

		try {
			const message = JSON.parse(event.data);
			if (['PositionStatus'].includes(message.messageType)) {
				positionListeners.current.forEach((callback) => callback(message));
			}
			if (['PositionOpened', 'PositionClosed'].includes(message.messageType)) {
				positionListeners.current.forEach((callback) => callback(message));
				dashboardListeners.current.forEach((callback) => callback(message));
			}
			if (['AccountStatus'].includes(message.messageType)) {
				accountListeners.current.forEach((callback) => callback(message));
			}
			if (['PriceUpdate'].includes(message.messageType)) {
				priceListeners.current.forEach((callback) => callback(message));
			}
			if (['PendingOrder'].includes(message.messageType)) {
				dashboardListeners.current.forEach((callback) => callback(message));
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	}, []);

	const subscribe = (subscriptionTopic: SubscriptionTopicEnum, token?: string) => {
		currentlySubscribedTopic.current = subscriptionTopic;
		token
			? sendJSON({ messageType: 'Subscribe', topic: subscriptionTopic, auth: token })
			: sendJSON({ messageType: 'Subscribe', topic: subscriptionTopic });
	};

	const unsubscribe = (subscriptionTopic: SubscriptionTopicEnum) => {
		sendJSON({ messageType: 'Unsubscribe', topic: subscriptionTopic });
	};

	const addListener = useCallback((channel: ListenersEnum, callback: ListenerFunction) => {
		switch (channel) {
			case ListenersEnum.POSITIONS:
				positionListeners.current.push(callback);
				break;
			case ListenersEnum.ACCOUNTS:
				accountListeners.current.push(callback);
				break;
			case ListenersEnum.PRICE:
				priceListeners.current.push(callback);
				break;
			case ListenersEnum.DASHBOARD:
				dashboardListeners.current.push(callback);
				break;
			default:
				break;
		}
	}, []);

	const removeListener = useCallback((channel: ListenersEnum, callback: ListenerFunction) => {
		switch (channel) {
			case ListenersEnum.POSITIONS:
				const index = positionListeners.current.indexOf(callback);
				if (index >= 0) {
					positionListeners.current.splice(index, 1);
				}
				break;
			case ListenersEnum.ACCOUNTS:
				const accountCBIndex = accountListeners.current.indexOf(callback);
				if (accountCBIndex >= 0) {
					accountListeners.current.splice(accountCBIndex, 1);
				}
				break;
			case ListenersEnum.PRICE:
				const priceCBIndex = priceListeners.current.indexOf(callback);
				if (priceCBIndex >= 0) {
					priceListeners.current.splice(priceCBIndex, 1);
				}
				break;
			case ListenersEnum.DASHBOARD:
				const dashboardCBIndex = dashboardListeners.current.indexOf(callback);
				if (dashboardCBIndex >= 0) {
					dashboardListeners.current.splice(dashboardCBIndex, 1);
				}
				break;
		}
	}, []);

	return (
		<WebSocketContext.Provider value={{ subscribe, unsubscribe, addListener, removeListener, sendPing }}>
			{children}
		</WebSocketContext.Provider>
	);
};
