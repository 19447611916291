export enum BonusType {
	SIGNUP = 'SIGNUP',
	DEPOSIT = 'DEPOSIT',
	DEPOSIT_PROPORTIONAL = 'DEPOSIT_PROPORTIONAL',
	APP_INSTALLED = 'APP_INSTALLED',
	PUSH_ACCEPTANCE = 'PUSH_ACCEPTANCE',
}

export interface IBonus {
	id: string;
	start: Date;
	end: Date;
	amount: number;
	addToCredit: boolean;
	minimumDepositUSD?: number;
	maximumAmountUSD?: number;
	methods: Array<string>;
}

export interface ICreateUpdateModalProps {
	show: boolean;
	hideModal: (refetch?: boolean) => void;
	currentBonus: IBonus | null;
}
