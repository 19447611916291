import CIcon from '@coreui/icons-react';
import React from 'react';

interface IProps {
	riskStatus: 'NONE' | 'REVIEW';
}

const CustomerWarning = ({ riskStatus }: IProps) => {
	if (riskStatus === 'REVIEW') {
		return <CIcon name="cil-warning" size="lg" className="ml-2 text-danger" />;
	}

	return null;
};

export default React.memo(CustomerWarning);
