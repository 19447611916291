export const cilWallpaper1 = [
  '40 40',
  `
<path d="M30 8.75H26.875V11.875H30V8.75Z" />
<path d="M5.625 5.625H17.5V3.125H3.125V17.5H5.625V5.625Z" />
<path d="M22.5 3.125V5.625H34.375V17.5H36.875V3.125H22.5Z" />
<path d="M5.625 22.5H3.125V36.875H17.5V34.375H5.625V22.5Z" />
<path d="M21.9141 24.1307L14.8047 17.0213L8.125 23.701V27.2365L14.8047 20.5569L26.1228 31.875H29.6584L23.6818 25.8984L29.3149 20.2653L34.375 25.3254V34.375H22.5V36.875H36.875V24.375L29.3149 16.7299L21.9141 24.1307Z" />
`,
];
