import React, { useEffect, useState } from 'react';
import _t from 'counterpart';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import { disableWallet, enableWallet, fetchWallet } from '../../services/BackendService';
import PageLayout from '../../components/PageLayout';
import {
	CRow,
	CCol,
	CCard,
	CCardHeader,
	CCardTitle,
	CCardBody,
	CBadge,
	CDropdown,
	CDropdownMenu,
	CDropdownToggle,
	CDropdownItem,
} from '@coreui/react';
import Loading from '../../components/Loading';
import Balance from '../customers/CustomerWalletBalance';
import Credit from '../customers/CustomerWalletCredit';
import PerformanceChart from './PerformanceChart';
import Error from '../../components/Error';
import { formatCurrency } from '../../helpers';
import { useLiveAccountUpdates } from '../../helpers/customHooks';
import ConfirmationModal from '../../components/ConfirmationModal';
import toast from 'react-hot-toast';
import { TradingType } from '../customers/types';

const WalletPage = () => {
	const [showEnableWallet, setShowEnableWallet] = useState<boolean>(false);
	const [showDisableWallet, setShowDisableWallet] = useState<boolean>(false);

	const params = useParams();
	const { id } = params as any;
	const fetchWalletQuery = useQuery(['customer-wallet', id], () => fetchWallet(id), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	useLiveAccountUpdates(fetchWalletQuery.data?.wallet.id!, id);
	const enableWalletMutation = useMutation(['enable-wallet'], () => enableWallet(id), {
		onSuccess: () => {
			fetchWalletQuery.refetch();
			setShowEnableWallet(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const disableWalletMutation = useMutation(['enable-wallet'], () => disableWallet(id), {
		onSuccess: () => {
			fetchWalletQuery.refetch();
			setShowDisableWallet(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});
	const onErrorRetry = () => {
		fetchWalletQuery.refetch();
	};

	if (fetchWalletQuery.isLoading || fetchWalletQuery.isIdle || !fetchWalletQuery.data) {
		return <Loading />;
	}

	if (fetchWalletQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}
	const { wallet, performance } = fetchWalletQuery.data;

	const marginLevel = () => {
		if (wallet.equity === null) {
			return null;
		}
		if (Number(wallet.margin) === 0) {
			return 0;
		}
		return Number(wallet.equity) / Number(wallet.margin);
	};

	const showEnableWalletModal = () => {
		setShowEnableWallet(true);
	};

	const hideEnableWalletModal = () => {
		setShowEnableWallet(false);
	};

	const showDisableWalletModal = () => {
		setShowDisableWallet(true);
	};

	const hideDisableWalletModal = () => {
		setShowDisableWallet(false);
	};

	return (
		<PageLayout
			title={_t('wallet.title')}
			titleAppend={
				<div className="d-flex justify-content-between float-right mb-2">
					<div className="d-flex flex-nowrap flex-row-reverse">
						<CDropdown className="float-right">
							<CDropdownToggle caret color="primary">
								{_t('wallet.options')}
							</CDropdownToggle>
							<CDropdownMenu>
								{wallet.status === 'DISABLED' && (
									<CDropdownItem onClick={showEnableWalletModal}>{_t('wallet.enable-wallet')}</CDropdownItem>
								)}
								{wallet.status === 'ACTIVE' && (
									<CDropdownItem onClick={showDisableWalletModal}>{_t('wallet.disable-wallet')}</CDropdownItem>
								)}
							</CDropdownMenu>
						</CDropdown>
					</div>
				</div>
			}
		>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.info')}</CCardTitle>
							<div className="float-right">
								<CBadge style={{ backgroundColor: wallet.tradingType === TradingType.Netting ? '#18B8C2' : '#9618C2' }}>
									{wallet.tradingType === TradingType.Netting ? _t('wallets.netting') : _t('wallets.hedging')}
								</CBadge>
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('global.status')}:</dt>
										<dd>
											<CBadge color={wallet.status.toLowerCase()}>{wallet.status}</CBadge>
											{wallet.primary && <br />}
											{wallet.primary && <CBadge color="primary">PRIMARY</CBadge>}
										</dd>

										<dt>{_t('customer.wallets.accountId')}:</dt>
										<dd>{wallet.id}</dd>

										<dt>{_t('customer.wallets.currency')}:</dt>
										<dd>{wallet.currency}</dd>

										<dt>{_t('customer.wallets.balance')}:</dt>
										<dd>
											{wallet.status === 'ACTIVE' && <Balance value={wallet.balance} currency={wallet.currency} />}
											{wallet.status !== 'ACTIVE' && 'n/a'}
										</dd>

										<dt>{_t('wallet.margin')}</dt>
										<dd>{formatCurrency(wallet.margin, wallet.currency)}</dd>

										<dt>{_t('wallet.profit-loss')}</dt>
										<dd>
											{wallet.profitLoss === undefined
												? _t('wallet.no-open-positions')
												: formatCurrency(wallet.profitLoss, wallet.currency)}
										</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('customer.wallets.credit')}:</dt>
										<dd>
											{wallet.status === 'ACTIVE' && <Credit value={wallet.credit} currency={wallet.currency} />}
											{wallet.status !== 'ACTIVE' && 'n/a'}
										</dd>

										<dt>{_t('wallet-modal.group')}:</dt>
										<dd>{wallet.group.name}</dd>

										<dt>{_t('wallet.equity')}</dt>
										<dd>{formatCurrency(wallet.equity, wallet.currency)}</dd>

										<dt>{_t('wallet.free-margin')}</dt>
										<dd>{formatCurrency(wallet.marginFree, wallet.currency)}</dd>

										<dt>{_t('wallet.margin-level')}</dt>
										<dd>{marginLevel()?.toFixed(2)}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('wallet.wallet-performance')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<PerformanceChart data={performance} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={id}
				show={showEnableWallet}
				onConfirm={enableWalletMutation.mutate}
				hidePanel={hideEnableWalletModal}
				title={_t('wallet.enable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={false}
			/>
			<ConfirmationModal
				id={id}
				show={showDisableWallet}
				onConfirm={disableWalletMutation.mutate}
				hidePanel={hideDisableWalletModal}
				title={_t('wallet.disable-wallet')}
				error={null}
				buttonColor="primary"
				withMutation={false}
				isLoading={false}
			/>
		</PageLayout>
	);
};

export default WalletPage;
