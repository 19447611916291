import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import {
	CRow,
	CCol,
	CCard,
	CCardHeader,
	CCardBody,
	CLink,
	CButton,
	CModalHeader,
	CModalTitle,
	CModal,
	CModalBody,
	CModalFooter,
	CLabel,
} from '@coreui/react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import FileDownload from 'js-file-download';
import { listLeads, saveLeadsInCSV } from '../../services/BackendService';
import { ILeadListing } from './types';
import { formatDateTime, extractErrorMessage, getFilenameFromHeader, getIconNameForCountry } from '../../helpers';
import { useAppSelector, useAppDispatch } from '../../helpers/customHooks';
import { shallowEqual } from 'react-redux';
import * as Sentry from '@sentry/react';
import { clearLeadsFilters } from '../../actions';
import { leadsFiltersToDTO, getFiltersCount } from './helpers';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import LeadsFilters from './LeadsFilters';
import SalesStatusBadge from '../../components/SalesStatusBadge';
import Error from '../../components/Error';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import FileUploader from '../../components/FileUploader';
import { Permission } from '../../reducers/userReducer';
import { stringify } from 'qs';
import axios from 'axios';
import SearchFilter from '../../components/SearchFilter';
import { useHistory } from 'react-router-dom';
import ExportXLSXButton from './../../components/ExportXLSXButton';
import BulkEditModal from './BulkEditModal';
import CIcon from '@coreui/icons-react';
import toast from 'react-hot-toast';

const LeadsPage = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [uploadCSVShow, setUploadCSVShow] = useState<boolean>(false);
	const [bulkEditShow, setBulkEditShow] = useState<boolean>(false);
	const [search, setSearch] = useQueryParam('filter', StringParam);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const [csvLocal, setCSVLocal] = useState<any>(null);
	const [fileUploadError, setFileUploadError] = useState<string | null>(null);
	const queryClient = useQueryClient();

	const leadsItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_leads_table') ?? 10);
	const [limit, setLimit] = useState<number>(leadsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	if (!page) {
		setPage(1, 'replaceIn');
	}

	const token = useAppSelector((state: any) => state.user.token);
	const roles = useAppSelector((state) => state.user.roles, shallowEqual);
	const permissions = useAppSelector((state) => state.user.permissions);
	const leadsFilters = useAppSelector((state) => state.filters.leadsFilters, shallowEqual);
	const leadsFiltersDTO = leadsFiltersToDTO(leadsFilters);

	const leadsQuery = useQuery(
		['leads', offset, orderBy, leadsFilters, search],
		() => listLeads(limit, offset, orderBy!, leadsFiltersDTO, search!),
		{
			onError: (e: any) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const saveDocumentsMutation = useMutation((csvFile) => saveLeadsInCSV(csvFile), {
		onSuccess: () => {
			toast.success(_t('leads.leads-uploaded-successfully'));
			leadsQuery.refetch();
			setCSVLocal(null);
			setFileUploadError(null);
			setUploadCSVShow(false);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_leads_table', limit.toString());
		await queryClient.invalidateQueries('leads');
		leadsQuery.refetch();
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const resetFilters = () => {
		dispatch(clearLeadsFilters());
	};

	const showUploadCSV = () => {
		setUploadCSVShow(true);
	};

	const hideUploadCSV = () => {
		setCSVLocal(null);
		setFileUploadError(null);
		setUploadCSVShow(false);
	};

	const onRowClicked = React.useCallback(
		(id: string) => {
			history.push(`/leads/${id}`);
		},
		[history]
	);

	const tableFields = React.useMemo(
		() => [
			{ key: 'name', label: _t('global.name'), sorter: false },
			{ key: 'salesPerson', label: _t('leads.sales-person'), sorter: false },
			{ key: 'status', label: _t('global.status'), sorter: false },
			{ key: 'source', label: _t('leads.source'), sorter: false },
			{ key: 'country', label: _t('global.country'), sorter: false },
			{ key: 'createdAt', label: _t('global.created-at'), sorter: true },
			{ key: 'lastModifiedAt', label: _t('leads.last-modified-at'), sorter: true },
		],
		[]
	);

	const getExportLink = (): string => {
		const params: any = { filters: leadsFiltersDTO, search, orderBy };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/leads/export/xlsx?${stringify(params)}`;
	};

	const scopedSlots = React.useMemo(
		() => ({
			name: ({ id, name }: ILeadListing) => (
				<td>
					<CLink onClick={() => onRowClicked(id)}>{name}</CLink>
				</td>
			),
			salesPerson: ({ salesPerson }: ILeadListing) => (
				<td>{salesPerson ? <CLink href={`/customers/${salesPerson.id}`}>{salesPerson.name}</CLink> : '-'}</td>
			),
			status: ({ salesStatus }: ILeadListing) => (
				<td>{salesStatus ? <SalesStatusBadge status={salesStatus} /> : '-'}</td>
			),
			country: (lead: any) => (
				<td>
					{lead.country && getIconNameForCountry(lead.country.toLowerCase()) ? (
						<CIcon name={getIconNameForCountry(lead.country.toLowerCase())!} size="xl" title={lead.countryName} />
					) : (
						lead.country || '-'
					)}
				</td>
			),
			createdAt: ({ createdAt }: ILeadListing) => <td className="text-nowrap">{formatDateTime(createdAt)}</td>,
			lastModifiedAt: ({ lastModifiedAt }: ILeadListing) => (
				<td className="text-nowrap">{lastModifiedAt ? formatDateTime(lastModifiedAt) : '-'}</td>
			),
		}),
		[onRowClicked]
	);

	const storeCSVWrapper = (documents: any) => {
		setCSVLocal(documents);
	};

	const removeCSVWrapper = () => {
		setCSVLocal(null);
	};

	const searchTableData = (value: string) => {
		setPage(1, 'replaceIn');
		setSearch(value, 'replaceIn');
	};

	const trySaveCSV = () => {
		saveDocumentsMutation.mutate(csvLocal);
	};

	if (leadsQuery.isError) {
		return <Error onRetry={leadsQuery.refetch} />;
	}

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const filtersCount = getFiltersCount(leadsFilters);
	const params: any = { token };
	const exampleCSVLink = `${process.env.REACT_APP_API_ROOT}/api/admin/leads/export/example_csv?${stringify(params)}`;

	const handleDownload = async () => {
		try {
			const response = await axios.get(exampleCSVLink, {
				responseType: 'blob',
			});
			FileDownload(response.data, getFilenameFromHeader(response.headers['content-disposition'], 'csv-example.csv'));
		} catch (e) {
			Sentry.captureException(e);
		}
	};

	const onBulkEditOpen = () => {
		setBulkEditShow(true);
	};

	const onBulkEditClose = (refetch?: boolean) => {
		setBulkEditShow(false);
		if (refetch) {
			leadsQuery.refetch();
			setPage(1, 'replaceIn');
		}
	};

	return (
		<PageLayout title={_t('leads.title')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>

										<div className="float-right">
											{permissions.includes(Permission.BULK_EDIT_LEADS) && (
												<CButton
													title="Bulk edit"
													className="mr-2 header-button-export"
													color="success"
													onClick={onBulkEditOpen}
												>
													<CIcon name="cil-note" size="md" />
												</CButton>
											)}
											{permissions.includes(Permission.UPLOAD_LEADS) && (
												<>
													<CButton className="mr-2 header-button-import" onClick={showUploadCSV} />
													<ExportXLSXButton downloadLink={getExportLink()} defaultFilename="leads.xlsx" />
												</>
											)}
										</div>
									</div>
								</div>
								<LeadsFilters show={showFilters} onFilterChanged={onFilterChanged} roles={roles} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={leadsQuery.data?.leads || []}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								loading={false}
								pages={leadsQuery.data?.pages || 0}
								activePage={page || 1}
								pagination
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{uploadCSVShow && (
				<CModal show={uploadCSVShow} onClose={hideUploadCSV}>
					<CModalHeader>
						<CModalTitle>{_t('leads.upload-leads-csv')}</CModalTitle>
					</CModalHeader>
					<CModalBody>
						<CButton onClick={handleDownload} className="float-right button-as-anchor" style={{ paddingBottom: '5px' }}>
							{_t('leads.download-example-csv')}
						</CButton>
						<FileUploader
							allowMultipleFiles={false}
							addFiles={storeCSVWrapper}
							removeFile={removeCSVWrapper}
							validFileTypes={['csv']}
							onError={setFileUploadError}
						/>
						<div className="d-flex align-items-center mb-2">
							<CLabel className="text-danger">{fileUploadError}</CLabel>
						</div>
					</CModalBody>
					<CModalFooter className="modal-footer--alternative">
						<ButtonWithLoader
							isLoading={false}
							onClick={trySaveCSV}
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('global.confirm')}
							disabled={csvLocal === null}
						/>
						<CButton color="light" variant="outline" onClick={hideUploadCSV} disabled={false}>
							{_t('global.cancel')}
						</CButton>
					</CModalFooter>
				</CModal>
			)}
			{permissions.includes(Permission.BULK_EDIT_LEADS) && (
				<BulkEditModal
					leadsPageFilters={leadsFiltersDTO}
					leadsPageSearch={search}
					show={bulkEditShow}
					onClose={onBulkEditClose}
					count={leadsQuery.data?.count || 0}
				/>
			)}
		</PageLayout>
	);
};

export default React.memo(LeadsPage);
