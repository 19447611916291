import React from 'react';
import _t from 'counterpart';
import { useAppSelector, useAppDispatch } from '../../helpers/customHooks';
import { CButton, CForm, CFormGroup, CCol, CRow, CLabel } from '@coreui/react';
import { ICoinsFilters as Filters, IDateRange } from '../../reducers/filtersReducer';
import { setCoinsFilters } from '../../actions';
import DateRangePicker from '../../components/DateRangePicker';

interface IProps {
	show: boolean;
	onFiltersChange: () => void;
}

export const coinsFiltersToDTO = (filters: Filters) => {
	return {
		startDate: filters.createdAt?.startDate?.toISOString(),
		endDate: filters.createdAt?.endDate?.toISOString(),
	};
};

const CoinsFilters = ({ show, onFiltersChange }: IProps) => {
	const dispatch = useAppDispatch();

	const coinsFilters = useAppSelector((state) => state.filters.coinsFilters);

	const setFilters = (filters: Partial<Filters>) => {
		dispatch(setCoinsFilters(filters));
		onFiltersChange();
	};

	const handleOpenedAtRange = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({ createdAt: range });
		}
	};

	const resetCreatedAtRange = () => {
		setFilters({ createdAt: undefined });
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0">{_t.translate('coins.createdAt')}</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetCreatedAtRange}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<DateRangePicker
							initialStartDate={coinsFilters.createdAt?.startDate ?? null}
							initialEndDate={coinsFilters.createdAt?.endDate ?? null}
							onRangeChanged={handleOpenedAtRange}
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default CoinsFilters;
