import React from 'react';
import { CAlert } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { loadBalanceReport } from '../../services/BackendService';
import { formatCurrency } from '../../helpers';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import PaginationTable, { ISorter } from '../../components/PaginationTable';

const ReportResultsBalance = ({ filters, onFetch }: { filters: any; onFetch: Function }) => {
	const from = filters.range?.startDate;
	const to = filters.range?.endDate;

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy = 'accountId|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const { isLoading, error, data } = useQuery<any, Error>(
		['reports', filters, page, orderBy],
		() => {
			return loadBalanceReport(from, to, limit, offset, orderBy!, filters.excludeZero || false);
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data: any) => {
				if (data?.report?.data) {
					onFetch(data.report.data.length);
				}
			},
			onError: () => onFetch(0),
		}
	);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const currency = data?.report?.currency;
	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';
	const pages = data?.report?.count > 0 ? Math.ceil(data?.report?.count / limit) : 0;

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const orderBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		setOrderBy(orderBy, 'replaceIn');
		setPage(1, 'replaceIn');
	};

	if (error) {
		return <CAlert color="danger">{error.toString()}</CAlert>;
	}

	return (
		<>
			<CAlert color="warning">
				Note that the selected range applies to Profit/Loss only. Balance, credit, equity are the current values.
			</CAlert>
			<PaginationTable
				tableFields={[
					{ key: 'accountId', label: _t.translate('reports.balance.accountId') },
					{ key: 'customer', label: _t.translate('reports.balance.customer') },
					{ key: 'balance', label: _t.translate('reports.balance.balance') },
					{ key: 'credit', label: _t.translate('reports.balance.credit') },
					{ key: 'profitLoss', label: _t.translate('reports.balance.profitLoss') },
					{ key: 'equity', label: _t.translate('reports.balance.equity') },
				]}
				scopedSlots={{
					accountId: (row: any) => <td className="text-nowrap">{row.accountId}</td>,
					customer: (row: any) => {
						const { customer } = row;
						if (!customer) {
							return <td className="text-nowrap">n/a</td>;
						}

						return (
							<td className="text-nowrap">
								<a href={`/customers/${customer.id}`}>{customer.name}</a>
							</td>
						);
					},
					balance: (row: any) => <td className="text-nowrap">{formatCurrency(row.balance, currency)}</td>,
					credit: (row: any) => <td className="text-nowrap">{formatCurrency(row.credit, currency)}</td>,
					profitLoss: (row: any) => <td className="text-nowrap">{formatCurrency(row.profitLoss, currency)}</td>,
					equity: (row: any) => <td className="text-nowrap">{formatCurrency(row.equity, currency)}</td>,
				}}
				data={data?.report?.data || []}
				loading={isLoading}
				pagination
				pages={pages}
				onPageChanged={onPageChanged}
				activePage={page || 1}
				onSorterChanged={onSorterChanged}
				sorter={{ column, asc }}
			/>
		</>
	);
};

export default ReportResultsBalance;
