import React, { useMemo } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { useParams, useHistory } from 'react-router-dom';
import { CRow, CCol, CCard, CCardHeader, CCardTitle, CCardBody, CDataTable } from '@coreui/react';
import Detail from '../../components/micro/Detail';
import { useQuery } from 'react-query';
import { fetchSalesDesk } from '../../services/BackendService';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import { formatCurrency } from '../../helpers';

const SalesDeskPage = () => {
	const params = useParams();

	const { id } = params as any;
	const listSalesDeskQuery = useQuery(['sales-desks', id], () => fetchSalesDesk(id));

	const history = useHistory();

	const onRowClicked = (user: any) => {
		history.push(`/customers/${user.id}`);
	};

	const fields = useMemo(() => [{ key: 'name', label: _t('global.name') }], []);

	if (listSalesDeskQuery.isLoading || listSalesDeskQuery.isIdle) {
		return <Loading />;
	}

	if (listSalesDeskQuery.isError) {
		return <Error onRetry={listSalesDeskQuery.refetch} />;
	}

	const {
		salesDeskName,
		salesAgents,
		totalGrossDeposits,
		totalNetDeposits,
		grossDepositsCurrentMonth,
		netDepositsCurrentMonth,
		grossDepositsLastMonth,
		netDepositsLastMonth,
	} = listSalesDeskQuery.data;

	return (
		<PageLayout title={_t('sales-desks.sales-desk')}>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center">
							<CCardTitle>{_t('global.details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<Detail title={_t('sales-desks.name')}>{salesDeskName}</Detail>
										<Detail title={_t('sales-desks.gross-deposit')}>{formatCurrency(totalGrossDeposits)}</Detail>
										<Detail title={_t('sales-desks.net-deposit')}>{formatCurrency(totalNetDeposits)}</Detail>
										<Detail title={_t('sales-desks.gross-deposits-current-month')}>
											{formatCurrency(grossDepositsCurrentMonth)}
										</Detail>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<Detail title={_t('sales-desks.gross-deposits-last-month')}>
											{formatCurrency(grossDepositsLastMonth)}
										</Detail>
										<Detail title={_t('sales-desks.net-deposits-current-month')}>
											{formatCurrency(netDepositsCurrentMonth)}
										</Detail>
										<Detail title={_t('sales-desks.net-deposits-last-month')}>
											{formatCurrency(netDepositsLastMonth)}
										</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('sales-desks.sales-people')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								items={salesAgents || []}
								fields={fields}
								sorter
								striped
								clickableRows
								onRowClick={onRowClicked}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(SalesDeskPage);
