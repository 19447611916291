import React, { useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { CRow, CCol, CCard, CCardHeader, CButton, CCardBody, CLink, CBadge } from '@coreui/react';
import PaginationTable from '../../components/PaginationTable';
import SearchFilter from '../../components/SearchFilter';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { clearAuditLogsFilters } from '../../actions';
import { extractErrorMessage, formatDateTime, getFiltersCount, getPagesFromCount } from '../../helpers';
import { fetchActivites } from '../../services/BackendService';
import { useQuery, useQueryClient } from 'react-query';
import { AffectedObjectType, IAdminActivity } from './types';
import CustomerRoleBadge from '../customers/CustomerPage/CustomerRoleBadge';
import { UserRole } from 'src/reducers/userReducer';
import AuditLogsFilter from './AuditLogsFilter';
import { shallowEqual } from 'react-redux';
import { toAuditLogsFilters } from './helpers';
import AffectedTypeBadge from './AffectedTypeBadge';
import toast from 'react-hot-toast';

const AuditLogsPage = () => {
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);

	const customersItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_audit_logs_table') ?? 10);
	const [limit, setLimit] = useState<number>(customersItemsPerPageFromLocalStorage);

	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const activeFilters = useAppSelector((state) => state.filters.auditLogsFilters, shallowEqual);
	const filtersDTO = toAuditLogsFilters(activeFilters);

	const logsQuery = useQuery(
		['audit-logs', offset, filter, filtersDTO],
		() => fetchActivites(limit, offset, filter, filtersDTO),
		{
			onError: (error: any) => {
				toast.error(extractErrorMessage(error));
			},
		}
	);

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_audit_logs_table', limit.toString());
		await queryClient.invalidateQueries('audit-logs');
		logsQuery.refetch();
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const tableFields = useMemo(
		() => [
			{ key: 'action', label: _t('action.action'), sorter: false },
			{ key: 'actingCustomer', label: _t('audit-logs.acting-customer'), sorter: false },
			{ key: 'role', label: _t('audit-logs.acted-as'), sorter: false },
			{ key: 'createdAt', label: _t('global.created-at'), sorter: false },
			{ key: 'affectedObjectId', label: _t('audit-logs.affected'), sorter: false },
			{ key: 'type', label: _t('audit-logs.affected-type'), sorter: false },
		],
		[]
	);

	const getAffectedLink = (id: string, type: AffectedObjectType) => {
		if (type === AffectedObjectType.Customer) {
			return `/customers/${id}`;
		}
		if (type === AffectedObjectType.Lead) {
			return `/leads/${id}`;
		}
		if (type === AffectedObjectType.Instrument) {
			return `/instruments/${id}`;
		}
		if (type === AffectedObjectType.CurrencyConversion) {
			return '/currency-conversion';
		}
		if (type === AffectedObjectType.CommissionLedgerRecord) {
			return `/commission-ledgers/${id}`;
		}
		if (type === AffectedObjectType.CommissionPayoutRequest) {
			return `/payout-requests/${id}`;
		}

		if (type === AffectedObjectType.Position) {
			return `/positions/${id}`;
		}

		if (type === AffectedObjectType.Order) {
			return `/orders/${id}`;
		}
	};

	const scopedSlots = useMemo(
		() => ({
			actingCustomer: ({ actingCustomerId, actingCustomerName }: IAdminActivity) => (
				<td>
					<CLink href={`/customers/${actingCustomerId}`}>{actingCustomerName}</CLink>
				</td>
			),
			role: ({ role }: IAdminActivity) => (
				<td>
					<CustomerRoleBadge role={role as UserRole} />
				</td>
			),
			createdAt: ({ createdAt }: IAdminActivity) => <td>{formatDateTime(createdAt)}</td>,
			affectedObjectId: ({ displayName, affectedObjectId, type, affectedObjectFound }: IAdminActivity) => (
				<td>
					{affectedObjectFound && (
						<CLink href={getAffectedLink(affectedObjectId, type)}>{displayName ?? affectedObjectId}</CLink>
					)}
					{!affectedObjectFound && (
						<div className="d-flex align-items-center justify-content-between">
							{displayName && <span>{displayName}</span>}
							<CBadge color="danger">{_t('global.deleted')}</CBadge>
						</div>
					)}
				</td>
			),
			type: ({ type }: IAdminActivity) => (
				<td>
					<AffectedTypeBadge type={type} />
				</td>
			),
		}),
		[]
	);

	const resetFilters = () => {
		dispatch(clearAuditLogsFilters());
	};

	const pagesCount = getPagesFromCount(logsQuery.data?.count, limit);
	const filtersCount = getFiltersCount(activeFilters);

	return (
		<PageLayout title={_t('audit-logs.title')}>
			<CRow>
				<CCol>
					<CCard className="card-overflow-visible">
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} placeholder={_t('audit-logs.search-by-acting-customer')} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
									</div>
								</div>
								<AuditLogsFilter show={showFilters} onFiltersChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								scopedSlots={scopedSlots}
								onPageChanged={onPageChanged}
								data={logsQuery.data?.activities || []}
								pages={pagesCount || 0}
								tableFields={tableFields}
								pagination
								loading={logsQuery.isLoading}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(AuditLogsPage);
