import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQueryClient } from 'react-query';
import { deleteConversion } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IConversion } from './types';
import toast from 'react-hot-toast';

interface IProps {
	conversion: IConversion;
	hideModal: () => void;
}

export const DeleteConversionModal = ({ conversion, hideModal }: IProps) => {
	const queryClient = useQueryClient();

	const deleteConversionMutation = useMutation((conversionId: number) => deleteConversion(conversionId), {
		onSuccess: async () => {
			toast.success(_t('currencyConversions.conversion-deleted'));
			await queryClient.invalidateQueries('conversions');
			hideModal();
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const loading = deleteConversionMutation.isLoading;

	const deleteConversionCall = () => {
		deleteConversionMutation.mutate(conversion.id);
	};

	return (
		<CModal size="sm" show onClose={hideModal} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>
					{_t('currencyConversions.delete-modal-title', { target: conversion.target, source: conversion.source })}
				</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{deleteConversionMutation.isError && (
					<span className="text-danger">{extractErrorMessage(deleteConversionMutation.error)}</span>
				)}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={loading}
					onClick={deleteConversionCall}
					buttonColor="danger"
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
					className="mr-2"
				/>
				<CButton disabled={loading} color="light" variant="outline" onClick={hideModal}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};
