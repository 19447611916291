import { ICustomersFilters } from '../reducers/filtersReducer';

export interface ICustomersFiltersDTO {
	country?: string;
	group?: string;
	lastActivityStart?: string;
	lastActivityEnd?: string;
	registrationStart?: string;
	registrationEnd?: string;
	selectedStatuses: Array<string>;
	selectedKycStatuses: Array<string>;
	sources: Array<string>;
	depositCount?: string;
	salesStatus?: string;
	positions?: string;
	activity?: string;
	riskStatus?: string;
	retentionAgents: Array<string>;
	conversionAgents: Array<string>;
}

export function customerFiltersToDTO(filters: ICustomersFilters): ICustomersFiltersDTO {
	return {
		country: filters.country?.value,
		group: filters.group?.value,
		lastActivityStart: filters.lastActivityRange?.startDate?.toISOString(),
		lastActivityEnd: filters.lastActivityRange?.endDate?.toISOString(),
		registrationStart: filters.registrationRange?.startDate?.toISOString(),
		registrationEnd: filters.registrationRange?.endDate?.toISOString(),
		selectedStatuses: filters.selectedStatuses.map((status) => status.value),
		selectedKycStatuses: filters.selectedKycStatuses.map((status) => status.value),
		sources: filters.sources.filter((s) => s.enabled).map((s) => s.value),
		depositCount: filters.depositCount?.value,
		salesStatus: filters.salesStatus?.value,
		positions: filters.positions?.value,
		activity: filters.activity?.value,
		riskStatus: filters.riskStatus?.value,
		retentionAgents: filters.retentionAgents.filter((option) => option.enabled).map((option) => option.value),
		conversionAgents: filters.conversionAgents.filter((option) => option.enabled).map((option) => option.value),
	};
}
