import React from 'react';
import { ICommissionModelLevel } from './types';
import { CLabel, CInput, CButton, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';

interface IProps {
	index: number;
	commissionLevel: ICommissionModelLevel;
	handleInputChange: (index: number, updatedLevel: ICommissionModelLevel) => void;
	onLevelRemoved: (index: number) => void;
}

const CommissionLevelForm = ({ index, commissionLevel, handleInputChange, onLevelRemoved }: IProps) => {
	const { level, cpaAmount, volumeCommissionAmount, profitSharePercentage } = commissionLevel;

	const onLevelChanged = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const value = target.value;

		const newCommissionLevel = { ...commissionLevel };
		newCommissionLevel.level = value ? Number(value) : null;
		handleInputChange(index, newCommissionLevel);
	};

	const onCpaAmountChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const value = target.value;

		const newCommissionLevel = { ...commissionLevel };
		newCommissionLevel.cpaAmount = value ? Number(value) : null;
		newCommissionLevel.cpaCurrency = 'USD';
		handleInputChange(index, newCommissionLevel);
	};

	const onVolumeCommissionAmountChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const value = target.value;

		const newCommissionLevel = { ...commissionLevel };
		newCommissionLevel.volumeCommissionAmount = value ? Number(value) : null;
		newCommissionLevel.volumeCommissionCurrency = 'USD';
		handleInputChange(index, newCommissionLevel);
	};

	const onProfitPercentage = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const value = target.value;

		const newCommissionLevel = { ...commissionLevel };
		newCommissionLevel.profitSharePercentage = value ? Number(value) : null;
		handleInputChange(index, newCommissionLevel);
	};

	const removeLevel = () => {
		onLevelRemoved(index);
	};

	return (
		<CInputGroup className="d-flex flex-row align-items-end mt-2" style={{ gap: '10px' }}>
			<div className="d-flex flex-row align-items-end">
				<div className="mr-1">
					<CLabel htmlFor="level">{_t('global.level')}</CLabel>
					<CInput type="number" name="level" placeholder="Level" value={level?.toString()} onChange={onLevelChanged} />
				</div>
				<div>
					<CLabel htmlFor="cpaAmount">{_t('commissions.cost-per-action')}</CLabel>
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput
							type="number"
							name="cpaAmount"
							placeholder="2.55"
							value={cpaAmount?.toString()}
							onChange={onCpaAmountChange}
						/>
					</CInputGroup>
				</div>
			</div>

			<div className="d-flex flex-row align-items-end ">
				<div className="mr-1">
					<CLabel htmlFor="profitSharePercentage">{_t('commissions.profit-share-percentage')}</CLabel>
					<CInput
						type="number"
						name="profitSharePercentage"
						placeholder="2.55"
						value={profitSharePercentage?.toString()}
						onChange={onProfitPercentage}
					/>
				</div>
				<div>
					<CLabel htmlFor="volumeCommissionAmount">{_t('commissions.volume-commission-amount')}</CLabel>
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput
							type="number"
							name="volumeCommissionAmount"
							placeholder="2.55"
							value={volumeCommissionAmount?.toString() || ''}
							onChange={onVolumeCommissionAmountChange}
						/>
					</CInputGroup>
				</div>
			</div>
			<CButton size="lg" color="danger" onClick={removeLevel}>
				<CIcon name="cil-trash" size="sm" />
			</CButton>
		</CInputGroup>
	);
};

export default CommissionLevelForm;
