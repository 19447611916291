export const cilClipboard = [
  '40 40',
  `
<path d="M33.75 4.375H29.375V6.875H33.125V36.25H6.875V6.875H10.625V4.375H6.25C5.7529 4.37558 5.27632 4.57331 4.92481 4.92481C4.57331 5.27632 4.37558 5.7529 4.375 6.25V36.875C4.37558 37.3721 4.57331 37.8487 4.92481 38.2002C5.27632 38.5517 5.7529 38.7494 6.25 38.75H33.75C34.2471 38.7494 34.7237 38.5517 35.0752 38.2002C35.4267 37.8487 35.6244 37.3721 35.625 36.875V6.25C35.6244 5.7529 35.4267 5.27632 35.0752 4.92481C34.7237 4.57331 34.2471 4.37558 33.75 4.375Z" />
<path d="M15 10.9375H25C25.4971 10.9369 25.9737 10.7392 26.3252 10.3877C26.6767 10.0362 26.8744 9.5596 26.875 9.0625V1.25H13.125V9.0625C13.1256 9.5596 13.3233 10.0362 13.6748 10.3877C14.0263 10.7392 14.5029 10.9369 15 10.9375ZM15.625 3.75H24.375V8.4375H15.625V3.75Z" />
<path d="M25.375 15H11V17.5H25.375V15Z" />
<path d="M29.75 21H11V23.5H29.75V21Z" />
<path d="M29.75 26.625H11V29.125H29.75V26.625Z" />
  `,
];
