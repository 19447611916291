export interface IOrder {
	averagePrice: string;
	executedAt: string | Date;
	externalId: string;
	filledQuantity: string;
	id: number;
	placedAt: string | Date;
	positionId: number;
	quantity: string;
	rejectReason: string;
	symbol: string;
	requestPrice: string;
	notionalValue: string | null;
	notionalValueInUSD: string | null;
	status: StatusType;
	type: string;
	limitPrice: string | null;
	stopPrice: string | null;
	takeProfit: string | null;
	stopLoss: string | null;
	side: 'buy' | 'sell';
	iconUrl?: string;
	userIpAddress: string | null;
	trailingStopLossPct: string | null;
}

export interface IOrdersQuery {
	count: number;
	orders: Array<IOrder>;
}

export enum StatusType {
	new = 'new',
	filled = 'filled',
	rejected = 'rejected',
	timeout = 'timeout',
	cancelled = 'cancelled',
	pending = 'pending',
	accepted = 'accepted',
}

export enum OrderType {
	market = 'market',
	limit = 'limit',
	stop = 'stop',
}

export interface IOrderQueryParams {
	limit: number;
	offset: number;
	search: string;
	statuses?: Array<StatusType>;
	types?: Array<OrderType>;
	accountId?: string | null;
	symbols?: Array<string>;
}
