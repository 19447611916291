import React, { useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchSalesCommunications, fetchSalesPerson } from '../../services/BackendService';
import { useAppSelector } from '../../helpers/customHooks';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardHeader,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react';
import CommunicationsTable from './CustomerPage/CommunicationsTable';
import { Permission } from '../../reducers/userReducer';
import { shallowEqual } from 'react-redux';
import AssignSalesDeskModal from './CustomerPage/AssignSalesDeskModal';
import PaginationTable from '../../components/PaginationTable';
import toast from 'react-hot-toast';

const SalesPeopleDetailsPage = () => {
	const [assignSalesDeskShow, setAssignSalesDeskShow] = useState<boolean>(false);

	const history = useHistory();
	const params = useParams();
	const { id } = params as { id: string };

	const [page, setPage] = useState<number>(1);
	const [customerUnderPage, setCustomersUnderPage] = useState<number>(1);

	const customersUnderLimit = 10;
	const customersUnderOffset =
		Number(customerUnderPage) > 0 ? Number(customerUnderPage) * customersUnderLimit - customersUnderLimit : 0;

	const limit = 15;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const userState = useAppSelector((state) => state.user, shallowEqual);

	const loadSalesPerson = useQuery(
		['sales-person', id, customersUnderLimit, customersUnderOffset],
		() => fetchSalesPerson(id, customersUnderLimit, customersUnderOffset),
		{
			onError: (e: unknown) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const communicationsQuery = useQuery(['sales-person-communications', id, offset], () =>
		fetchSalesCommunications(id, limit, offset)
	);

	const fields = React.useMemo(
		() => [
			{ key: 'date', label: _t('global.date'), sorter: false },
			{ key: 'to', label: _t('sales-people.recipient'), sorter: false },
			{ key: 'type', label: _t('global.type'), sorter: false },
			{ key: 'detail', label: _t('global.details'), sorter: false },
		],
		[]
	);

	const tableFields = useMemo(
		() => [
			{
				key: 'name',
				label: _t('customers.columns.name'),
				sorter: false,
			},
			{
				key: 'level',
				label: _t('customers.columns.level'),
				sorter: false,
			},
			{
				key: 'totalDeposit',
				label: _t('customers.columns.depositTotal'),
				sorter: false,
			},
			{
				key: 'tradingVolume',
				label: _t('customers.columns.totalTradingVolume'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			name: (salesPerson: any) => <td>{salesPerson.name}</td>,
			totalDeposit: (salesPerson: any) => <td>{formatCurrency(salesPerson.totalDeposit)}</td>,
			tradingVolume: (salesPerson: any) => <td>{salesPerson.tradingVolume}</td>,
		};
	}, []);

	const onRowClicked = (user: { id: string }) => {
		history.push(`/customers/${user.id}`);
	};

	const showSalesDeskModal = () => {
		setAssignSalesDeskShow(true);
	};

	const hideSalesDeskModal = () => {
		setAssignSalesDeskShow(false);
	};

	if (loadSalesPerson.isLoading || loadSalesPerson.isIdle) {
		return <Loading />;
	}

	if (loadSalesPerson.isError) {
		return <NotFound />;
	}

	const { customer, customers, pages: customerUnderPages } = loadSalesPerson.data;
	const { data } = communicationsQuery;
	const pages = data?.count === undefined ? 0 : Math.ceil(Number(data.count) / limit);

	return (
		<PageLayout
			title=""
			titleAppend={
				<>
					<h2 className="float-left c-main__title">
						<Link to={`/customers/${customer.id}`}>{customer.name}</Link>
					</h2>
					<div className="d-flex justify-content-between float-right mb-2">
						<div className="d-flex flex-nowrap flex-row-reverse">
							<CDropdown className="float-right">
								<CDropdownToggle caret color="primary">
									{_t('customer.sales-agent-options')}
								</CDropdownToggle>
								<CDropdownMenu>
									{userState.permissions.includes(Permission.MANAGE_SALES_DESK) && (
										<CDropdownItem onClick={showSalesDeskModal} name="assignSalesDeskShow">
											{_t('sales-desks.assign-sales-desk')}
										</CDropdownItem>
									)}
								</CDropdownMenu>
							</CDropdown>
						</div>
					</div>
				</>
			}
		>
			<CRow>
				<CCol>
					<CCard className="card card--listing">
						<CCardBody className="card__holder">
							<CCardHeader className="card__header">
								<div className="card__info">
									<h4 className="card__title">{_t('customers.title')}</h4>
								</div>
							</CCardHeader>
							<PaginationTable
								data={customers}
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								clickableRows
								onRowClicked={onRowClicked}
								loading={loadSalesPerson.isLoading}
								activePage={customerUnderPage}
								onPageChanged={setCustomersUnderPage}
								pages={customerUnderPages}
								pagination
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CCard>
						<CommunicationsTable
							data={communicationsQuery.data?.communications || []}
							fields={fields}
							isLoading={communicationsQuery.isLoading}
							pages={pages}
							page={page}
							onPageChanged={setPage}
						/>
					</CCard>
				</CCol>
			</CRow>

			<AssignSalesDeskModal show={assignSalesDeskShow} onClose={hideSalesDeskModal} customerId={customer.id} />
		</PageLayout>
	);
};

export default React.memo(SalesPeopleDetailsPage);
