import {
	CRow,
	CCol,
	CCard,
	CCardTitle,
	CCardHeader,
	CForm,
	CFormGroup,
	CInput,
	CLabel,
	CSelect,
	CCardBody,
	CCardFooter,
} from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import _t from 'counterpart';
import countries from '../../../vendor/ftt_countries.json';
import PageLayout from '../../../components/PageLayout';
import { extractErrorMessage, findErrorFromValidation } from '../../../helpers';
import { useParams } from 'react-router-dom';
import { loadCustomer, loadGroups, updateCustomer } from '../../../services/BackendService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import { ISelectOption } from '../../../components/ToggleSelect';
import { UserRole } from '../../../reducers/userReducer';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../../helpers/customHooks';
import { shallowEqual } from 'react-redux';
import SelectMultipleFilter from '../SelectMultipleFilter';

interface IState {
	fullname: string | undefined;
	email: string | undefined;
	phone: string | undefined;
	country: string | undefined;
	groups: Array<number>;
}

interface IEdit {
	roles: string[];
	fullname: string | undefined;
	email: string | undefined;
	mobilenumber: string | undefined;
	country: string | undefined;
}

const CustomerEditPage = () => {
	const [selectedRoles, setSelectedRoles] = useState<Array<ISelectOption>>([]);
	const [selectedGroups, setSelectedGroups] = useState<Array<ISelectOption>>([]);
	const [formState, setFormState] = useState<IState>({
		fullname: undefined,
		email: undefined,
		phone: undefined,
		country: undefined,
		groups: [],
	});

	const options = useMemo(() => Object.keys(UserRole), []);

	const userState = useAppSelector((state) => state.user, shallowEqual);
	const { id } = useParams() as any;
	const history = useHistory();

	const showError = (errorMessage: string) => {
		const error = extractErrorMessage(errorMessage);
		toast.error(error);
	};

	const queryClient = useQueryClient();
	const loadCustomerQuery = useQuery(['customer', id], () => loadCustomer(id), {
		onError: (e: any) => {
			showError(e);
		},
	});

	const groupsQuery = useQuery(['customer-load-groups'], () => loadGroups(), {
		onError: (e: any) => {
			showError(e);
		},
	});
	const roles = selectedRoles.filter((r) => r.enabled).map((r) => r.value.toUpperCase());
	const groups = selectedGroups.filter((g) => g.enabled).map((g) => Number(g.value));
	const editCustomerDTO = {
		...formState,
		roles,
		groups,
	};

	const updateCustomerMutation = useMutation(
		['updated-user'],
		(customer: { customerId: string; editCustomerDTO: IEdit }) =>
			updateCustomer(customer.customerId, customer.editCustomerDTO),
		{
			onSuccess: () => {
				toast.success(_t('customer.customer-successful-update'));
				queryClient.invalidateQueries(['customer', id]);
				history.push(`/customers/${id}`);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	useEffect(() => {
		if (loadCustomerQuery.isSuccess && loadCustomerQuery.data) {
			const { name, email, mobilenumber, country, roles, groups } = loadCustomerQuery.data;
			setFormState({
				fullname: name,
				email,
				phone: mobilenumber,
				country: country,
				groups,
			});
			const foundRoles = options.map((o) => {
				if (o === 'GroupAdmin') {
					return {
						value: 'GROUP_ADMIN',
						label: o,
						enabled: roles.includes('GROUP_ADMIN' as UserRole),
					};
				} else {
					return {
						value: o,
						label: o,
						enabled: roles.includes(o.toUpperCase() as UserRole),
					};
				}
			});

			setSelectedRoles(foundRoles);
		}
	}, [loadCustomerQuery.isSuccess, loadCustomerQuery.data, options]);

	useEffect(() => {
		if (loadCustomerQuery.isSuccess && groupsQuery.isSuccess && groupsQuery.data) {
			const { groups } = loadCustomerQuery.data;
			const userGroups = groupsQuery.data.map((group) => {
				const enabled = groups?.includes(group.id) || false;
				return {
					value: group.id.toString(),
					label: group.name,
					enabled,
				};
			});
			setSelectedGroups(userGroups);
		}
	}, [groupsQuery.isSuccess, loadCustomerQuery.isSuccess, groupsQuery.data]);

	const tryUpdateCustomer = (e: React.FormEvent) => {
		e.preventDefault();
		const { phone, ...rest } = editCustomerDTO;
		const editCustomer = { mobilenumber: phone, ...rest };
		updateCustomerMutation.mutate({ customerId: id, editCustomerDTO: editCustomer });
	};

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		if (name) {
			setFormState({ ...formState, [name]: target.value });
		}
	};

	const onOptionsChanged = (options: ISelectOption) => {
		const filters = [...selectedRoles];
		const current = filters.find((option: ISelectOption) => option.value === options.value);
		if (current) {
			current.enabled = !current.enabled;
		}

		setSelectedRoles(filters);
	};

	const onRolesAllChanged = (checked: boolean) => {
		const roles = [...selectedRoles];
		roles.forEach((option: ISelectOption) => (option.enabled = checked));
		setSelectedRoles(roles);
	};

	const onGroupsOptionsChanged = (options: ISelectOption) => {
		const filters = [...selectedGroups];
		const current = filters.find((option: ISelectOption) => option.value === options.value);
		if (current) {
			current.enabled = !current.enabled;
		}

		setSelectedGroups(filters);
	};

	const onGroupsAllChanged = (checked: boolean) => {
		const groups = [...selectedGroups];
		groups.forEach((option: ISelectOption) => (option.enabled = checked));
		setSelectedGroups(groups);
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateCustomerMutation.error, paramName);
	};

	if (loadCustomerQuery.isLoading || loadCustomerQuery.isIdle) {
		return <Loading />;
	}

	if (loadCustomerQuery.isError) {
		return <Error onRetry={loadCustomerQuery.refetch} />;
	}

	const { name, mobilenumber } = loadCustomerQuery.data;

	return (
		<PageLayout title={name || mobilenumber}>
			<CRow>
				<CCol>
					<CForm onSubmit={tryUpdateCustomer}>
						<CCard style={{ overflow: 'visible' }}>
							<CCardHeader>
								<CCardTitle>{_t('edit-customer.title')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<CFormGroup>
									<CLabel htmlFor="fullname">{_t('edit-customer.edit-name')}</CLabel>
									<CInput
										type="text"
										id="fullname"
										name="fullname"
										autoComplete="name"
										value={formState?.fullname || ''}
										onChange={handleInputChange}
									/>
									{findError('fullname') && <CLabel className="text-danger">{findError('fullname')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="email">{_t('edit-customer.edit-email')}</CLabel>
									<CInput
										type="text"
										id="email"
										name="email"
										autoComplete="email"
										value={formState?.email || ''}
										onChange={handleInputChange}
									/>
									{findError('email') && <CLabel className="text-danger">{findError('email')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="mobilenumber">{_t('edit-customer.edit-mobilenumber')}</CLabel>
									<CInput
										type="text"
										id="mobilenumber"
										name="phone"
										autoComplete="tel"
										value={formState?.phone || ''}
										onChange={handleInputChange}
									/>
									{findError('mobilenumber') && <CLabel className="text-danger">{findError('mobilenumber')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="country">{_t('edit-customer.edit-country')}</CLabel>
									<CSelect name="country" value={formState?.country || ''} onChange={handleInputChange}>
										{countries.map((option) => {
											return (
												<option key={option.name} value={option['alpha-2']}>
													{option.name}
												</option>
											);
										})}
									</CSelect>
									{findError('country') && <CLabel className="text-danger">{findError('country')}</CLabel>}
								</CFormGroup>
								{userState.roles.includes(UserRole.Admin) && (
									<CFormGroup>
										<CLabel htmlFor="roles">{_t('customer.roles')}</CLabel>
										<SelectMultipleFilter
											data={selectedRoles}
											onChange={onOptionsChanged}
											onAllChange={onRolesAllChanged}
										/>
										{findError('roles') && <CLabel className="text-danger">{findError('roles')}</CLabel>}
									</CFormGroup>
								)}
								{userState.roles.includes(UserRole.Admin) &&
									roles.includes(UserRole.GroupAdmin) &&
									groupsQuery.isSuccess && (
										<CFormGroup>
											<CLabel htmlFor="groups">{_t('customer.admin-groups')}</CLabel>
											<SelectMultipleFilter
												data={selectedGroups}
												onChange={onGroupsOptionsChanged}
												onAllChange={onGroupsAllChanged}
											/>
											{findError('groups') && <CLabel className="text-danger">{findError('groups')}</CLabel>}
										</CFormGroup>
									)}
							</CCardBody>
							<CCardFooter>
								<ButtonWithLoader
									isLoading={updateCustomerMutation.isLoading}
									type="submit"
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t.translate('global.update')}
									className="mr-2"
								/>
							</CCardFooter>
						</CCard>
					</CForm>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(CustomerEditPage);
