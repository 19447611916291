import React from 'react';
import { CInput } from '@coreui/react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import _t from 'counterpart';

interface IProps {
	onSearch: (value: string) => void;
	placeholder?: string;
}

const SearchFilter = ({ onSearch, placeholder }: IProps) => {
	const searchInput = (e: React.FormEvent<any>) => {
		const { value } = e.target as HTMLInputElement | HTMLSelectElement;
		onSearch(value);
	};

	const { search } = useLocation();
	const { filter } = parse(search.replace(/^\?/, ''));
	const searchValue = filter?.toString();

	return (
		<CInput
			className="filters-header-search"
			placeholder={placeholder ?? _t('global.search-something')}
			value={searchValue || ''}
			onChange={searchInput}
		/>
	);
};

export default SearchFilter;
