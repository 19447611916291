import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CDataTable, CButton, CBadge } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useQuery, useMutation } from 'react-query';
import { loadCountries, toggleCountryAvailability } from '../../services/BackendService';
import ConfirmationModal from '../../components/ConfirmationModal';
import { extractErrorMessage } from '../../helpers';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import toast from 'react-hot-toast';

interface ICountry {
	name: string;
	isoCode: string;
	allowForRegistration: boolean;
}

const CountriesPage = () => {
	const [toggleCountry, setToggleCountry] = useState<ICountry | null>(null);
	const [tableFilter, setTableFilter] = useState<string>('');

	const countriesQuery = useQuery(['countries-list'], () => loadCountries());

	const enableDisableCountryMutation = useMutation(
		['enable-disable-country'],
		() => toggleCountryAvailability(toggleCountry!.isoCode, !toggleCountry!.allowForRegistration),
		{
			onSuccess: () => {
				countriesQuery.refetch();
			},
			onError: (error) => {
				toast.error(extractErrorMessage(error));
			},
		}
	);

	const enabled = toggleCountry?.allowForRegistration;

	const hideCountryToggle = () => {
		setToggleCountry(null);
	};

	const onErrorRetry = () => {
		countriesQuery.refetch();
	};

	if (countriesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('sidebar.countries')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<CDataTable
								fields={[
									{ key: 'name', label: _t.translate('global.name'), sorter: true },
									{ key: 'isoCode', label: _t.translate('countries.country-code'), sorter: false },
									{
										key: 'allowForRegistration',
										label: _t.translate('countries.allow-for-registration'),
										sorter: true,
									},
									{ key: 'actions', label: _t.translate('global.actions'), sorter: false },
								]}
								scopedSlots={{
									allowForRegistration: (country: ICountry) => (
										<td>
											<CBadge color={country.allowForRegistration ? 'success' : 'danger'}>
												{country.allowForRegistration ? 'ENABLED' : 'DISABLED'}
											</CBadge>
										</td>
									),
									actions: (country: ICountry) => (
										<td>
											<CButton size="sm" className="mr-1" color="primary" onClick={() => setToggleCountry(country)}>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
										</td>
									),
								}}
								items={countriesQuery.data || []}
								loading={countriesQuery.isLoading}
								sorterValue={{ column: 'name', asc: true }}
								tableFilter
								tableFilterValue={tableFilter}
								onTableFilterChange={setTableFilter}
								pagination
								itemsPerPage={10}
								sorter
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<ConfirmationModal
				id={toggleCountry?.name || ''}
				show={Boolean(toggleCountry)}
				hidePanel={hideCountryToggle}
				onConfirm={enableDisableCountryMutation.mutate}
				title={enabled ? _t.translate('countries.disable-country') : _t('countries.enable-country')}
				error={null}
				buttonColor={enabled ? 'danger' : 'primary'}
				withMutation
			/>
		</PageLayout>
	);
};

export default CountriesPage;
