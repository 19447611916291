import React, { useState, useMemo, useCallback } from 'react';
import { CModalHeader, CModal, CModalTitle, CModalBody, CButton } from '@coreui/react';
import _t from 'counterpart';
import { useQuery, useMutation } from 'react-query';
import { listSalesDesks, updateCustomer } from '../../../services/BackendService';
import { ISalesDesk } from '../../salesDesks/types';
import PaginationTable from '../../../components/PaginationTable';
import { useAppSelector } from '../../../helpers/customHooks';
import { extractErrorMessage } from '../../../helpers';
import { Permission } from '../../../reducers/userReducer';
import toast from 'react-hot-toast';

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	customerId: string;
}

interface IMutationsParams {
	customerId: string;
	salesDeskId: string;
}

const AssignSalesDeskModal = ({ show, onClose, customerId }: IProps) => {
	const [page, setPage] = useState<number>(0);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const user = useAppSelector((state) => state.user);
	const hasPermission = user.permissions.includes(Permission.VIEW_SALES_DESK);

	const listSalesDesksQuery = useQuery(['sales-desks-assign', offset], () => listSalesDesks(limit, offset), {
		enabled: hasPermission,
	});

	const assignSalesDeskMutation = useMutation(
		['assign-sales-desk'],
		({ customerId, salesDeskId }: IMutationsParams) => updateCustomer(customerId, { salesDeskId }),
		{
			onSuccess: () => {
				toast.success(_t('sales-desks.sales-desk-assigned'));
				onClose(true);
				listSalesDesksQuery.refetch();
			},
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
		}
	);

	const onPageChanged = (page: number) => {
		setPage(page);
	};

	const invokeMutation = useCallback(
		(salesDeskId: string) => {
			assignSalesDeskMutation.mutate({ salesDeskId, customerId });
		},
		[assignSalesDeskMutation, customerId]
	);

	const fields = useMemo(
		() => [
			{ key: 'name', label: _t('sales-desks.name'), sorter: false },
			{
				key: 'actions',
				label: _t('global.actions'),
				sorter: false,
				_style: {
					width: '100%',
					display: 'flex',
					justifyContent: 'end',
				},
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			name: ({ name }: ISalesDesk) => <td>{name}</td>,
			actions: ({ id }: ISalesDesk) => (
				<td className="d-flex justify-content-end">
					<CButton color="primary" size="sm" onClick={() => invokeMutation(id)}>
						{_t('action.set')}
					</CButton>
				</td>
			),
		};
	}, [invokeMutation]);

	return (
		<CModal show={show} onClose={onClose} size="xl">
			<CModalHeader>
				<CModalTitle>{_t('sales-desks.assign-sales-desk')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<PaginationTable
					data={listSalesDesksQuery.data?.salesDesks || []}
					pages={listSalesDesksQuery.data?.pages || 0}
					scopedSlots={scopedSlots}
					tableFields={fields}
					pagination
					loading={listSalesDesksQuery.isLoading}
					noItemsViewSlot={<br />}
					activePage={page || 1}
					onPageChanged={onPageChanged}
				/>
			</CModalBody>
		</CModal>
	);
};

export default AssignSalesDeskModal;
