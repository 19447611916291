import React, { useState, useEffect, FormEvent } from 'react';
import { CCard, CCardBody, CRow, CCol, CForm, CFormGroup, CLabel, CButton, CInputGroup } from '@coreui/react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import {
	IInstrument,
	categories,
	calculationTypes,
	stakeModes,
	ISize,
	statuses,
	InstrumentCategory,
	swapModes,
	swapDays,
	IUploadedFile,
	InstrumentWithoutSort,
} from './types';
import FormTradingHoursRange from './FormTradingHoursRange';
import { createInstrument, updateInstrument, getInstrument } from '../../services/BackendService';
import { FormGroupInput, FormGroupSizesInput } from './InstrumentHelperComponents';
import CustomSelect from '../../components/CustomSelect';
import { findErrorFromValidation, errorWithCode, blobUrlToDataArray } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import PageLayout from '../../components/PageLayout';
import Dropzone from 'react-dropzone';
import Avatar from '../../components/avatar/Avatar';
import toast from 'react-hot-toast';

const AddUpdateInstrumentPage = () => {
	const validFileTypes = ['png', 'jpg', 'jpeg', 'gif'];
	const [cacheOverrideDate] = useState<number>(new Date().getTime());
	const [iconError, setIconError] = useState<string | null>(null);
	const [formState, setFormState] = useState<InstrumentWithoutSort>({
		profitCalcMode: calculationTypes[0].value,
		marginCalcMode: calculationTypes[0].value,
		stakeMode: stakeModes[0].value,
		symbol: '',
		category: InstrumentCategory[categories[0].value as keyof typeof InstrumentCategory],
		decimals: '',
		contractSize: '',
		currency: '',
		marginCurrency: '',
		sizes: [],
		tradingHours: [],
		status: statuses[0].value,
		marginPercentage: '',
		hedgedMarginPercentage: '',
		swapMode: swapModes[0].value,
		swapLong: '',
		swapShort: '',
		minOrderSize: '',
		maxOrderSize: '',
		stepOrderSize: '',
		swap3DayWeekday: swapDays[0].value,
		icon: null,
		iconUrl: null,
		price: null,
	});

	const history = useHistory();

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);

			if (code !== 422) {
				toast.error(message);
			}
		}
	};

	const createInstrumentMutation = useMutation((instrument: InstrumentWithoutSort) => createInstrument(instrument), {
		onSuccess: () => {
			toast.success(_t('instruments.instrument-created-success'));
			history.push('/instruments');
		},
		onError,
		retry: false,
	});

	const updateInstrumentMutation = useMutation((instrument: InstrumentWithoutSort) => updateInstrument(instrument), {
		onSuccess: () => {
			toast.success(_t('instruments.instrument-updated-successfully'));
			history.push('/instruments');
		},
		onError,
		retry: false,
	});

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(createInstrumentMutation.error || updateInstrumentMutation.error, paramName);
		return error;
	};

	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');
	const cloneParam = searchParams.get('clone');
	const instrumentFromUrl = editParam || cloneParam ? decodeURIComponent(editParam! || cloneParam!) : null;

	const isEditing = editParam !== null;
	const isCloning = cloneParam !== null;

	const { data: instrument } = useQuery<IInstrument, Error>(
		[`instrument-${instrumentFromUrl ?? 'unknown'}`],
		async () => {
			if (instrumentFromUrl) {
				const data = await getInstrument(instrumentFromUrl);
				return data.instrument;
			}
			return null;
		},
		{
			onError,
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (instrument) {
			setFormState({ ...instrument, decimals: instrument.decimals.toString() });
		}
	}, [instrument]);

	const createOrUpdateInstrument = (e: React.FormEvent) => {
		e.preventDefault();
		const mutationData = {
			...formState,
			contractSize: Number(formState.contractSize),
			marginPercentage: Number(formState.marginPercentage),
			hedgedMarginPercentage: Number(formState.hedgedMarginPercentage),
			swapShort: Number(formState.swapShort),
			swapLong: Number(formState.swapLong),
		};

		if (isEditing) {
			updateInstrumentMutation.mutate(mutationData);
		} else {
			createInstrumentMutation.mutate(mutationData);
		}
	};

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const addSize = () => {
		setFormState({ ...formState, sizes: [...formState.sizes, { left: '', right: '' }] });
	};

	const removeSize = (index: number) => {
		const { sizes } = formState;
		sizes.splice(index, 1);
		setFormState({ ...formState, sizes });
	};

	const onSizeValueChange = (index: number, value: ISize) => {
		const { sizes } = formState;
		sizes[index] = value;
		setFormState({ ...formState, sizes });
	};

	const addTradingHour = () => {
		const { tradingHours } = formState;
		setFormState({
			...formState,
			tradingHours: [
				...tradingHours,
				{
					startTime: { day: '', hour: '', minute: '' },
					endTime: { day: '', hour: '', minute: '' },
				},
			],
		});
	};

	const onTradingHourChange = (index: number, tradingHourRange: any) => {
		const { tradingHours } = formState;
		tradingHours[index] = tradingHourRange;
		setFormState({ ...formState, tradingHours: [...tradingHours] });
	};

	const onTradingHourRemove = (index: number) => {
		const { tradingHours } = formState;
		tradingHours.splice(index, 1);
		setFormState({ ...formState, tradingHours });
	};

	const onTradingHourClone = (index: number) => {
		const { tradingHours } = formState;
		const toClone = tradingHours[index];
		setFormState({
			...formState,
			tradingHours: [...tradingHours, toClone],
		});
	};

	const onSelectChange = (newValue: any, meta: any) => {
		setFormState({ ...formState, [meta.name]: newValue.value });
	};

	const currentCategory = categories.find((c) => c.value === formState.category);
	const currentProfitCalc = calculationTypes.find((c) => c.value === formState.profitCalcMode);
	const currentMarginCalc = calculationTypes.find((c) => c.value === formState.marginCalcMode);
	const currentStakeMode = stakeModes.find((c) => c.value === formState.stakeMode);
	const currentStatus = statuses.find((c) => c.value === formState.status);
	const currentSwapMode = swapModes.find((c) => c.value === formState.swapMode);
	const currentSwapDay = swapDays.find((c) => c.value === formState.swap3DayWeekday);

	const loading = createInstrumentMutation.isLoading || updateInstrumentMutation.isLoading;

	const getTitle = () => {
		if (!instrumentFromUrl) {
			return _t.translate('instruments.add-instrument');
		}
		if (isEditing) {
			return _t.translate('instruments.edit-instrument');
		}
		return _t.translate('instruments.clone-instrument');
	};

	const storeFiles = async (files: Array<any>) => {
		const file = files[0];
		const { name } = file;
		const extension = name.substring(name.lastIndexOf('.') + 1, name.length);

		if (!validFileTypes.includes(extension.toLowerCase())) {
			setIconError(_t('dropzone.extension-not-allowed', { extension }));
			return;
		}

		const storedFile: IUploadedFile = {
			preview: URL.createObjectURL(file),
			data: null,
			name: extension,
			blob: new Blob([URL.createObjectURL(file)]),
		};

		const data = await blobUrlToDataArray(storedFile.preview);
		storedFile.data = data;
		setFormState({ ...formState, icon: storedFile });
	};

	const title = getTitle();

	return (
		<PageLayout title={title}>
			<CForm onSubmit={createOrUpdateInstrument}>
				<CRow>
					<CCol>
						<CCard>
							<CCardBody>
								{isCloning && <CLabel className="text-danger">{_t('instruments.cloned-name-notify')}</CLabel>}

								<CFormGroup className="d-flex flex-column">
									<CLabel htmlFor="status">{_t('instruments.instrument-icon')}</CLabel>
									<Dropzone multiple={false} onDrop={(acceptedFiles) => storeFiles(acceptedFiles)}>
										{({ getRootProps, getInputProps }) => (
											<div className="c-avatar c-avatar-square" {...getRootProps()}>
												<Avatar
													imgUrl={formState.icon?.preview || `${instrument?.iconUrl}?${cacheOverrideDate}`}
													name={instrument?.symbol || 'I'}
												/>
												<input {...getInputProps()} />
											</div>
										)}
									</Dropzone>
									{iconError}
								</CFormGroup>

								<FormGroupInput
									label="symbol"
									value={formState.symbol}
									onChange={handleInputChange}
									placeholder="GBPUSD"
									type="text"
									disabled={isEditing}
									error={findError('symbol')}
								/>

								<div className="d-flex flex-row flex-wrap">
									<CFormGroup>
										<CLabel htmlFor="status">{_t('instruments.instrument-status')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={statuses}
												name="status"
												value={currentStatus}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
										{findError('status') && <CLabel className="text-danger">{findError('status')}</CLabel>}
									</CFormGroup>

									<CFormGroup>
										<CLabel htmlFor="category">{_t('instruments.form.category')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={categories}
												name="category"
												value={currentCategory}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
										{findError('category') && <CLabel className="text-danger">{findError('category')}</CLabel>}
									</CFormGroup>
								</div>

								<div className="d-flex flex-row flex-wrap">
									<CFormGroup>
										<CLabel htmlFor="profitCalcMode">{_t('instruments.profit-calculation')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={calculationTypes}
												name="profitCalcMode"
												value={currentProfitCalc}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
										{findError('profitCalcMode') && (
											<CLabel className="text-danger">{findError('profitCalcMode')}</CLabel>
										)}
									</CFormGroup>

									<CFormGroup>
										<CLabel htmlFor="marginCalcMode">{_t('instruments.margin-calculation')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={calculationTypes}
												name="marginCalcMode"
												value={currentMarginCalc}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
										{findError('marginCalcMode') && (
											<CLabel className="text-danger">{findError('marginCalcMode')}</CLabel>
										)}
									</CFormGroup>

									<FormGroupInput
										className="trading-select"
										label="marginPercentage"
										value={Number(formState.marginPercentage).toFixed(2)}
										onChange={handleInputChange}
										placeholder="0.05%"
										type="number"
										error={findError('marginPercentage')}
									/>

									<FormGroupInput
										label="hedgedMarginPercentage"
										value={Number(formState.hedgedMarginPercentage).toFixed(2)}
										onChange={handleInputChange}
										placeholder="0.05%"
										type="number"
										error={findError('hedgedMarginPercentage')}
									/>
								</div>

								<FormGroupInput
									label="decimals"
									value={formState.decimals}
									onChange={handleInputChange}
									placeholder="2"
									type="number"
									error={findError('decimals')}
								/>
								<FormGroupInput
									label="contractSize"
									value={formState.contractSize.toString()}
									onChange={handleInputChange}
									placeholder="100000"
									type="number"
									error={findError('contractSize')}
								/>
								<FormGroupInput
									label="currency"
									value={formState.currency}
									onChange={handleInputChange}
									placeholder="USD"
									type="text"
									error={findError('currency')}
								/>
								<FormGroupInput
									label="marginCurrency"
									value={formState.marginCurrency}
									onChange={handleInputChange}
									placeholder="USD"
									type="text"
									error={findError('marginCurrency')}
								/>

								<CFormGroup className="d-flex flex-column mt-5">
									<CLabel htmlFor="sizes" className="instrument-section-label">
										{_t('instruments.form.sizes')}
									</CLabel>

									<div className="d-flex flex-row flex-wrap gap-3">
										<CFormGroup className="mr-3">
											<FormGroupInput
												label="minOrderSize"
												value={formState.minOrderSize}
												onChange={handleInputChange}
												placeholder="0.01"
												type="number"
												error={findError('minOrderSize')}
											/>
										</CFormGroup>
										<CFormGroup className="mr-3">
											<FormGroupInput
												label="maxOrderSize"
												value={formState.maxOrderSize}
												onChange={handleInputChange}
												placeholder="10.00"
												type="number"
												error={findError('maxOrderSize')}
											/>
										</CFormGroup>
										<CFormGroup>
											<FormGroupInput
												label="stepOrderSize"
												value={formState.stepOrderSize}
												onChange={handleInputChange}
												placeholder="0.01"
												type="number"
												error={findError('stepOrderSize')}
											/>
										</CFormGroup>
									</div>

									<CFormGroup>
										<CLabel htmlFor="stakeMode">{_t('instruments.stake-mode')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={stakeModes}
												name="stakeMode"
												value={currentStakeMode}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
									</CFormGroup>

									{formState.sizes.map((size, index) => {
										return (
											<FormGroupSizesInput
												key={`fgs-${size}`}
												index={index}
												onRemoveSize={removeSize}
												onChange={onSizeValueChange}
												size={formState.sizes[index]}
											/>
										);
									})}
									{findError('sizes') && <CLabel className="text-danger">{findError('sizes')}</CLabel>}
									<CButton onClick={addSize} className="instrument-buttons mt-2" color="success">
										{_t('instruments.add-new-size')}
									</CButton>
								</CFormGroup>
								<CFormGroup className="d-flex flex-column mt-5">
									<CLabel htmlFor="trading-hours" className="instrument-section-label">
										{_t('instruments.form.trading-hours')}
									</CLabel>
									{formState.tradingHours.map((size, index) => {
										return (
											<FormTradingHoursRange
												key={index}
												index={index}
												onRemove={onTradingHourRemove}
												onChange={onTradingHourChange}
												onClone={onTradingHourClone}
												tradingHoursRange={formState.tradingHours[index]}
											/>
										);
									})}
									{findError('tradingHours') && <CLabel className="text-danger">{findError('tradingHours')}</CLabel>}
									<CButton onClick={addTradingHour} className="instrument-buttons mt-2" color="success">
										{_t('instruments.add-trading-hours')}
									</CButton>
								</CFormGroup>
								<div>
									<CLabel htmlFor="swaps" className="instrument-section-label">
										{_t.translate('instruments.form.swaps')}
									</CLabel>
									<CFormGroup>
										<CLabel htmlFor="swap3DayWeekday">{_t.translate('instruments.form.swap-mode')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={swapModes}
												name="swapMode"
												value={currentSwapMode}
												whiteBackground
												onChange={onSelectChange}
											/>
										</CInputGroup>
										{findError('swapMode') && <CLabel className="text-danger">{findError('swapMode')}</CLabel>}
									</CFormGroup>
									<FormGroupInput
										label="swapLong"
										value={Number(formState.swapLong).toFixed(2)}
										onChange={handleInputChange}
										placeholder="-10"
										type="number"
										error={findError('swapLong')}
									/>
									<FormGroupInput
										label="swapShort"
										value={Number(formState.swapShort).toFixed(2)}
										onChange={handleInputChange}
										placeholder="-5"
										type="number"
										error={findError('swapShort')}
									/>
									<CFormGroup>
										<CLabel htmlFor="swapDay">{_t.translate('instruments.form.swap-weekday')}</CLabel>
										<CInputGroup>
											<CustomSelect
												className="trading-select"
												options={swapDays}
												name="swap3DayWeekday"
												value={currentSwapDay}
												whiteBackground
												onChange={onSelectChange}
												menuPlacement="auto"
											/>
										</CInputGroup>
										{findError('swap3DayWeekday') && (
											<CLabel className="text-danger">{findError('swap3DayWeekday')}</CLabel>
										)}
									</CFormGroup>
								</div>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default AddUpdateInstrumentPage;
