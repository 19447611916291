import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _t from 'counterpart';
import NoItems from './NoItems';
import { IExposure } from '../services/RiskService';

interface IProps {
	exposures: Array<IExposure>;
}

const ExposureChart: React.FC<IProps> = ({ exposures }) => {
	const { parsedExposures, maxAbsValue } = useMemo(() => {
		let maxAbsValue = 0;
		const parsedExposures = exposures.map((exposure) => {
			if (Math.abs(Number(exposure.exposure)) > maxAbsValue) {
				maxAbsValue = Number(exposure.exposure);
			}
			return {
				...exposure,
				exposure: Number(exposure.exposure),
			};
		});

		return { parsedExposures, maxAbsValue };
	}, [exposures]);

	if (parsedExposures.length === 0) {
		return <NoItems />;
	}
	return (
		<ResponsiveContainer height={parsedExposures.length * 30}>
			<BarChart
				barCategoryGap={1}
				layout="vertical"
				data={parsedExposures}
				margin={{
					top: 0,
					left: -25,
					right: 0,
					bottom: 0,
				}}
			>
				<XAxis type="number" domain={[maxAbsValue * -1, maxAbsValue]} hide padding={{ left: 45, right: 45 }} />

				<YAxis type="category" dataKey="symbol" width={100} />

				<Tooltip formatter={(value: string) => [Number(value).toFixed(2), _t('global.exposure')]} />

				<Bar dataKey="exposure" fill="var(--primary)">
					{parsedExposures.map((v) => {
						const color = v.exposure > 0 ? 'var(--success)' : 'var(--danger)';
						return <Cell key={`cell-${v.symbol}`} fill={color} />;
					})}
					<LabelList dataKey="exposure" position="right" />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default ExposureChart;
