import React from 'react';
import { Switch } from 'react-router-dom';
import { FadeInRoute } from './FadeInRoute';
import BookmarksPage from '../pages/BookmarksPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import CustomerPage from '../pages/customers/CustomerPage/CustomerPage';
import CustomersPage from '../pages/customers/CustomersPage';
import CoinsPage from '../pages/coins/CoinsPage';
import NotFound from './NotFound';
import TransactionPage from '../pages/TransactionPage';
import CompetitionsPage from '../pages/competitions/CompetitionsPage';
import CompetitionPage from '../pages/competitions/CompetitionPage';
import AllPositionsPage from '../pages/positions/AllPositionsPage';
import PositionPage from '../pages/positions/PositionPage';
import DepositsPage from '../pages/DepositsPage';
import WithdrawalsPage from '../pages/WithdrawalsPage';
import RiskManagementPage from '../pages/RiskManagementPage';
import GroupsPage from '../pages/groups/GroupsPage';
import RiskReportsPage from '../pages/riskManagement/RiskReportsPage';
import InstrumentsPage from '../pages/instruments/InstrumentsPage/InstrumentsPage';
import InstrumentPage from '../pages/instruments/InstrumentPage';
import AddUpdateInstrumentPage from '../pages/instruments/AddUpdateInstrumentPage';
import ProfilePage from '../pages/ProfilePage';
import EmailsPage from '../pages/emails/EmailsPage';
import OpenPositionsPage from '../pages/positions/OpenPositionsPage';
import OnboardingPage from '../pages/onboarding/OnboardingPage';
import BonusesPage from '../pages/bonuses/BonusesPage';
import CountriesPage from '../pages/countries/CountriesPage';
import BannersPage from '../pages/banners/BannersPage';
import OrdersPage from '../pages/orders/OrdersPage';
import OrderPage from '../pages/orders/OrderPage';
import DowntimesPage from '../pages/downtimes/DowntimesPage';
import PaymentProvidersPage from '../pages/psp/PaymentProvidersPage';
import WalletsPage from '../pages/wallets/WalletsPage';
import WalletPage from '../pages/wallets/WalletPage';
import MassBonusesPage from '../pages/massBonuses/MassBonusesPage';
import AffiliatesPage from '../pages/customers/AffiliatesPage';
import MessagingPage from '../pages/messages/MessagingPage';
import { useAppSelector } from '../helpers/customHooks';
import { Permission } from '../reducers/userReducer';
import SalesPeoplePage from '../pages/customers/SalesPeoplePage';
import SalesPeopleDetailsPage from '../pages/customers/SalesPeopleDetailsPage';
import VoipProvidersPage from '../pages/voipProviders/VoipProvidersPage';
import SalesDesksPage from '../pages/salesDesks/SalesDesksPage';
import SalesDeskPage from '../pages/salesDesks/SalesDeskPage';
import LeadsPage from '../pages/leads/LeadsPage';
import LeadPage from '../pages/leads/LeadPage';
import PaymentProviderPage from '../pages/psp/PaymentProviderPage';
import CustomerEditPage from '../pages/customers/CustomerPage/CustomerEditPage';
import TradingOptions from '../pages/tradingOptions/TradingOptionsPage';
import CoinsConversionsPage from '../pages/coins/CoinsConversionsPage';
import EmailEditCreatePage from '../pages/emails/EmailEditCreatePage';
import AddEditCompetitionPage from '../pages/competitions/AddEditCompetitionPage';
import ExchangeRatesPage from '../pages/exchangeRates/ExchangeRatesPage';
import SalesStatusPage from '../pages/salesStatus/SalesStatusPage';
import ReferralTreeViewPage from '../pages/customers/ReferralTreeViewPage';
import OnboardingStepsPage from '../pages/customers/CustomerPage/OnboardingStepsPage';
import CurrencyConversionsPage from '../pages/conversions/ConversionsPage';
import AuditLogsPage from '../pages/auditLogs/AuditLogsPage';
import AffiliatePage from '../pages/customers/AffiliatePage';
import AdaptersPage from '../pages/adapters/AdaptersPage';
import CreateUpdateAdapter from '../pages/adapters/CreateUpdateAdapter';
import AdapterPage from '../pages/adapters/AdapterPage';
import CreateCustomerPositionPage from '../pages/customers/CreatePositionPage';
import GroupPage from '../pages/groups/GroupPage';
import GeneralSettingsPage from '../pages/generalSettings/GeneralSettingsPage';
import CoinsSettingsPage from '../pages/coins/CoinsSettingsPage';
import { useGetSetting } from '../pages/generalSettings/hooks';
import CommissionsPage from '../pages/commissions/CommissionModelsPage';
import CommissionPage from '../pages/commissions/CommissionModelPage';
import CreateUpdateCommissionModel from '../pages/commissions/CreateUpdateCommissionModel';
import PayoutRequests from '../pages/commissions/PayoutRequests';
import PayoutRequest from '../pages/commissions/PayoutRequest';
import CommissionLedgerPage from '../pages/commissions/CommissionLedgerPage';
import TransactionsPage from '../pages/TransactionsPage';
import CommissionLedgersPage from '../pages/commissions/CommissionLedgersPage';

const Content = () => {
	const permissions = useAppSelector((state) => state.user.permissions);

	const hasPermission = (permission: Permission) => {
		return permissions?.includes(permission);
	};

	const voipProviderQuery = useGetSetting('VOIP_PROVIDER', 'voip-provider-content', {
		enabled: hasPermission(Permission.MANAGE_GENERAL_SETTINGS),
	});
	const enableVoipProvider = voipProviderQuery.data !== '' && hasPermission(Permission.MANAGE_CALL_PROVIDERS);

	const emailProviderQuery = useGetSetting('EMAIL_PROVIDER', 'email-provider-content', {
		enabled: hasPermission(Permission.MANAGE_GENERAL_SETTINGS),
	});
	const enableEmailProvider = emailProviderQuery.data !== '' && hasPermission(Permission.VIEW_EMAILS);

	return (
		<Switch>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_DASHBOARD)} exact path="/">
				<DashboardPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_CUSTOMERS)} exact path="/customers">
				<CustomersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_CUSTOMERS)} exact path="/referral_tree">
				<ReferralTreeViewPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_CUSTOMERS)} exact path="/customers/edit/:id">
				<CustomerEditPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_CUSTOMERS)} exact path="/customers/:customerId">
				<CustomerPage />
			</FadeInRoute>
			<FadeInRoute
				enabled={hasPermission(Permission.MANAGE_POSITIONS)}
				exact
				path="/customers/:customerId/position_form"
			>
				<CreateCustomerPositionPage />
			</FadeInRoute>
			<FadeInRoute
				enabled={hasPermission(Permission.MANAGE_CUSTOMER_ONBOARDING_STEPS)}
				exact
				path="/customers/onboarding_steps/:id"
			>
				<OnboardingStepsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_TRANSACTIONS)} exact path="/transactions">
				<TransactionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_TRANSACTIONS)} exact path="/transactions/deposits">
				<DepositsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_TRANSACTIONS)} exact path="/transactions/withdrawals">
				<WithdrawalsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_TRANSACTIONS)} exact path="/transactions/:id">
				<TransactionPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_COMPETITIONS)} exact path="/competitions">
				<CompetitionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_COMPETITIONS)} exact path="/competitions/:id">
				<CompetitionPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMPETITIONS)} exact path="/competitions_form">
				<AddEditCompetitionPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_POSITIONS)} exact path="/positions">
				<AllPositionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_POSITIONS)} exact path="/positions/open">
				<OpenPositionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_SINGLE_POSITION)} exact path="/positions/:id">
				<PositionPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_RISK)} exact path="/risk_management">
				<RiskManagementPage />
			</FadeInRoute>
			<FadeInRoute
				enabled={Boolean(process.env.REACT_APP_STANDALONE) && hasPermission(Permission.VIEW_REPORTS)}
				exact
				path="/risk_management/reports"
			>
				<RiskReportsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_GROUPS)} exact path="/groups">
				<GroupsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_GROUPS)} exact path="/groups/:id">
				<GroupPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/commission_models">
				<CommissionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/commission-ledgers">
				<CommissionLedgersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/commission-ledgers/:id">
				<CommissionLedgerPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/commission_models/:id">
				<CommissionPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/commission_model_edit">
				<CreateUpdateCommissionModel />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/payout-requests">
				<PayoutRequests />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COMMISSION_MODELS)} exact path="/payout-requests/:id">
				<PayoutRequest />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_INSTRUMENTS)} exact path="/instruments">
				<InstrumentsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_INSTRUMENTS)} exact path="/instruments/:id">
				<InstrumentPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_INSTRUMENTS)} exact path="/instruments_form">
				<AddUpdateInstrumentPage />
			</FadeInRoute>
			<FadeInRoute enabled={enableEmailProvider} exact path="/emails">
				<EmailsPage />
			</FadeInRoute>
			<FadeInRoute enabled={enableEmailProvider} exact path="/email_form">
				<EmailEditCreatePage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_BOOKMARKS)} exact path="/bookmarks">
				<BookmarksPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_COINS)} exact path="/coins">
				<CoinsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_COINS)} exact path="/conversions">
				<CoinsConversionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_COINS)} exact path="/coins_settings">
				<CoinsSettingsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_CUSTOMER_FIELD_CONFIGURATIONS)} exact path="/onboarding">
				<OnboardingPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_BONUSES)} exact path="/bonuses">
				<BonusesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_COUNTRIES)} exact path="/countries">
				<CountriesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_BANNERS)} exact path="/banners">
				<BannersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_ORDERS)} exact path="/orders">
				<OrdersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_ORDERS)} exact path="/orders/:id">
				<OrderPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_DOWNTIMES)} exact path="/downtimes">
				<DowntimesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_PAYMENT_PROVIDERS)} exact path="/payment-providers">
				<PaymentProvidersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_PAYMENT_PROVIDERS)} exact path="/payment-providers/:id">
				<PaymentProviderPage />
			</FadeInRoute>
			<FadeInRoute exact path="/profile">
				<ProfilePage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_WALLETS)} exact path="/wallets">
				<WalletsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_WALLETS)} exact path="/wallets/:id">
				<WalletPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_MASS_BONUSES)} exact path="/mass-bonuses">
				<MassBonusesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_AFFILIATES)} exact path="/affiliates">
				<AffiliatesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_AFFILIATES)} exact path="/affiliates/:id">
				<AffiliatePage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_MESSAGES)} exact path="/messaging">
				<MessagingPage />
			</FadeInRoute>
			<FadeInRoute enabled={enableVoipProvider} exact path="/voip-providers">
				<VoipProvidersPage />
			</FadeInRoute>
			<FadeInRoute exact path="/sales-people">
				<SalesPeoplePage />
			</FadeInRoute>
			<FadeInRoute exact path="/sales-people/:id">
				<SalesPeopleDetailsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_SALES_DESK)} exact path="/sales-desks">
				<SalesDesksPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_SALES_DESK)} exact path="/sales-desks/:id">
				<SalesDeskPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_SALES_STATUS)} exact path="/sales-status">
				<SalesStatusPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_LEADS)} exact path="/leads">
				<LeadsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_LEADS)} exact path="/leads/:id">
				<LeadPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_MAX_RISK)} exact path="/trading-options">
				<TradingOptions />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_EXCHANGE_RATE)} exact path="/exchange-rate">
				<ExchangeRatesPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_CURRENCY_CONVERSIONS)} exact path="/currency-conversion">
				<CurrencyConversionsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_GENERAL_SETTINGS)} exact path="/general_settings">
				<GeneralSettingsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_AUDIT_LOGS)} exact path="/logs">
				<AuditLogsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_AUDIT_LOGS)} exact path="/logs">
				<AuditLogsPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.VIEW_ADAPTERS)} exact path="/adapters">
				<AdaptersPage />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_ADAPTERS)} exact path="/adapters_form">
				<CreateUpdateAdapter />
			</FadeInRoute>
			<FadeInRoute enabled={hasPermission(Permission.MANAGE_ADAPTERS)} exact path="/adapters/:name">
				<AdapterPage />
			</FadeInRoute>
			<FadeInRoute>
				<NotFound />
			</FadeInRoute>
		</Switch>
	);
};

export default Content;
