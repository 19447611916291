import { ISelectOption } from 'src/components/ToggleSelect';
import { ICustomersFilters } from 'src/reducers/filtersReducer';

export const getFiltersCount = (customersFilters: ICustomersFilters) => {
	const objectKeys = Object.keys(customersFilters);
	return Object.values(customersFilters).reduce<number>((count, filterValue, currentIndex) => {
		const filterKey = objectKeys[currentIndex];
		const notToggleSelect =
			filterKey === 'conversionAgents' || filterKey === 'sources' || filterKey === 'retentionAgents';
		if (
			(filterValue && !Array.isArray(filterValue)) ||
			(Array.isArray(filterValue) && filterValue.length > 0 && !notToggleSelect)
		) {
			return count + 1;
		}
		switch (filterKey) {
			case 'sources': {
				const foundEnabledSources = customersFilters.sources.find((option: ISelectOption) => option.enabled);
				if (foundEnabledSources) {
					return count + 1;
				}
				break;
			}

			case 'conversionAgents': {
				const foundEnabledConversionAgents = customersFilters.conversionAgents.find(
					(option: ISelectOption) => option.enabled
				);
				if (foundEnabledConversionAgents) {
					return count + 1;
				}
				break;
			}

			case 'retentionAgents': {
				const foundEnabledRetentionAgents = customersFilters.retentionAgents.find(
					(option: ISelectOption) => option.enabled
				);
				if (foundEnabledRetentionAgents) {
					return count + 1;
				}
				break;
			}

			default:
				// Handle default case if needed
				break;
		}

		return count;
	}, 0);
};
