import { createBrowserHistory } from 'history';
import { createStore, Action, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import createRootReducer, { AppState } from './reducers';
import rootSaga from './sagas';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user'],
	stateReconciler: autoMergeLevel2,
};

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<AppState, Action<any>, any, any>(
	// @ts-ignore
	persistedReducer,
	composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
