import { CRow, CCol, CCard, CCardBody, CDataTable, CButton, CCardHeader, CLink } from '@coreui/react';
import React, { useCallback, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { useHistory } from 'react-router-dom';
import { deleteCommissionModel, getCommissionModels } from '../../services/BackendService';
import { useMutation, useQuery } from 'react-query';
import { ICommissionModel } from './types';
import BooleanBadge from '../../components/BooleanBadge';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import CIcon from '@coreui/icons-react';
import ConfirmationModal from '../../components/ConfirmationModal';
import toast from 'react-hot-toast';
import { extractErrorMessage } from '../../helpers';

const CommissionsModelsPage = () => {
	const [toDeleteModel, setToDeleteModel] = useState<ICommissionModel | null>(null);
	const history = useHistory();

	const linkToCreateUpdatePage = () => {
		history.push('/commission_model_edit');
	};

	const linkToUpdatePage = useCallback(
		(commissionModel: ICommissionModel) => {
			history.push(`/commission_model_edit?edit=${commissionModel.id}`);
		},
		[history]
	);

	const commissionModelsQuery = useQuery(['commission-models'], () => getCommissionModels());

	const hideDeleteModel = () => {
		setToDeleteModel(null);
	};

	const deleteCommissionModelMutation = useMutation((id: string) => deleteCommissionModel(id), {
		onError: (e) => {
			toast.error(extractErrorMessage(e));
		},
		onSuccess: () => {
			toast.success(_t('commissions.commission-model-deleted-successfully'));
			hideDeleteModel();
			commissionModelsQuery.refetch();
		},
	});

	const invokeDeleteMutation = () => {
		if (toDeleteModel?.id) {
			deleteCommissionModelMutation.mutate(toDeleteModel.id?.toString());
		}
	};

	const fields = useMemo(
		() => [
			{ key: 'name', label: _t('global.name') },
			{ key: 'subCommissionModelId', label: _t('commissions.sub-commission-scheme') },
			{ key: 'greyLabelingEnabled', label: _t('commissions.grey-labeling-enabled') },
			{ key: 'commissionModelLevels', label: _t('commissions.levels') },
			{ key: 'actions', label: _t('global.actions') },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			name: (commissionModel: ICommissionModel) => (
				<td>
					<CLink href={`/commission_models/${commissionModel.id}`}>
						{commissionModel.name} {commissionModel.default ? `(${_t('global.default')})` : ''}
					</CLink>
				</td>
			),
			subCommissionModelId: ({ subCommissionModelId, subCommissionModel }: ICommissionModel) => (
				<td>
					<CLink href={`/commissions_model/${subCommissionModelId}`}>{subCommissionModel?.name}</CLink>
				</td>
			),
			greyLabelingEnabled: ({ greyLabelingEnabled }: ICommissionModel) => (
				<td>
					<BooleanBadge value={greyLabelingEnabled} />
				</td>
			),
			commissionModelLevels: ({ commissionModelLevels }: ICommissionModel) => <td>{commissionModelLevels.length}</td>,
			actions: (commissionModel: ICommissionModel) => (
				<td>
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={() => linkToUpdatePage(commissionModel)}
						title="Edit instrument"
						size="sm"
					>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton className="mr-2" size="sm" color="danger" onClick={() => setToDeleteModel(commissionModel)}>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</td>
			),
		}),
		[linkToUpdatePage]
	);

	const { isLoading, isError, data, refetch } = commissionModelsQuery;

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	return (
		<PageLayout title={_t('commissions.commission-models')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={linkToCreateUpdatePage} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								loading={commissionModelsQuery.isLoading}
								items={data || []}
								fields={fields}
								scopedSlots={scopedSlots}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={toDeleteModel?.id?.toString() || ''}
				show={Boolean(toDeleteModel)}
				onConfirm={invokeDeleteMutation}
				title={_t('commissions.delete-commission-model')}
				error={null}
				hidePanel={hideDeleteModel}
				buttonColor="danger"
				withMutation={false}
			/>
		</PageLayout>
	);
};

export default CommissionsModelsPage;
