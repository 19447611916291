import React, { PureComponent } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { timeFormat } from 'd3-time-format';
import _t from 'counterpart';
import { IWalletPerformanceRecord } from './types';
import { formatCurrency } from '../../helpers';

interface ChartProps {
	data: Array<IWalletPerformanceRecord>;
}

export default class PerformanceChart extends PureComponent<ChartProps> {
	tickFormatter(tick: string) {
		return timeFormat('%m/%d')(new Date(tick));
	}

	renderTime(timePoint: string): string {
		return this.tickFormatter(timePoint.toLocaleString());
	}

	render() {
		const formatter = (value: any, name: any, props: any) => {
			return [formatCurrency(props.value), props.name];
		};

		const { data } = this.props;

		return (
			<ResponsiveContainer width="100%" height={400}>
				<AreaChart
					data={data}
					margin={{
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				>
					<defs>
						<linearGradient id="leftArea" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--primary)" stopOpacity={0.8} />
							<stop offset="95%" stopColor="var(--primary)" stopOpacity={0} />
						</linearGradient>
						<linearGradient id="rightArea" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--success)" stopOpacity={0.8} />
							<stop offset="95%" stopColor="var(--success)" stopOpacity={0} />
						</linearGradient>
					</defs>

					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="day" interval="preserveStartEnd" tickFormatter={this.tickFormatter} />
					<YAxis
						yAxisId="left"
						domain={['auto', (dataMax: number) => dataMax * 1.1]}
						tickFormatter={(v) => formatCurrency(v, 'USD', 0)}
					/>

					<Tooltip formatter={formatter} labelFormatter={(value) => this.renderTime(value.toString())} />
					<Legend iconType="plainline" />
					<Area
						type="monotone"
						dataKey="balance"
						fill="url(#leftArea)"
						stroke="var(--primary)"
						strokeWidth="2"
						dot={false}
						activeDot={{ r: 4 }}
						name={_t('wallet.balance-performance')}
						yAxisId="left"
						stackId={1}
					/>
					<Area
						type="monotone"
						dataKey="credit"
						fill="url(#rightArea)"
						stroke="var(--success)"
						strokeWidth="2"
						dot={false}
						activeDot={{ r: 4 }}
						name={_t('wallet.credit-performance')}
						yAxisId="left"
						stackId={1}
					/>
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}
