export interface IEmailTemplate {
	id: string;
	mjml: string;
	subject: string;
	txt: string;
	title: string;
	trigger: string;
	conditions: any;
	enabled: boolean;
}

export interface IMJMLError {
	formattedMessage: string;
	line: number;
	message: string;
	tagName: string;
}

export interface IEmailTemplateUpdate {
	subject?: string | null;
	mjml?: string | null;
	txt?: string | null;
	enabled?: boolean | null;
	trigger?: string | null;
	title?: string | null;
	conditions?: any | null;
}

export interface IEmailTemplateCreate {
	id?: string | null;
	subject?: string | null;
	mjml?: string | null;
	txt?: string | null;
	enabled?: boolean | null;
	trigger?: string | null;
	title?: string | null;
	conditions?: any | null;
}

export interface IEditMjml {
	mjml: any;
	html: any;
}

export enum EditType {
	GENERAL = 'general',
	TITLE = 'title',
	SUBJECT = 'subject',
	MJML = 'mjml',
	TXT = 'txt',
}
