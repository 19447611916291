import axios from "axios";
import { createTokenRefreshInterceptor, handleReject, handleRequest, handleSuccess } from "./helpers/interceptors";

const baseURL = process.env.REACT_APP_API_ROOT;
const client = axios.create({ baseURL });

createTokenRefreshInterceptor(client);
client.interceptors.response.use(handleSuccess, (error) => handleReject(error));
client.interceptors.request.use(handleRequest);

export default client;