import React, { useCallback } from 'react';
import { CDataTable, CPagination } from '@coreui/react';
import _t from 'counterpart';
import LimitDropdown from './LimitDropdown';
import { itemsPerPageOptions } from './types';

export interface ISorter {
	column: string;
	asc: boolean;
}

interface IProps {
	onSearchValueChanged?: (value: string) => void;
	onPageChanged?: (page: number) => void;
	onSorterChanged?: (values: ISorter) => void;
	onRowClicked?: (value: any) => void;
	onLimitChanged?: (value: number) => void;
	noItemsViewSlot?: any;
	sorter?: ISorter;
	showFilter?: boolean;
	pagination?: boolean;
	activePage?: number;
	search?: string;
	clickableRows?: boolean;
	data: any;
	tableFields: any;
	loading: boolean;
	pages: number;
	scopedSlots: any;
	itemsPerPage?: number;
}

const PaginationTable: React.FC<IProps> = ({
	data,
	pages,
	tableFields,
	loading,
	scopedSlots,
	onSearchValueChanged = null,
	onPageChanged = null,
	onSorterChanged = null,
	onRowClicked = null,
	onLimitChanged,
	sorter = null,
	noItemsViewSlot = null,
	showFilter = false,
	pagination = false,
	clickableRows = undefined,
	activePage = 0,
	search = '',
	itemsPerPage = 10,
}) => {
	const onSorterChange = useCallback(
		(values: ISorter) => {
			if (onSorterChanged) {
				onSorterChanged(values);
			}
		},
		[onSorterChanged]
	);

	const setPage = useCallback(
		(page: number) => {
			if (onPageChanged) {
				onPageChanged(page);
			}
		},
		[onPageChanged]
	);

	const setTableFilterValue = useCallback(
		(search: string) => {
			if (onSearchValueChanged) {
				onSearchValueChanged(search);
			}
		},
		[onSearchValueChanged]
	);

	const { column, asc } = sorter || { column: 'createdAt', asc: true };

	return (
		<>
			<CDataTable
				items={data}
				fields={tableFields}
				tableFilter={showFilter && { external: true, placeholder: _t('global.search-something') }}
				tableFilterValue={search}
				onTableFilterChange={setTableFilterValue}
				hover
				sorter={{ external: true }}
				sorterValue={{ column, asc }}
				onSorterValueChange={onSorterChange}
				loading={loading}
				striped
				scopedSlots={scopedSlots}
				clickableRows={clickableRows ? true : undefined}
				onRowClick={clickableRows ? onRowClicked! : undefined}
				noItemsViewSlot={noItemsViewSlot}
				itemsPerPage={itemsPerPage}
			/>

			<div className="d-flex justify-content-start flex-wrap">
				{pagination && data && pages > 1 && (
					<CPagination
						pages={pages}
						activePage={Number(activePage)}
						onActivePageChange={setPage}
						className={pages! < 2 ? 'd-none' : ''}
					/>
				)}
				{onLimitChanged && data && data.length >= itemsPerPageOptions[0] && (
					<LimitDropdown limit={itemsPerPage} onLimitChanged={onLimitChanged} />
				)}
			</div>
		</>
	);
};

export default React.memo(PaginationTable);
