import React from 'react';
import _t from 'counterpart';
import UpdatePassword from '../../components/UpdatePassword';
import ConfirmationModal from '../../components/ConfirmationModal';
import { CustomerStatus, ICustomerModalsShow } from './types';
import { callCustomer, updateCustomer } from '../../services/BackendService';
import AssignableCustomers, { SalesAgentRoles } from './AssignableCustomers';
import { useMutation, useQueryClient } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import EmailCustomerModal from './CustomerPage/EmailCustomerModal';
import AssignSalesStatusModal from './CustomerPage/AssignSalesStatusModal';
import CommissionModelChangeModal from '../commissions/CommissionModelChangeModal';
import toast from 'react-hot-toast';
import DisableCustomerModal from './DisableCustomerModal';
import MessageCustomerModal from './CustomerPage/MessageCustomerModal';

interface IProps {
	id: string;
	customer: any;
	modalsVisibility: ICustomerModalsShow;
	setTargetModalVisibility: (modalsVisiblity: ICustomerModalsShow, refetch?: boolean) => void;
}

const CustomerModals = ({ id, customer, modalsVisibility, setTargetModalVisibility }: IProps) => {
	const queryClient = useQueryClient();

	const hideAffiliatesModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, affiliatesModalShow: false }, refetch);
		document.querySelector('body')?.classList.remove('modal-open');
	};

	const hideSalesPersonModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, salesPersonModalShow: false }, refetch);
		document.querySelector('body')?.classList.remove('modal-open');
	};

	const updateSalesPersonMutation = useMutation(
		({ referrerId, salesPersonRole }: { referrerId: string; salesPersonRole: SalesAgentRoles }) =>
			updateCustomer(id, { salesPersonId: referrerId, salesAgentRole: salesPersonRole }),
		{
			onSuccess: () => {
				toast.success(_t('customer.sales-person-assigned'));
				hideSalesPersonModal(true);
			},
			onError: (e) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const confirmEmailMutation = useMutation(() => updateCustomer(id, { confirmEmail: true }), {
		onSuccess: () => {
			hideConfirmEmailPanel(true);
		},
		onError: (e) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const removeRiskStatusMutation = useMutation(() => updateCustomer(id, { removeRiskStatus: true }), {
		onSuccess: () => {
			hideSetRiskStatusModal(true);
		},
		onError: (e) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const requestUpdateCustomerStatusMutation = useMutation(
		(status: CustomerStatus) => updateCustomer(id, { customerStatus: status }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('customer');
			},
			onError: (e) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const updateReferrerMutation = useMutation(
		(referrerId: string) => updateCustomer(id, { newReferrerId: referrerId }),
		{
			onSuccess: () => {
				hideAffiliatesModal(true);
			},
			onError: (e) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const hideCommissionModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, changeCommissionSchemeShow: false }, refetch);
	};

	const hideUpdatePassword = () => {
		setTargetModalVisibility({ ...modalsVisibility, updatePasswordShow: false });
	};

	const hideConfirmEmailPanel = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, confirmEmailShow: false }, refetch);
	};

	const hideConfirmBar = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, confirmBarShow: false }, refetch);
	};

	const closeAssignSalesStatus = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, assignSalesStatusShow: false }, refetch);
	};

	const hideEmailCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, emailCustomerModalShow: false });
	};

	const showEmailCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, emailCustomerModalShow: true });
	};

	const hideMessageCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, messageModalShow: false });
	};

	const showMessageCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, messageModalShow: true });
	};

	const hideSetRiskStatusModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, removeRiskStatusShow: false }, refetch);
	};

	const assignSalesPerson = (salesPersonId: string, salesPersonRole: SalesAgentRoles | undefined) => {
		if (salesPersonRole) {
			updateSalesPersonMutation.mutate({ referrerId: salesPersonId, salesPersonRole });
		}
	};

	const removeRiskStatus = () => {
		removeRiskStatusMutation.mutate();
	};

	const confirmEmail = () => {
		confirmEmailMutation.mutate();
	};

	const requestChangeCustomerStatus = (status: CustomerStatus) => {
		if (status === CustomerStatus.Disabled) {
			requestUpdateCustomerStatusMutation.mutate(CustomerStatus.Disabled);
		} else {
			requestUpdateCustomerStatusMutation.mutate(CustomerStatus.Active);
		}
	};

	const assignReferrer = (referrerId: string) => {
		updateReferrerMutation.mutate(referrerId);
	};

	const { status, mobilenumber, terminationRequestedAt } = customer;

	const hideCallCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, callCustomerModalShow: false }, false);
	};

	return (
		<>
			{modalsVisibility.changeCommissionSchemeShow && (
				<CommissionModelChangeModal userId={id!} hideModal={hideCommissionModal} />
			)}
			<ConfirmationModal
				id={id}
				show={modalsVisibility.callCustomerModalShow}
				onConfirm={callCustomer}
				hidePanel={hideCallCustomerModal}
				title={_t('customer.call-customer-on', { number: mobilenumber })}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<DisableCustomerModal
				id={id}
				show={['ACTIVE', 'PENDING'].includes(status) && modalsVisibility.confirmBarShow}
				onConfirm={requestChangeCustomerStatus}
				hidePanel={hideConfirmBar}
				title={_t('customer.disable-user')}
				error={null}
				buttonColor="danger"
				terminationRequested={terminationRequestedAt ? true : false}
			/>

			<ConfirmationModal
				id={id}
				show={status === 'DISABLED' && modalsVisibility.confirmBarShow}
				onConfirm={requestChangeCustomerStatus}
				hidePanel={hideConfirmBar}
				title={_t('customer.enable-user')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<ConfirmationModal
				id={id}
				show={modalsVisibility.removeRiskStatusShow}
				onConfirm={removeRiskStatus}
				hidePanel={hideSetRiskStatusModal}
				title={_t('customer.remove-risk-status')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			{modalsVisibility.salesPersonModalShow && (
				<AssignableCustomers
					show={modalsVisibility.salesPersonModalShow}
					onClose={hideSalesPersonModal}
					queryKey="sales-people"
					action={assignSalesPerson}
					roles={['SALES']}
					assignSalesAgent
				/>
			)}

			{modalsVisibility.affiliatesModalShow && (
				<AssignableCustomers
					show={modalsVisibility.affiliatesModalShow}
					onClose={hideAffiliatesModal}
					queryKey="affiliates-assign"
					action={assignReferrer}
					roles={null}
					assignSalesAgent={false}
				/>
			)}

			<UpdatePassword
				show={modalsVisibility.updatePasswordShow}
				customerId={customer.id}
				onClose={hideUpdatePassword}
			/>

			<ConfirmationModal
				id={id}
				show={modalsVisibility.confirmEmailShow}
				hidePanel={hideConfirmEmailPanel}
				onConfirm={confirmEmail}
				title={_t('customer.confirm-email-question')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<AssignSalesStatusModal
				show={modalsVisibility.assignSalesStatusShow}
				onClose={closeAssignSalesStatus}
				customerId={customer.id}
			/>

			<EmailCustomerModal
				show={modalsVisibility.emailCustomerModalShow}
				onClose={hideEmailCustomerModal}
				customer={customer!}
				onCloseDiscardChanges={showEmailCustomerModal}
			/>

			<MessageCustomerModal
				show={modalsVisibility.messageModalShow}
				onClose={hideMessageCustomerModal}
				customer={customer!}
				onCloseDiscardChanges={showMessageCustomerModal}
			/>
		</>
	);
};

export default CustomerModals;
