import React, { useMemo, useState } from 'react';
import { CRow, CCol, CCard, CCardBody, CButton, CCardHeader } from '@coreui/react';
import _t from 'counterpart';
import { shallowEqual } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { loadTransactions } from '../services/BackendService';
import CIcon from '@coreui/icons-react';
import { useAppSelector, useAppDispatch } from '../helpers/customHooks';
import { ITransaction } from './transactions/types';
import { TransactionStatusBadge } from './transactions/TransactionStatusBadge';
import { clearTransactionsFilters, setTransactionsFilters } from '../actions';
import { formatCurrency, formatDateTime, getFiltersCount } from '../helpers';
import PaginationTable, { ISorter } from '../components/PaginationTable';
import TransactionFilter from './transactions/TransactionFilters';
import SearchFilter from '../components/SearchFilter';
import { getExportLink, transactionFiltersToDTO } from '../helpers/transactionsHelpers';
import ExportXLSXButton from '../components/ExportXLSXButton';
import PageLayout from '../components/PageLayout';
import Error from '../components/Error';

const TransactionsPage = () => {
	const prefix = 'withdrawals-table.fields.';

	const [showFilters, setShowFilters] = useState<boolean>(false);

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const dispatch = useAppDispatch();

	const token = useAppSelector((state: any) => state.user.token);

	const transactionsItemsPerPageFromLocalStorage = Number(
		localStorage.getItem('items_per_page_transactions_table') ?? 10
	);
	const [limit, setLimit] = useState<number>(transactionsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const transactionsFilters = useAppSelector((state) => state.filters.transactionsFilters, shallowEqual);
	const { type } = transactionsFilters;
	const filtersDTO = transactionFiltersToDTO(transactionsFilters);

	const { data, isLoading, refetch, isError } = useQuery<any>(
		['transactions', filter, page, orderBy, transactionsFilters],
		() => loadTransactions(type, filter!, limit, offset, orderBy!, filtersDTO)
	);
	const createField = (key: string, sorter: boolean, filter: boolean, overrideColumnName?: string) => {
		return {
			key,
			label: overrideColumnName || _t(`${prefix}${key}`),
			sorter,
			filter,
		};
	};

	const history = useHistory();
	const queryClient = useQueryClient();

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_transactions_table', limit.toString());
		await queryClient.invalidateQueries('transactions');
		refetch();
	};

	const onRowClicked = (transaction: ITransaction) => {
		history.push(`/transactions/${transaction.id}`);
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const fields = useMemo(
		() => [
			createField('createdAt', true, false, _t(`${prefix}created`)),
			createField('type', false, false),
			createField('wallet', false, false),
			createField('customer', false, false),
			createField('kyc', false, false, 'KYC'),
			createField('amount', true, false),
			createField('currency', false, false),
			createField('status', false, false),
		],
		[]
	);

	const kycBadgeState: any = useMemo(
		() => ({
			APPROVED: {
				name: 'cil-check-circle',
				class: 'text-success',
			},
			REVOKED: {
				name: 'cil-x-circle',
				class: 'text-danger',
			},
			PENDING: {
				name: 'cil-x-circle',
				class: 'text-danger',
			},
		}),
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			createdAt: (transaction: ITransaction) => (
				<td className="text-nowrap">{formatDateTime(transaction.createdAt)}</td>
			),
			type: (transaction: ITransaction) => <td>{transaction.type}</td>,
			amount: (item: any) => <td className="py-2">{formatCurrency(item.amount)}</td>,
			wallet: (transaction: ITransaction) => <td>{transaction.wallet}</td>,
			customer: (transaction: ITransaction) => <td>{transaction.customer.name}</td>,
			status: (transaction: ITransaction) => (
				<td>
					<TransactionStatusBadge status={transaction.status} />
				</td>
			),
			kyc: (transaction: ITransaction) => {
				const { kycStatus } = transaction.customer;
				return (
					<td>
						{kycStatus && <CIcon className={kycBadgeState[kycStatus].class} name={kycBadgeState[kycStatus].name} />}
						{!kycStatus && <CIcon className="danger" name="cil-minus-circle" />}
					</td>
				);
			},
		}),
		[kycBadgeState]
	);

	const resetFilters = () => {
		dispatch(clearTransactionsFilters());
	};

	const filtersCount = getFiltersCount(transactionsFilters);
	let recordCount = 0;

	if (data) {
		const { pages, transactions } = data;
		recordCount = Number(pages) + Number(transactions.length);
	}

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('transactions.all-transactions')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										<div className="float-right">
											<ExportXLSXButton
												downloadLink={getExportLink(filter, transactionsFilters, type)}
												defaultFilename="transactions.xlsx"
												disabled={recordCount < 1}
											/>
										</div>
									</div>
								</div>
								<TransactionFilter
									dispatchAction={setTransactionsFilters}
									show={showFilters}
									activeFilters={transactionsFilters}
									onFilterChanged={onFilterChanged}
									showTypeFilter={true}
								/>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								scopedSlots={scopedSlots}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								data={data?.transactions || []}
								pages={data?.pages || 0}
								tableFields={fields}
								pagination
								clickableRows
								onRowClicked={onRowClicked}
								loading={isLoading}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default TransactionsPage;
