import React from 'react';
import { ICompetition } from './types';
import { CBadge } from '@coreui/react';

export const CompetitionStatusBadge = (props: { competition: ICompetition }) => {
	const { competition } = props;

	let color = 'success';
	let status = 'active';

	if (competition.end.getTime() < new Date().getTime()) {
		color = 'danger';
		status = 'expired';
	}

	if (competition.start.getTime() > new Date().getTime()) {
		color = 'primary';
		status = 'upcoming';
	}

	return <CBadge color={color}>{status}</CBadge>;
};
