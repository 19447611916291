import React, { useEffect, useState } from 'react';
import {
	CFormGroup,
	CLabel,
	CInput,
	CForm,
	CInputGroupPrepend,
	CInputGroupText,
	CInputGroup,
	CInputCheckbox,
	CCard,
	CCardBody,
	CCol,
	CRow,
} from '@coreui/react';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';
import { CompetitionType } from './types';
import { createCompetition, loadCompetition, updateCompetition } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import PageLayout from '../../components/PageLayout';
import { useHistory } from 'react-router-dom';
import { extractErrorMessage } from '../../helpers';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import toast from 'react-hot-toast';

interface IState {
	competitionName: string;
	startDate: Date;
	endDate: Date;
	prizeOne: number | null;
	prizeTwo: number | null;
	prizeThree: number | null;
	competitionId: string | null;
	tcLink: string | null;
	promoted: boolean;
}

const AddEditCompetitionPage = () => {
	const [competitionType, setCompetitionType] = useState<ISelectOption | null>(null);

	const competitionTypes = [
		{ value: CompetitionType.PROFIT, label: _t('competitions.PROFIT') },
		{ value: CompetitionType.PROFITLOSS, label: _t('competitions.PROFITLOSS') },
	];

	const initialStartDate = moment().minutes(0).seconds(0).millisecond(0).toDate();

	const initialEndDate = moment().add(1, 'month').minutes(0).seconds(0).toDate();

	const initialState = {
		competitionId: '',
		competitionName: '',
		startDate: initialStartDate,
		endDate: initialEndDate,
		prizeOne: null,
		prizeTwo: null,
		prizeThree: null,
		competitionNameError: null,
		competitionDateError: null,
		competitionPrizeError: null,
		tcLink: '',
		promoted: false,
	};

	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');
	const isEditing = editParam !== null;

	const [state, setState] = useState<IState>(initialState);

	const { competitionName, startDate, endDate, prizeOne, prizeTwo, prizeThree, tcLink, promoted } = state;

	const loadCompetitionQuery = useQuery(['competition-edit', editParam], () => loadCompetition(editParam || ''), {
		enabled: isEditing,
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const createUpdateCompetitionMutation = useMutation(
		() => {
			const prizes = [prizeOne, prizeTwo, prizeThree];
			if (isEditing) {
				return updateCompetition(
					editParam || '',
					competitionName,
					startDate.toString(),
					endDate.toString(),
					prizes,
					tcLink || null,
					promoted
				);
			}
			const type = (competitionType?.value as CompetitionType) || null;
			return createCompetition(
				competitionName,
				startDate.toString(),
				endDate.toString(),
				prizes,
				type,
				tcLink || null,
				promoted
			);
		},
		{
			onSuccess: () => {
				if (isEditing) {
					toast.success(_t('competitions.competition-update-successful'));
				} else {
					toast.success(_t('competitions.competition-create-successful'));
				}
				history.push('/competitions');
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	useEffect(() => {
		if (loadCompetitionQuery.data && loadCompetitionQuery.isSuccess) {
			const { competition } = loadCompetitionQuery.data;
			setState({
				competitionId: competition.id,
				competitionName: competition.description,
				startDate: competition.start,
				endDate: competition.end,
				prizeOne: competition.firstPrizeInUSD,
				prizeTwo: competition.secondPrizeInUSD,
				prizeThree: competition.thirdPrizeInUSD,
				tcLink: competition.tcLink,
				promoted: competition.promoted,
			});
			const { type } = competition;
			setCompetitionType({ value: type, label: _t(`competitions.${type}`) });
		}
	}, [loadCompetitionQuery.data, loadCompetitionQuery.isSuccess]);

	if (isEditing) {
		if (loadCompetitionQuery.isLoading || loadCompetitionQuery.isIdle) {
			return <Loading />;
		}

		if (loadCompetitionQuery.isError) {
			return <Error onRetry={loadCompetitionQuery.refetch} />;
		}
	}

	const error = createUpdateCompetitionMutation.error as any;
	const errors = error?.response?.data?.errors || null;

	const extractValidationError = (param: string) => {
		if (errors) {
			const errorObject = errors.find((e: any) => e.param === param);
			if (errorObject) {
				return errorObject.msg;
			}
		}
		return null;
	};

	const handleStartDateChange = (startDate: Moment | string) => {
		if (typeof startDate !== 'string') {
			setState({ ...state, startDate: startDate.toDate() });
		}
	};

	const handleEndDateChange = (endDate: Moment | string) => {
		if (typeof endDate !== 'string') {
			setState({ ...state, endDate: endDate.toDate() });
		}
	};

	const handleInputChange = (e: React.FormEvent<any>, assureInputIsNumber?: boolean) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		const value = assureInputIsNumber ? Number(target.value) : target.value;

		if (name) {
			setState({ ...state, [name]: value });
		}
	};

	const handleCheckboxChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement;
		const name: string | null = target.getAttribute('name');
		const { checked } = target;

		if (name) {
			setState({ ...state, [name]: checked });
		}
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		createUpdateCompetitionMutation.mutate();
	};

	const handleTypeChanged = (newValue: any) => {
		const value = newValue as ISelectOption;
		setCompetitionType(value);
	};

	return (
		<PageLayout title={isEditing ? _t('competitions.edit-competition') : _t('competitions.create.competition')}>
			<CForm onSubmit={submitForm}>
				<CRow>
					<CCol>
						<CCard>
							<CCardBody>
								<CFormGroup>
									<CLabel htmlFor="name">{_t('global.name')}</CLabel>
									<CInput
										type="text"
										id="competitionName"
										name="competitionName"
										value={state.competitionName}
										onChange={handleInputChange}
									/>
								</CFormGroup>
								<div className="text-danger">{extractValidationError('description')}</div>
								<CFormGroup>
									<CLabel>{_t('competitions.columns.start')}</CLabel>
									<Datetime
										value={state.startDate}
										onChange={handleStartDateChange}
										dateFormat="DD/MM/YYYY"
										timeFormat="HH:mm"
										timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
										inputProps={{ readOnly: true }}
									/>
									<CLabel>{_t('competitions.columns.end')}</CLabel>
									<Datetime
										value={state.endDate}
										onChange={handleEndDateChange}
										dateFormat="DD/MM/YYYY"
										timeFormat="HH:mm"
										timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
										inputProps={{ readOnly: true }}
									/>
									<div className="text-danger">{extractValidationError('end')}</div>
								</CFormGroup>

								<CFormGroup>
									<div className="d-flex justify-content-between">
										<div className="w-30">
											<CLabel htmlFor="name">{_t('competitions.create.first-prize')}</CLabel>
											<CInputGroup>
												<CInputGroupPrepend>
													<CInputGroupText>$</CInputGroupText>
												</CInputGroupPrepend>
												<CInput
													type="number"
													id="prizeOne"
													name="prizeOne"
													placeholder="100"
													value={state.prizeOne ?? ''}
													onChange={(e) => handleInputChange(e, true)}
												/>
											</CInputGroup>
										</div>
										<div className="w-30">
											<CLabel htmlFor="name">{_t('competitions.create.second-prize')}</CLabel>
											<CInputGroup>
												<CInputGroupPrepend>
													<CInputGroupText>$</CInputGroupText>
												</CInputGroupPrepend>
												<CInput
													type="number"
													id="prizeTwo"
													name="prizeTwo"
													placeholder="50"
													value={state.prizeTwo ?? ''}
													onChange={(e) => handleInputChange(e, true)}
												/>
											</CInputGroup>
										</div>
										<div className="w-30">
											<CLabel htmlFor="name">{_t('competitions.create.third-prize')}</CLabel>
											<CInputGroup>
												<CInputGroupPrepend>
													<CInputGroupText>$</CInputGroupText>
												</CInputGroupPrepend>
												<CInput
													type="number"
													id="prizeThree"
													name="prizeThree"
													placeholder="20"
													value={state.prizeThree ?? ''}
													onChange={(e) => handleInputChange(e, true)}
												/>
											</CInputGroup>
										</div>
									</div>
									<div className="text-danger">{extractValidationError('secondPrizeInUSD')}</div>
									<div className="text-danger">{extractValidationError('firstPrizeInUSD')}</div>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="tcLink">{_t('global.type')}</CLabel>
									<CustomSelect
										value={competitionType}
										options={competitionTypes}
										onChange={handleTypeChanged}
										isClearable={false}
										whiteBackground
										isDisabled={isEditing}
									/>
									<div className="text-danger">{extractValidationError('type')}</div>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="tcLink">{_t('competitions.tc-link')}</CLabel>
									<CInput
										type="text"
										id="tcLink"
										name="tcLink"
										value={state.tcLink ?? ''}
										onChange={handleInputChange}
									/>
								</CFormGroup>
								<div className="text-danger">{extractValidationError('tcLink')}</div>
								<CFormGroup variant="checkbox" inline>
									<CInputCheckbox
										id="promoted"
										name="promoted"
										checked={state.promoted}
										onChange={handleCheckboxChange}
									/>
									<CLabel variant="checkbox" htmlFor="promoted">
										{_t('competitions.promoted')}
									</CLabel>
								</CFormGroup>
								<div className="text-danger">{extractValidationError('promoted')}</div>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={createUpdateCompetitionMutation.isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isEditing ? _t('action.update') : _t('action.create')}
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default AddEditCompetitionPage;
