import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import Loading from './Loading';

interface IProps {
	onClose: () => void;
	title: string;
}

const LoadingModal = ({ onClose, title }: IProps) => {
	return (
		<CModal show onClose={onClose}>
			<CModalHeader closeButton className="d-flex align-items-center flex-column">
				<CModalTitle>{title}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<Loading />
			</CModalBody>
		</CModal>
	);
};

export default React.memo(LoadingModal);
