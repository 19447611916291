import React, { useMemo } from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _t from 'counterpart';
import NoItems from './NoItems';
import { ILotsPerInstrument } from '../services/RiskService';

interface IProps {
	lotsPerInstrument: Array<ILotsPerInstrument>;
}

const LotsPerInstrumentChart: React.FC<IProps> = ({ lotsPerInstrument }) => {
	const parsedLotsPerInstrument = useMemo(() => {
		return lotsPerInstrument.map((item) => ({
			...item,
			lots: Number(item.lots),
		}));
	}, [lotsPerInstrument]);

	if (parsedLotsPerInstrument.length === 0) {
		return <NoItems />;
	}
	return (
		<ResponsiveContainer height={parsedLotsPerInstrument.length * 30}>
			<BarChart
				barCategoryGap={1}
				layout="vertical"
				data={parsedLotsPerInstrument}
				margin={{
					top: 0,
					left: -25,
					right: 0,
					bottom: 0,
				}}
			>
				<XAxis type="number" hide padding={{ right: 45 }} />

				<YAxis type="category" dataKey="symbol" width={100} height={30} />

				<Tooltip formatter={(value: number) => [Number(value.toFixed(2)), _t('customer.lots')]} />

				<Bar dataKey="lots" fill="var(--primary)" width={30} strokeWidth={30} height={30}>
					<LabelList dataKey="lots" position="right" />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default LotsPerInstrumentChart;
