import React from 'react';
import { CAlert } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { loadProfitLossReport } from '../../services/BackendService';
import { formatCurrency } from '../../helpers';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

const ReportResultsProfitLoss = ({ filters, onFetch }: { filters: any; onFetch: Function }) => {
	const from = filters.range?.startDate;
	const to = filters.range?.endDate;

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy = 'accountId|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const { isLoading, error, data } = useQuery<any, Error>(
		['reports', filters, page, orderBy],
		() => {
			return loadProfitLossReport(from, to, limit, offset, orderBy!);
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data: any) => {
				if (data?.report?.data) {
					onFetch(data.report.data.length);
				}
			},
			onError: () => onFetch(0),
		}
	);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const orderBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		setOrderBy(orderBy, 'replaceIn');
		setPage(1, 'replaceIn');
	};

	const currency = data?.report?.currency;
	const pages = data?.report?.count > 0 ? Math.ceil(data?.report?.count / limit) : 0;

	if (error) {
		return <CAlert color="danger">{error.toString()}</CAlert>;
	}

	return (
		<PaginationTable
			tableFields={[
				{ key: 'accountId', label: _t('reports.profitloss.accountId') },
				{ key: 'customer', label: _t('reports.profitloss.customer') },
				{ key: 'deposit', label: _t('reports.profitloss.deposit') },
				{ key: 'withdrawal', label: _t('reports.profitloss.withdrawal') },
				{ key: 'profitLoss', label: _t('reports.profitloss.profitLoss') },
			]}
			scopedSlots={{
				accountId: (row: any) => <td className="text-nowrap">{row.accountId}</td>,
				customer: (row: any) => {
					const { customer } = row;
					if (!customer) {
						return <td className="text-nowrap">n/a</td>;
					}

					return (
						<td className="text-nowrap">
							<a href={`/customers/${customer.id}`}>{customer.name}</a>
						</td>
					);
				},
				deposit: (row: any) => <td className="text-nowrap">{formatCurrency(row.deposit, currency)}</td>,
				withdrawal: (row: any) => <td className="text-nowrap">{formatCurrency(row.withdrawal, currency)}</td>,
				profitLoss: (row: any) => <td className="text-nowrap">{formatCurrency(row.profitLoss, currency)}</td>,
			}}
			data={data?.report?.data || []}
			loading={isLoading}
			pagination
			pages={pages}
			onPageChanged={onPageChanged}
			activePage={page || 1}
			onSorterChanged={onSorterChanged}
			sorter={{ column, asc }}
		/>
	);
};

export default ReportResultsProfitLoss;
