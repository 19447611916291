import React from 'react';
import { CAlert } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { loadCoinsBalanceReport } from '../../services/BackendService';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import { formatCurrency } from '../../helpers';
import { ICoinsBalanceRequest, ICoinsBalance } from './types';

const ReportCoinsBalanace = ({ filters, onFetch }: { filters: any; onFetch: Function }) => {
	const allowedSortColumns = ['totalEarnedFromTrades', 'balance'];

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy = 'balance|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	if (!allowedSortColumns.includes(column)) {
		setOrderBy('balance|DESC');
	}

	const { isLoading, error, data } = useQuery<ICoinsBalanceRequest, Error>(
		['reports', filters, page, orderBy],
		() => {
			return loadCoinsBalanceReport(limit, offset, orderBy!);
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data: any) => {
				if (data?.rows) {
					onFetch(data.rows.length);
				}
			},
			onError: () => onFetch(0),
		}
	);

	const count = data?.count || 0;
	const pages = count > 0 ? Math.ceil(count / limit) : 0;

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	if (error) {
		return <CAlert color="danger">{error.toString()}</CAlert>;
	}

	return (
		<>
			<PaginationTable
				tableFields={[
					{ key: 'accountId', label: _t.translate('reports.balance.accountId'), sorter: false },
					{ key: 'id', label: _t.translate('reports.balance.customer'), sorter: false },
					{ key: 'balance', label: _t.translate('reports.balance.balance') },
					{
						key: 'totalCreditedToAffiliate',
						label: _t.translate('reports.coins.totalCreditedToAffiliate'),
						sorter: false,
					},
					{ key: 'totalEarnedFromTrades', label: _t.translate('reports.coins.totalEarnedFromTrades') },
				]}
				scopedSlots={{
					accountId: (row: ICoinsBalance) => <td className="text-nowrap">{row.accountId}</td>,
					id: (row: ICoinsBalance) => {
						const { id, name } = row;
						if (!id) {
							return <td className="text-nowrap">n/a</td>;
						}

						return (
							<td className="text-nowrap">
								<a href={`/customers/${id}`}>{name}</a>
							</td>
						);
					},
					balance: (row: ICoinsBalance) => <td className="text-nowrap">{row.balance}</td>,
					totalCreditedToAffiliate: (row: ICoinsBalance) => (
						<td className="text-nowrap">{row.totalCreditedToAffiliate}</td>
					),
					totalEarnedFromTrades: (row: ICoinsBalance) => <td className="text-nowrap">{row.totalEarnedFromTrades}</td>,
					equity: (row: any) => <td className="text-nowrap">{formatCurrency(row.equity, 'USD')}</td>,
				}}
				data={data?.rows || []}
				loading={isLoading}
				pagination
				pages={pages}
				onPageChanged={onPageChanged}
				activePage={page || 1}
				onSorterChanged={onSorterChanged}
				sorter={{ column, asc }}
			/>
		</>
	);
};

export default ReportCoinsBalanace;
