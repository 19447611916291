import React from 'react';
import { CLabel } from '@coreui/react';

interface IInputHeaderProps {
	labelText: string;
}

const InputHeaderNoButton = ({ labelText }: IInputHeaderProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
		</div>
	);
};

export default React.memo(InputHeaderNoButton);
