import React, { useMemo, useState } from 'react';
import _t from 'counterpart';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardHeader,
	CNav,
	CNavItem,
	CNavLink,
	CTabContent,
	CTabPane,
	CTabs,
	CButton,
} from '@coreui/react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { IPosition } from '../positions/position';
import { formatCurrency, formatDateTime, isSet } from '../../helpers';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import PositionTypeBadge from '../../components/PositionTypeBadge';
import PositionStatusBadge from '../../components/PositionStatusBadge';
import { getUsersOrders, getUsersPositions } from '../../services/BackendService';
import { useAppSelector, useLiveCustomerOpenPositionUpdate } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';
import { IOrder } from '../orders/types';
import OrderStatusBadge from '../orders/OrderStatusBadge';

function CustomerPositionsTable() {
	const [closedPage, setClosedPage] = useState<number>(1);
	const [orderBy, setOrderBy] = useState<string>('closedAt|DESC');
	const limit = 10;
	const offset = closedPage * limit - limit;

	const params = useParams<{ customerId: string }>();
	const history = useHistory();
	const { customerId } = params;

	const permissions = useAppSelector((state) => state.user.permissions);

	const onRowClicked = (pos: IPosition) => {
		if (permissions?.includes(Permission.MANAGE_TRANSACTIONS)) {
			history.push(`/positions/${pos.id}`);
		}
	};

	const onOrderRowClicked = (order: IOrder) => {
		if (permissions?.includes(Permission.MANAGE_ORDERS)) {
			history.push(`/orders/${order.id}`);
		}
	};

	const openPositionsQuery = useQuery(['customer-open-positions', customerId], () =>
		getUsersPositions(customerId, 0, 0, 'open')
	);

	useLiveCustomerOpenPositionUpdate(customerId, openPositionsQuery.data?.positions.map((p) => p.id) ?? []);

	const closedPositionsQuery = useQuery(['customer-closed-positions', customerId, offset, orderBy], () =>
		getUsersPositions(customerId, limit, offset, 'closed', orderBy)
	);

	const pendingOrdersQuery = useQuery(['customer-pending-orders', customerId], () => getUsersOrders(customerId));

	const count = closedPositionsQuery.data?.count || null;
	const pages = count === null ? 0 : Math.ceil(count / limit);

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy);
		}
	};

	const fields = useMemo(
		() => [
			{
				key: 'side',
				label: _t('positions.side'),
				sorter: false,
			},
			{
				key: 'symbol',
				label: _t('positions.symbol'),
				sorter: false,
			},
			{
				key: 'openedAt',
				label: _t('positions.opened-at'),
				sorter: false,
			},
			{
				key: 'id',
				label: _t('positions.external-id'),
				sorter: false,
			},
			{
				key: 'lots',
				label: _t('positions.lots'),
				sorter: false,
			},
			{
				key: 'profit',
				label: _t('positions.profit'),
				sorter: false,
				_style: { minWidth: '150px' },
			},
			{
				key: 'status',
				label: _t('positions.status'),
				sorter: false,
			},
		],
		[]
	);

	const slots = useMemo(
		() => ({
			side: (position: IPosition) => (
				<td>
					<PositionTypeBadge position={position} />
				</td>
			),
			status: (position: IPosition) => (
				<td>
					<PositionStatusBadge position={position} />
				</td>
			),
			profit: (position: IPosition) => {
				const { closedPLInAccountCurrency } = position;
				return (
					<td className="text-nowrap">
						{isSet(closedPLInAccountCurrency) ? formatCurrency(closedPLInAccountCurrency) : '-'}
					</td>
				);
			},
			lots: (position: IPosition) => <td>{position?.size ? position.size : '-'}</td>,
			openedAt: (position: IPosition) => (
				<td className="text-nowrap">{new Date(position.openedAt).toLocaleString()}</td>
			),
		}),
		[]
	);

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'externalId', label: _t('positions.orders.external-id'), sorter: false },
			{ key: 'positionId', label: _t('orders.position-id'), sorter: false },
			{ key: 'side', label: _t('positions.side'), sorter: false },
			{ key: 'dates', label: _t('positions.orders.dates'), sorter: false },
			{ key: 'quantity', label: _t('positions.orders.quantity'), sorter: false },
			{ key: 'prices', label: _t('positions.orders.prices'), sorter: false },
			{ key: 'status', label: _t('positions.status'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			externalId: ({ externalId }: IOrder) => <td>{externalId || '-'}</td>,
			dates: ({ executedAt, placedAt }: IOrder) => (
				<td>
					<div>{placedAt ? formatDateTime(placedAt) : '-'}</div>
					<div>{executedAt ? formatDateTime(executedAt) : '-'}</div>
				</td>
			),
			quantity: ({ quantity, filledQuantity }: IOrder) => (
				<td>
					<div>{quantity || '-'}</div>
					<div>{filledQuantity || '-'}</div>
				</td>
			),
			prices: ({ averagePrice, requestPrice }: IOrder) => (
				<td>
					<div>{requestPrice || '-'}</div>
					<div>{averagePrice || '-'}</div>
				</td>
			),
			status: ({ status, rejectReason }: IOrder) => (
				<td>
					<div>
						<OrderStatusBadge status={status} />
					</div>
					{rejectReason && <div>{rejectReason}</div>}
				</td>
			),
		}),
		[]
	);

	const openPositionsSlots = useMemo(
		() => ({
			...slots,
			profit: (position: IPosition) => {
				const { profitLoss } = position;
				return <td className="text-nowrap">{isSet(profitLoss) ? formatCurrency(profitLoss) : '-'}</td>;
			},
		}),
		[slots]
	);

	const closedPositionFields = useMemo(
		() => [
			...fields,
			{
				key: 'closedAt',
				label: _t('positions.closed-at'),
				sorter: true,
			},
		],
		[fields]
	);

	const closedPositionsSlots = useMemo(
		() => ({
			...slots,
			closedAt: (position: IPosition) => {
				return (
					<td className="text-nowrap">{position.closedAt ? new Date(position.closedAt).toLocaleString() : '-'}</td>
				);
			},
		}),
		[slots]
	);

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onPositionCreateClicked = () => {
		history.push(`/customers/${customerId}/position_form`);
	};

	return (
		<CRow>
			<CCol md={12}>
				<CCard className="card card--listing">
					<CCardBody className="card__holder">
						<CCardHeader className="card__header">
							<div className="card__info">
								<h4 className="card__title">{_t('customer.customer-positions-title')}</h4>
							</div>
							{permissions?.includes(Permission.MANAGE_POSITIONS) && (
								<CButton color="primary" className="mr-2 header-button-add" onClick={onPositionCreateClicked} />
							)}
						</CCardHeader>
						<CTabs activeTab="open">
							<CNav variant="tabs">
								<CNavItem>
									<CNavLink data-tab="open">{_t('global.open')}</CNavLink>
								</CNavItem>
								<CNavItem>
									<CNavLink data-tab="closed">{_t('global.closed')}</CNavLink>
								</CNavItem>
								<CNavItem>
									<CNavLink data-tab="orders">{_t('customer.customer-pending-orders')}</CNavLink>
								</CNavItem>
							</CNav>
							<CTabContent>
								<CTabPane data-tab="open" className="no-border-top">
									<PaginationTable
										tableFields={fields}
										scopedSlots={openPositionsSlots}
										data={openPositionsQuery.data?.positions || []}
										loading={openPositionsQuery.isLoading}
										pages={0}
										pagination
										clickableRows={permissions?.includes(Permission.MANAGE_TRANSACTIONS)}
										onRowClicked={onRowClicked}
										activePage={1}
									/>
								</CTabPane>
								<CTabPane data-tab="closed" className="no-border-top">
									<PaginationTable
										tableFields={closedPositionFields}
										scopedSlots={closedPositionsSlots}
										onSorterChanged={onSorterChanged}
										sorter={{ column, asc }}
										data={closedPositionsQuery.data?.positions || []}
										loading={closedPositionsQuery.isLoading}
										pages={pages}
										pagination
										clickableRows={permissions?.includes(Permission.MANAGE_TRANSACTIONS)}
										onRowClicked={onRowClicked}
										activePage={closedPage}
										onPageChanged={setClosedPage}
									/>
								</CTabPane>
								<CTabPane data-tab="orders" className="no-border-top">
									<PaginationTable
										tableFields={tableFields}
										scopedSlots={scopedSlots}
										data={pendingOrdersQuery.data || []}
										loading={pendingOrdersQuery.isLoading}
										pages={0}
										clickableRows={permissions?.includes(Permission.MANAGE_TRANSACTIONS)}
										onRowClicked={onOrderRowClicked}
									/>
								</CTabPane>
							</CTabContent>
						</CTabs>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	);
}

export default CustomerPositionsTable;
