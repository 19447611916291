import React from 'react';
import { CBadge } from '@coreui/react';
import { UserRole } from '../../../reducers/userReducer';

const CustomerRoleBadge = ({ role }: { role: UserRole }) => {
	const colors: any = {
		ADMIN: 'success',
		TRADER: 'danger',
		AFFILIATE: 'primary',
		LEADER: 'info',
		SALES: 'warning',
		GROUP_ADMIN: 'success',
	};

	return <CBadge color={colors[role]}>{role}</CBadge>;
};

export default CustomerRoleBadge;
