export const cilCurrencyConversion1 = [
  '40 40',
  `
<path d="M12.775 26.7316H8.975C8.375 26.7316 7.875 26.5316 7.475 26.0316C7.075 25.6316 6.875 25.0316 6.875 24.4316C6.875 23.8316 7.075 23.2316 7.475 22.8316C7.875 22.4316 8.475 22.1316 8.975 22.1316H14.975V20.5316H11.775V17.5316H10.275V20.5316H8.975C7.975 20.5316 7.075 20.9316 6.375 21.6316C5.775 22.3316 5.375 23.3316 5.375 24.4316C5.375 25.5316 5.775 26.4316 6.475 27.2316C7.075 27.9316 8.075 28.3316 8.975 28.3316H12.675C13.275 28.3316 13.775 28.5316 14.175 29.0316C14.575 29.4316 14.775 30.0316 14.775 30.6316V30.8316C14.775 31.4316 14.575 32.0316 14.175 32.4316C13.775 32.8316 13.275 33.1316 12.675 33.1316H6.475V34.7316H10.375V37.5316H11.875V34.7316H12.775C13.775 34.7316 14.675 34.3316 15.375 33.6316C16.075 32.9316 16.475 31.9316 16.475 30.8316V30.6316C16.475 29.6316 16.075 28.6316 15.375 27.8316C14.675 27.1316 13.675 26.7316 12.775 26.7316Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.9 17.1566H12.15V20.1566H15.35V22.5066H8.975C8.60069 22.5066 8.09086 22.7461 7.74016 23.0968C7.4264 23.4105 7.25 23.906 7.25 24.4316C7.25 24.9572 7.4264 25.4527 7.74016 25.7664L7.75485 25.7811L7.76783 25.7973C8.08817 26.1978 8.47459 26.3566 8.975 26.3566H12.775C13.7756 26.3566 14.8706 26.7969 15.6402 27.5664L15.649 27.5753L15.6572 27.5847C16.4111 28.4462 16.85 29.5321 16.85 30.6316V30.8316C16.85 32.0218 16.4168 33.1202 15.6402 33.8968C14.8746 34.6624 13.881 35.1066 12.775 35.1066H12.25V37.9066H10V35.1066H6.1V32.7566H12.675C13.1405 32.7566 13.5507 32.5256 13.9098 32.1664C14.2236 31.8527 14.4 31.3572 14.4 30.8316V30.6316C14.4 30.106 14.2236 29.6105 13.9098 29.2968L13.8951 29.2821L13.8822 29.2659C13.5618 28.8654 13.1754 28.7066 12.675 28.7066H8.975C7.98097 28.7066 6.87202 28.2699 6.19155 27.4771C5.44185 26.6198 5 25.6351 5 24.4316C5 23.2428 5.43156 22.1561 6.09028 21.3876L6.09965 21.3766L6.10983 21.3664C6.87543 20.6008 7.86904 20.1566 8.975 20.1566H9.9V17.1566ZM10.65 17.9066V20.9066H8.975C8.08576 20.9066 7.28324 21.2585 6.6504 21.8866C6.11421 22.5176 5.75 23.4262 5.75 24.4316C5.75 25.4275 6.10767 26.2423 6.75722 26.9847L6.75974 26.9875C7.27938 27.5938 8.16965 27.9566 8.975 27.9566H12.675C13.3683 27.9566 13.9772 28.1935 14.4549 28.7814C14.9309 29.267 15.15 29.964 15.15 30.6316V30.8316C15.15 31.506 14.9264 32.2105 14.4402 32.6968C13.9993 33.1376 13.4095 33.5066 12.675 33.5066H6.85V34.3566H10.75V37.1566H11.5V34.3566H12.775C13.669 34.3566 14.4754 34.0008 15.1098 33.3664C15.7332 32.743 16.1 31.8414 16.1 30.8316V30.6316C16.1 29.7349 15.742 28.8248 15.1011 28.088C14.471 27.463 13.5707 27.1066 12.775 27.1066H8.975C8.28166 27.1066 7.6728 26.8697 7.19506 26.2818C6.71906 25.7962 6.5 25.0992 6.5 24.4316C6.5 23.7572 6.72359 23.0527 7.20983 22.5664C7.65914 22.1171 8.34931 21.7566 8.975 21.7566H14.6V20.9066H11.4V17.9066H10.65Z" />
<path d="M32.475 8.0316H35.375V6.5316H32.475C28.975 6.5316 25.975 8.7316 24.775 11.7316H22.375V13.2316H24.375C24.275 14.1316 24.275 14.9316 24.375 15.8316H22.375V17.3316H24.775C25.975 20.3316 28.975 22.5316 32.475 22.5316H35.375V21.0316H32.475C31.175 21.0316 29.975 20.6316 28.875 20.0316C27.775 19.3316 26.975 18.4316 26.375 17.3316H33.775V15.8316H25.875C25.675 14.9316 25.675 14.1316 25.875 13.2316H33.775V11.7316H26.375C26.875 10.6316 27.775 9.6316 28.875 9.0316C29.975 8.3316 31.275 8.0316 32.475 8.0316Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.5253 11.3566C25.84 8.34105 28.908 6.1566 32.475 6.1566H35.75V8.4066H32.475C31.3333 8.4066 30.106 8.6927 29.0763 9.34797L29.0657 9.35476L29.0546 9.36081C28.2082 9.82249 27.4852 10.5414 26.9955 11.3566H34.15V13.6066H26.1827C26.0724 14.2385 26.0724 14.8247 26.1827 15.4566H34.15V17.7066H27.0366C27.5602 18.5019 28.2187 19.1674 29.0657 19.7085C30.1239 20.284 31.2584 20.6566 32.475 20.6566H35.75V22.9066H32.475C28.908 22.9066 25.84 20.7222 24.5253 17.7066H22V15.4566H23.9625C23.9125 14.8287 23.9125 14.2345 23.9625 13.6066H22V11.3566H24.5253ZM32.475 6.9066C29.1343 6.9066 26.2685 9.00765 25.1232 11.8709L25.0289 12.1066H22.75V12.8566H24.794L24.7477 13.273C24.6508 14.1455 24.6508 14.9177 24.7477 15.7902L24.794 16.2066H22.75V16.9566H25.0289L25.1232 17.1923C26.2685 20.0556 29.1343 22.1566 32.475 22.1566H35V21.4066H32.475C31.0958 21.4066 29.834 20.9819 28.6954 20.3608L28.6843 20.3548L28.6737 20.348C27.516 19.6113 26.6737 18.6623 26.0458 17.5112L25.7433 16.9566H33.4V16.2066H25.5742L25.5089 15.913C25.297 14.9594 25.297 14.1038 25.5089 13.1503L25.5742 12.8566H33.4V12.1066H25.7926L26.0336 11.5764C26.5643 10.4089 27.5153 9.3495 28.6843 8.70846C29.8523 7.9686 31.2205 7.6566 32.475 7.6566H35V6.9066H32.475Z" />
<path d="M25.075 35.2316C27.275 35.1316 29.275 34.1316 30.775 32.4316C32.175 30.7316 32.875 28.6316 32.775 26.3316L31.375 26.4316C31.475 28.3316 30.875 30.1316 29.675 31.5316C28.475 32.9316 26.775 33.7316 24.975 33.8316L21.975 34.0316L24.475 31.2316L23.475 30.3316L19.375 34.8316L23.975 39.0316L24.875 38.0316L22.075 35.3316L25.075 35.2316Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.1328 25.9301L33.1496 26.3153C33.2535 28.7053 32.5249 30.8966 31.0644 32.67L31.0563 32.6798C29.4933 34.4512 27.3999 35.5013 25.092 35.6062L25.0874 35.6064L22.9732 35.6769L25.3961 38.0132L24.001 39.5632L18.8455 34.8559L23.4488 29.8035L25.0034 31.2027L22.8663 33.5963L24.9541 33.4572C26.6563 33.3626 28.2595 32.6068 29.3902 31.2876C30.5254 29.9632 31.0956 28.2585 31.0005 26.4513L30.9811 26.0838L33.1328 25.9301ZM31.7605 26.78C31.7741 28.6372 31.1464 30.3911 29.9597 31.7756C28.6909 33.2559 26.8949 34.1 24.9979 34.2059L21.0836 34.4669L23.9465 31.2605L23.5011 30.8597L19.9044 34.8072L23.9489 38.5L24.3538 38.05L21.1767 34.9863L25.0602 34.8569C27.1494 34.7613 29.0535 33.8134 30.4895 32.1883C31.7481 30.6577 32.4155 28.7894 32.4093 26.7337L31.7605 26.78Z" />
<path d="M15.975 5.4316C13.775 5.5316 11.775 6.5316 10.275 8.2316C8.87502 9.9316 8.17502 12.0316 8.27502 14.3316L9.67502 14.2316C9.47502 12.3316 10.175 10.5316 11.375 9.1316C12.575 7.7316 14.275 6.9316 16.075 6.8316L19.075 6.6316L16.575 9.4316L17.575 10.3316L21.675 5.7316L17.075 1.5316L16.175 2.5316L18.975 5.3316L15.975 5.4316Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.049 1L22.2015 5.70451L17.6042 10.8624L16.0465 9.46047L18.1837 7.06686L16.0958 7.20605C14.3936 7.30061 12.7905 8.05644 11.6597 9.37565C10.5148 10.7115 9.86056 12.412 10.048 14.1923L10.0886 14.578L7.91713 14.7331L7.90038 14.3479C7.79646 11.9579 8.52512 9.76659 9.98555 7.99321L9.99367 7.98335C11.5566 6.21199 13.6501 5.16189 15.958 5.05699L15.9625 5.05678L18.0987 4.9856L15.6583 2.54519L17.049 1ZM17.1011 2.0632L16.6918 2.51801L19.8514 5.6776L15.9898 5.80632C13.9006 5.9019 11.9965 6.84978 10.5604 8.47492C9.30186 10.0055 8.63443 11.8738 8.64066 13.9295L9.27291 13.8844C9.18711 12.0097 9.91566 10.258 11.0903 8.88755C12.3591 7.40731 14.1551 6.56323 16.0521 6.45729L19.9664 6.19635L17.1035 9.40274L17.5458 9.80079L21.1485 5.75869L17.1011 2.0632Z" />
`,
];
