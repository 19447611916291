import React, { useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { CRow, CCol, CCard, CCardBody, CCardHeader, CButton, CDataTable } from '@coreui/react';
import ExchangeRateModal from './ExchangeRateModal';
import { getExchangeRates } from '../../services/BackendService';
import { useQuery } from 'react-query';
import { IExchangeRate } from './types';
import SourceBadge from './SourceBadge';
import CIcon from '@coreui/icons-react';

const ExchangeRatesPage = () => {
	const [mutationModalShow, setShowMutationModal] = useState<boolean>(false);
	const [currentExchangeRate, setCurrentExchangeRate] = useState<IExchangeRate | null>(null);

	const exchangeRatesQuery = useQuery(['exchange-rates'], () => getExchangeRates());

	const setExchangeRate = (exchangeRate: IExchangeRate) => {
		setCurrentExchangeRate(exchangeRate);
		setShowMutationModal(true);
	};

	const fields = useMemo(
		() => [
			{ key: 'fromCurrency', label: _t('exchangeRates.from-currency') },
			{ key: 'toCurrency', label: _t('exchangeRates.to-currency') },
			{ key: 'source', label: _t('global.source') },
			{ key: 'rate', label: _t('exchangeRates.rate') },
			{ key: 'actions', label: _t('global.actions') },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			source: (exchangeRate: IExchangeRate) => (
				<td>
					<SourceBadge source={exchangeRate.source} />
				</td>
			),
			rate: ({ rate }: IExchangeRate) => <td>{rate === null ? '-' : rate}</td>,
			actions: (exchangeRate: IExchangeRate) => (
				<td>
					<CButton size="sm" className="mr-1" color="primary" onClick={() => setExchangeRate(exchangeRate)}>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	const showMutationModal = () => {
		setShowMutationModal(true);
	};

	const hideMutationModal = (refetch?: boolean) => {
		setShowMutationModal(false);
		setCurrentExchangeRate(null);
		if (refetch) {
			exchangeRatesQuery.refetch();
		}
	};

	return (
		<PageLayout title={_t('exchangeRates.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={showMutationModal} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								fields={fields}
								scopedSlots={scopedSlots}
								items={exchangeRatesQuery.data || []}
								loading={exchangeRatesQuery.isLoading}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<ExchangeRateModal show={mutationModalShow} onClose={hideMutationModal} exchangeRate={currentExchangeRate} />
		</PageLayout>
	);
};

export default React.memo(ExchangeRatesPage);
