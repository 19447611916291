import React, { useState } from 'react';
import {
	CButton,
	CModalHeader,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CInput,
	CModalTitle,
	CSelect,
	CFormGroup,
	CLink,
} from '@coreui/react';
import { useQuery, useMutation } from 'react-query';
import _t from 'counterpart';
import { getExchangeRate, requestTransfer } from '../../services/BackendService';
import { ITransaction } from './types';
import { extractErrorMessage, capitalize, findErrorFromValidation } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import toast from 'react-hot-toast';

function InputWithBitcoinExchanged(props: { onChange: any; initialAmount: number }) {
	const [amount, setAmount] = useState<string | null>(null);
	const { onChange, initialAmount } = props;

	const {
		isLoading,
		error,
		data: amountInBTC,
	} = useQuery<number, Error>(['exchange-rate'], async () => {
		const data = await getExchangeRate(initialAmount);
		return data.exchangeRate;
	});

	if (isLoading) {
		return (
			<CSelect id="nf-group" name="nf-group" disabled>
				<option>{_t('global.loading')}</option>
			</CSelect>
		);
	}

	if (error) {
		return <span>{error.message}</span>;
	}

	const setAmountWrapper = (e: any) => {
		setAmount(e.target.value);
		onChange(Number(e.target.value));
	};

	if (!amount && amountInBTC) {
		setAmount(amountInBTC.toFixed(5));
		onChange(amountInBTC.toFixed(5));
	}

	if (!amount) {
		return (
			<CSelect id="nf-type" name="nf-type" disabled>
				<option>{_t('global.loading')}</option>
			</CSelect>
		);
	}

	return (
		<CFormGroup>
			<CLabel htmlFor="btc-amount">{_t('transactions.amount-to-transfer')}</CLabel>
			<CInputGroup>
				<CInputGroupPrepend>
					<CInputGroupText>BTC</CInputGroupText>
				</CInputGroupPrepend>
				<CInput type="number" id="btc-amount" name="btc-amount" value={amount} onChange={setAmountWrapper} />
			</CInputGroup>
		</CFormGroup>
	);
}

function TransferModal(props: {
	methods: Array<any>;
	show: boolean;
	onClose: any;
	onSuccessClose: any;
	withdrawal: ITransaction;
	initialAmount: number;
}) {
	const { methods, show, onClose, onSuccessClose, withdrawal, initialAmount } = props;
	const { walletAddress } = withdrawal;

	const [transferMethod, setTransferMethod] = useState<string>(methods[0].id);
	const [recipientAddress, setRecipientAddress] = useState<string>(walletAddress!);
	const [withdrawalAmountBTC, setWithdrawalAmountBTC] = useState<number>(0);

	const transferMoneyMutation = useMutation(
		() => requestTransfer(withdrawal.id, transferMethod, withdrawalAmountBTC, recipientAddress),
		{
			onSuccess: () => {
				onSuccessClose();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	const changeTransferMethod = (e: any) => {
		setTransferMethod(e.target.value);
	};

	const transfer = () => {
		transferMoneyMutation.mutate();
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(transferMoneyMutation.error, paramName);
	};

	return (
		<CModal show={show} onClose={onClose} closeOnBackdrop={false}>
			<CModalHeader className="d-flex flex-column">
				<CModalTitle>{_t('action.transfer')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="transferMethod">{_t('global.method')}</CLabel>
					<CSelect name="transferMethod" value={transferMethod!} onChange={changeTransferMethod}>
						{methods.map((option: any) => {
							return (
								<option key={option.id} value={option.id}>
									{capitalize(option.id)}
								</option>
							);
						})}
					</CSelect>
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="amount">{_t('transactions.amount')}</CLabel>
					<CInputGroup>
						<CInputGroupPrepend>
							<CInputGroupText>$</CInputGroupText>
						</CInputGroupPrepend>
						<CInput id="nf-amount" name="nf-amount" type="number" value={initialAmount.toFixed(2)} readOnly />
					</CInputGroup>
				</CFormGroup>
				<InputWithBitcoinExchanged
					onChange={(amount: any) => setWithdrawalAmountBTC(Number(amount))}
					initialAmount={Number(initialAmount)}
				/>
				<CFormGroup>
					<CLabel htmlFor="recipientAddress">{_t('transactions.recipient-address')}</CLabel>
					<CInput
						type="text"
						id="recipientAddress"
						name="recipientAddress"
						value={recipientAddress}
						onChange={(e: any) => setRecipientAddress(e.target.value)}
					/>
					{!findError('address') && (
						<CLabel className="text-normal">
							{_t('transactions.address-user-requested')}
							<CLink href={`deposits?customerId=${withdrawal.customer.id}`} className="text-primary p-0">
								&nbsp;{_t('transactions.check-user-deposits')}
							</CLink>
						</CLabel>
					)}
					{findError('address') && <CLabel className="text-danger">{findError('address')}</CLabel>}
				</CFormGroup>
			</CModalBody>
			<CModalFooter>
				<CButton onClick={onClose} size="md" className="mr-1" color="danger">
					{_t('action.back')}
				</CButton>
				<ButtonWithLoader
					isLoading={transferMoneyMutation.isLoading}
					onClick={transfer}
					buttonColor="success"
					spinnerColor="secondary"
					title={_t.translate('action.transfer')}
					className="mr-2"
				/>
			</CModalFooter>
		</CModal>
	);
}

export default TransferModal;
