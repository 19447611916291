export const cilCash1 = [
  '40 40',
  `
<path d="M33.75 5H1.25V30H33.75V5ZM31.25 27.5H3.75V7.5H31.25V27.5Z" />
<path d="M36.25 11.25V32.5H7.5V35H38.75V11.25H36.25Z" />
<path d="M17.5 23.6297C20.6016 23.6297 23.125 20.8846 23.125 17.5105C23.125 14.1363 20.6016 11.3913 17.5 11.3913C14.3984 11.3913 11.875 14.1363 11.875 17.5105C11.875 20.8847 14.3984 23.6297 17.5 23.6297ZM17.5 13.8913C19.2231 13.8913 20.625 15.5148 20.625 17.5105C20.625 19.5061 19.2231 21.1297 17.5 21.1297C15.7769 21.1297 14.375 19.5061 14.375 17.5105C14.375 15.5148 15.7769 13.8913 17.5 13.8913Z" />
<path d="M8.75 10.625H6.25V24.375H8.75V10.625Z" />
<path d="M28.75 10.625H26.25V24.375H28.75V10.625Z" />
`,
];
