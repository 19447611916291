import { CRow, CCol, CInput, CLabel, CFormGroup, CInputGroup } from '@coreui/react';
import React, { useState } from 'react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { useGetSetting } from './hooks';
import { useMutation } from 'react-query';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { updateCoperato } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IProps {
	onChange: () => void;
}

const CoperatoIntegration = ({ onChange }: IProps) => {
	const [coperatoUrl, setCoperatoUrl] = useState<string>('');

	const requestUrlQuery = useGetSetting('COPERATO_REQUEST_URL', 'coperato-url', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setCoperatoUrl(value);
		},
	});

	const updateCoperatoMutation = useMutation((url: string) => updateCoperato(url), {
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				toast.error(extractErrorMessage(error));
			}
		},
		onSuccess: () => {
			toast.success(_t('coperato.coperato-configured'));
			onChange();
		},
	});

	const callUpdateVariableMutation = () => {
		updateCoperatoMutation.mutate(coperatoUrl);
	};

	const setFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCoperatoUrl(e.target.value);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateCoperatoMutation.error, paramName);
		return error;
	};

	return (
		<>
			<CRow>
				<CCol>
					<p>{_t('coperato.paragraph-one')}</p>
					<p>{_t('coperato.paragraph-two')}</p>
					<p>{_t('coperato.paragraph-three')}</p>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CFormGroup>
						<CLabel htmlFor="url">{_t(`coperato.url`)}</CLabel>
						<CInputGroup>
							<CInput
								type="text"
								id="url"
								name="url"
								value={coperatoUrl}
								onChange={setFormValue}
								className="instrument-input-height"
							/>
						</CInputGroup>
						{requestUrlQuery.error && <CLabel className="text-danger">{_t('integrations.coperato-url-failed')}</CLabel>}
						{findError('url') && <CLabel className="text-danger">{findError('url')}</CLabel>}
					</CFormGroup>
				</CCol>
				<CCol md={12} className="d-flex justify-content-end">
					<ButtonWithLoader
						isLoading={updateCoperatoMutation.isLoading}
						onClick={callUpdateVariableMutation}
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t('action.save')}
						className="btn-md"
						showText={false}
						disabled={coperatoUrl === ''}
					/>
				</CCol>
			</CRow>
		</>
	);
};

export default CoperatoIntegration;
