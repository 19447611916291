import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { CButton, CCol, CForm, CFormGroup, CInput, CLabel, CRow } from '@coreui/react';
import { IDateRange, IOpenPositionsFilters, IPositionsFilters } from '../../reducers/filtersReducer';
import { getAllSymbols } from '../../services/BackendService';
import CustomSelect from '../../components/CustomSelect';
import DateRangePicker from '../../components/DateRangePicker';
import { debounce } from 'lodash';

interface IProps {
	show: boolean;
	onFiltersChange: (filters: Partial<IPositionsFilters | IOpenPositionsFilters>) => void;
	filters: IPositionsFilters | IOpenPositionsFilters;
}

const PositionsFilter = ({ show, filters, onFiltersChange }: IProps) => {
	const [accountId, setAccountId] = useState<string | null>(null);

	const statuses = [
		{ label: _t('positions.statuses.closed'), value: 'closed' },
		{ label: _t('positions.statuses.error'), value: 'error' },
		{ label: _t('positions.statuses.open'), value: 'open' },
	];

	useEffect(() => {
		if (!filters.accountId) {
			setAccountId(null);
		}
	}, [filters, setAccountId]);

	const symbolsQuery = useQuery(['trading-symbols'], () => getAllSymbols());

	const symbolsOptions = symbolsQuery.data?.symbols.map((symbol: string) => {
		return { value: symbol, label: symbol };
	});
	const setFilters = useCallback(
		(filters: Partial<IPositionsFilters | IOpenPositionsFilters>) => {
			onFiltersChange(filters);
		},
		[onFiltersChange]
	);

	const handleSymbolChanged = (symbols: any) => {
		setFilters({
			symbols,
		});
	};

	const resetSymbols = () => {
		setFilters({
			symbols: [],
		});
	};

	const handleSideChanged = (types: any) => {
		setFilters({
			types,
		});
	};

	const resetSides = () => {
		setFilters({
			types: [],
		});
	};

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses,
		});
	};

	const resetStatuses = () => {
		setFilters({
			statuses: [],
		});
	};

	const handleOpenedAtRange = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({
				openedAt: range,
			});
		}
	};

	const resetOpenedAtRange = () => {
		setFilters({
			openedAt: undefined,
		});
	};

	const debouncedAccountIdFilterUpdate = useMemo(
		() =>
			debounce((accountId: string) => {
				setFilters({
					accountId,
				});
			}, 300),
		[setFilters]
	);

	const handleAccountIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const accountId = event.target.value;
		setAccountId(accountId);
		debouncedAccountIdFilterUpdate(accountId);
	};

	const resetAccountId = () => {
		setAccountId(null);
		setFilters({
			accountId: null,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('positions.side')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSides}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={filters.types ?? null}
							options={[
								{ label: 'buy', value: 'buy' },
								{ label: 'sell', value: 'sell' },
							]}
							onChange={handleSideChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('positions.symbols')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSymbols}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={filters.symbols ?? null}
							options={symbolsOptions}
							onChange={handleSymbolChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				{'statuses' in filters && (
					<CCol md={4}>
						<CFormGroup>
							<div className="d-flex align-items-center justify-content-between">
								<span>{_t('positions.statuses-filter')}</span>
								<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatuses}>
									{_t('action.reset').toUpperCase()}
								</CButton>
							</div>
							<CustomSelect
								value={filters.statuses ?? null}
								options={statuses}
								onChange={handleStatusChanged}
								isMulti
								isClearable={false}
								whiteBackground
							/>
						</CFormGroup>
					</CCol>
				)}
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0">{_t('positions.opened-at')}</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetOpenedAtRange}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<DateRangePicker
							initialStartDate={filters.openedAt?.startDate ?? null}
							initialEndDate={filters.openedAt?.endDate ?? null}
							onRangeChanged={handleOpenedAtRange}
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0">{_t('positions.account-id')}</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetAccountId}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>

						<CInput value={accountId || ''} onChange={handleAccountIdChange} />
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default PositionsFilter;
