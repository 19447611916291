import React from 'react';
import { CBadge } from '@coreui/react';

const TradingSideBadge = (props: { side: string }) => {
	const { side } = props;
	const sideColorBadge = side === 'buy' ? 'success' : 'danger';
	return (
		<CBadge color={sideColorBadge} title={side}>
			{side.toUpperCase()}
		</CBadge>
	);
};

export default TradingSideBadge;
