import { AnyAction } from 'redux';
import { SET_LANGUAGE, SET_SIDEBAR_SHOWN, SET_SOCKET_CONNECTED, SHOW_GLOBAL_LOADER, HIDE_GLOBAL_LOADER } from '../actions';

export interface ICoreState {
	sidebarShown: boolean | '' | 'responsive';
	language: string;
	socketConnected: boolean;
  globalLoading: boolean;
}

const initialState: ICoreState = {
	sidebarShown: 'responsive',
	language: 'en',
	socketConnected: false,
  globalLoading: false,
};

function coreReducer(state: ICoreState | undefined, action: AnyAction): ICoreState {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case SET_SIDEBAR_SHOWN:
			return {
				...state,
				sidebarShown: action.payload.shown,
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
			};
		case SET_SOCKET_CONNECTED:
			return {
				...state,
				socketConnected: action.payload.connected,
			};
		case SHOW_GLOBAL_LOADER:
			return {
				...state,
				globalLoading: true,
			};
		case HIDE_GLOBAL_LOADER:
			return {
				...state,
				globalLoading: false,
			};
		default:
			return state;
	}
}

export default coreReducer;
