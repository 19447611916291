import React from 'react';
import { CCard, CCardBody, CRow, CCol } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { loadBookmarkedCustomers } from '../services/BackendService';
import { ISorter } from '../components/PaginationTable';
import CustomersTable from './customers/CustomersTable';
import PageLayout from '../components/PageLayout';
import Error from '../components/Error';

const BookmarksPage = () => {
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter, setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const history = useHistory();

	const { data, isLoading, isError, refetch } = useQuery<any>(
		['customers', filter, page, orderBy],
		() => loadBookmarkedCustomers(filter || '', limit, offset, orderBy!),
		{
			refetchInterval: 60000,
		}
	);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onRowClicked = (user: any) => {
		history.push(`/customers/${user.id}`);
	};

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('bookmarks.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<CustomersTable
								data={data?.customers || []}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								isLoading={isLoading}
								pages={data?.pages || 0}
								activePage={page || 1}
								onRowClicked={onRowClicked}
								onSearchValueChanged={searchTableData}
								filterValue={filter || ''}
								showFilter
								itemsPerPage={limit}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default BookmarksPage;
