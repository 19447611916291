import React, { useState } from 'react';
import _t from 'counterpart';
import { CCard, CCardHeader, CCardTitle, CCardBody, CFormGroup, CCol, CRow } from '@coreui/react';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import { useGetSetting, useSetSetting } from './hooks';
import CustomSelect from '../../components/CustomSelect';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import SendgridIntegration from './SendgridIntegration';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import MailgunIntegration from './MailgunIntegration';
import SmtpProviderIntegration from './SmtpProviderIntegration';
import BrevoIntegration from './BrevoIntegration';
import { extractErrorMessage } from '../../helpers';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

const EmailIntegration = () => {
	const providersOptions = [
		{ label: 'Disabled', value: '' },
		{ label: 'Sendgrid', value: 'sendgrid' },
		{ label: 'Mailgun', value: 'mailgun' },
		{ label: 'SMTP Provider', value: 'smtpProvider' },
		{ label: 'Brevo', value: 'brevo' },
	];

	const queryClient = useQueryClient();

	const [provider, setProvider] = useState<{ label: string; value: string }>(providersOptions[0]);

	const onError = (e: any) => {
		toast.error(extractErrorMessage(e));
	};

	const emailProvider = useGetSetting('EMAIL_PROVIDER', 'email-provider', {
		refetchOnWindowFocus: false,
		onError,
		onSuccess: (provider: string) => {
			if (provider === '') {
				setProvider(providersOptions[0]);
			}
			if (provider === 'sendgrid') {
				setProvider(providersOptions[1]);
			}
			if (provider === 'mailgun') {
				setProvider(providersOptions[2]);
			}
			if (provider === 'smtpProvider') {
				setProvider(providersOptions[3]);
			}
			if (provider === 'brevo') {
				setProvider(providersOptions[4]);
			}
		},
	});

	const emailProviderMutation = useSetSetting();

	const onProviderChange = () => {
		queryClient.invalidateQueries(['email-provider-content']);
	};

	const onProviderSuccess = () => {
		toast.success(_t('integrations.email-provider-disabled'));
		emailProvider.refetch();
		onProviderChange();
	};

	const disableEmailMutationCall = () => {
		emailProviderMutation.mutate(
			{ key: 'EMAIL_PROVIDER', value: provider.value },
			{
				onSuccess: onProviderSuccess,
				onError,
			}
		);
	};

	const handleProviderChanged = (provider: any) => {
		setProvider(provider);
	};

	const isLoading = emailProviderMutation.isLoading || emailProvider.isFetching;
	const isError = Boolean(emailProvider.error) && !isLoading;

	return (
		<CCol md={12}>
			<CCard>
				<CCardHeader>
					<CCardTitle>{_t('integrations.email-provider')}</CCardTitle>
				</CCardHeader>
				<CCardBody>
					<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={emailProvider.refetch}>
						<CFormGroup>
							<InputHeaderNoButton labelText={_t('providers.label')} />
							<CustomSelect
								value={provider}
								onChange={handleProviderChanged}
								options={providersOptions}
								menuPortalTarget={document.body}
							/>
						</CFormGroup>
						{provider.value === 'sendgrid' && <SendgridIntegration onChange={onProviderChange} />}
						{provider.value === 'mailgun' && <MailgunIntegration onChange={onProviderChange} />}
						{provider.value === 'smtpProvider' && <SmtpProviderIntegration onChange={onProviderChange} />}
						{provider.value === 'brevo' && <BrevoIntegration onChange={onProviderChange} />}
						{provider.value === '' && (
							<CRow>
								<CCol md={12} className="d-flex justify-content-end">
									<ButtonWithLoader
										isLoading={emailProviderMutation.isLoading}
										onClick={disableEmailMutationCall}
										buttonColor="primary"
										spinnerColor="secondary"
										title={_t('action.save')}
										className="btn-md"
										showText={false}
									/>
								</CCol>
							</CRow>
						)}
					</QueryDataRenderer>
				</CCardBody>
			</CCard>
		</CCol>
	);
};

export default EmailIntegration;
