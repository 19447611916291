import React, { useEffect, useMemo } from 'react';
import toast, { Toast, ToastBar } from 'react-hot-toast';
import _t from 'counterpart';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import exitIcon from '../icons/x.svg';

interface IProps {
	t: Toast;
	onDismissCallback?: () => void;
}

const Notification = ({ t, onDismissCallback }: IProps) => {
	useEffect(() => {
		// sometimes toast won't hide on expiery, this ensures it does
		setTimeout(() => toast.dismiss(t.id), t.duration);
	}, [t.duration, t.id]);

	const colorsClass: { [type: string]: string } = useMemo(
		() => ({
			success: 'bg-success',
			error: 'bg-danger',
			blank: 'bg-warning',
		}),
		[]
	);

	const titles: { [type: string]: string } = useMemo(
		() => ({
			success: _t('global.success'),
			error: _t('global.error-occured'),
			blank: '',
		}),
		[]
	);

	const onDismissClicked = () => {
		if (onDismissCallback) {
			onDismissCallback();
		}

		toast.dismiss(t.id);
	};

	return (
		<ToastBar toast={t}>
			{({ icon, message }) => {
				const title = titles[t.type];
				const progressBgClass = colorsClass[t.type];
				const isBlank = t.type === 'blank';

				return (
					<>
						<div className="d-flex justify-content-between w-100 m-3">
							{!isBlank && (
								<div className="d-flex">
									{icon}
									<div className="d-flex flex-column ml-2 toast-message">
										<span className="p-0 m-0">{title}</span>
										{message}
									</div>
								</div>
							)}
							{isBlank && message}
							<CButton onClick={onDismissClicked}>
								<CIcon src={exitIcon} />
							</CButton>
						</div>
						<div
							className={`expanding-div ${progressBgClass}`}
							style={{
								animation: `expandWidth ${Number(t.duration)}ms linear forwards`,
							}}
						></div>
					</>
				);
			}}
		</ToastBar>
	);
};

export default Notification;
