import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _t from 'counterpart';
import {
	CRow,
	CCol,
	CCard,
	CCardHeader,
	CCardTitle,
	CCardBody,
	CTabs,
	CNav,
	CNavItem,
	CNavLink,
	CTabContent,
	CTabPane,
	CLink,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CButton,
} from '@coreui/react';
import Detail from '../../components/micro/Detail';
import PageLayout from '../../components/PageLayout';
import {
	getLead,
	createLeadComment,
	listLeadComments,
	claimLead,
	sendEmailToLead,
	callLead,
	fetchLeadCommunications,
	updateLeadSalesPerson,
} from '../../services/BackendService';
import { useQuery, useMutation } from 'react-query';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import SalesStatusBadge from '../../components/SalesStatusBadge';
import { formatDateTime, extractErrorMessage, getIconNameForCountry } from '../../helpers';
import CommentsList from '../../components/CommentsList';
import { useAppSelector } from '../../helpers/customHooks';
import ConfirmationModal from '../../components/ConfirmationModal';
import SetLeadStatusModal from './SetLeadStatusModal';
import CIcon from '@coreui/icons-react';
import SendEmailModal from '../../components/SendEmailModal';
import CommunicationsTable from '../customers/CustomerPage/CommunicationsTable';
import { shallowEqual } from 'react-redux';
import { Permission } from '../../reducers/userReducer';
import AssignableCustomers from '../customers/AssignableCustomers';
import countries from '../../vendor/ftt_countries.json';
import toast from 'react-hot-toast';

interface ICreateComment {
	id: string;
	comment: string;
}

interface ISendEmailParameters {
	id: string;
	subject: string;
	body: string;
}

const CountryDetails = ({ country }: { country: string | null }) => {
	if (!country) {
		return <Detail title={_t('global.country')}>-</Detail>;
	}

	const iconName = getIconNameForCountry(country.toLowerCase());

	return (
		<Detail title={_t('global.country')}>
			{iconName && <CIcon name={iconName} size="xl" title={country} />}
			&nbsp;
			{countries.find((i) => i['alpha-2'] === country)?.name}
		</Detail>
	);
};

const LeadPage = () => {
	const [comment, setComment] = useState<string>('');
	const [claimModalShow, setClaimModalShow] = useState<boolean>(false);
	const [statusModalShow, setStatusModalShow] = useState<boolean>(false);
	const [emailModalShow, setEmailModalShow] = useState<boolean>(false);
	const [callModalShow, setCallModalShow] = useState<boolean>(false);
	const [isCommunicating, setIsCommunicating] = useState<boolean>(false);
	const [body, setBody] = useState<string>('');
	const [subject, setSubject] = useState<string>('');
	const [salesPersonModalShow, setSalesPersonModalShow] = useState<boolean>(false);

	const userState = useAppSelector((state) => state.user, shallowEqual);
	const params = useParams<{ id: string }>();
	const { id } = params;

	const leadQuery = useQuery(['lead', id], () => getLead(id));
	const leadCommentsQuery = useQuery(['lead-comments', id], () => listLeadComments(id));
	const leadCommunicationsQuery = useQuery(['lead-communications'], () => fetchLeadCommunications(id));

	const sendEmailMutation = useMutation(
		({ id, subject, body }: ISendEmailParameters) => sendEmailToLead(id, subject, body),
		{
			onSuccess: () => {
				toast.success(_t('leads.email-sent-to-lead'));
				setBody('');
				setSubject('');
				setEmailModalShow(false);
				leadCommunicationsQuery.refetch();
				setIsCommunicating(false);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					toast.error(extractErrorMessage(error));
				}
				setIsCommunicating(false);
			},
		}
	);

	const updateLeadSalesPersonMutation = useMutation(
		(salesPersonId: string) => updateLeadSalesPerson(id, salesPersonId),
		{
			onSuccess: () => {
				toast.success(_t('customer.sales-person-assigned'));
				leadQuery.refetch();
			},
			onError: (error: any) => {
				toast.error(extractErrorMessage(error));
			},
		}
	);

	const createCommentMutation = useMutation(({ id, comment }: ICreateComment) => createLeadComment(id, comment), {
		onSuccess: () => {
			leadCommentsQuery.refetch();
			setComment('');
		},
		onError: (error: any) => {
			toast.error(extractErrorMessage(error));
		},
	});

	const callLeadMutation = useMutation((id: string) => callLead(id), {
		onSuccess: () => {
			leadCommunicationsQuery.refetch();
			setIsCommunicating(false);
		},
		onError: (error: any) => {
			toast.error(extractErrorMessage(error));
		},
	});

	const assignSalesPerson = (salesPersonId: string) => {
		setSalesPersonModalShow(false);
		updateLeadSalesPersonMutation.mutate(salesPersonId);
	};

	const fields = React.useMemo(
		() => [
			{ key: 'date', label: _t('global.date'), sorter: false },
			{ key: 'from', label: _t('global.from'), sorter: false },
			{ key: 'type', label: _t('global.type'), sorter: false },
			{ key: 'detail', label: _t('global.details'), sorter: false },
		],
		[]
	);

	const onCommentCreated = () => {
		createCommentMutation.mutate({ id, comment });
	};

	const showClaimModal = () => {
		setClaimModalShow(true);
	};

	const showSalesPersonModal = () => {
		setSalesPersonModalShow(true);
	};

	const hideClaimModal = (refetch: boolean) => {
		setClaimModalShow(false);
		if (refetch) {
			leadQuery.refetch();
		}
	};

	const showSetStatus = () => {
		setStatusModalShow(true);
	};

	const hideSetstatus = (refetch?: boolean) => {
		setStatusModalShow(false);
		if (refetch) {
			leadQuery.refetch();
		}
	};

	const showSendEmail = () => {
		setEmailModalShow(true);
	};

	const hideSendEmail = () => {
		setEmailModalShow(false);
		sendEmailMutation.reset();
	};

	const hideSalesPersonModal = () => {
		setSalesPersonModalShow(false);
		updateLeadSalesPersonMutation.reset();
	};

	const onSubjectChange = (e: string) => {
		setSubject(e);
	};

	const onBodyChange = (e: string) => {
		setBody(e);
	};

	const showCallModal = () => {
		setCallModalShow(true);
	};

	const hideCallModal = () => {
		setCallModalShow(false);
		setIsCommunicating(false);
	};

	const invokeCallMutation = () => {
		callLeadMutation.mutate(id);
		setIsCommunicating(true);
	};

	const onSend = () => {
		sendEmailMutation.mutate({
			id: leadQuery.data?.id || '',
			subject,
			body,
		});
		setIsCommunicating(true);
	};

	const copy = useCallback((value: string) => {
		navigator.clipboard.writeText(value);
		toast.success(_t('global.copied'));
	}, []);

	const renderSignupUrl = useMemo(() => {
		const code = leadQuery.data?.signupCode?.code;
		if (!code) {
			return '-';
		}

		const signupUrl = `${process.env.REACT_APP_CRM_ROOT}/signup?signupCode=${code}`;

		return (
			<div>
				<span>{signupUrl}</span>
				<CButton style={{ width: '20px', padding: '0px' }} onClick={() => copy(signupUrl)} className="ml-1">
					<CIcon name="cil-copy" color="white" />
				</CButton>
			</div>
		);
	}, [copy, leadQuery.data?.signupCode]);

	const renderSignupCode = useMemo(() => {
		const code = leadQuery.data?.signupCode?.code;
		if (!code) {
			return '-';
		}

		return (
			<div>
				<span>{code}</span>
				<CButton style={{ width: '20px', padding: '0px' }} onClick={() => copy(code)}>
					<CIcon name="cil-copy" />
				</CButton>
			</div>
		);
	}, [copy, leadQuery.data?.signupCode]);

	if (leadQuery.isError) {
		return <Error onRetry={leadQuery.refetch} />;
	}

	if (leadQuery.isLoading || leadQuery.isIdle) {
		return <Loading />;
	}

	const {
		name,
		email,
		mobilenumber,
		source,
		salesStatus,
		createdAt,
		lastModifiedAt,
		country,
		salesPerson,
		canBeCalled,
	} = leadQuery.data;

	return (
		<PageLayout
			title={name}
			titleAppend={
				<div>
					<CDropdown className="float-right">
						<CDropdownToggle caret color="primary">
							{_t('leads.lead-options')}
						</CDropdownToggle>
						<CDropdownMenu>
							{salesPerson === null && userState.permissions?.includes(Permission.CLAIM_LEADS) && (
								<CDropdownItem onClick={showClaimModal}>{_t('leads.claim')}</CDropdownItem>
							)}
							<CDropdownItem onClick={showSetStatus}>{_t('leads.set-lead-status')}</CDropdownItem>
							{userState.permissions.includes(Permission.MANAGE_LEADS_SALES_PERSON) && (
								<CDropdownItem onClick={showSalesPersonModal}>{_t('leads.assign-sales-person')}</CDropdownItem>
							)}
						</CDropdownMenu>
					</CDropdown>
					<CButton className="float-right mr-2" color="primary" onClick={showSendEmail}>
						<CIcon className="mr-2" size="sm" name="cilMail" />
						{_t('global.email')}
					</CButton>
					{canBeCalled && (
						<CButton className="float-right mr-2" color="primary" onClick={showCallModal}>
							<CIcon className="mr-2" size="sm" name="cilPhone" />
							{_t('action.call')}
						</CButton>
					)}
				</div>
			}
		>
			<CRow>
				<CCol md={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.info')}</CCardTitle>
							<div className="float-right">
								<SalesStatusBadge status={salesStatus} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<Detail title={_t('leads.email')}>{email || '-'}</Detail>
										<CountryDetails country={country} />
										<Detail title={_t('leads.source')}>{source}</Detail>
										<Detail title={_t('leads.phone-number')}>{mobilenumber || '-'}</Detail>
										<Detail title={_t('leads.signup-url')}>{renderSignupUrl}</Detail>
										<Detail title={_t('leads.signup-code')}>{renderSignupCode}</Detail>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<Detail title={_t('global.created-at')}>{formatDateTime(createdAt)}</Detail>
										<Detail title={_t('leads.last-modified-at')}>
											{lastModifiedAt ? formatDateTime(lastModifiedAt) : '-'}
										</Detail>
										<Detail title={_t('leads.sales-person')}>
											{salesPerson ? (
												<CLink href={`/customers/${salesPerson.id}`}>
													{salesPerson.firstname} {salesPerson.lastname}
												</CLink>
											) : (
												'-'
											)}
										</Detail>
										<Detail title={_t('leads.signup-url')}>{renderSignupUrl}</Detail>
										<Detail title={_t('leads.signup-code')}>{renderSignupCode}</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={6}>
					<CTabs activeTab="notes">
						<CNav variant="tabs">
							<CNavItem>
								<CNavLink data-tab="notes">{_t('customer.notes')}</CNavLink>
							</CNavItem>
						</CNav>
						<CCard className="card--with-top-tabs">
							<CCardBody>
								<CTabContent>
									<CTabPane data-tab="notes">
										<CommentsList
											comments={leadCommentsQuery.data || []}
											isCreatingComment={createCommentMutation.isLoading}
											isLoadingData={leadCommentsQuery.isLoading || leadCommentsQuery.isIdle}
											onCommentCreated={onCommentCreated}
											comment={comment}
											onCommentChanged={setComment}
										/>
									</CTabPane>
								</CTabContent>
							</CCardBody>
						</CCard>
					</CTabs>
				</CCol>
			</CRow>
			<CommunicationsTable
				isLoading={leadCommunicationsQuery.isLoading || isCommunicating}
				fields={fields}
				data={leadCommunicationsQuery.data || []}
			/>
			<ConfirmationModal
				id={id}
				show={claimModalShow}
				onConfirm={claimLead}
				hidePanel={hideClaimModal}
				title={_t('leads.claim-this-lead')}
				error={null}
				buttonColor="primary"
				withMutation
			/>
			<SetLeadStatusModal id={id} show={statusModalShow} onHide={hideSetstatus} currentStatus={salesStatus} />
			<SendEmailModal
				subject={subject}
				setSubject={onSubjectChange}
				body={body}
				setBody={onBodyChange}
				show={emailModalShow}
				onClose={hideSendEmail}
				onCloseDiscardChanges={showSendEmail}
				customer={{
					name,
				}}
				errors={sendEmailMutation.error}
				isLoading={sendEmailMutation.isLoading}
				onSend={onSend}
			/>
			<ConfirmationModal
				id={id}
				show={callModalShow}
				onConfirm={invokeCallMutation}
				hidePanel={hideCallModal}
				title={_t('customer.call-customer-on', { number: mobilenumber })}
				error={null}
				buttonColor="primary"
				withMutation={false}
			/>

			<AssignableCustomers
				show={salesPersonModalShow}
				onClose={hideSalesPersonModal}
				queryKey="sales-people"
				action={assignSalesPerson}
				roles={['SALES']}
				assignSalesAgent
				assignForLeads={true}
			/>
		</PageLayout>
	);
};

export default React.memo(LeadPage);
