import React, { useCallback, useEffect, useMemo } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { CButton, CCard, CCardBody, CCol, CLink, CRow } from '@coreui/react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { getLedgers } from '../../services/BackendService';
import { useQuery } from 'react-query';
import Error from '../../components/Error';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import { IAffiliateCommissionLedger } from './types';
import { useHistory } from 'react-router';
import { formatCurrency, formatDateTime } from '../../helpers';

const CommissionLedgersPage = () => {
	const limit = 10;
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy = 'actionTimestamp|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const ledgerRecordsQuery = useQuery(['ledger-records', page, orderBy], () => getLedgers(limit, offset, orderBy!));

	const history = useHistory();

	const handleDetailsClicked = useCallback(
		(id: number) => {
			history.push(`/commission-ledgers/${id}`);
		},
		[history]
	);

	useEffect(() => {
		setOrderBy('actionTimestamp|DESC', 'replaceIn');
		setPage(1, 'replaceIn');
	}, [setPage, setOrderBy]);

	const onPageChanged = useCallback(
		(page: number) => {
			setPage(page, 'replaceIn');
		},
		[setPage]
	);

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const fields = useMemo(
		() => [
			{ key: 'actionTimestamp', label: _t('global.date') },
			{ key: 'introducer', label: _t('commissions.introducer'), sorter: false },
			{ key: 'referral', label: _t('commissions.referral'), sorter: false },
			{ key: 'commissionType', label: _t('commissions.commission-type'), sorter: false },
			{ key: 'actionType', label: _t('commissions.action-type'), sorter: false },
			{ key: 'commissionAmount', label: _t('global.amount') },
			{ key: 'correctedCommissionAmount', label: _t('commissions.corrected-amount'), sorter: false },
			{ key: 'showDetails', label: _t('global.details'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			referral: ({ referral }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/customers/${referral.id}`}>{referral.name}</CLink>
				</td>
			),
			introducer: ({ introducer }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/customers/${introducer.id}`}>{introducer.name}</CLink>
				</td>
			),
			actionTimestamp: ({ actionTimestamp }: IAffiliateCommissionLedger) => <td>{formatDateTime(actionTimestamp)}</td>,
			commissionAmount: ({ commissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{formatCurrency(commissionAmount, currency)}</td>
			),
			correctedCommissionAmount: ({ correctedCommissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{correctedCommissionAmount ? formatCurrency(correctedCommissionAmount, currency) : '-'}</td>
			),
			showDetails: ({ id }: IAffiliateCommissionLedger) => (
				<td>
					<CButton
						color="primary"
						type="button"
						onClick={() => handleDetailsClicked(id)}
						title="Edit commission ledger"
						size="sm"
					>
						{_t('global.details')}
					</CButton>
				</td>
			),
		}),
		[handleDetailsClicked]
	);

	if (ledgerRecordsQuery.isError) {
		return <Error onRetry={ledgerRecordsQuery.refetch} />;
	}

	const count = ledgerRecordsQuery.data?.count || 0;
	const pages = count === 0 ? 0 : Math.ceil(count / limit);

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	return (
		<PageLayout title={_t('commissions.affiliate-commission-ledgers')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<PaginationTable
								data={ledgerRecordsQuery.data?.records ?? []}
								loading={ledgerRecordsQuery.isLoading}
								onPageChanged={onPageChanged}
								pages={pages}
								activePage={page ?? 0}
								tableFields={fields}
								scopedSlots={scopedSlots}
								pagination
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default CommissionLedgersPage;
