export enum AdapterType {
  Fixquoter = 'fixquoter',
  Demoquoter = 'demoquoter',
  Fix = 'fix',
  Warehouse = 'warehouse',
}

export type IAdapter = IFIXAdapter | IDemoQuoterAdapter | IWarehouseAdapter;

interface IFIXAdapter {
  name: string;
  enabled: boolean;
  type: AdapterType.Fix | AdapterType.Fixquoter;
  configuration: IFIXAdapterConfig;
  status?: IFIXAdapterStatus;
}

export enum FixProvider {
  CENTROID = 'centroid',
  ONEZERO = 'onezero',
  FORTEX = 'fortex',
}

interface IDemoQuoterAdapter {
  name: string;
  enabled: boolean;
  type: AdapterType.Demoquoter;
  configuration: IDemoQuoterAdapterConfig;
  status?: any;
}

interface IWarehouseAdapter {
  name: string;
  enabled: boolean;
  type: AdapterType.Warehouse;
  configuration: {};
  status?: any;
}

export interface IFIXAdapterStatus {
  connected: boolean;
  lastMessageReceived: string;
  messagesReceived: number;
  messagesSent: number
}

export interface IDemoQuoterAdapterConfig {
  intervalMs: number|null;
}

export interface IFIXAdapterConfig {
  ssl: boolean;
  host: string;
  port: number;
  session: string;
  password: string;
  username: string;
  resetOnLogon: boolean;
  senderCompId: string;
  targetCompId: string;
  provider?: string;
  account?: string;
}

export const defaultConfigs = {
  [AdapterType.Fix]: {
    ssl: false,
    host: '',
    port: 0,
    session: '',
    password: '',
    username: '',
    resetOnLogon: false,
    senderCompId: '',
    targetCompId: '',
  },
  [AdapterType.Fixquoter]: {
    ssl: false,
    host: '',
    port: 0,
    session: '',
    password: '',
    username: '',
    resetOnLogon: false,
    senderCompId: '',
    targetCompId: '',
  },
  [AdapterType.Demoquoter]: {
    intervalMs: 500,
  },
  [AdapterType.Warehouse]: {},
}

export interface IConfigurationProps {
	configuration: IDemoQuoterAdapterConfig;
	setConfiguration: (config: IDemoQuoterAdapterConfig) => void;
	findError: (field: string) => string | null;
}

export interface IFIXConfigurationProps {
	isFix: boolean;
	configuration: IFIXAdapterConfig;
	setConfiguration: (config: IFIXAdapterConfig) => void;
	findError: (field: string) => string | null;
}