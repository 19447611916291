import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CProgress, CProgressBar, CRow } from '@coreui/react';
import React from 'react';
import _t from 'counterpart';
import Detail from '../../../components/micro/Detail';
import { useQuery } from 'react-query';
import { fetchCustomerLoyatlyLevelDetails } from '../../../services/BackendService';
import { formatDateTime } from '../../../helpers';
import CIcon from '@coreui/icons-react';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';

interface ILoyaltyProgressBarProps {
	totalCoinsMonth: number;
	coinsUntilNextLevel: number|null;
}

/**
 * Renders a progress bar that shows the progress to the next reward level.
 */
const LoyaltyProgressBar = ({ totalCoinsMonth, coinsUntilNextLevel }: ILoyaltyProgressBarProps) => {
	// topmost level has been reached, we just render a full (100%) progress bar
	if (coinsUntilNextLevel === null) {
		return (
			<CProgress>
				<CProgressBar
					value={1}
					max={1}
					showPercentage
					showValue
					precision={0}
				/>
			</CProgress>
		);
	}

	const targetLevelCoinsNeeded = totalCoinsMonth + coinsUntilNextLevel;

	return (
		<CProgress>
			<CProgressBar
				value={totalCoinsMonth}
				max={targetLevelCoinsNeeded}
				showPercentage
				showValue
				precision={0}
			/>
		</CProgress>
	)
}

interface IProps {
	customerId: string;
}

const LoyaltyLevelDetails = ({ customerId }: IProps) => {
	const loyaltyLevelDetailsQuery = useQuery(
		['loyalty-level-details', customerId],
		() => fetchCustomerLoyatlyLevelDetails(customerId),
		{
			retry: false,
		}
	);

	const crownColors: { [value: string]: string } = {
		bronze: '#E36629',
		silver: '#BCB8B7',
		gold: '#F8A300',
		platinum: '#EEEEEE',
		diamond: '#A3F4FF',
		default: 'black',
	};

	const getCrownColor = (level: string) => {
		return crownColors[level];
	};

	if (loyaltyLevelDetailsQuery.isLoading || loyaltyLevelDetailsQuery.isIdle) {
		return <Loading />;
	}

	if (loyaltyLevelDetailsQuery.isError) {
		return <Error onRetry={loyaltyLevelDetailsQuery.refetch} />;
	}

	const { totalCoinsMonth, coinsUntilNextLevel, currentLevelName, awardedAt, validUntil, coinsRequiredToRetain } =
		loyaltyLevelDetailsQuery.data;

	return (
		<CCard>
			<CCardHeader>
				<CCardTitle>{_t(`customer.loyalty-level.title`)}</CCardTitle>
			</CCardHeader>
			<CCardBody>
				<CRow>
					<CCol md={6}>
						<dl>
							<Detail title={_t('customer.loyalty-level.month-coins')}>{totalCoinsMonth}</Detail>
							<Detail title={_t('customer.loyalty-level.current-level')}>
								<CIcon name="crown" size="xl" style={{ color: getCrownColor(currentLevelName) }} className="mr-1" />
								{_t(`customer.loyalty-level.${currentLevelName}`)}
							</Detail>
							<Detail title={_t('customer.loyalty-level.date-current-awarded')}>{formatDateTime(awardedAt)}</Detail>
							<Detail title={_t('global.progress')}>
								<LoyaltyProgressBar totalCoinsMonth={totalCoinsMonth} coinsUntilNextLevel={coinsUntilNextLevel} />
							</Detail>
						</dl>
					</CCol>
					<CCol md={6}>
						<dl>
							<Detail title={_t('customer.loyalty-level.date-level-expire')}>
								{validUntil ? formatDateTime(validUntil) : _t('global.never')}
							</Detail>
							<Detail title={_t('customer.loyalty-level.coins-to-retain-level')}>{coinsRequiredToRetain}</Detail>
							<Detail title={_t('customer.loyalty-level.coins-to-next-level')}>{coinsUntilNextLevel}</Detail>
						</dl>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};

export default React.memo(LoyaltyLevelDetails);
