import { CCol, CFormGroup, CInput, CInputGroup, CLabel, CRow } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import React, { useState } from 'react';
import { useGetSetting } from './hooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updateVoiso } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IParams {
	url: string;
	apiKey: string;
}

interface IProps {
	onChange: () => void;
}

const VoisoIntegration = ({ onChange }: IProps) => {
	const [voisoUrl, setVoisoUrl] = useState<string>('');
	const [voisoApiKey, setVoisoApiKey] = useState<string>('');

	const requestUrlQuery = useGetSetting('VOISO_REQUEST_URL', 'voiso-request-url', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setVoisoUrl(value);
		},
	});

	const apiKeyQuery = useGetSetting('VOISO_API_KEY', 'voiso-api-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setVoisoApiKey(value);
		},
	});

	const updateVoisoMutation = useMutation(({ url, apiKey }: IParams) => updateVoiso(url, apiKey), {
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			}
		},
		onSuccess: () => {
			toast.success(_t('voiso.voiso-configured'));
			onChange();
		},
	});

	const callUpdateVoisoMutation = () => {
		updateVoisoMutation.mutate({ url: voisoUrl, apiKey: voisoApiKey });
	};

	const setUrlFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVoisoUrl(e.target.value);
	};

	const setApiKeyFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVoisoApiKey(e.target.value);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateVoisoMutation.error, paramName);
		return error;
	};

	return (
		<>
			<CRow>
				<CCol>
					<p>{_t('voiso.paragraph-one')}</p>
					<p>{_t('voiso.paragraph-two')}</p>
					<p>{_t('voiso.paragraph-three')}</p>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CFormGroup>
						<CLabel htmlFor="url">{_t(`voiso.url`)}</CLabel>
						<CInputGroup>
							<CInput
								type="text"
								id="url"
								name="url"
								value={voisoUrl}
								onChange={setUrlFormValue}
								className="instrument-input-height"
							/>
						</CInputGroup>
						{requestUrlQuery.error && <CLabel className="text-danger">{_t('integrations.voiso-url-failed')}</CLabel>}
						{findError('url') && <CLabel className="text-danger">{findError('url')}</CLabel>}
					</CFormGroup>
				</CCol>
				<CCol md={12}>
					<CFormGroup>
						<CLabel htmlFor="apiKey">{_t(`voiso.api-key`)}</CLabel>
						<CInputGroup>
							<CInput
								type="text"
								id="apiKey"
								name="apiKey"
								value={voisoApiKey}
								onChange={setApiKeyFormValue}
								className="instrument-input-height"
							/>
						</CInputGroup>
						{apiKeyQuery.error && <CLabel className="text-danger">{_t('integrations.voiso-url-failed')}</CLabel>}
						{findError('apiKey') && <CLabel className="text-danger">{findError('apiKey')}</CLabel>}
					</CFormGroup>
				</CCol>
				<CCol md={12} className="d-flex justify-content-end">
					<ButtonWithLoader
						isLoading={updateVoisoMutation.isLoading}
						onClick={callUpdateVoisoMutation}
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t('action.save')}
						className="btn-md"
						showText={false}
						disabled={voisoUrl === '' || voisoApiKey === ''}
					/>
				</CCol>
			</CRow>
		</>
	);
};

export default VoisoIntegration;
