import React, { useMemo, useCallback, useState } from 'react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { CRow, CCol, CCardBody, CCard, CButton, CCardHeader } from '@coreui/react';
import { useQuery, useMutation } from 'react-query';
import { useQueryParam, NumberParam } from 'use-query-params';
import { getDowntimes, deleteDowntime, getAllSymbols } from '../../services/BackendService';
import { IDowntime } from './types';
import PaginationTable from '../../components/PaginationTable';
import Loading from '../../components/Loading';
import ConfirmationModal from '../../components/ConfirmationModal';
import { extractErrorMessage } from '../../helpers';
import MutateDowntimeModal from './MutateDowntimeModal';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import toast from 'react-hot-toast';

const DowntimesPage = () => {
	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [editDowntime, setEditDowntime] = useState<IDowntime | null>(null);
	const [downtimeFormShow, setDowntimeFormShow] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const onError = (e: any) => {
		const msg = extractErrorMessage(e);
		toast.error(msg);
	};

	const fetchDowntimesQuery = useQuery(['downtimes', page, offset, limit], () => getDowntimes(limit, offset), {
		onError,
	});

	const fetchSymbolsQuery = useQuery(['downtimes-symbols'], () => getAllSymbols(), {
		onError,
	});

	const deleteDowntimeMutation = useMutation(['delete-downtime'], () => deleteDowntime(deleteId!), {
		onSuccess: () => {
			setDeleteId(null);
			fetchDowntimesQuery.refetch();
			toast.success(_t('downtimes.downtime-deleted-success'));
		},
		onError,
	});

	const onPageChanged = useCallback(
		(page: number) => {
			setPage(page, 'replaceIn');
		},
		[setPage]
	);

	const hideDeleteModal = () => {
		setDeleteId(null);
	};

	const openCreateModal = () => {
		setDowntimeFormShow(true);
	};

	const hideMutateDowntimeModal = (fetchAgain: boolean) => {
		setEditDowntime(null);
		setDowntimeFormShow(false);
		if (fetchAgain) {
			fetchDowntimesQuery.refetch();
		}
	};

	const tableFields = useMemo(
		() => [
			{
				key: 'title',
				label: _t('global.title'),
				sorter: false,
			},
			{
				key: 'startDate',
				label: _t('global.start-date'),
				sorter: false,
			},
			{
				key: 'endDate',
				label: _t('global.end-date'),
				sorter: false,
			},
			{
				key: 'symbols',
				label: _t('downtimes.symbols'),
				sorter: false,
			},
			{
				key: 'actions',
				label: _t('global.actions'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			startDate: ({ startDate }: IDowntime) => <td>{new Date(startDate).toLocaleString()}</td>,
			endDate: ({ endDate }: IDowntime) => <td>{new Date(endDate).toLocaleString()}</td>,
			symbols: ({ symbols }: IDowntime) => {
				return <td className="text-nowrap">{symbols.length}</td>;
			},
			actions: (downtime: IDowntime) => (
				<td>
					<CButton className="mr-2" size="sm" color="primary" onClick={() => setEditDowntime(downtime)}>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton className="mr-2" size="sm" color="danger" onClick={() => setDeleteId(downtime.id)}>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</td>
			),
		};
	}, []);

	const onErrorRetry = () => {
		fetchDowntimesQuery.refetch();
		fetchSymbolsQuery.refetch();
	};

	if (fetchDowntimesQuery.isIdle || fetchDowntimesQuery.isLoading) {
		return <Loading />;
	}

	if (fetchDowntimesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const { count, downtimes } = fetchDowntimesQuery.data;
	const pages = Number(count) === 0 ? 0 : Math.ceil(Number(count) / Number(limit));

	return (
		<PageLayout title={_t.translate('sidebar.downtimes')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={openCreateModal} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={downtimes}
								loading={fetchDowntimesQuery.isLoading}
								pages={pages}
								pagination
								onPageChanged={onPageChanged}
								activePage={page || 1}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<ConfirmationModal
				id={deleteId?.toString() || ''}
				show={Boolean(deleteId)}
				hidePanel={hideDeleteModal}
				onConfirm={deleteDowntimeMutation.mutate}
				title={_t('downtimes.delete-downtime')}
				error={null}
				buttonColor="danger"
				withMutation={false}
			/>

			{(Boolean(editDowntime) || downtimeFormShow) && (
				<MutateDowntimeModal
					downtime={editDowntime}
					show
					hidePanel={hideMutateDowntimeModal}
					allSymbols={fetchSymbolsQuery.data?.symbols || []}
				/>
			)}
		</PageLayout>
	);
};

export default DowntimesPage;
