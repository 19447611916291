export const instrument = [
  '40 40',
  `
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.79687 24.2187L6.79688 31.7969L4.29688 31.7969L4.29687 24.2188L6.79687 24.2187Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.4844 31.1719L21.4844 38.75L18.9844 38.75L18.9844 31.1719L21.4844 31.1719Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.8594 24.7656L35.8594 32.3437L33.3594 32.3438L33.3594 24.7656L35.8594 24.7656Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.79687 1.25L6.79688 8.82812L4.29688 8.82812L4.29687 1.25L6.79687 1.25Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.4844 8.20312L21.4844 15.7812L18.9844 15.7812L18.9844 8.20312L21.4844 8.20312Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.8594 1.79687L35.8594 9.45312L33.3594 9.45312L33.3594 1.79688L35.8594 1.79687Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.10938 11.3281H4.0625V21.7969H7.10938V11.3281ZM1.5625 8.82812V24.2969H9.60938V8.82812H1.5625Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.7969 18.2812H18.75V28.75H21.7969V18.2812ZM16.25 15.7812V31.25H24.2969V15.7812H16.25Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.0938 11.9531H33.0469V22.4219H36.0938V11.9531ZM30.5469 9.45312V24.9219H38.5938V9.45312H30.5469Z" />
`,
];
