import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CLabel } from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation } from 'react-query';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import { acceptPayoutRequest } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IProps {
	id: string;
	show: boolean;
	hidePanel: (refetch?: boolean) => void;
	acceptedAmount: number;
}

interface IParams {
	requestId: string;
}

const AcceptPayoutRequestModal = ({ id, show, hidePanel, acceptedAmount }: IProps) => {
	const acceptRequestMutation = useMutation(({ requestId }: IParams) => acceptPayoutRequest(requestId), {
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			toast.error(msg);
		},
		onSuccess: () => {
			hidePanel(true);
			toast.success(_t('commissions.payout-request-is-accepted'));
		},
	});

	const invokeMutation = () => {
		acceptRequestMutation.mutate({ requestId: id });
	};

	return (
		<CModal show={show} onClose={hidePanel} size="sm">
			<CModalHeader className="d-flex flex-column">
				<CModalTitle>{_t('commissions.accept-payout-request')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CLabel>{_t('commissions.accepted-amount')}</CLabel>
				<p>
					<b>{formatCurrency(acceptedAmount)}</b>
				</p>
				<hr />
			</CModalBody>
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={acceptRequestMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="success"
					spinnerColor="secondary"
					title={_t('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={hidePanel} disabled={acceptRequestMutation.isLoading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default AcceptPayoutRequestModal;
