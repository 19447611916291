import React, { useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CModalTitle,
	CButton,
} from '@coreui/react';
import _t from 'counterpart';
import { extractErrorMessage, findErrorFromValidation } from '../../../helpers';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import Textarea from 'react-expanding-textarea';
import { useMutation, useQueryClient } from 'react-query';
import { sendMessageToCustomers } from '../../../services/BackendService';
import toast from 'react-hot-toast';
import { ICustomer } from '../types';

interface IProps {
	show: boolean;
	onClose: () => void;
	onCloseDiscardChanges: () => void;
	customer: ICustomer;
}

interface ISendMessageParameters {
	customerId: string;
	subject: string;
	text: string;
}

const MessageCustomerModal = ({ show, onClose, onCloseDiscardChanges, customer }: IProps) => {
	const [subject, setSubject] = useState<string>('');
	const [text, setText] = useState<string>('');
	const [discardChangesModalShow, setDiscardChangesModalShow] = useState<boolean>(false);

	const queryClient = useQueryClient();
	const sendMessageMutation = useMutation(
		({ customerId, subject, text }: ISendMessageParameters) => sendMessageToCustomers(customerId, text, subject),
		{
			onSuccess: () => {
				toast.success(_t('customer.message-sent-to-customer'));
				queryClient.invalidateQueries('customer-communications');
				setDiscardChangesModalShow(false);
				setSubject('');
				setText('');
				onClose();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	const close = () => {
		setDiscardChangesModalShow(false);
		setSubject('');
		setText('');
		sendMessageMutation.reset();
	};

	const handleClose = () => {
		if (subject !== '' || text !== '') {
			setDiscardChangesModalShow(true);
		}
		if (subject === '' && text === '') {
			sendMessageMutation.reset();
		}
		onClose();
	};

	const handleCloseDiscardChanges = () => {
		setDiscardChangesModalShow(false);
		onCloseDiscardChanges();
	};

	const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSubject(e.currentTarget.value);
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.currentTarget.value);
	};

	const sendMessage = (e: React.FormEvent) => {
		e.preventDefault();
		sendMessageMutation.mutate({ customerId: customer.id, subject, text });
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(sendMessageMutation.error, paramName);
	};

	return (
		<>
			<CModal show={show} onClose={handleClose}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('customer.send-message-to', { customer: customer.name })}</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={sendMessage}>
					<CModalBody>
						<CFormGroup>
							<CLabel htmlFor="subject">{_t('emails.subject')}</CLabel>
							<CInput type="text" id="subject" name="subject" value={subject} onChange={handleSubjectChange} />
						</CFormGroup>
						{findError('subject') && <CLabel className="text-danger">{findError('subject')}</CLabel>}
						<CFormGroup>
							<CLabel htmlFor="text">{_t('emails.text')}</CLabel>
							<Textarea className="w-100 mt-2 comments-input" onChange={handleTextChange} value={text} rows={3} />
						</CFormGroup>
						{findError('text') && <CLabel className="text-danger">{findError('text')}</CLabel>}
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={sendMessageMutation.isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('action.send')}
							className="mr-2"
						/>
						<CButton color="light" variant="outline" onClick={handleClose} disabled={sendMessageMutation.isLoading}>
							{_t('global.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
			<CModal show={discardChangesModalShow} size="sm" closeOnBackdrop={false}>
				<CModalHeader className="d-flex flex-column">
					<CModalTitle>{_t('customer.discard-changes')}</CModalTitle>
				</CModalHeader>
				<CModalBody />
				<CModalFooter className="modal-footer--alternative">
					<CButton color="primary" onClick={close}>
						{_t('global.confirm')}
					</CButton>
					<CButton color="light" variant="outline" onClick={handleCloseDiscardChanges}>
						{_t('global.cancel')}
					</CButton>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default React.memo(MessageCustomerModal);
