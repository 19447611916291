import _t from 'counterpart';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/ar';
import 'moment/locale/tr';
import 'moment/locale/ru';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/hi';
import 'moment/locale/ur';
import 'moment/locale/sw';
import 'moment/locale/ms';
import 'moment/locale/ja';
import 'moment/locale/vi';
import 'moment/locale/km';
import 'moment/locale/zh-cn';

import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import ptbr from './ptbr.json';
import ar from './ar.json';
import tr from './tr.json';
import ru from './ru.json';
import it from './it.json';
import de from './de.json';
import hi from './hi.json';
import ur from './ur.json';
import sw from './sw.json';
import ms from './ms.json';
import ja from './ja.json';
import vi from './vi.json';
import km from './km.json';
import zh from './zh.json';

export const correctedMomentLocales: any = {
	tz: 'sw',
	ke: 'sw',
	zh: 'zh-cn',
};

_t.registerTranslations('en', en);
_t.registerTranslations('es', es);
_t.registerTranslations('fr', fr);
_t.registerTranslations('pt', ptbr);
_t.registerTranslations('ar', ar);
_t.registerTranslations('tr', tr);
_t.registerTranslations('ru', ru);
_t.registerTranslations('it', it);
_t.registerTranslations('de', de);
_t.registerTranslations('hi', hi);
_t.registerTranslations('ur', ur);
_t.registerTranslations('tz', sw);
_t.registerTranslations('ke', sw);
_t.registerTranslations('ms', ms);
_t.registerTranslations('ja', ja);
_t.registerTranslations('vi', vi);
_t.registerTranslations('km', km);
_t.registerTranslations('zh', zh);

export const supportedLocales = [
	'en',
	'es',
	'fr',
	'pt',
	'ar',
	'tr',
	'ru',
	'it',
	'de',
	'hi',
	'ur',
	'ke',
	'ms',
	'ja',
	'vi',
	'km',
	'zh',
];
