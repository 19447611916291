import { IAuditLogsFilters } from 'src/reducers/filtersReducer';
import { UserRole } from 'src/reducers/userReducer';
import { IAuditLogsFiltersDTO } from './types';

export const toAuditLogsFilters = (filters: IAuditLogsFilters): IAuditLogsFiltersDTO => {
	return {
		actions: filters.selectedActions.map((a) => a.value),
		createdAt: filters.createdAt,
		actedAs: (filters.actedAs?.value as UserRole) ?? undefined,
		types: filters.selectedTypes.map((t) => t.value),
	};
};
