import React, { FormEvent, useEffect, useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CFormGroup,
	CLabel,
	CInput,
	CModalFooter,
	CButton,
	CInputCheckbox,
} from '@coreui/react';
import _t from 'counterpart';
import { useMutation } from 'react-query';
import { IOrder } from './types';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { updateOrder } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IProps {
	order: IOrder | null;
	onClose: (refetch?: boolean) => void;
}

interface IState {
	limitPrice: string | undefined | null;
	stopPrice: string | undefined | null;
	takeProfit: string | undefined | null;
	stopLoss: string | undefined | null;
	trailingStopLossPct: string | undefined | null;
}

interface IParams extends IState {
	id: string;
}

const OrderUpdateModal = ({ order, onClose }: IProps) => {
	const [state, setState] = useState<IState>({
		limitPrice: '',
		stopPrice: '',
		takeProfit: '',
		stopLoss: '',
		trailingStopLossPct: '',
	});
	const [editLimitPrice, setEditLimitPrice] = useState<boolean>(false);
	const [editStopPrice, setEditStopPrice] = useState<boolean>(false);
	const [editTakeProfit, setEditTakeProfit] = useState<boolean>(false);
	const [editStopLoss, setEditStopLoss] = useState<boolean>(false);
	const [editTrailingStopLossPct, setEditTrailingStopLossPct] = useState<boolean>(false);

	useEffect(() => {
		if (order) {
			setState({
				limitPrice: order.limitPrice || undefined,
				stopPrice: order.stopPrice || undefined,
				takeProfit: order.takeProfit || undefined,
				stopLoss: order.stopLoss || undefined,
				trailingStopLossPct: order.trailingStopLossPct || undefined,
			});
			if (order.limitPrice) {
				setEditLimitPrice(true);
			}
			if (order.stopPrice) {
				setEditStopPrice(true);
			}

			if (order.type === 'limit') {
				setState((currentState) => ({ ...currentState, stopPrice: undefined }));
				setEditLimitPrice(true);
				setEditStopPrice(false);
			}

			if (order.type === 'stop') {
				setState((currentState) => ({ ...currentState, limitPrice: undefined }));
				setEditStopPrice(true);
				setEditLimitPrice(false);
			}
		}
	}, [order]);

	const toggleTakeProfitCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isEnabled = e.target.checked;
		setEditTakeProfit(isEnabled);
	};

	const toggleStopLossCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isEnabled = e.target.checked;
		setEditStopLoss(isEnabled);
	};

	const toggleTrailingStopLossPctCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isEnabled = e.target.checked;
		setEditTrailingStopLossPct(isEnabled);
	};

	const closeModal = (refetch?: boolean) => {
		setEditTakeProfit(false);
		setEditStopLoss(false);
		setEditTrailingStopLossPct(false);
		onClose(refetch);
	};

	const updateOrderMutation = useMutation(
		({ id, limitPrice, stopPrice, takeProfit, stopLoss, trailingStopLossPct }: IParams) =>
			updateOrder(id, limitPrice, stopPrice, takeProfit, stopLoss, trailingStopLossPct),
		{
			onSuccess: async () => {
				toast.success(_t('orders.order-updates-successfully'));
				closeModal(true);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const error = extractErrorMessage(e);
					toast.error(error);
				}
			},
			retry: false,
		}
	);

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;
		setState({ ...state, [name]: value });
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateOrderMutation.error, paramName);
	};

	const callMutation = () => {
		if (order) {
			updateOrderMutation.mutate({
				id: order.id.toString(),
				limitPrice: state.limitPrice === '' ? undefined : state.limitPrice,
				stopPrice: state.stopPrice === '' ? undefined : state.stopPrice,
				takeProfit: state.takeProfit === '' ? undefined : state.takeProfit,
				stopLoss: state.stopLoss === '' ? undefined : state.stopLoss,
				trailingStopLossPct: state.trailingStopLossPct === '' ? undefined : state.trailingStopLossPct,
			});
		}
	};

	return (
		<CModal show={order !== null} onClose={closeModal} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('orders.update-order')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{editLimitPrice ? (
					<CFormGroup>
						<CLabel htmlFor="limitPrice">{_t('orders.limit-price')}</CLabel>
						<CInput
							disabled={!editLimitPrice}
							type="number"
							name="limitPrice"
							placeholder="10.0"
							value={state.limitPrice || ''}
							onChange={handleInputChange}
						/>
						{findError('limitPrice') && <CLabel className="text-danger">{findError('limitPrice')}</CLabel>}
					</CFormGroup>
				) : (
					''
				)}
				<CFormGroup>
					{editStopPrice ? (
						<CFormGroup>
							<CLabel htmlFor="stopPrice">{_t('orders.stop-price')}</CLabel>
							<CInput
								disabled={!editStopPrice}
								type="number"
								name="stopPrice"
								placeholder="10.0"
								value={state.stopPrice || ''}
								onChange={handleInputChange}
							/>
							{findError('stopPrice') && <CLabel className="text-danger">{findError('stopPrice')}</CLabel>}
						</CFormGroup>
					) : (
						''
					)}
				</CFormGroup>
				<CFormGroup>
					<CInputCheckbox checked={editTakeProfit} onChange={toggleTakeProfitCheckbox} style={{ marginLeft: '1px' }} />
					<CLabel variant="checkbox" htmlFor="takeProfit" style={{ marginLeft: '5%' }}>
						{_t('orders.set-take-profit')}
					</CLabel>
					<CInput
						disabled={!editTakeProfit}
						type="number"
						name="takeProfit"
						placeholder="10.0"
						value={state.takeProfit || ''}
						onChange={handleInputChange}
					/>
					{findError('takeProfit') && <CLabel className="text-danger">{findError('takeProfit')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CInputCheckbox checked={editStopLoss} onChange={toggleStopLossCheckbox} style={{ marginLeft: '1px' }} />
					<CLabel variant="checkbox" htmlFor="stopLoss" style={{ marginLeft: '5%' }}>
						{_t('orders.set-stop-loss')}
					</CLabel>
					<CInput
						disabled={!editStopLoss}
						type="number"
						name="stopLoss"
						placeholder="10.0"
						value={state.stopLoss || ''}
						onChange={handleInputChange}
					/>
					{findError('stopLoss') && <CLabel className="text-danger">{findError('stopLoss')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CInputCheckbox
						checked={editTrailingStopLossPct}
						onChange={toggleTrailingStopLossPctCheckbox}
						style={{ marginLeft: '1px' }}
					/>
					<CLabel variant="checkbox" htmlFor="trailingStopLossPct" style={{ marginLeft: '5%' }}>
						{_t('orders.set-trailing-stop-loss-pct')}
					</CLabel>
					<CInput
						disabled={!editTrailingStopLossPct}
						type="number"
						name="trailingStopLossPct"
						placeholder="10.0"
						value={state.trailingStopLossPct || ''}
						onChange={handleInputChange}
					/>
					{findError('trailingStopLossPct') && (
						<CLabel className="text-danger">{findError('trailingStopLossPct')}</CLabel>
					)}
				</CFormGroup>
			</CModalBody>

			<CModalFooter>
				<CButton color="primary" onClick={callMutation} className="mr-2">
					{_t('action.update')}
				</CButton>
				<CButton color="secondary" onClick={closeModal}>
					{_t('positions.position.close')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(OrderUpdateModal);
