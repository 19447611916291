import React from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import MaxRisks from './MaxRisks';
import PositionDurations from './PositionDurations';

const TradingOptions = () => {
	return (
		<PageLayout title={_t('sidebar.trading-options')}>
			<MaxRisks />
			<PositionDurations />
		</PageLayout>
	);
};

export default React.memo(TradingOptions);
