import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { CButton, CForm, CFormGroup, CCol, CLabel, CRow } from '@coreui/react';
import _t from 'counterpart';
import {
	loadGroups,
	loadCountries,
	loadCustomerSources,
	getSalesStatuses,
	listSalesPeople,
} from '../../services/BackendService';
import DateRangePicker from '../../components/DateRangePicker';
import { ICustomersFilters, IDateRange } from '../../reducers/filtersReducer';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { setCustomersFilters } from '../../actions';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { Permission } from '../../reducers/userReducer';
import ToggleSelect, { ISelectOption as IToggleSelectOption } from '../../components/ToggleSelect';
import { extractErrorMessage } from '../../helpers';
import toast from 'react-hot-toast';
import SelectMultipleFilter from './SelectMultipleFilter';
import { ICustomer } from './types';
import { ArrayParam, useQueryParam } from 'use-query-params';

interface IProps {
	show: boolean;
	onFilterChanged: (resetPagination?: boolean) => void;
}

interface IInputProps {
	labelText: string;
	resetFilter: () => void;
}

const InputHeader = ({ labelText, resetFilter }: IInputProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
			<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFilter}>
				{_t('action.reset').toUpperCase()}
			</CButton>
		</div>
	);
};

const CustomersFiltersComponent = ({ show, onFilterChanged }: IProps) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [selectAll, setSelectAll] = useState<boolean>(false);

	const activeFilters = useAppSelector((state) => state.filters.customersFilters, shallowEqual);
	const permissions = useAppSelector((state) => state.user.permissions);
	const dispatch = useAppDispatch();
	const setFilters = useCallback(
		(filters: Partial<ICustomersFilters>, resetPagination?: boolean) => {
			dispatch(setCustomersFilters(filters));
			onFilterChanged(resetPagination);
		},
		[dispatch, onFilterChanged]
	);
	const [statuses, setStatuses] = useQueryParam('statuses', ArrayParam);

	const salesPersonToToggleOption = (customer: ICustomer, enabled: boolean): IToggleSelectOption => {
		let label = `${customer.firstname || ''} ${customer.lastname || ''}`;

		if (label.replace(/ /g, '') === '' || label.trim() === 'NA') {
			label = customer.email;
		}

		return {
			value: customer.id,
			label,
			enabled,
		};
	};

	useQuery(['customers-for-filter'], listSalesPeople, {
		onSuccess: (data: Array<ICustomer>) => {
			setFilters(
				{
					conversionAgents: data.map((customer: ICustomer) => {
						const foundOption = activeFilters.conversionAgents.find(
							(option: ISelectOption) => option.value === customer.id
						);
						return salesPersonToToggleOption(customer, foundOption?.enabled || false);
					}),
				},
				false
			);
			setFilters(
				{
					retentionAgents: data.map((customer: ICustomer) => {
						const foundOption = activeFilters.retentionAgents.find(
							(option: ISelectOption) => option.value === customer.id
						);
						return salesPersonToToggleOption(customer, foundOption?.enabled || false);
					}),
				},
				false
			);
		},
		enabled: permissions?.includes(Permission.VIEW_SALES_PEOPLE),
	});

	const riskStatuses = [
		{ value: 'NONE', label: 'NONE' },
		{ value: 'REVIEW', label: 'REVIEW' },
	];

	const customerStatuses = useMemo(
		() => [
			{ value: 'ACTIVE', label: 'Active' },
			{ value: 'downloaded', label: 'Downloaded' },
			{ value: 'push', label: 'Push' },
			{ value: 'PENDING', label: 'Pending' },
			{ value: 'DISABLED', label: 'Disabled' },
			{ value: 'pending_termination', label: 'Pending termination' },
		],
		[]
	);

	const kycStatuses = [
		{ value: 'APPROVED', label: 'Approved' },
		{ value: 'REVOKED', label: 'Revoked' },
		{ value: 'PENDING', label: 'Pending' },
	];

	const depositCounts = [
		{ value: '0', label: '0' },
		{ value: '1', label: '1' },
		{ value: '2', label: '2' },
		{ value: '>2', label: '> 2' },
	];

	const positionsOptions = [{ value: 'open', label: 'Open' }];

	const activity = [
		{ value: 'online', label: 'online' },
		{ value: 'offline', label: 'offline' },
	];

	const { data: countries }: { data: any } = useQuery<Array<ISelectOption>, Error>(
		['countries'],
		async () => {
			const data = await loadCountries();
			return data.map((country) => ({
				value: country.isoCode,
				label: country.name,
			}));
		},
		{
			onError: (e) => {
				const error = extractErrorMessage(e);
				toast.error(error);
			},
			retry: false,
		}
	);

	const { data: groups } = useQuery<Array<ISelectOption>, Error>(
		['order-groups'],
		async () => {
			const data = await loadGroups();
			return data.map((group: any) => ({
				value: group.id,
				label: group.name,
			}));
		},
		{
			enabled: permissions?.includes(Permission.VIEW_GROUPS),
			onError: (e) => {
				const error = extractErrorMessage(e);
				toast.error(error);
			},
			retry: false,
		}
	);

	const { data: salesStatusesData } = useQuery('sales-statuses', () => getSalesStatuses(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(`${_t('sales-status.failed-to-fetch-sales-statuses')}: ${error}`);
		},
		retry: false,
	});

	const sourcesQuery = useQuery(['customer-sources'], () => loadCustomerSources());

	useEffect(() => {
		if (sourcesQuery.isSuccess && sourcesQuery.data) {
			const sources = sourcesQuery.data;
			if (activeFilters.sources.length !== sources.length) {
				const mappedValues = sources.map((s: any) => {
					const found = activeFilters.sources.find((c) => c.value === s);
					return {
						label: s,
						value: s,
						enabled: found?.enabled || false,
					};
				});
				setFilters({
					sources: mappedValues,
				});
			}
		}
	}, [sourcesQuery.data, sourcesQuery.isSuccess, activeFilters.sources, setFilters]);

	useEffect(() => {
		if (statuses && statuses.length > 0) {
			const statusWithValueAndLabel = customerStatuses.filter((customerStatus) =>
				statuses.includes(customerStatus.value)
			);
			setFilters(
				{
					selectedStatuses: statusWithValueAndLabel,
				},
				false
			);
		}
	}, [customerStatuses, setFilters, statuses]);

	const handleGroupChanged = (group: any) => {
		setFilters({
			group,
		});
	};

	const handleCountryChanged = (country: any) => {
		setFilters({
			country,
		});
	};

	const handleStatusChanged = (statuses: any) => {
		const statusesArray = statuses.map((status: any) => {
			return status.value;
		});
		setStatuses(statusesArray, 'replaceIn');

		setFilters({
			selectedStatuses: statuses,
		});
	};

	const handleKycStatusChanged = (statuses: any) => {
		setFilters({
			selectedKycStatuses: statuses,
		});
	};

	const handleRegistrationRangeChanged = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({
				registrationRange: range,
			});
		}
	};

	const handleLastActivityRangeChanged = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({
				lastActivityRange: range,
			});
		}
	};

	const resetSelectedStatuses = () => {
		setStatuses(undefined, 'replaceIn');
		setFilters({
			selectedStatuses: [],
		});
	};

	const resetKycStatuses = () => {
		setFilters({
			selectedKycStatuses: [],
		});
	};

	const resetGroup = () => {
		setFilters({
			group: undefined,
		});
	};

	const resetCountry = () => {
		setFilters({
			country: undefined,
		});
	};

	const resetLastActivityRange = () => {
		setFilters({
			lastActivityRange: undefined,
		});
	};

	const resetRegistrationRange = () => {
		setFilters({
			registrationRange: undefined,
		});
	};

	const onOptionsChanged = (options: Array<IToggleSelectOption>) => {
		const disabledIndex = options.findIndex((o) => !o.enabled);
		setSelectAll(disabledIndex === -1);
		setFilters({
			sources: options,
		});
	};

	const resetSources = () => {
		setSelectAll(false);
		setFilters({
			sources: activeFilters.sources.map((s) => {
				return {
					...s,
					enabled: false,
				};
			}),
		});
	};

	const handleDepositCountChanged = (depositCount: any) => {
		setFilters({
			depositCount,
		});
	};

	const resetDepositCount = () => {
		setFilters({
			depositCount: undefined,
		});
	};

	const handleSalesStatusChanged = (salesStatus: any) => {
		setFilters({
			salesStatus,
		});
	};

	const resetSalesStatus = () => {
		setFilters({
			salesStatus: undefined,
		});
	};

	const handlePositionsChanged = (positions: any) => {
		setFilters({
			positions,
		});
	};

	const resetPositionsCount = () => {
		setFilters({
			positions: undefined,
		});
	};

	const handleActivityChanged = (activity: any) => {
		setFilters({
			activity,
		});
	};

	const resetActivity = () => {
		setFilters({
			activity: undefined,
		});
	};

	const handleRiskStatusChanged = (riskStatus: any) => {
		setFilters({
			riskStatus,
		});
	};

	const resetRiskStatus = () => {
		setFilters({
			riskStatus: undefined,
		});
	};

	const onConversionAgentOptionChanged = (toChange: IToggleSelectOption) => {
		const filters = [...activeFilters.conversionAgents];
		const current = filters.find((option: IToggleSelectOption) => option.value === toChange.value);
		if (current) {
			current.enabled = !current.enabled;
		}

		setFilters({
			conversionAgents: filters,
		});
	};

	const onConversionAgentOptionReset = () => {
		onConversionAgentAllChanged(false);
	};

	const onConversionAgentAllChanged = (checked: boolean) => {
		const agents = [...activeFilters.conversionAgents];
		agents.forEach((option: IToggleSelectOption) => (option.enabled = checked));
		setFilters({
			conversionAgents: agents,
		});
	};

	const onRetentionAgentOptionChanged = (toChange: IToggleSelectOption) => {
		const filters = [...activeFilters.retentionAgents];
		const current = filters.find((option: IToggleSelectOption) => option.value === toChange.value);
		if (current) {
			current.enabled = !current.enabled;
		}

		setFilters({
			retentionAgents: filters,
		});
	};

	const onRetentionAgentOptionReset = () => {
		onRetentionAgentAllChanged(false);
	};

	const onRetentionAgentAllChanged = (checked: boolean) => {
		const agents = [...activeFilters.retentionAgents];
		agents.forEach((option: IToggleSelectOption) => (option.enabled = checked));
		setFilters({
			retentionAgents: agents,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSelectedStatuses}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedStatuses ?? null}
							options={customerStatuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.country')} resetFilter={resetCountry} />
						<CustomSelect
							value={activeFilters.country ?? null}
							options={countries}
							onChange={handleCountryChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.last-activity-date')} resetFilter={resetLastActivityRange} />
						<DateRangePicker
							initialStartDate={activeFilters.lastActivityRange?.startDate ?? null}
							initialEndDate={activeFilters.lastActivityRange?.endDate ?? null}
							onRangeChanged={handleLastActivityRangeChanged}
						/>
					</CFormGroup>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.registration-date')} resetFilter={resetRegistrationRange} />
						<DateRangePicker
							initialStartDate={activeFilters.registrationRange?.startDate ?? null}
							initialEndDate={activeFilters.registrationRange?.endDate ?? null}
							onRangeChanged={handleRegistrationRangeChanged}
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.kyc-status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetKycStatuses}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedKycStatuses ?? null}
							options={kycStatuses}
							onChange={handleKycStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				{permissions?.includes(Permission.VIEW_GROUPS) && (
					<CCol md={4}>
						<CFormGroup>
							<InputHeader labelText={_t('customers.filters.group')} resetFilter={resetGroup} />
							<CustomSelect
								value={activeFilters.group ?? null}
								onChange={handleGroupChanged}
								options={groups}
								whiteBackground
							/>
						</CFormGroup>
					</CCol>
				)}
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('global.sources')} resetFilter={resetSources} />
						<ToggleSelect
							options={activeFilters.sources ?? null}
							onOptionsChanged={onOptionsChanged}
							inputValue={inputValue}
							onInputValueChanged={setInputValue}
							selectAll={selectAll}
							onSelectChanged={setSelectAll}
							className="customer-toggle-select"
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.deposit-count')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetDepositCount}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.depositCount ?? null}
							options={depositCounts}
							onChange={handleDepositCountChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.positions')} resetFilter={resetPositionsCount} />
						<CustomSelect
							value={activeFilters.positions ?? null}
							options={positionsOptions}
							onChange={handlePositionsChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.activity')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetActivity}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.activity ?? null}
							options={activity}
							onChange={handleActivityChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.sales-status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSalesStatus}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.salesStatus ?? null}
							options={salesStatusesData?.map((s) => {
								return { label: s.status, value: s.status };
							})}
							onChange={handleSalesStatusChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.risk-status')} resetFilter={resetRiskStatus} />
						<CustomSelect
							value={activeFilters.riskStatus ?? null}
							options={riskStatuses}
							onChange={handleRiskStatusChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				{permissions?.includes(Permission.VIEW_SALES_PEOPLE) && (
					<>
						<CCol md={4}>
							<CFormGroup>
								<InputHeader
									labelText={_t('customers.filters.conversion-agent')}
									resetFilter={onConversionAgentOptionReset}
								/>
								<SelectMultipleFilter
									data={activeFilters.conversionAgents}
									onChange={onConversionAgentOptionChanged}
									onAllChange={onConversionAgentAllChanged}
								/>
							</CFormGroup>
						</CCol>
						<CCol md={4}>
							<CFormGroup>
								<InputHeader
									labelText={_t('customers.filters.retention-agent')}
									resetFilter={onRetentionAgentOptionReset}
								/>
								<SelectMultipleFilter
									data={activeFilters.retentionAgents}
									onChange={onRetentionAgentOptionChanged}
									onAllChange={onRetentionAgentAllChanged}
								/>
							</CFormGroup>
						</CCol>
					</>
				)}
			</CRow>
		</CForm>
	);
};

export default CustomersFiltersComponent;
