import React, { FormEvent, useState } from 'react';
import {
	CButton,
	CModal,
	CModalBody,
	CModalHeader,
	CModalTitle,
	CModalFooter,
	CInput,
	CLabel,
	CFormGroup,
	CTextarea,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
} from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { extractErrorMessage, formatCurrency, formatDateTime } from '../../helpers';
import { getAffiliateCommissionLedger, updateAffiliateCommissionLedger } from '../../services/BackendService';
import { IAffiliateCommissionLedger } from './types';
import Loading from '../../components/Loading';
import toast from 'react-hot-toast';

interface IProps {
	ledger: IAffiliateCommissionLedger | null;
	show: boolean;
	hidePanel: (refetch?: boolean) => void;
}

interface IParams {
	ledgerId: string;
	correctedCommissionAmount?: number;
	adminComments?: string;
}

const FormDetail = ({ title, value }: { title: string; value: string }) => {
	return (
		<div>
			<b>
				<span>{title}: </span>
			</b>
			<span>{value}</span>
		</div>
	);
};

const EditCommissionLedgerModal = ({ ledger, show, hidePanel }: IProps) => {
	const [correctedCommissionAmount, setCorrectedCommissionAmount] = useState<string>('');
	const [adminComments, setAdminComments] = useState<string>('');

	const onCorrectedCommissionAmountChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		setCorrectedCommissionAmount(target.value);
	};

	const onAdminCommentsChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		setAdminComments(target.value);
	};

	const ledgerQuery = useQuery(
		['commission-ledger', ledger],
		() => getAffiliateCommissionLedger(ledger?.id.toString() ?? ''),
		{
			onSuccess: (data: IAffiliateCommissionLedger) => {
				setCorrectedCommissionAmount(data.correctedCommissionAmount?.toString() ?? '');
				setAdminComments(data.adminComments);
			},
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
			enabled: ledger !== null,
		}
	);

	const queryClient = useQueryClient();

	const updateLedgerMutation = useMutation(
		({ ledgerId, correctedCommissionAmount, adminComments }: IParams) =>
			updateAffiliateCommissionLedger(ledgerId, correctedCommissionAmount, adminComments),
		{
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
			onSuccess: () => {
				hidePanel(true);
				toast.success(_t('commissions.ledger-updated-successfully'));
				queryClient.invalidateQueries('commission-ledger-page');
			},
		}
	);

	const invokeMutation = () => {
		if (ledger) {
			updateLedgerMutation.mutate({
				ledgerId: ledger.id.toString(),
				correctedCommissionAmount: correctedCommissionAmount ? Number(correctedCommissionAmount) : undefined,
				adminComments,
			});
		}
	};

	return (
		<CModal show={show} onClose={hidePanel} size="sm" closeOnBackdrop={false}>
			<CModalHeader className="d-flex flex-column">
				<CModalTitle>{_t('commissions.edit-commission-ledger', { id: ledger?.id })}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{ledgerQuery.isLoading && <Loading />}
				{!ledgerQuery.isLoading && (
					<>
						<FormDetail
							title={_t('commissions.commission-amount')}
							value={formatCurrency(ledgerQuery.data?.commissionAmount ?? '')}
						/>

						<FormDetail title={_t('global.date')} value={formatDateTime(ledgerQuery.data?.actionTimestamp ?? '')} />
						<FormDetail title={_t('commissions.action-type')} value={ledgerQuery.data?.actionType ?? ''} />
						<FormDetail title={_t('commissions.action-details')} value={ledgerQuery.data?.actionDetails ?? ''} />

						<hr />

						<CFormGroup>
							<CLabel>{_t('commissions.corrected-amount')}</CLabel>
							<CInputGroup>
								<CInputGroupPrepend>
									<CInputGroupText>$</CInputGroupText>
								</CInputGroupPrepend>
								<CInput
									type="number"
									placeholder="12.64"
									value={correctedCommissionAmount}
									onChange={onCorrectedCommissionAmountChange}
								/>
							</CInputGroup>
						</CFormGroup>

						<CFormGroup>
							<CLabel>{_t('commissions.admin-comments')}</CLabel>
							<CTextarea onChange={onAdminCommentsChange} value={adminComments} />
						</CFormGroup>
					</>
				)}
			</CModalBody>
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={updateLedgerMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="success"
					spinnerColor="secondary"
					title={_t('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={hidePanel} disabled={updateLedgerMutation.isLoading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default EditCommissionLedgerModal;
