import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IDateRange } from '../reducers/filtersReducer';

interface IProps {
	initialStartDate: Date | null;
	initialEndDate: Date | null;
	onRangeChanged: (range: IDateRange) => void;
}

const DateRangePicker = ({ initialStartDate, initialEndDate, onRangeChanged }: IProps) => {
	const pickerRef = useRef(null);
	const [dateRange, setDateRange] = useState<IDateRange>({
		startDate: initialStartDate,
		endDate: initialEndDate,
	});
	const { startDate, endDate } = dateRange;

	const handleDateRangeUpdated = ([startDate, endDate]: Array<Date | null>) => {
		endDate?.setHours(23, 59, 59, 999);
		setDateRange({ startDate, endDate });
		onRangeChanged({ startDate, endDate });
	};

	useEffect(() => {
		if (!initialStartDate || !initialEndDate) {
			// @ts-ignore
			pickerRef.current?.clear();
		}
	}, [initialStartDate, initialEndDate]);

	return (
		<DatePicker
			ref={pickerRef}
			dateFormat="dd/MM/yyyy"
			className="custom-date-input"
			selectsRange
			startDate={startDate}
			endDate={endDate}
			onChange={(update) => {
				handleDateRangeUpdated(update as Array<Date | null>);
			}}
		/>
	);
};

export default DateRangePicker;
