import { CSpinner } from '@coreui/react';
import React from 'react';

interface Props {
	color?: string;
}

const InlineSpinner = ({ color }: Props) => {
	const spinnerStyle = React.useMemo(() => ({ width: '1rem', height: '1rem' }), []);

	return <CSpinner style={spinnerStyle} color={color} />;
};

export default React.memo(InlineSpinner);
