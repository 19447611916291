import React from 'react';
import { CSpinner, CButton } from '@coreui/react';
import _t from 'counterpart';

interface IProps extends Pick<React.HTMLProps<HTMLButtonElement>, 'type'> {
	isLoading: boolean;
	onClick?: () => void;
	buttonColor: string;
	title: string;
	spinnerColor?: string;
	spinnerSize?: string;
	className?: string;
	disabled?: boolean;
	showText?: boolean;
	style?: React.CSSProperties;
}

const ButtonWithLoader = ({
	isLoading,
	onClick,
	buttonColor,
	spinnerSize = 'sm',
	spinnerColor = 'secondary',
	title,
	className,
	disabled,
	showText = true,
	style,
	...rest
}: React.PropsWithChildren<IProps>) => {
	return (
		<CButton color={buttonColor} onClick={onClick} disabled={isLoading || disabled} style={style} className={className} {...rest}>
			{isLoading && (
				<div className="d-flex align-items-center">
					<CSpinner color={spinnerColor} size={spinnerSize} />
					{showText && <>&nbsp;{_t('global.please-wait')}</>}
				</div>
			)}
			{!isLoading && title}
		</CButton>
	);
};

export default ButtonWithLoader;
