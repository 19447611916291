import React, { useState } from 'react';
import _t from 'counterpart';
import { CFormGroup, CCol, CRow, CLabel, CInputGroup, CInput, CFormText } from '@coreui/react';
import { useGetSetting } from './hooks';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { extractErrorMessage } from '../../helpers';
import { useMutation } from 'react-query';
import { updateSumsub } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IParams {
	apiUrl: string;
	secretKey: string;
	levelName: string;
	webhookSecretKey: string;
	appToken: string;
}

const SumSubConfiguration = () => {
	const [apiUrl, setApiUrl] = useState<string>('');
	const [secretKey, setSecretKey] = useState<string>('');
	const [levelName, setLevelName] = useState<string>('');
	const [webhookSecretKey, setWebhookSecretKey] = useState<string>('');
	const [appToken, setAppToken] = useState<string>('');

	const updateSumsubMutation = useMutation(
		({ apiUrl, secretKey, levelName, webhookSecretKey, appToken }: IParams) =>
			updateSumsub(apiUrl, secretKey, levelName, webhookSecretKey, appToken),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
			onSuccess: () => {
				toast.success(_t('integrations.sumsub-updated'));
			},
		}
	);

	const apiUrlQuery = useGetSetting('SUMSUB_API_URL', 'sumsub-api-url', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setApiUrl(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const secretKeyQuery = useGetSetting('SUMSUB_SECRET_KEY', 'sumsub-secret-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setSecretKey(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const levelNameQuery = useGetSetting('SUMSUB_LEVEL_NAME', 'sumsub-level-name', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setLevelName(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const webhookSecretKeyQuery = useGetSetting('SUMSUB_WEBHOOK_SECRET_KEY', 'sumsub-webhook-secret-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setWebhookSecretKey(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const appTokenQuery = useGetSetting('SUMSUB_APP_TOKEN', 'sumsub-app-token', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setAppToken(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const callUpdateSumsubMutation = () => {
		updateSumsubMutation.mutate({ apiUrl, secretKey, levelName, webhookSecretKey, appToken });
	};

	const setApiUrlFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setApiUrl(e.target.value);
	};

	const setSecretKeyFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSecretKey(e.target.value);
	};

	const setAppTokenFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAppToken(e.target.value);
	};

	const setWebhookSecretKeyFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setWebhookSecretKey(e.target.value);
	};

	const setLevelNameFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLevelName(e.target.value);
	};

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="apiUrl">{_t(`integrations.sumsub-api-url`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="apiUrl"
							name="apiUrl"
							value={apiUrl}
							onChange={setApiUrlFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					<CFormText className="help-block">{_t('integrations.sumsub-api-url-description')}</CFormText>
					{apiUrlQuery.error && <CLabel className="text-danger">{_t('integrations.sumsub-api-url-failed')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="appToken">{_t(`integrations.sumsub-app-token`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="appToken"
							name="appToken"
							value={appToken}
							onChange={setAppTokenFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{appTokenQuery.error && <CLabel className="text-danger">{_t('integrations.sumsub-app-token-failed')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="secretKey">{_t(`integrations.sumsub-secret-key`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="secretKey"
							name="secretKey"
							value={secretKey}
							onChange={setSecretKeyFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{secretKeyQuery.error && (
						<CLabel className="text-danger">{_t('integrations.sumsub-secret-key-failed')}</CLabel>
					)}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="levelName">{_t(`integrations.sumsub-level-name`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="levelName"
							name="levelName"
							value={levelName}
							onChange={setLevelNameFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{levelNameQuery.error && (
						<CLabel className="text-danger">{_t('integrations.sumsub-level-name-failed')}</CLabel>
					)}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="webhookSecretKey">
						{_t(`integrations.sumsub-webhook-secret-key`, {
							webhookApi: `${process.env.REACT_APP_API_ROOT}/api/sumsub/webhook`,
						})}
					</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="webhookSecretKey"
							name="webhookSecretKey"
							value={webhookSecretKey}
							onChange={setWebhookSecretKeyFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					<CFormText className="help-block">
						{_t('integrations.sumsub-webhook-secret-key-description', {
							webhookApi: `${process.env.REACT_APP_API_ROOT}/api/sumsub/webhook`,
						})}
					</CFormText>
					{webhookSecretKeyQuery.error && (
						<CLabel className="text-danger">{_t('integrations.sumsub-webhook-secret-key-failed')}</CLabel>
					)}
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={updateSumsubMutation.isLoading}
					onClick={callUpdateSumsubMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={apiUrl === '' || appToken === '' || webhookSecretKey === '' || secretKey === '' || levelName === ''}
				/>
			</CCol>
		</CRow>
	);
};

export default SumSubConfiguration;
