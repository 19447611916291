import {
	CButton,
	CFormGroup,
	CInput,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import _t from 'counterpart';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { useMutation } from 'react-query';
import {
	ExchangeRateSource,
	ICreateExchangeRateParams as ICreateParams,
	IUpdateExchangeRateParams as IUpdateParams,
	IExchangeRate,
} from './types';
import { createExchangeRate, updateExchangeRate } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import toast from 'react-hot-toast';

interface IState {
	fromCurrency: string;
	toCurrency: string;
	source: ISelectOption | null;
	rate: number | null;
}

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	exchangeRate: IExchangeRate | null;
}

const ExchangeRateModal = ({ show, onClose, exchangeRate }: IProps) => {
	const initalState = {
		fromCurrency: '',
		toCurrency: '',
		source: null,
		rate: null,
	};
	const [formState, setFormState] = useState<IState>(initalState);
	const [exchangeRateId, setExchangeRateId] = useState<string | null>(null);

	useEffect(() => {
		if (exchangeRate !== null) {
			setFormState({
				fromCurrency: exchangeRate.fromCurrency,
				toCurrency: exchangeRate.toCurrency,
				source: { label: exchangeRate.source, value: exchangeRate.source },
				rate: exchangeRate.rate,
			});
			setExchangeRateId(exchangeRate.id);
		}
	}, [exchangeRate]);

	const createExchangeRateMutation = useMutation(
		(params: ICreateParams) => createExchangeRate(params.fromCurrency, params.toCurrency, params.source, params.rate),
		{
			onSuccess: () => {
				toast.success(_t('exchangeRates.exchange-rate-created'));
				setFormState(initalState);
				onClose(true);
			},
			onError: (e: any) => {
				if (e.response.status !== 422) {
					toast.error(extractErrorMessage(e));
				}
			},
		}
	);

	const updateExchangeRateMutation = useMutation(
		(params: IUpdateParams) =>
			updateExchangeRate(params.id, params.fromCurrency, params.toCurrency, params.source, params.rate),
		{
			onSuccess: () => {
				toast.success(_t('exchangeRates.exchange-rate-updated'));
				setFormState(initalState);
				setExchangeRateId(null);
				onClose(true);
			},
			onError: (e: any) => {
				if (e.response.status !== 422) {
					toast.error(extractErrorMessage(e));
				}
			},
		}
	);

	const sources: Array<ISelectOption> = Object.values(ExchangeRateSource).map((s) => {
		return {
			value: s,
			label: s,
		};
	});

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement;
		const name: string | null = target.getAttribute('name');
		if (name) {
			setFormState({ ...formState, [name]: target.value });
		}
	};

	const handleSourceChanged = (source: any) => {
		setFormState({ ...formState, source });
	};

	const closeHandler = () => {
		setFormState(initalState);
		createExchangeRateMutation.reset();
		updateExchangeRateMutation.reset();
		onClose();
		setExchangeRateId(null);
	};

	const invokeMutation = () => {
		if (exchangeRateId) {
			updateExchangeRateMutation.mutate({
				id: exchangeRateId,
				fromCurrency: formState.fromCurrency,
				toCurrency: formState.toCurrency,
				source: formState.source?.value || '',
				rate: formState.rate ? Number(formState.rate) : null,
			});
		} else {
			createExchangeRateMutation.mutate({
				fromCurrency: formState.fromCurrency,
				toCurrency: formState.toCurrency,
				source: formState.source?.value || '',
				rate: formState.rate ? Number(formState.rate) : null,
			});
		}
	};

	const findError = (paramName: string) => {
		const error = createExchangeRateMutation.error || updateExchangeRateMutation.error;
		return findErrorFromValidation(error, paramName);
	};

	const isLoading = createExchangeRateMutation.isLoading || updateExchangeRateMutation.isLoading;

	return (
		<CModal show={show} onClose={closeHandler} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>
					{exchangeRateId ? _t('exchangeRates.update-exchange-rate') : _t('exchangeRates.add-exchange-rate')}
				</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="fromCurrency">{_t('exchangeRates.from-currency')}</CLabel>
					<CInput name="fromCurrency" value={formState.fromCurrency} onChange={handleInputChange} />
					{findError('fromCurrency') && <CLabel className="text-danger">{findError('fromCurrency')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="toCurrency">{_t('exchangeRates.to-currency')}</CLabel>
					<CInput name="toCurrency" value={formState.toCurrency} onChange={handleInputChange} />
					{findError('toCurrency') && <CLabel className="text-danger">{findError('toCurrency')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="source">{_t('global.source')}</CLabel>
					<CustomSelect
						value={formState.source}
						options={sources}
						onChange={handleSourceChanged}
						isClearable={false}
						whiteBackground
					/>
					{findError('source') && <CLabel className="text-danger">{findError('source')}</CLabel>}
				</CFormGroup>
				{formState.source?.value === ExchangeRateSource.Manual && (
					<CFormGroup>
						<CLabel htmlFor="rate">{_t('exchangeRates.rate')}</CLabel>
						<CInput
							type="number"
							name="rate"
							placeholder="1.5"
							value={formState.rate || ''}
							onChange={handleInputChange}
						/>
						{findError('rate') && <CLabel className="text-danger">{findError('rate')}</CLabel>}
					</CFormGroup>
				)}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={isLoading}
					onClick={invokeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={exchangeRateId ? _t('action.update') : _t('action.add')}
					className="mr-2"
				/>
				<CButton color="secondary" onClick={closeHandler} disabled={isLoading}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(ExchangeRateModal);
