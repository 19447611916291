import React from 'react';
import _t from 'counterpart';

const NoItems = () => (
	<div className="d-flex align-items-center justify-content-center h-100">
		<h3>{_t('global.no-items')}</h3>
	</div>
);

export default NoItems;
