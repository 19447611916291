import React from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import CaptchaIntegration from './CaptchaIntegration';
import EmailIntegration from './EmailIntegration';
import VoipIntegration from './VoipIntegration';
import WalletConfiguration from './WalletConfiguration';
import SumSubIntegration from './SumSubIntegration';

const GeneralSettingsPage = () => {
	return (
		<PageLayout title={_t('sidebar.general-settings')}>
			<VoipIntegration />
			<EmailIntegration />
			<CaptchaIntegration />
			<WalletConfiguration />
			<SumSubIntegration />
		</PageLayout>
	);
};

export default GeneralSettingsPage;
