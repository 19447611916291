import React, { useMemo, useState } from 'react';
import _t from 'counterpart';
import PageLayout from '../../components/PageLayout';
import { useQuery, useQueryClient } from 'react-query';
import { fetchSalesPeopleWithTradingVolume } from '../../services/BackendService';
import PaginationTable from '../../components/PaginationTable';
import { formatCurrency } from '../../helpers';
import { CRow, CCol, CCard, CCardBody, CCardHeader } from '@coreui/react';
import SearchFilter from '../../components/SearchFilter';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { ISalesPeopleRow } from './types';

const SalesPeoplePage = () => {
	const history = useHistory();

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);

	const salesPeopleItemsPerPageFromLocalStorage = Number(
		localStorage.getItem('items_per_page_sales_people_table') ?? 10
	);
	const [limit, setLimit] = useState<number>(salesPeopleItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const salesPeopleQuery = useQuery(['sales-people-query', filter, offset, page], () =>
		fetchSalesPeopleWithTradingVolume(filter, limit, offset)
	);

	const queryClient = useQueryClient();

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_sales_people_table', limit.toString());
		await queryClient.invalidateQueries('sales-people-query');
		salesPeopleQuery.refetch();
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const tableFields = useMemo(
		() => [
			{
				key: 'name',
				label: _t('customers.columns.name'),
				sorter: false,
			},
			{
				key: 'usersUnder',
				label: _t('customers.columns.users-under'),
				sorter: false,
			},
			{
				key: 'totalDeposit',
				label: _t('customers.columns.depositTotal'),
				sorter: false,
			},
			{
				key: 'totalTradingVolume',
				label: _t('customers.columns.totalTradingVolume'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			name: (salesPerson: ISalesPeopleRow) => <td>{salesPerson.name}</td>,
			totalDeposit: (salesPerson: ISalesPeopleRow) => <td>{formatCurrency(salesPerson.totalDeposit)}</td>,
			totalTradingVolume: (salesPerson: ISalesPeopleRow) => <td>{salesPerson.totalTradingVolume}</td>,
		};
	}, []);

	const onRowClicked = (user: ISalesPeopleRow) => {
		history.push(`/sales-people/${user.id}`);
	};

	return (
		<PageLayout title={_t('sales-people.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
								</div>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								data={salesPeopleQuery.data?.salesPeople || []}
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								loading={salesPeopleQuery.isLoading}
								pages={salesPeopleQuery.data?.pages || 0}
								pagination
								activePage={page || 1}
								onPageChanged={onPageChanged}
								clickableRows
								onRowClicked={onRowClicked}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default SalesPeoplePage;
