import _t from 'counterpart';

export interface ICommissionModelLevel {
	level: number | null;
	cpaAmount: number | null;
	cpaCurrency: string | null;
	volumeCommissionAmount: number | null;
	volumeCommissionCurrency: string | null;
	profitSharePercentage: number | null;
}

export interface ICommissionModel {
	id?: number;
	name: string;
	subCommissionModel: { id: string; name: string } | null;
	subCommissionModelId: number | null;
	greyLabelingEnabled: boolean;
	commissionModelLevels: Array<ICommissionModelLevel>;
	default: boolean;
}

export interface IUpdateCommissionModelParams {
	commissionModel: Omit<ICommissionModel, 'subCommissionModel' | 'default'>;
	id: number;
}

export const initialCommissionModelState: ICommissionModel = {
	name: '',
	subCommissionModel: null,
	subCommissionModelId: null,
	greyLabelingEnabled: false,
	commissionModelLevels: [],
	default: false
};

export const initialCommissionModelLevel: ICommissionModelLevel = {
	level: 1,
	cpaAmount: null,
	cpaCurrency: null,
	volumeCommissionAmount: null,
	volumeCommissionCurrency: null,
	profitSharePercentage: null,
};

export enum CommissionType {
	CPA = 'CPA',
	VOLUME = 'VOLUME',
	PROFIT_SHARE = 'PROFIT_SHARE',
}

export enum ActionType {
	FTD = 'FTD',
	Trade = 'Trade',
}

export interface IAffiliateCommissionLedger {
	id: number;
	introducer: { name: string; id: string };
	referral: { name: string; id: string };
	commissionType: CommissionType;
	actionType: ActionType;
	actionDetails: string;
	commissionAmount: number;
	correctedCommissionAmount: number | null;
	currency: string;
	actionTimestamp: Date;
	payoutAt: Date | null;
	updatedAt: Date;
	adminComments: string;
	createdAt: Date;
}

export interface IPayoutRequest {
	id: number;
	introducer: { name: string; id: string };
	payoutWalletId: number;
	requestedAt: Date;
	approvedAt: Date | null;
	rejectedAt: Date | null;
	rejectReason: string | null;
	updatedAt: Date;
	createdAt: Date;
	transactionId: string | null;
	admin: {
		name: string;
		id: string;
	} | null;
}

export interface IPayoutRequestResponse {
	payoutRequest: IPayoutRequest;
	commissions: Array<IAffiliateCommissionLedger>;
	commissionsCount: number;
	totalPayoutAmount: number;
}

export interface IPayoutRequestFiltersDTO {
	statuses: Array<string>;
}
