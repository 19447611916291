import Select, { Props } from 'react-select';
import React from 'react';
import classNames from 'classnames';

interface IProps {
	whiteBackground?: boolean;
	fullWidth?: boolean;
}

export interface ISelectOption {
	value: string;
	label: string;
}

const CustomSelect = ({ whiteBackground = false, fullWidth = false, ...rest }: IProps & Props) => {
	return (
		<Select
			className={classNames('react-select__container', {
				'react-select__container--white-bg': whiteBackground,
				'react-select__container--full-width': fullWidth,
			})}
			classNamePrefix="react-select"
			{...rest}
		/>
	);
};

CustomSelect.defaultProps = {
	whiteBackground: false,
};

export default CustomSelect;
