import React, { useState } from 'react';
import { CRow, CCol, CInput, CLabel, CFormGroup, CInputGroup } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import toast from 'react-hot-toast';
import { useGetSetting } from './hooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updateMailgun } from '../../services/BackendService';

interface IParams {
	sender: string;
	apiKey: string;
	domain: string;
	username: string;
	staffEmail: string;
}

interface IProps {
	onChange: () => void;
}

const MailgunIntegration = ({ onChange }: IProps) => {
	const [apiKey, setApiKey] = useState<string>('');
	const [username, setUsername] = useState<string>('');
	const [domain, setDomain] = useState<string>('');
	const [emailSender, setEmailSender] = useState<string>('');
	const [staffEmail, setStaffEmail] = useState<string>('');

	const apiKeyQuery = useGetSetting('MAILGUN_API_KEY', 'mailgun-api-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setApiKey(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const emailSenderQuery = useGetSetting('EMAIL_TRANSACTIONAL_SENDER', 'email-sender', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setEmailSender(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const domainQuery = useGetSetting('MAILGUN_DOMAIN', 'mailgun-domain', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setDomain(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const usernameQuery = useGetSetting('MAILGUN_USERNAME', 'mailgun-username', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setUsername(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const staffEmailQuery = useGetSetting('STAFF_NOTIFICATION_EMAIL', 'staff-email', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setStaffEmail(value);
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const updateMailgunMutation = useMutation(
		({ sender, apiKey, domain, username, staffEmail }: IParams) =>
			updateMailgun(sender, apiKey, username, domain, staffEmail),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					toast.error(extractErrorMessage(error));
				}
			},
			onSuccess: () => {
				toast.success(_t('integrations.mailgun-configured'));
				onChange();
			},
		}
	);

	const setApiKeyFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setApiKey(e.target.value);
	};

	const setUsernameFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUsername(e.target.value);
	};

	const setDomainFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDomain(e.target.value);
	};

	const setSenderFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmailSender(e.target.value);
	};

	const setStaffFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStaffEmail(e.target.value);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateMailgunMutation.error, paramName);
		return error;
	};

	const callUpdateMailgunMutation = () => {
		updateMailgunMutation.mutate({ apiKey, sender: emailSender, domain, username, staffEmail });
	};

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="apiKey">{_t(`integrations.mailgun-api-key`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="apiKey"
							name="apiKey"
							value={apiKey}
							onChange={setApiKeyFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{apiKeyQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-api-key-failed')}</CLabel>}
					{findError('apiKey') && <CLabel className="text-danger">{findError('apiKey')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="username">{_t(`integrations.mailgun-username`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="username"
							name="username"
							value={username}
							onChange={setUsernameFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{usernameQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-username-failed')}</CLabel>}
					{findError('username') && <CLabel className="text-danger">{findError('username')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="domain">{_t(`integrations.mailgun-domain`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="domain"
							name="domain"
							value={domain}
							onChange={setDomainFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{domainQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-domain-failed')}</CLabel>}
					{findError('domain') && <CLabel className="text-danger">{findError('domain')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.email-sender`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="sender"
							name="sender"
							value={emailSender}
							onChange={setSenderFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{emailSenderQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('sender') && <CLabel className="text-danger">{findError('sender')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.staff-email`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="staffEmail"
							name="staffEmail"
							value={staffEmail}
							onChange={setStaffFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{staffEmailQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('staffEmail') && <CLabel className="text-danger">{findError('staffEmail')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={updateMailgunMutation.isLoading}
					onClick={callUpdateMailgunMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={apiKey === '' || emailSender === '' || username === '' || domain === ''}
				/>
			</CCol>
		</CRow>
	);
};

export default MailgunIntegration;
