import React from 'react';
import { CCard, CCardBody, CCardHeader, CBadge } from '@coreui/react';

const StatsCard = ({
	badgeText,
	badgeColor,
	textColor,
	data,
}: {
	badgeText: string;
	badgeColor: string;
	textColor: string;
	data: any;
}) => {
	return (
		<CCard className="text-center mb-4">
			<CCardHeader>
				<CBadge style={{ backgroundColor: badgeColor, color: textColor }}>{badgeText}</CBadge>
			</CCardHeader>
			<CCardBody>
				{data.map((item: any, index: number) => (
					<div key={index}>
						<h2 className="text-left">
							<b>{item.value}</b>
						</h2>
						<p className="dashboard-text-muted">{item.label}</p>
						{index < data.length - 1 && <hr />}
					</div>
				))}
			</CCardBody>
		</CCard>
	);
};

export default StatsCard;
