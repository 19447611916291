export enum ExchangeRateSource {
	Manual = 'Manual',
	OpenExchangeRates = 'OpenExchangeRates',
}

export interface ICreateExchangeRateParams {
	fromCurrency: string;
	toCurrency: string;
	source: string;
	rate: number | null;
}

export interface IUpdateExchangeRateParams extends ICreateExchangeRateParams {
	id: string;
}

export interface IExchangeRate {
	id: string;
	fromCurrency: string;
	toCurrency: string;
	rate: number | null;
	source: ExchangeRateSource;
	createdAt: Date;
	updatedAt: Date;
}
