import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CButton, CCard, CCardBody, CCol, CDataTable, CRow } from '@coreui/react';
import { getConversions } from '../../services/BackendService';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import { AddEditConversionModal } from './AddEditConversionModal';
import { IConversion } from './types';
import { DeleteConversionModal } from './DeleteConversionModal';

interface IAddEditModalState {
	shown: boolean;
	conversion: IConversion | null;
}

interface IDeleteModalState {
	shown: boolean;
	conversion: IConversion | null;
}

export default function CurrencyConversionsPage() {
	const { isLoading, isError, data, refetch } = useQuery(['conversions'], () => getConversions());

	const [addEditModalState, setAddEditModalState] = useState<IAddEditModalState>({
		shown: false,
		conversion: null,
	});

	const [deleteModalState, setDeleteModalState] = useState<IDeleteModalState>({
		shown: false,
		conversion: null,
	});

	const showAddModal = () => {
		setAddEditModalState({
			shown: true,
			conversion: null,
		});
	};

	const showEditModal = (conversion: IConversion) => {
		setAddEditModalState({
			shown: true,
			conversion,
		});
	};

	const showDeleteModal = (conversion: IConversion) => {
		setDeleteModalState({
			shown: true,
			conversion,
		});
	};

	const hideAddEditModal = () => {
		setAddEditModalState({
			shown: false,
			conversion: null,
		});
	};

	const hideDeleteModal = () => {
		setDeleteModalState({
			shown: false,
			conversion: null,
		});
	};

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	return (
		<PageLayout title={_t('currencyConversions.title')}>
			<CRow>
				<CCol>
					<p>{_t('currencyConversions.paragraph-one')}</p>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<div className="my-2 float-sm-right d-flex justify-content-end">
								<CButton className="header-button-add" onClick={showAddModal} />
							</div>
							<CDataTable
								items={data}
								fields={[
									{
										key: 'source',
										label: _t('currencyConversions.source'),
									},
									{
										key: 'target',
										label: _t('currencyConversions.target'),
									},
									{
										key: 'actions',
										label: _t('global.actions'),
										sorter: false,
										filter: false,
									},
								]}
								scopedSlots={{
									actions: (conversion: IConversion) => (
										<td>
											<CButton onClick={() => showEditModal(conversion)} size="sm" className="mr-1" color="primary">
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											<CButton color="danger" size="sm" onClick={() => showDeleteModal(conversion)}>
												<CIcon name="cil-trash" size="sm" />
											</CButton>
										</td>
									),
								}}
								loading={isLoading}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{addEditModalState.shown && (
				<AddEditConversionModal conversion={addEditModalState.conversion} hideModal={hideAddEditModal} />
			)}

			{deleteModalState.shown && (
				<DeleteConversionModal conversion={deleteModalState.conversion!} hideModal={hideDeleteModal} />
			)}
		</PageLayout>
	);
}
