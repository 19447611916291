import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import storeRegistry from './storeRegistry';
import { logout, setLoginErrorMessage, updateUserRefreshToken, updateUserToken } from '../actions';
import _t from 'counterpart';
import { oAuthClientId, oAuthClientSecret } from './oAuthLogin';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import toast from 'react-hot-toast';

export async function getOAuthTokensWithRefreshToken(
	refreshToken: string,
	clientId: string,
	clientSecret: string,
	redirectUri: string
) {
	const response = await axios.post(
		`${process.env.REACT_APP_API_ROOT}/api/oauth/token`,
		new URLSearchParams({
			grant_type: 'refresh_token',
			client_id: clientId,
			client_secret: clientSecret,
			redirect_uri: redirectUri,
			refresh_token: refreshToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);
	return response.data;
}

const refreshAuthLogic = async (failedRequest: any) => {
	const { refreshToken, impersonation } = storeRegistry.getStore().getState().user;
	if (impersonation || !refreshToken) {
		storeRegistry.getStore().dispatch(logout());
		storeRegistry.getStore().dispatch(setLoginErrorMessage(_t('global.session-expired')));
		return Promise.reject(failedRequest);
	}
	if (refreshToken) {
		try {
			const { access_token: token, refresh_token: newRefreshToken } = await getOAuthTokensWithRefreshToken(
				refreshToken,
				oAuthClientId as string,
				oAuthClientSecret as string,
				`${window.location.origin}/login`
			);
			storeRegistry.getStore().dispatch(updateUserToken(token));
			storeRegistry.getStore().dispatch(updateUserRefreshToken(newRefreshToken));
			failedRequest.response.config.headers.authorization = `Bearer ${token}`;
			return await Promise.resolve();
		} catch (e) {
			storeRegistry.getStore().dispatch(logout());
			return Promise.reject(failedRequest);
		}
	}

	return Promise.reject(failedRequest);
};

export function createTokenRefreshInterceptor(instance: AxiosInstance) {
	return createAuthRefreshInterceptor(instance, refreshAuthLogic, {
		statusCodes: [401, 403],
	});
}

export function handleRequest(config: AxiosRequestConfig) {
	if (config.url?.endsWith('oauth/token') || (config.url?.endsWith('users/self') && config.headers.authorization)) {
		return config;
	}
	const { token } = storeRegistry.getStore().getState().user;
	if (token) {
		config.headers.authorization = `Bearer ${token}`;
	}
	return config;
}

export function handleSuccess(response: AxiosResponse) {
	return response;
}

export async function handleReject(error: AxiosError) {
	const responseStatusCode = error.response?.status;
	if (responseStatusCode) {
		if (responseStatusCode === 400 && error.config.url?.endsWith('oauth/token')) {
			storeRegistry.getStore().dispatch(logout());
		} else if (responseStatusCode === 503 || responseStatusCode === 504) {
			storeRegistry.getStore().dispatch(logout());
			storeRegistry.getStore().dispatch(setLoginErrorMessage(_t('global.maintenance-mode')));
		} else if (responseStatusCode === 403) {
			toast.error(_t('errors.lacks-permissions'));
		}
	}
	return Promise.reject(error);
}
