import React, { useState } from 'react';
import _t from 'counterpart';
import { CModal, CModalHeader, CModalFooter, CButton, CModalTitle, CModalBody, CLabel, CSelect } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { getSalesStatuses, updateLead } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';

interface IStatus {
	id: number;
	status: string;
}

interface IProps {
	id: string;
	show: boolean;
	onHide: (refetch?: boolean) => void;
	currentStatus: string;
}

interface ISetStatus {
	id: string;
	status: number;
}

const SetLeadStatusModal = ({ id, show, onHide, currentStatus }: IProps) => {
	const [selectedStatus, setSelectedStatus] = useState<string>();
	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const setStatusMutation = useMutation(({ id, status }: ISetStatus) => updateLead(id, status), {
		onSuccess: () => {
			onHide(true);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setSelectedStatus(target.value);
	};

	const setInitialStatus = () => {
		setSelectedStatus(currentStatus);
	};

	const executeMutation = () => {
		const statusBySelectedStatus = salesStatusesQuery.data?.find((status) => status.status === selectedStatus);
		if (statusBySelectedStatus?.id) {
			setStatusMutation.mutate({ id, status: statusBySelectedStatus.id });
		}
	};

	return (
		<CModal show={show} onClose={onHide} onOpened={setInitialStatus}>
			<CModalHeader>
				<CModalTitle>{_t('leads.set-lead-status')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CLabel htmlFor="status">{_t('leads.select-status')}</CLabel>
				<CSelect name="status" value={selectedStatus} onChange={handleInputChange}>
					{salesStatusesQuery.data
						? Object.values(salesStatusesQuery.data!).map((status: IStatus) => {
								return (
									<option key={status.status} value={status.status}>
										{status.status}
									</option>
								);
						  })
						: ''}
				</CSelect>
			</CModalBody>
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={setStatusMutation.isLoading}
					onClick={executeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={onHide} disabled={setStatusMutation.isLoading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(SetLeadStatusModal);
