export const payments = [
	'24 24',
	`
<g>
<path d="M18.4375 2.8125H1.5625C1.31395 2.81279 1.07566 2.91165 0.899907 3.08741C0.724154 3.26316 0.625289 3.50145 0.625 3.75V16.25C0.625289 16.4986 0.724154 16.7368 0.899907 16.9126C1.07566 17.0883 1.31395 17.1872 1.5625 17.1875H18.4375C18.6861 17.1872 18.9243 17.0883 19.1001 16.9126C19.2758 16.7368 19.3747 16.4986 19.375 16.25V3.75C19.3747 3.50145 19.2758 3.26316 19.1001 3.08741C18.9243 2.91165 18.6861 2.81279 18.4375 2.8125ZM18.125 4.0625V6.5625H1.875V4.0625H18.125ZM1.875 15.9375V9.0625H18.125V15.9375H1.875Z" />
<path d="M5.9375 12.1875H3.4375V13.4375H5.9375V12.1875Z" />
<path d="M9.6875 12.1875H7.1875V13.4375H9.6875V12.1875Z" />
</g>
`,
];
