import React from 'react';
import { CButton, CFormGroup, CInput, CInputGroup, CLabel } from '@coreui/react';
import _t from 'counterpart';
import { IFormGroupInputProps, IFormGroupSizesProps } from './types';
import CIcon from '@coreui/icons-react';

export const FormGroupInput = (props: IFormGroupInputProps) => {
	const { label, value, onChange, placeholder, type, disabled, error, className } = props;
	const prefix = 'instruments.form';

	return (
		<CFormGroup className={className}>
			<CLabel htmlFor={label}>{_t(`${prefix}.${label}`)}</CLabel>
			<CInputGroup>
				<CInput
					type={type}
					id={label}
					name={label}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					className="instrument-input-height"
				/>
			</CInputGroup>
			{error && <CLabel className="text-danger">{error}</CLabel>}
		</CFormGroup>
	);
};

export const FormGroupSizesInput = (props: IFormGroupSizesProps) => {
	const { index, onRemoveSize, onChange, size } = props;

	const onInputLeftChange = (e: any) => {
		onChange(index, {
			left: e.target.value,
			right: size.right,
		});
	};

	const onInputRightChange = (e: any) => {
		onChange(index, {
			left: size.left,
			right: e.target.value,
		});
	};

	return (
		<div className="d-flex flex-row align-items-end mt-2">
			<div className="mr-2 flex-grow-1">
				{index === 0 && <CLabel htmlFor={`size-left-${index}`}>{_t('instruments.form.order-size')}</CLabel>}
				<CInput
					type="number"
					id={`size-left-${index}`}
					name={`size-left-${index}`}
					placeholder="1"
					label="test"
					value={size.left}
					onChange={onInputLeftChange}
					className="mr-2 border-fix"
				/>
			</div>
			<div className="mr-2 flex-grow-1">
				{index === 0 && <CLabel htmlFor={`size-right-${index}`}>{_t('instruments.form.display-amount')}</CLabel>}
				<CInput
					type="number"
					id={`size-right-${index}`}
					name={`size-right-${index}`}
					placeholder="1"
					value={size.right}
					onChange={onInputRightChange}
					className="mr-2 border-fix"
				/>
			</div>
			<CButton className="instrument-button" color="danger" onClick={() => onRemoveSize(index)}>
				<CIcon name="cil-trash" size="md" />
			</CButton>
		</div>
	);
};
