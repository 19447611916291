import React from 'react';
import Select, { components } from 'react-select';
import _t from 'counterpart';
import { ISelectOption } from '../../components/ToggleSelect';

interface IProps {
	options: Array<ISelectOption>;
	onOptionsChanged: (options: Array<ISelectOption>) => void;
	inputValue: string;
	onInputValueChanged: (value: string) => void;
	selectAll: boolean;
	onSelectAllChanged: (value: boolean) => void;
	onOnlyAssignedToMeChanged: (value: boolean) => void;
	onlyAssignedToMe: boolean;
	className?: string;
}

const AssigneFilter = (props: IProps) => {
	const {
		options,
		onOptionsChanged,
		inputValue,
		onInputValueChanged,
		selectAll,
		onSelectAllChanged: onSelectChanged,
		onOnlyAssignedToMeChanged,
		onlyAssignedToMe,
		className,
	} = props;

	const handleInputValue = (input: any, reason: any) => {
		const { action } = reason;
		const returnFields = ['set-value', 'input-blur', 'menu-close'];
		if (!returnFields.includes(action)) {
			onInputValueChanged(input);
		}
	};

	const toggleSelectAllValues = () => {
		const toggledValue = !selectAll;
		const newArray = options.map((c: any) => {
			return {
				...c,
				enabled: toggledValue,
			};
		});
		onSelectChanged(!selectAll);
		onOptionsChanged(newArray);

		if (toggledValue) {
			onOnlyAssignedToMeChanged(false);
		}
	};

	const toggleAssignedToMe = () => {
		const toggledValue = !onlyAssignedToMe;
		onOnlyAssignedToMeChanged(toggledValue);

		if (toggledValue) {
			const newArray = options.map((c: any) => {
				return {
					...c,
					enabled: false,
				};
			});
			onSelectChanged(false);
			onOptionsChanged(newArray);
		}
	};

	const setValue = (countryCode: string, checked: boolean) => {
		const newArray = [...options];
		const assignee = newArray.find((c) => c.value === countryCode);
		assignee!.enabled = checked;
		onOptionsChanged(newArray);
		if (!checked) {
			onSelectChanged(false);
		}

		const findSelectedAssigne = newArray.find((c) => c.enabled);
		if (findSelectedAssigne) {
			onOnlyAssignedToMeChanged(false);
		}
	};

	const customOption = (all: any) => {
		const currentValue = options.find((c) => c.value === all.data.value);
		const { value, enabled } = currentValue!;
		return (
			<div
				{...all.innerProps}
				onClick={() => setValue(value, !enabled)}
				onKeyDown={() => setValue(value, !enabled)}
				className="p-2 custom-select-row"
				role="button"
				tabIndex={value}
			>
				<span>{all.data.label}</span>
				<input className="float-right" type="checkbox" name={all.data.value} checked={all.data.enabled} readOnly />
			</div>
		);
	};

	const MenuList = (props: any) => {
		return (
			<components.MenuList {...props}>
				<div className="d-flex flex-column p-2 border-bottom">
					<div
						className="d-flex align-items-center justify-content-between pt-1 pb-1"
						onClick={toggleSelectAllValues}
						onKeyDown={toggleSelectAllValues}
						role="button"
						tabIndex={0}
					>
						<span>{_t('global.all')}</span>
						<input className="float-right" type="checkbox" checked={selectAll} readOnly />
					</div>
					<div
						className="d-flex align-items-center justify-content-between pt-1 pb-1"
						onClick={toggleAssignedToMe}
						onKeyDown={toggleAssignedToMe}
						role="button"
						tabIndex={-1}
					>
						<span>{_t('leads.only-assigned-to-me')}</span>
						<input className="float-right" type="checkbox" checked={onlyAssignedToMe} readOnly />
					</div>
				</div>
				{props.children}
			</components.MenuList>
		);
	};

	return (
		<Select
			options={options}
			components={{ Option: customOption, MenuList }}
			isMulti
			closeMenuOnSelect={false}
			value={null}
			inputValue={inputValue}
			onInputChange={handleInputValue}
			className={className}
		/>
	);
};

export default AssigneFilter;
