import React from 'react';
import _t from 'counterpart';
import { CForm, CRow, CCol, CFormGroup } from '@coreui/react';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../helpers/customHooks';
import { useQuery } from 'react-query';
import { loadCountries } from '../../services/BackendService';
import { IAffiliateFilters } from '../../reducers/filtersReducer';
import { setAffiliateFilters } from '../../actions';
import { customerStatuses } from './types';
import InputHeader from '../../components/InputHeader';

interface IProps {
	show: boolean;
	onFilterChanged: Function;
}

const AffiliatesFilter = ({ show, onFilterChanged }: IProps) => {
	const activeFilters = useAppSelector((state) => state.filters.affiliateFilters, shallowEqual);
	const dispatch = useAppDispatch();

	const { data: countries }: { data: any } = useQuery<Array<ISelectOption>, Error>(['countries'], async () => {
		const data = await loadCountries();
		return data.map((country) => ({
			value: country.isoCode,
			label: country.name,
		}));
	});

	const setFilters = (filters: Partial<IAffiliateFilters>) => {
		dispatch(setAffiliateFilters(filters));
		onFilterChanged();
	};

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses,
		});
	};

	const handleCountryChanged = (country: any) => {
		setFilters({
			country,
		});
	};

	const resetSelectedStatuses = () => {
		setFilters({
			statuses: [],
		});
	};

	const resetCountry = () => {
		setFilters({
			country: undefined,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.country')} resetFilter={resetCountry} />
						<CustomSelect
							value={activeFilters.country ?? null}
							options={countries}
							onChange={handleCountryChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.status')} resetFilter={resetSelectedStatuses} />
						<CustomSelect
							value={activeFilters.statuses ?? null}
							options={customerStatuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default React.memo(AffiliatesFilter);
