import { CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CLabel, CLink, CRow } from '@coreui/react';
import PaginationTable from '../../components/PaginationTable';
import _t from 'counterpart';
import React, { useMemo, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { ICustomerWithAgent } from './types';
import { Link } from 'react-router-dom';
import CustomerRoleBadge from '../customers/CustomerPage/CustomerRoleBadge';
import { UserRole } from '../../reducers/userReducer';
import { NumberParam, useQueryParam } from 'use-query-params';
import { getCustomersWithAgents } from '../../services/BackendService';
import { useQuery } from 'react-query';
import ConfigureCallAgentModal from './ConfigureCallAgentModal';

const VoisoPage = () => {
	const [customerToConfigure, setCustomerToConfigure] = useState<{
		id: string;
		agent: string;
	} | null>(null);
	const [page, setPage] = useQueryParam('page', NumberParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const CALL_PROVIDER = 'voiso';

	const customersWithAgentsQuery = useQuery(['customers-agents', offset], () =>
		getCustomersWithAgents(limit, offset, CALL_PROVIDER)
	);

	const tableFields = useMemo(
		() => [
			{ key: 'role', label: _t('coperato.role'), sorter: false },
			{ key: 'name', label: _t('global.name'), sorter: false },
			{ key: 'agent', label: _t('coperato.agent'), sorter: false },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			role: ({ role }: ICustomerWithAgent) => (
				<td>
					<CustomerRoleBadge role={role as UserRole} />
				</td>
			),
			name: ({ id, name }: ICustomerWithAgent) => (
				<td>
					<Link to={`/customers/${id}`}>{name}</Link>
				</td>
			),
			agent: ({ agent }: ICustomerWithAgent) => <td>{agent || 'n/a'}</td>,
			actions: ({ id, agent }: ICustomerWithAgent) => (
				<td>
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={() => setCustomerToConfigure({ id, agent: agent || '' })}
						title="Edit instrument"
						size="sm"
					>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	const hideConfigureAgentModal = (refetch?: boolean) => {
		setCustomerToConfigure(null);
		if (refetch) {
			customersWithAgentsQuery.refetch();
		}
	};

	return (
		<>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<CCardTitle>Voiso</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={customersWithAgentsQuery.data?.customers || []}
								loading={customersWithAgentsQuery.isLoading}
								pages={customersWithAgentsQuery.data?.pages || 0}
								pagination
								onPageChanged={setPage}
								activePage={page || 1}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{Boolean(customerToConfigure) && (
				<ConfigureCallAgentModal
					customerId={customerToConfigure!.id}
					initialAgentId={customerToConfigure!.agent}
					show={Boolean(customerToConfigure)}
					hidePanel={hideConfigureAgentModal}
					providerType={CALL_PROVIDER}
				/>
			)}
		</>
	);
};

export default VoisoPage;
