import React, { useMemo } from 'react';
import * as Avatar from '@radix-ui/react-avatar';
import styles from './Avatar.module.scss';
import { getInitials } from '../../helpers';

interface IProps {
	name: string;
	imgUrl: string | null;
}

const AvatarIcon = ({ name, imgUrl }: IProps) => {
	const avatarImg = imgUrl || undefined;

	const nameInitials = useMemo(() => {
		if (!name) {
			return null;
		}
		return getInitials(name);
	}, [name]);

	return (
		<Avatar.Root className={styles.avatarRoot}>
			<Avatar.Image className={styles.avatarImage} src={avatarImg} alt={name ?? ''} />
			<Avatar.Fallback className={styles.avatarFallback} delayMs={imgUrl ? 600 : 0}>
				{nameInitials}
			</Avatar.Fallback>
		</Avatar.Root>
	);
};

export default React.memo(AvatarIcon);
