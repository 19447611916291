import React, { useMemo, useState } from 'react';
import { CRow, CCol, CCard, CCardHeader, CButton, CCardBody, CDataTable } from '@coreui/react';
import _t from 'counterpart';
import PageLayout from '../../components/PageLayout';
import CreateEditStatusModal from './CreateEditStatusModal';
import { useQuery } from 'react-query';
import { getSalesStatuses } from '../../services/BackendService';
import { ISalesStatus } from './types';
import CIcon from '@coreui/icons-react';
import Error from '../../components/Error';

const SalesStatusPage = () => {
	const [addEditStatusShow, setAddEditStatusShow] = useState<boolean>(false);
	const [currentStatus, setCurrentStatus] = useState<ISalesStatus | null>(null);

	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses());

	const closeStatusPage = (refetch?: boolean) => {
		if (refetch) {
			salesStatusesQuery.refetch();
		}
		setAddEditStatusShow(false);
		setCurrentStatus(null);
	};

	const setStatusHandler = (status: ISalesStatus) => {
		setCurrentStatus(status);
		setAddEditStatusShow(true);
	};

	const fields = useMemo(
		() => [
			{ key: 'status', label: _t('global.status'), sorter: true, _style: { width: '90%' } },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const slots = useMemo(
		() => ({
			actions: (status: ISalesStatus) => (
				<td>
					<CButton size="sm" className="mr-1" color="primary" onClick={() => setStatusHandler(status)}>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	return (
		<PageLayout title={_t('sidebar.sales-status')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={() => setAddEditStatusShow(true)} />
							</div>
						</CCardHeader>
						<CCardBody>
							{salesStatusesQuery.isError && <Error onRetry={salesStatusesQuery.refetch} />}
							{!salesStatusesQuery.isError && (
								<CDataTable
									fields={fields}
									scopedSlots={slots}
									items={salesStatusesQuery.data || []}
									loading={salesStatusesQuery.isLoading}
								/>
							)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<CreateEditStatusModal show={addEditStatusShow} onClose={closeStatusPage} currentStatus={currentStatus} />
		</PageLayout>
	);
};

export default SalesStatusPage;
