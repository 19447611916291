import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import _t from 'counterpart';
import { stringify } from 'qs';
import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrayParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { clearCustomersFilters } from '../../actions';
import Error from '../../components/Error';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import PageLayout from '../../components/PageLayout';
import { ISorter } from '../../components/PaginationTable';
import SearchFilter from '../../components/SearchFilter';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { customerFiltersToDTO } from '../../sagas/customersSaga';
import { getCustomerIds, loadCustomers } from '../../services/BackendService';
import AddCustomerModal from './AddCustomerModal';
import CustomersFilters from './CustomersFilters';
import CustomersTable from './CustomersTable';
import CustomersTableColumnSelect from './CustomersTableColumnSelect';
import CIcon from '@coreui/icons-react';
import BulkEditModal from './BulkEditModal';
import { getFiltersCount } from './helpers';
import toast from 'react-hot-toast';
import { extractErrorMessage } from '../../helpers';
import { Permission, UserRole } from '../../reducers/userReducer';

const CustomersPage = () => {
	const customersFilters = useAppSelector((state) => state.filters.customersFilters, shallowEqual);

	const [showFilters, setShowFilters] = useState<boolean>(false);
	const permissions = useAppSelector((state) => state.user.permissions);

	const [addCustomerVisible, setAddCustomerVisible] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter, setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const [introducerId] = useQueryParam('introducerId', StringParam);

	const customersItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_customers_table') ?? 10);
	const [limit, setLimit] = useState<number>(customersItemsPerPageFromLocalStorage);
	const [bulkEditShow, setBulkEditShow] = useState<boolean>(false);

	const [_, setStatuses] = useQueryParam('statuses', ArrayParam);

	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const history = useHistory();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const dtoFilters = customerFiltersToDTO(customersFilters);
	const { data, isLoading, refetch, isError } = useQuery<any>(
		['customers', filter, page, orderBy, customersFilters, introducerId],
		() => loadCustomers(filter || '', limit, offset, introducerId || undefined, orderBy!, dtoFilters)
	);

	const sendMessageToCustomers = async () => {
		try {
			const customersIds = await getCustomerIds(dtoFilters, filter || '');
			history.push('/messaging', {
				customersIds: customersIds.toString(),
			});
		} catch (e) {
			toast.error(extractErrorMessage(e));
		}
	};

	const onFilterChanged = useCallback(
		(resetPagination: boolean = true) => {
			if (resetPagination) {
				setPage(1, 'replaceIn');
			}
		},
		[setPage]
	);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_customers_table', limit.toString());
		await queryClient.invalidateQueries('customers');
		refetch();
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const showAddCustomer = () => {
		setAddCustomerVisible(true);
	};

	const hideAddCustomer = () => {
		setAddCustomerVisible(false);
		refetch();
	};

	const onRowClicked = (user: any) => {
		history.push(`/customers/${user.id}`);
	};

	const resetFilters = () => {
		dispatch(clearCustomersFilters());
		setStatuses(undefined);
	};

	const getExportLink = (): string => {
		const filters = customerFiltersToDTO(customersFilters);
		const params: any = { ...filters, introducerId };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/customers/export/xlsx?${stringify(params)}`;
	};

	const filtersCount = getFiltersCount(customersFilters);

	let recordCount = 0;
	if (data) {
		const { pages, customers } = data;
		recordCount = Number(pages) + Number(customers.length);
	}

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		refetch();
	};

	const onBulkEditOpen = () => {
		setBulkEditShow(true);
	};

	const onBulkEditClose = (fetchAgain?: boolean) => {
		setBulkEditShow(false);
		if (fetchAgain) {
			refetch();
			setPage(1, 'replaceIn');
		}
	};

	const count = data?.count ?? limit;
	const pages = Math.ceil(count / Number(limit));
	const state = useAppSelector((state) => state.user);

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t('customers.title')}>
			<CRow>
				<CCol>
					<CCard className="card-overflow-visible">
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">
															{getFiltersCount(customersFilters)}
														</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>

											<CustomersTableColumnSelect />
										</div>
										<div className="float-right">
											{permissions?.includes(Permission.MANAGE_CUSTOMERS) && (
												<>
													{state.roles.includes(UserRole.Admin) && (
														<CButton className="mr-2 header-button-add" onClick={showAddCustomer} />
													)}
													{state.roles.includes(UserRole.Admin) && (
														<CButton
															title={_t('action.bulk-edit')}
															className="mr-2 header-button-export"
															color="success"
															onClick={onBulkEditOpen}
														>
															<CIcon name="cil-note" />
														</CButton>
													)}
												</>
											)}

											{permissions?.includes(Permission.MANAGE_MESSAGES) && (
												<CButton className="mr-2 header-button-msg" onClick={sendMessageToCustomers}>
													<CIcon name="cil-speech" />
												</CButton>
											)}

											{state.roles.includes(UserRole.Admin) && recordCount !== 0 && (
												<ExportXLSXButton downloadLink={getExportLink()} defaultFilename="customers.xlsx" />
											)}
										</div>
									</div>
								</div>
								<CustomersFilters show={showFilters} onFilterChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CustomersTable
								data={data?.customers || []}
								columnsAreHideable
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								isLoading={isLoading}
								pages={pages}
								activePage={page || 1}
								onRowClicked={onRowClicked}
								showFilter={false}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<BulkEditModal
				customersFilters={dtoFilters}
				search={filter}
				show={bulkEditShow}
				onClose={onBulkEditClose}
				count={data?.count || 0}
			/>

			<AddCustomerModal show={addCustomerVisible} onClose={hideAddCustomer} />
		</PageLayout>
	);
};

export default CustomersPage;
