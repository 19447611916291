import React, { useEffect, useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CFormGroup,
	CLabel,
	CInput,
	CModalFooter,
	CButton,
} from '@coreui/react';
import { IPosition } from './position';
import _t from 'counterpart';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updatePosition } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import toast from 'react-hot-toast';

interface IProps {
	position: IPosition | null;
	onClose: (refetch?: boolean) => void;
}

interface IParams {
	id: string;
	stopLoss?: number;
	takeProfit?: number;
	openPrice?: number;
	closePrice?: number;
	commission?: number;
	trailingStopLossPct?: number;
}

const UpdatePositionModal = ({ position, onClose }: IProps) => {
	const [takeProfit, setTakeProfit] = useState<string>('');
	const [stopLoss, setStopLoss] = useState<string>('');
	const [openPrice, setOpenPrice] = useState<string>('');
	const [closePrice, setClosePrice] = useState<string>('');
	const [commission, setCommission] = useState<string>('');
	const [trailingStopLossPct, setTrailingStopLossPct] = useState<string>('');

	const [editTakeProfit, setEditTakeProfit] = useState<boolean>(false);
	const [editStopLoss, setEditStopLoss] = useState<boolean>(false);
	const [editOpenPrice, setEditOpenPrice] = useState<boolean>(false);
	const [editClosePrice, setEditClosePrice] = useState<boolean>(false);
	const [editCommission, setEditCommission] = useState<boolean>(false);
	const [editTrailingStopLossPct, setEditTrailingStopLossPct] = useState<boolean>(false);

	const updatePositionMutation = useMutation(
		({ id, stopLoss, takeProfit, openPrice, closePrice, commission, trailingStopLossPct }: IParams) =>
			updatePosition(id, stopLoss, takeProfit, openPrice, closePrice, commission, trailingStopLossPct),
		{
			onSuccess: async () => {
				toast.success(_t('positions.updated-successfully'));
				closeModal(true);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422 || (e.response?.status === 422 && e.response.data.errors[0].param === null)) {
					const error = extractErrorMessage(e);
					toast.error(error);
				}
			},
			retry: false,
		}
	);

	const closeModal = (refetch?: boolean) => {
		setEditTakeProfit(false);
		setEditStopLoss(false);
		setEditOpenPrice(false);
		setEditClosePrice(false);
		setEditCommission(false);
		setEditTrailingStopLossPct(false);
		onClose(refetch);
	};

	const toggleTakeProfit = () => {
		setEditTakeProfit(!editTakeProfit);
	};

	const toggleStopLoss = () => {
		setEditStopLoss(!editStopLoss);
	};

	const toggleOpenPrice = () => {
		setEditOpenPrice(!editOpenPrice);
	};

	const toggleClosePrice = () => {
		setEditClosePrice(!editClosePrice);
	};

	const toggleCommissionPrice = () => {
		setEditCommission(!editCommission);
	};

	const toggleTrailingStopLossPct = () => {
		setEditTrailingStopLossPct(!editTrailingStopLossPct);
	};

	useEffect(() => {
		if (position) {
			setTakeProfit(position.takeProfit || '');
			setStopLoss(position.stopLoss || '');
			setOpenPrice(position.openPrice || '');
			setClosePrice(position.closePrice || '');
			setCommission(position.commission?.toString() || '');
			setTrailingStopLossPct(position.trailingStopLossPct || '');
		}
	}, [position]);

	const findError = (paramName: string) => {
		return findErrorFromValidation(updatePositionMutation.error, paramName);
	};

	const onTakeProfitChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTakeProfit(e.target.value);
	};

	const onStopLossChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStopLoss(e.target.value);
	};

	const onOpenPriceEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOpenPrice(e.target.value);
	};

	const onClosePriceEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setClosePrice(e.target.value);
	};

	const onTrailingStopLossPctEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTrailingStopLossPct(e.target.value);
	};

	const onCommissionEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCommission(e.target.value);
	};

	const prepareParam = (value: string, isEditing: boolean) => {
		if (value === '' || !isEditing) {
			return undefined;
		}
		return Number(value);
	};

	const callMutation = () => {
		if (position) {
			updatePositionMutation.mutate({
				id: position.id.toString(),
				takeProfit: prepareParam(takeProfit, editTakeProfit),
				stopLoss: prepareParam(stopLoss, editStopLoss),
				openPrice: prepareParam(openPrice, editOpenPrice),
				closePrice: prepareParam(closePrice, editClosePrice),
				commission: prepareParam(commission, editCommission),
				trailingStopLossPct: prepareParam(trailingStopLossPct, editTrailingStopLossPct),
			});
		}
	};

	const onOpen = () => {
		updatePositionMutation.reset();
	};

	const showClosePriceInput = position !== null && position.status === 'closed';
	const updateDisabled =
		!editTakeProfit &&
		!editStopLoss &&
		!editOpenPrice &&
		!editClosePrice &&
		!editCommission &&
		!editTrailingStopLossPct;

	return (
		<CModal show={position !== null} onClose={closeModal} onOpened={onOpen} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('positions.update-position')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<div className="d-flex align-items-center">
						<input type="checkbox" onChange={toggleTakeProfit} checked={editTakeProfit} />
						<CLabel variant="checkbox" htmlFor="takeProfit" className="ml-1">
							{_t('orders.set-take-profit')}
						</CLabel>
					</div>

					<CInput
						type="number"
						placeholder="10.0"
						value={takeProfit || ''}
						onChange={onTakeProfitChanged}
						disabled={!editTakeProfit}
					/>
					{findError('takeProfit') && <CLabel className="text-danger">{findError('takeProfit')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<div className="d-flex align-items-center">
						<input type="checkbox" onChange={toggleStopLoss} checked={editStopLoss} />
						<CLabel variant="checkbox" htmlFor="stopLoss" className="ml-1">
							{_t('orders.set-stop-loss')}
						</CLabel>
					</div>

					<CInput
						type="number"
						placeholder="10.0"
						value={stopLoss || ''}
						disabled={!editStopLoss}
						onChange={onStopLossChanged}
					/>
					{findError('stopLoss') && <CLabel className="text-danger">{findError('stopLoss')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<div className="d-flex align-items-center">
						<input type="checkbox" onChange={toggleTrailingStopLossPct} checked={editTrailingStopLossPct} />
						<CLabel variant="checkbox" htmlFor="trailingStopLossPct" className="ml-1">
							{_t('orders.set-trailing-stop-loss-pct')}
						</CLabel>
					</div>

					<CInput
						type="number"
						placeholder="5"
						value={trailingStopLossPct || ''}
						disabled={!editTrailingStopLossPct}
						onChange={onTrailingStopLossPctEdit}
					/>
					{findError('trailingStopLoss') && <CLabel className="text-danger">{findError('trailingStopLoss')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<div className="d-flex align-items-center">
						<input type="checkbox" onChange={toggleOpenPrice} checked={editOpenPrice} />
						<CLabel variant="checkbox" htmlFor="openPrice" className="ml-1">
							{_t('positions.modify-open-price')}
						</CLabel>
					</div>
					<CInput
						type="number"
						placeholder="10.0"
						value={openPrice || ''}
						disabled={!editOpenPrice}
						onChange={onOpenPriceEdit}
					/>
					{findError('openPrice') && <CLabel className="text-danger">{findError('openPrice')}</CLabel>}
				</CFormGroup>
				{showClosePriceInput && (
					<CFormGroup>
						<div className="d-flex align-items-center">
							<input type="checkbox" onChange={toggleClosePrice} checked={editClosePrice} />
							<CLabel variant="checkbox" htmlFor="openPrice" className="ml-1">
								{_t('positions.modify-close-price')}
							</CLabel>
						</div>
						<CInput
							type="number"
							placeholder="10.0"
							value={closePrice || ''}
							disabled={!editClosePrice}
							onChange={onClosePriceEdit}
						/>
						{findError('closePrice') && <CLabel className="text-danger">{findError('closePrice')}</CLabel>}
					</CFormGroup>
				)}
				<CFormGroup>
					<div className="d-flex align-items-center">
						<input type="checkbox" onChange={toggleCommissionPrice} checked={editCommission} />
						<CLabel variant="checkbox" htmlFor="commission" className="ml-1">
							{_t('positions.modify-commission')}
						</CLabel>
					</div>
					<CInput
						type="number"
						placeholder="10"
						value={commission || ''}
						disabled={!editCommission}
						onChange={onCommissionEdit}
					/>
					{findError('commission') && <CLabel className="text-danger">{findError('commission')}</CLabel>}
				</CFormGroup>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					className="mr-2"
					buttonColor="primary"
					disabled={updateDisabled}
					onClick={callMutation}
					isLoading={updatePositionMutation.isLoading}
					title={_t('action.update')}
				/>
				<CButton color="secondary" onClick={closeModal} disabled={updatePositionMutation.isLoading}>
					{_t('positions.position.close')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(UpdatePositionModal);
