import React, { useEffect, useMemo, useState } from 'react';
import { CCard, CCardHeader, CCol, CDataTable, CLink, CRow } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { extractErrorMessage, formatCurrency, formatDateTime } from '../../helpers';
import { loadDashboardStats } from '../../services/BackendService';
import { IDashboardState, IPendingKYC, IPendingWithdrawal } from './types';
import Loading from '../../components/Loading';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import toast from 'react-hot-toast';
import StatsCard from '../../components/dashboard/StatsCard';
import { useHistory } from 'react-router';
import LiveFeedEntryTransactions, {
	ReceivedTransactionEvent,
} from '../../components/dashboard/LiveFeedEntryTransactions';
import LiveFeedEntrySignup from '../../components/dashboard/LiveFeedEntrySignup';
import cilCheckCircle from '../../icons/cil-check-circle.svg';
import dashboardPlaceholder from '../../icons/dashboard-placeholder.svg';
import LiveFeedEntryCreatedOrders from '../../components/dashboard/LiveFeedEntryCreatedOrders';

const DashboardPage = () => {
	const [signUpData, setSignUpData] = useState<Array<{ value: number; label: string }>>([]);
	const [tradingVolumeData, setTradingVolumeData] = useState<Array<{ value: number; label: string }>>([]);
	const [depositsData, setDepositsData] = useState<Array<{ value: string; label: string }>>([]);
	const [downloadsData, setDownloadsData] = useState<Array<{ value: number; label: string }>>([]);

	const REFETCH_INTERVAL_IN_MS = 5000;

	const dashboardQuery = useQuery<IDashboardState | null>(['dashboard'], () => loadDashboardStats(), {
		onError: (error: any) => {
			toast.error(extractErrorMessage(error));
		},
		retry: false,
		refetchInterval: REFETCH_INTERVAL_IN_MS,
	});

	const onErrorRetry = () => {
		dashboardQuery.refetch();
	};

	const history = useHistory();

	const pendingKYCTableFields = useMemo(
		() => [
			{
				key: 'name',
				label: _t.translate('customers.columns.name'),
				_style: { paddingLeft: '20px !im' },
			},
			{ key: 'uploadedAt', label: _t.translate('global.uploaded-at') },
		],
		[]
	);

	const pendingWithdrawalsTableFields = useMemo(
		() => [
			{ key: 'name', label: _t.translate('customers.columns.name') },
			{ key: 'amount', label: _t.translate('global.amount') },
			{ key: 'uploadedAt', label: _t.translate('global.requested-at') },
		],
		[]
	);

	const pendingKYCTableSlots = useMemo(
		() => ({
			name: (customerKYC: IPendingKYC) => (
				<td>
					<CLink href={`/customers/${customerKYC.id}`}>{customerKYC.name}</CLink>
				</td>
			),
			uploadedAt: (customerKYC: IPendingKYC) => <td>{formatDateTime(customerKYC.createdAt)}</td>,
		}),
		[]
	);

	const pendingWithdrawalsTableSlots = useMemo(
		() => ({
			name: (customerWithdrawal: IPendingWithdrawal) => <td>{customerWithdrawal.name}</td>,
			amount: (customerWithdrawal: IPendingWithdrawal) => <td>{formatCurrency(customerWithdrawal.amount)}</td>,
			uploadedAt: (customerWithdrawal: IPendingWithdrawal) => <td>{formatDateTime(customerWithdrawal.createdAt)}</td>,
		}),
		[]
	);

	useEffect(() => {
		if (dashboardQuery.isSuccess && dashboardQuery.data) {
			setSignUpData([
				{
					value: dashboardQuery.data.totalRegistrationsLast24Hours,
					label: _t.translate('dashboard.last-24-hours'),
				},
				{ value: dashboardQuery.data.totalRegistrationsLastWeek, label: _t.translate('dashboard.last-week') },
				{ value: dashboardQuery.data.totalRegistrationsLastMonth, label: _t.translate('dashboard.last-month') },
			]);

			setTradingVolumeData([
				{ value: dashboardQuery.data.totalTradingVolumeLast24Hours, label: _t.translate('dashboard.last-24-hours') },
				{ value: dashboardQuery.data.totalTradingVolumeLastWeek, label: _t.translate('dashboard.last-week') },
				{ value: dashboardQuery.data.totalTradingVolumeLastMonth, label: _t.translate('dashboard.last-month') },
			]);

			setDepositsData([
				{
					value: formatCurrency(dashboardQuery.data.totalDepositsLast24Hours),
					label: _t.translate('dashboard.last-24-hours'),
				},
				{
					value: formatCurrency(dashboardQuery.data.totalDepositsLastWeek),
					label: _t.translate('dashboard.last-week'),
				},
				{
					value: formatCurrency(dashboardQuery.data.totalDepositsLastMonth),
					label: _t.translate('dashboard.last-month'),
				},
			]);

			setDownloadsData([
				{ value: dashboardQuery.data.totalDownloadsLast24Hours, label: _t.translate('dashboard.last-24-hours') },
				{ value: dashboardQuery.data.totalDownloadsLastWeek, label: _t.translate('dashboard.last-week') },
				{ value: dashboardQuery.data.totalDownloadsLastMonth, label: _t.translate('dashboard.last-month') },
			]);
		}
	}, [dashboardQuery.data, dashboardQuery.isSuccess]);

	if (dashboardQuery.isLoading || dashboardQuery.isIdle) {
		return <Loading />;
	}

	if (dashboardQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const { pendingKYC, pendingWithdrawals, newestLiveFeedRecords } = dashboardQuery.data!;

	return (
		<PageLayout title={_t.translate('global.overview')}>
			<CRow>
				<CCol xs="12" md="4">
					{newestLiveFeedRecords.length === 0 ? (
						<CCard style={{ height: '80dvh' }}>
							<div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
								<img
									src={dashboardPlaceholder}
									alt={_t.translate('dashboard.no-events-in-feed')}
									style={{ width: 'auto', height: '66px' }}
									className="mb-3"
								/>
								<h5 className="text-center dashboard-table-no-results">
									{_t.translate('dashboard.no-events-in-feed')}
								</h5>
							</div>
						</CCard>
					) : (
						<div style={{ height: '80dvh', overflowY: 'scroll' }}>
							{newestLiveFeedRecords.map((record: any) => {
								switch (record.recordType) {
									case 'ORDER_CREATED':
										return <LiveFeedEntryCreatedOrders eventData={record} />;
									case 'DEPOSIT':
									case 'WITHDRAWAL':
										return (
											<LiveFeedEntryTransactions
												eventData={record}
												receivedEvent={record.recordType as ReceivedTransactionEvent}
											/>
										);
									case 'SIGNUP':
										return <LiveFeedEntrySignup eventData={record} />;
									default:
										return;
								}
							})}
						</div>
					)}
				</CCol>
				<CCol xs="12" md="8" className="d-flex flex-column" style={{ height: '82dvh' }}>
					<CRow>
						<CCol key={'signups'} xs="12" sm="6" md="3">
							<StatsCard
								badgeText={_t.translate('dashboard.signups')}
								badgeColor="#CBD9FF"
								textColor="#285FEE"
								data={signUpData}
							/>
						</CCol>
						<CCol key={'tradingVolume'} xs="12" sm="6" md="3">
							<StatsCard
								badgeText={_t.translate('dashboard.trading-volume')}
								badgeColor="#E6FFDF"
								textColor="#35A11A"
								data={tradingVolumeData}
							/>
						</CCol>
						<CCol key={'successfulDeposits'} xs="12" sm="6" md="3">
							<StatsCard
								badgeText={_t.translate('dashboard.successful-deposits')}
								textColor="#889219"
								badgeColor="#FCFFD6"
								data={depositsData}
							/>
						</CCol>
						<CCol key={'downloads'} xs="12" sm="6" md="3">
							<StatsCard
								badgeText={_t.translate('dashboard.installations')}
								textColor="#EE2828"
								badgeColor="#FFD7D7"
								data={downloadsData}
							/>
						</CCol>
					</CRow>

					<CRow className="flex-grow-1 d-flex">
						<CCol xs="12" md="6" className="dashboard-table-left d-flex flex-column">
							<CCard className="dashboard-table flex-grow-1">
								<CCardHeader>
									<strong className="dashboard-table-header-title">{_t.translate('dashboard.pending-kyc')}</strong>
								</CCardHeader>
								{pendingKYC.length === 0 ? (
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: '100%' }}
									>
										<img
											src={cilCheckCircle}
											alt={_t.translate('dashboard.no-kyc-documents')}
											style={{ width: '56px', height: '56px' }}
											className="mb-3"
										/>
										<h5 className="text-center dashboard-table-no-results">
											{_t.translate('dashboard.no-kyc-documents')}
										</h5>
									</div>
								) : (
									<CDataTable
										items={pendingKYC}
										fields={pendingKYCTableFields}
										pagination={false}
										scopedSlots={pendingKYCTableSlots}
										loading={dashboardQuery.isLoading}
										striped
									/>
								)}
							</CCard>
						</CCol>
						<CCol xs="12" md="6" className="dashboard-table-right d-flex flex-column">
							<CCard className="dashboard-table flex-grow-1">
								<CCardHeader>
									<strong className="dashboard-table-header-title">
										{_t.translate('dashboard.pending-withdrawals')}
									</strong>
								</CCardHeader>
								{pendingWithdrawals.length === 0 ? (
									<div
										className="d-flex flex-column align-items-center justify-content-center"
										style={{ height: '100%' }}
									>
										<img
											src={cilCheckCircle}
											alt={_t.translate('dashboard.no-pending-withdrawals')}
											style={{ width: '56px', height: '56px' }}
											className="mb-3"
										/>
										<h5 className="text-center dashboard-table-no-results">
											{_t.translate('dashboard.no-pending-withdrawals')}
										</h5>
									</div>
								) : (
									<CDataTable
										items={pendingWithdrawals}
										fields={pendingWithdrawalsTableFields}
										pagination={false}
										scopedSlots={pendingWithdrawalsTableSlots}
										loading={dashboardQuery.isLoading}
										striped
										clickableRows
										onRowClick={(withdrawal: any) => history.push(`/transactions/${withdrawal.id}`)}
									/>
								)}
							</CCard>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default DashboardPage;
