import React from 'react';
import { CSpinner, CButton } from '@coreui/react';

interface Props {
	isLoading: boolean;
	onClick: Function;
	[x: string]: any;
}

const ButtonWrapper = ({ isLoading, onClick, children, ...rest }: React.PropsWithChildren<Props>) => {
	if (isLoading) {
		return <CSpinner color="primary" />;
	}

	return (
		<CButton onClick={onClick} {...rest}>
			{children}
		</CButton>
	);
};

export default ButtonWrapper;
