import React, { useEffect, useState } from 'react';
import { CButton, CForm, CFormGroup, CCol, CRow, CLabel, CInput } from '@coreui/react';
import _t from 'counterpart';
import { ITransactionFilters, IDateRange } from '../../reducers/filtersReducer';
import { useAppDispatch } from '../../helpers/customHooks';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import DateRangePicker from '../../components/DateRangePicker';
import { useQuery } from 'react-query';
import { fetchPaymentProvidersForFilters } from '../../services/BackendService';
import { TransactionType } from './types';

interface IProps {
	show: boolean;
	dispatchAction: (filters: any) => void;
	activeFilters: any;
	onFilterChanged: () => void;
	showTypeFilter: boolean;
	type?: TransactionType;
}

enum TransactionStatus {
	Created = 'created',
	Pending = 'pending',
	Successful = 'successful',
	Canceled = 'canceled',
	Failed = 'failed',
	Error = 'error',
}

const TransactionFilter = ({ show, dispatchAction, activeFilters, onFilterChanged, showTypeFilter, type }: IProps) => {
	const [providers, setProviders] = useState<Array<ISelectOption>>([]);
	const dispatch = useAppDispatch();

	const providersQuery = useQuery(['transactions-payment-provider-filter', type], () =>
		fetchPaymentProvidersForFilters(type || activeFilters.type)
	);

	useEffect(() => {
		if (providersQuery.isSuccess && providersQuery.data) {
			const methods = providersQuery.data;
			const selectOptions: Array<ISelectOption> = methods.map((m: string) => {
				return {
					label: m,
					value: m,
				};
			});
			setProviders(selectOptions);
		}
	}, [providersQuery.data, providersQuery.isSuccess]);

	const setFilters = (filters: Partial<ITransactionFilters>) => {
		dispatch(dispatchAction(filters));
		onFilterChanged();
	};

	const statuses: Array<ISelectOption> = [];
	Object.values(TransactionStatus).forEach((status) => {
		statuses.push({ value: status, label: status });
	});

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			selectedStatuses: statuses,
		});
	};

	const resetSelectedStatuses = () => {
		setFilters({
			selectedStatuses: [],
		});
	};

	const handleProvidersChanged = (providers: any) => {
		setFilters({
			selectedProviders: providers,
		});
	};

	const resetSelectedProviders = () => {
		setFilters({
			selectedProviders: [],
		});
	};

	const handleCreatedAtRange = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({
				createdAtRange: range,
			});
		}
	};

	const resetHandleCreatedAtRange = () => {
		setFilters({
			createdAtRange: undefined,
		});
	};

	const handleFrom = (e: any) => {
		const amount = e.target.value;

		setFilters({
			amountFrom: amount || undefined,
		});
	};

	const handleTo = (e: any) => {
		const amount = e.target.value;

		setFilters({
			amountTo: amount || undefined,
		});
	};

	const typeOptions: Array<ISelectOption> = [];
	Object.values(TransactionType).forEach((type) => {
		typeOptions.push({ value: type, label: type });
	});

	const handleTypeChange = (type: any) => {
		setFilters({
			type: type.value,
		});
	};

	const resetTypeChange = (e: any) => {
		setFilters({
			type: undefined,
		});
	};

	const resetAmountRange = () => {
		setFilters({
			amountFrom: '',
			amountTo: '',
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				{showTypeFilter && (
					<CCol md={4}>
						<CFormGroup>
							<div className="d-flex align-items-center justify-content-between">
								<span>{_t('transactions.type')}</span>
								<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetTypeChange}>
									{_t('action.reset').toUpperCase()}
								</CButton>
							</div>
							<CustomSelect
								value={activeFilters.type ? { label: activeFilters.type, value: activeFilters.type } : null}
								options={typeOptions}
								onChange={handleTypeChange}
								isClearable={false}
								whiteBackground
							/>
						</CFormGroup>
					</CCol>
				)}
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSelectedStatuses}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedStatuses ?? null}
							options={statuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('transactions.filters.provider')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSelectedProviders}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedProviders ?? null}
							options={providers}
							onChange={handleProvidersChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0" htmlFor="regdate">
								{_t('transactions.filters.createdAt')}
							</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetHandleCreatedAtRange}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<DateRangePicker
							initialStartDate={activeFilters.createdAtRange?.startDate ?? null}
							initialEndDate={activeFilters.createdAtRange?.endDate ?? null}
							onRangeChanged={handleCreatedAtRange}
						/>
					</CFormGroup>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0" htmlFor="regdate">
								{_t('transactions.filters.amount')}
							</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetAmountRange}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<div className="d-flex justify-content-between">
							<CInput
								type="number"
								value={activeFilters.amountFrom}
								onChange={handleFrom}
								className="mr-1"
								placeholder={_t('global.from')}
							/>
							<CInput
								type="number"
								value={activeFilters.amountTo}
								onChange={handleTo}
								className="ml-1"
								placeholder={_t('global.to')}
							/>
						</div>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default TransactionFilter;
