import React from 'react';
import Dropzone from 'react-dropzone';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';

import '../scss/components/fileUploader.scss';

interface IProps {
	allowMultipleFiles: boolean;
	addFiles: (documents: any) => void;
	removeFile: (documentName: string) => void;
	externalFile?: string | null;
	externalFileName?: string | null;
	onError: Function;
	maxFilesSizeInBytes?: number;
	validFileTypes?: Array<string>;
	generateFileName?: boolean;
}

interface IState {
	locallyStoredFiles: any;
}

class FileUploader extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			locallyStoredFiles: [],
		};
	}

	componentDidMount() {
		const { externalFile, externalFileName, generateFileName } = this.props;

		if (externalFile) {
			const extension = externalFileName!.substring(externalFileName!.lastIndexOf('.') + 1, externalFileName!.length);
			this.blobUrlToDataArray(externalFile).then((file) => {
				const docs = [];
				docs.push({
					data: file,
					preview: externalFile,
					name: generateFileName ? this.generateFileName(extension) : externalFileName,
				});
				this.addThumbnail(docs);
			});
		}
	}

	getScaleType = (bytes: number) => {
		if (bytes > 1000000) {
			return 'MB';
		}
		return 'kB';
	};

	convertBytes = (bytes: number) => {
		if (bytes > 1000000) {
			return bytes / 1000000;
		}
		return bytes / 1000;
	};

	validateFiles = (documents: any) => {
		const { maxFilesSizeInBytes, validFileTypes, allowMultipleFiles } = this.props;
		const { locallyStoredFiles } = this.state;
		let validationError = null;
		if (maxFilesSizeInBytes) {
			documents.forEach((document: any) => {
				if (document.data.length > maxFilesSizeInBytes) {
					validationError = _t('dropzone.max-file-size', {
						size: this.convertBytes(maxFilesSizeInBytes),
						scaleType: this.getScaleType(maxFilesSizeInBytes),
					});
				}
			});
		}

		if (!allowMultipleFiles && locallyStoredFiles.length >= 1) {
			validationError = _t('dropzone.one-file-allowed');
		}

		if (validFileTypes) {
			documents.forEach((document: any) => {
				const { name } = document;
				const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
				if (!validFileTypes.includes(extension.toLowerCase())) {
					validationError = _t('dropzone.extension-not-allowed', { extension });
				}
			});
		}

		return validationError;
	};

	addThumbnail = (documents: any) => {
		const { onError } = this.props;
		const validationError = this.validateFiles(documents);
		if (!validationError) {
			const { addFiles } = this.props;
			const { locallyStoredFiles } = this.state;
			const withAddedFiles: Array<any> = [...locallyStoredFiles, ...documents];
			this.setState({ locallyStoredFiles: withAddedFiles });
			addFiles(withAddedFiles);
		} else {
			onError(validationError);
		}
	};

	removeThumbnail = (documentName: string) => {
		const { removeFile } = this.props;
		const { locallyStoredFiles } = this.state;
		let filteredResults = locallyStoredFiles.filter((file: any) => {
			return file.name !== documentName;
		});
		filteredResults = filteredResults.length === 0 ? [] : filteredResults;
		this.setState({ locallyStoredFiles: filteredResults });
		removeFile(documentName);
	};

	renderThumbs() {
		const { locallyStoredFiles } = this.state;

		if (locallyStoredFiles) {
			return locallyStoredFiles.map((file: any) => (
				<div className="thumbnail" key={file.name}>
					<div className="thumbnail__wrapper">
						<button type="button" className="thumbnail__close-button" onClick={() => this.removeThumbnail(file.name)}>
							<CIcon name="cil-x" />
						</button>
						<a className="thumbnail__link" href={file.preview} target="_blank" rel="noreferrer">
							{file.name.endsWith('pdf') && <CIcon name="cis-file-pdf" className="thumbnail__icon" size="custom" />}
							{file.name.endsWith('csv') && <CIcon name="cis-file" className="thumbnail__icon" size="custom" />}
							{!file.name.endsWith('csv') && !file.name.endsWith('pdf') && (
								<img src={file.preview} alt={file.name} className="thumbnail__image" />
							)}
						</a>
					</div>
				</div>
			));
		}
		return null;
	}

	blobUrlToDataArray = async (file: any) => {
		return new Promise((resolve, reject) => {
			try {
				let blob;
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';

				xhr.onload = () => {
					const recoveredBlob = xhr.response;
					const reader = new FileReader();
					reader.addEventListener('loadend', (e) => {
						// @ts-ignore
						blob = new Uint8Array(e.target.result);
						resolve(blob);
					});
					reader.readAsArrayBuffer(recoveredBlob);
				};

				xhr.open('GET', file);
				xhr.send();
			} catch (e) {
				reject(e);
			}
		});
	};

	createDataArrayStore = async (files: any) => {
		const data: Array<any> = [];
		const promises = files.map(async (file: any) => {
			const bytes = await this.blobUrlToDataArray(file.preview);
			data.push({
				...file,
				data: bytes,
			});
		});

		await Promise.allSettled(promises);
		return data;
	};

	storeFiles = async (files: any) => {
		const { generateFileName } = this.props;
		const storedFiles = files.map((file: any) => {
			const { name } = file;
			const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
			return {
				preview: URL.createObjectURL(file),
				data: null,
				name: generateFileName ? this.generateFileName(extension) : file.name,
				blob: new Blob([URL.createObjectURL(file)]),
			};
		});

		const documents = await this.createDataArrayStore(storedFiles);
		this.addThumbnail(documents);
	};

	generateFileName = (extension: string): string => {
		const date = new Date().toString();
		const a = date.split('');
		const n = a.length;

		for (let i = n - 1; i > 0; i -= 1) {
			const j = Math.floor(Math.random() * (i + 1));
			const tmp = a[i];
			a[i] = a[j];
			a[j] = tmp;
		}
		return `${a.join('')}.${extension}`.replace(/\s/g, '');
	};

	render() {
		const { allowMultipleFiles } = this.props;
		const { locallyStoredFiles } = this.state;

		let showBar = allowMultipleFiles;
		if (locallyStoredFiles.length === 0 && !allowMultipleFiles) {
			showBar = true;
		}

		return (
			<Dropzone multiple={allowMultipleFiles} onDrop={(acceptedFiles) => this.storeFiles(acceptedFiles)}>
				{({ getRootProps, getInputProps }) => (
					<section>
						{showBar && (
							<div className="dropzone" {...getRootProps()}>
								<input {...getInputProps()} />
								<span>{_t('global.drag-and-drop')}</span>
							</div>
						)}
						<aside className="thumbnails-container">{this.renderThumbs()}</aside>
					</section>
				)}
			</Dropzone>
		);
	}
}

export default FileUploader;
