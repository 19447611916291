import React from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CCardHeader,
	CCardTitle,
	CDataTable,
	CButton,
	CBadge,
	CNavLink,
} from '@coreui/react';
import { useParams } from 'react-router';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { IInstrument, statusClassNames, swapDays } from './types';
import { getInstrument } from '../../services/BackendService';
import Loading from '../../components/Loading';
import { errorWithCode } from '../../helpers';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import Avatar from '../../components/avatar/Avatar';
import toast from 'react-hot-toast';
import { useLivePriceUpdate } from '../../helpers/customHooks';
import MarketDataChart from './MarketDataChart';

interface IAskBid {
	ask: number;
	bid: number;
	symbol: string;
}

const InstrumentPage = () => {
	const { id } = useParams<{ id: string }>();
	const unescapedId = decodeURIComponent(id);

	useLivePriceUpdate(unescapedId);

	const { data: askBidData } = useQuery<IAskBid, Error>(['ask-bid', unescapedId], () => {
		return {
			ask: 0,
			bid: 0,
			symbol: unescapedId,
		};
	},
	{
		refetchOnWindowFocus: false
	}
	);

	const { data, isLoading, isError, refetch } = useQuery<IInstrument, Error>(
		['instrument', unescapedId],
		async () => {
			const data = await getInstrument(unescapedId);
			return data.instrument;
		},
		{
			onError: (error: any) => {
				if (error) {
					const { message, code } = errorWithCode(error);
					if (code === 500) {
						toast.error(message);
					}
				}
			},
			retry: false,
		}
	);

	const onErrorRetry = () => {
		refetch();
	};

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const {
		symbol,
		category,
		marginCurrency,
		currency,
		decimals,
		contractSize,
		marginCalcMode,
		profitCalcMode,
		stakeMode,
		tradingHours,
		sizes,
		status,
		marginPercentage,
		hedgedMarginPercentage,
		swapMode,
		swap3DayWeekday,
		swapShort,
		swapLong,
		iconUrl,
		price,
	} = data;

	const currentSwapDay = swapDays.find((c) => c.value === swap3DayWeekday);

	const tradingHoursData = tradingHours.map((range: any) => {
		return {
			fromday: range.startTime.day,
			fromhour: range.startTime.hour,
			fromminute: range.startTime.minute,
			today: range.endTime.day,
			tohour: range.endTime.hour,
			tominute: range.endTime.minute,
		};
	});

	return (
		<PageLayout
			title={_t.translate('instruments.instrument')}
			titleAppend={
				<>
					<div className="c-avatar ml-2">
						<Avatar imgUrl={iconUrl} name={symbol || ''} />
					</div>
					<CNavLink to={`/instruments_form?edit=${symbol}`} className="float-lg-right">
						<CButton color="primary">{_t.translate('action.edit')}</CButton>
					</CNavLink>
				</>
			}
		>
			<CRow>
				<CCol md={6}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center trending-header">
							<CCardTitle>{_t('global.details')}</CCardTitle>
							<div className="float-right">
								<CBadge color={statusClassNames[status]}>{status}</CBadge>
							</div>
						</CCardHeader>
						<CCardBody className="instrument-card">
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('instruments.form.symbol')}:</dt>
										<dd>{symbol}</dd>

										<dt>{_t('instruments.price')}</dt>
										<dd>{price ?? '-'}</dd>

										<dt>{_t('instruments.form.currency')}:</dt>
										<dd>{currency}</dd>

										<dt>{_t('instruments.form.category')}:</dt>
										<dd>{_t.translate(`instruments.category.${category}`)}</dd>

										<dt>{_t('instruments.stake-mode')}:</dt>
										<dd>{stakeMode}</dd>

										<dt>{_t.translate('instruments.form.swap-mode')}:</dt>
										<dd>{swapMode}</dd>

										<dt>{_t.translate('instruments.form.swap-weekday')}:</dt>
										<dd>{currentSwapDay?.label}</dd>

										<dt>{_t.translate('instruments.form.swapLong')}:</dt>
										<dd>{swapLong}</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('instruments.form.decimals')}:</dt>
										<dd>{decimals}</dd>

										<dt>{_t('instruments.form.marginCurrency')}:</dt>
										<dd>{marginCurrency}</dd>

										<dt>{_t('instruments.form.contractSize')}:</dt>
										<dd>{contractSize}</dd>

										<dt>{_t('instruments.profit-calculation')}:</dt>
										<dd>{profitCalcMode}</dd>

										<dt>{_t('instruments.margin-calculation')}:</dt>
										<dd>{marginCalcMode}</dd>

										<dt>{_t('instruments.form.marginPercentage')}:</dt>
										<dd>{marginPercentage}%</dd>

										<dt>{_t('instruments.form.hedgedMarginPercentage')}:</dt>
										<dd>{hedgedMarginPercentage}%</dd>

										<dt>{_t.translate('instruments.form.swapShort')}:</dt>
										<dd>{swapShort}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={6}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center exposure-lots-header">
							<CCardTitle>{_t('instruments.form.sizes')}</CCardTitle>
						</CCardHeader>
						<CCardBody className="instrument-card">
							<CDataTable
								items={sizes || []}
								fields={[
									{ key: 'size', label: _t('instruments.form.order-size') },
									{ key: 'calculated', label: _t('instruments.form.display-amount') },
								]}
								scopedSlots={{
									size: (size: any) => <td>{size.left}</td>,
									calculated: (size: any) => <td>{size.right}</td>,
								}}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center exposure-lots-header">
							<CCardTitle>{_t('instruments.form.trading-hours')}</CCardTitle>
						</CCardHeader>
						<CCardBody className="instrument-card">
							<CDataTable
								items={tradingHoursData || []}
								fields={[
									{ key: 'fromday', label: _t('instruments.start-day') },
									{ key: 'fromhour', label: _t('instruments.hour') },
									{ key: 'fromminute', label: _t('instruments.minute') },
									{ key: 'today', label: _t('instruments.end-day') },
									{ key: 'tohour', label: _t('instruments.hour') },
									{ key: 'tominute', label: _t('instruments.minute') },
								]}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{!isLoading && <MarketDataChart decimals={decimals} ask={askBidData?.ask ?? 0} bid={askBidData?.bid ?? 0} />}
		</PageLayout>
	);
};

export default InstrumentPage;
