import React, { useMemo } from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _t from 'counterpart';
import { ITrendingInstrument } from '../services/RiskService';

interface IProps {
	trendingInstruments: Array<ITrendingInstrument>;
}

const TrendingInstrumentsChart: React.FC<IProps> = ({ trendingInstruments }) => {
	const parsedTrendingInstruments = useMemo(() => {
		return trendingInstruments.map((instrument) => ({
			...instrument,
			sumVolumeTraded: Number(instrument.sumVolumeTraded),
		}));
	}, [trendingInstruments]);

	return (
		<ResponsiveContainer height={parsedTrendingInstruments.length * 30}>
			<BarChart
				barCategoryGap={1}
				layout="vertical"
				data={parsedTrendingInstruments}
				margin={{
					top: 0,
					left: -25,
					right: 0,
					bottom: 0,
				}}
			>
				<XAxis type="number" hide padding={{ right: 45 }} />

				<YAxis type="category" dataKey="symbol" width={100} />

				<Tooltip
					formatter={(value: string) => [
						`${Number(value).toFixed(2)} ${_t('customer.lots').toLowerCase()}`,
						_t('customer.total-volume-traded'),
					]}
				/>

				<Bar dataKey="sumVolumeTraded" fill="var(--primary)">
					<LabelList dataKey="sumVolumeTraded" position="right" />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default TrendingInstrumentsChart;
