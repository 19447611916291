import React, { useEffect, useState } from 'react';
import {
	CButton,
	CButtonToolbar,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CForm,
	CFormGroup,
	CInput,
	CLabel,
	CRow,
} from '@coreui/react';
import _t from 'counterpart';
import { requestUpdateRefkey } from '../actions';
import PageLayout from '../components/PageLayout';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { deleteSubscription, getSubscription, getVapidKey, pushSubscribe } from '../services/BackendService';
import { extractErrorMessage, urlBase64ToUint8Array } from '../helpers';
import { useMutation } from 'react-query';
import ButtonWithLoader from '../components/ButtonWithLoader';
import toast from 'react-hot-toast';

const ProfilePage = () => {
	const [newRefKey, setNewRefKey] = useState<string | null>(null);
	const [pushEnabled, setPushEnabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const updateReferralCode = (e: React.FormEvent) => {
		e.preventDefault();

		if (newRefKey) {
			dispatch(requestUpdateRefkey(newRefKey));
		}
	};

	const pushSubcribeMutation = useMutation((subsciption: PushSubscription) => pushSubscribe(subsciption), {
		onError: (e) => {
			setIsLoading(false);
			toast.error(extractErrorMessage(e));
		},
		onSuccess: () => {
			checkPushEnabledAndSet();
			setIsLoading(false);
		},
	});

	const checkPushEnabledAndSet = async () => {
		if ('serviceWorker' in navigator && 'Notification' in window) {
			try {
				const registration = await navigator.serviceWorker.register('/push-worker.js');
				await navigator.serviceWorker.ready;

				const subscription = await registration.pushManager.getSubscription();
				if (!subscription) {
					setPushEnabled(false);
					return;
				}

				const dbSubscription = await getSubscription(subscription.endpoint);
				if (!dbSubscription) {
					setPushEnabled(false);
					return;
				}

				setPushEnabled(true);
			} catch (e) {
				toast.error(extractErrorMessage(e));
			}
		}
	};

	useEffect(() => {
		checkPushEnabledAndSet();
	}, []);

	const registerPush = async () => {
		if ('serviceWorker' in navigator && 'Notification' in window) {
			try {
				setIsLoading(true);
				const registration = await navigator.serviceWorker.register('/push-worker.js');
				await navigator.serviceWorker.ready;

				const subscription = await registration.pushManager.getSubscription();
				if (subscription) {
					await deleteOldSubscription(subscription);
					await subscription.unsubscribe();
				}

				const vapidKey = await getVapidKey();
				const convertedVapidKey = urlBase64ToUint8Array(vapidKey);

				const pushSubscription = await registration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: convertedVapidKey,
				});

				pushSubcribeMutation.mutate(pushSubscription);
			} catch (e) {
				setIsLoading(false);
				toast.error(extractErrorMessage(e));
			}
		}
	};

	const deleteOldSubscription = async (subscription: PushSubscription) => {
		try {
			await deleteSubscription(subscription.endpoint);
		} catch (e) {}
	};

	const disablePush = async () => {
		if ('serviceWorker' in navigator && 'Notification' in window) {
			try {
				setIsLoading(true);
				const registration = await navigator.serviceWorker.register('/push-worker.js');
				await navigator.serviceWorker.ready;

				const subscription = await registration.pushManager.getSubscription();
				if (subscription) {
					await deleteOldSubscription(subscription);
					await subscription.unsubscribe();
				}

				await registration.unregister();
				checkPushEnabledAndSet();
			} catch (e) {
				toast.error(extractErrorMessage(e));
			} finally {
				setIsLoading(false);
			}
		}
	};

	const user = useAppSelector((state) => state.user);
	const { name, mobilenumber, email, refKey } = user;

	return (
		<PageLayout title={_t('profile.title')}>
			<CRow>
				<CCol md={4}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('global.details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<dl>
								<dt>{_t('global.name')}:</dt>
								<dd>{name}</dd>
							</dl>
							<dl>
								<dt>{_t('global.email')}:</dt>
								<dd>{email}</dd>
							</dl>
							<dl>
								<dt>{_t('global.mobile-number')}:</dt>
								<dd>{mobilenumber}</dd>
							</dl>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={4}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('profile.referral-code')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CForm onSubmit={updateReferralCode}>
								<dl>
									<dt>{_t('profile.current-referral-code')}</dt>
									<dd>{refKey}</dd>
								</dl>
								<CFormGroup>
									<CLabel htmlFor="nf-referral">{_t('profile.new-referral-code')}</CLabel>
									<CInput
										id="nf-referral"
										placeholder={_t('profile.new-referral-code')}
										onChange={(e: any) => setNewRefKey(e.target.value)}
										disabled
									/>
								</CFormGroup>

								<CButtonToolbar justify="end">
									<CButton type="submit" color="primary" onClick={updateReferralCode} disabled>
										{_t('action.update')}
									</CButton>
								</CButtonToolbar>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={4}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center">
							<CCardTitle>{_t('profile.push-notifications')}</CCardTitle>
							{!pushEnabled && (
								<ButtonWithLoader
									buttonColor="primary"
									title={_t('action.enable')}
									isLoading={isLoading}
									onClick={registerPush}
								/>
							)}
							{pushEnabled && (
								<ButtonWithLoader
									buttonColor="danger"
									title={_t('action.disable')}
									isLoading={isLoading}
									onClick={disablePush}
								/>
							)}
						</CCardHeader>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default ProfilePage;
