import React, { useEffect, useState } from 'react';
import { CBadge, CButton, CCard, CCardBody, CCol, CNav, CNavItem, CNavLink, CRow, CTabs } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { useHistory } from 'react-router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IInstrument, InstrumentCategory, IInstrumentSort, statusClassNames } from '../types';
import { deleteIstrument, getInstruments, updateInstrumentOrder } from '../../../services/BackendService';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { errorWithCode } from '../../../helpers';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NetworkError from '../../../components/NetworkError';
import { AxiosError } from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { stringify } from 'qs';
import InlineSpinner from '../../../components/InlineSpinner';
import styles from './InstrumentsPage.module.scss';
import ExportXLSXButton from '../../../components/ExportXLSXButton';
import { useAppSelector, useLivePriceUpdates } from '../../../helpers/customHooks';
import PageLayout from '../../../components/PageLayout';
import Avatar from '../../../components/avatar/Avatar';
import toast from 'react-hot-toast';

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const arrayToInstrumentSort = (instruments: Array<IInstrument>): IInstrumentSort => {
	return instruments.reduce((acc: IInstrumentSort, instrument, index) => {
		acc[instrument.symbol] = index;
		return acc;
	}, {});
};

const InstrumentsPage = () => {
	const token = useAppSelector((state: any) => state.user.token);
	const [toDeleteInstrument, setToDeleteInstrument] = useState<IInstrument | null>(null);
	const [activeCategory, setActiveCategory] = useState<InstrumentCategory>(InstrumentCategory.CURRENCIES);
	const [reorderedInstruments, setReorderedInstruments] = useState<Array<IInstrument> | null>(null);
	const [cacheOverrideDate] = useState<number>(new Date().getTime());

	const queryClient = useQueryClient();
	const history = useHistory();

	useLivePriceUpdates(activeCategory);

	const instrumentsQuery = useQuery<Array<IInstrument>, Error>(
		['instruments', activeCategory],
		async () => getInstruments(activeCategory),
		{
			onError: (error: any) => {
				if (error) {
					const { message, code } = errorWithCode(error);
					if (code === 500) {
						toast.error(message);
					}
				}
			},
			retry: false,
		}
	);

	const onDeletedInstrument = (refetch?: boolean) => {
		setToDeleteInstrument(null);
		if (refetch) {
			queryClient.invalidateQueries(['instruments', activeCategory]);
		}
	};

	const updateInstrumentOrderMutation = useMutation(
		(instrumentSort: IInstrumentSort) => updateInstrumentOrder(instrumentSort),
		{
			onSuccess: (data) => {
				setReorderedInstruments(null);
				const activeCategoryInstruments = data.filter((instrument) => instrument.category === activeCategory);
				queryClient.setQueryData(['instruments', activeCategory], activeCategoryInstruments);
			},
			onError: () => {
				setReorderedInstruments(null);
				instrumentsQuery.refetch();
			},
		}
	);

	const { mutate } = updateInstrumentOrderMutation;

	useEffect(() => {
		if (reorderedInstruments !== null) {
			const instrumentSort = arrayToInstrumentSort(reorderedInstruments);
			mutate(instrumentSort);
		}
	}, [reorderedInstruments, mutate]);

	const onSymbolEditClicked = (instrument: IInstrument) => {
		history.push(`/instruments_form?edit=${encodeURIComponent(instrument.symbol)}`);
	};

	const onSymbolCloneClicked = (instrument: IInstrument) => {
		history.push(`/instruments_form?clone=${encodeURIComponent(instrument.symbol)}`);
	};

	const openDeleteInstrumentModal = (instrument: IInstrument) => {
		setToDeleteInstrument(instrument);
	};

	const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',

		// change background colour if dragging
		background: isDragging ? 'lightgrey' : 'white',

		display: isDragging ? 'table' : 'table-row',
		tableLayout: isDragging ? 'auto' : 'initial',

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const getExportLink = (): string => {
		const params: any = { token };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/instruments/export/xlsx?${stringify(params)}`;
	};

	const renderInstrumentRow = (instrument: IInstrument) => {
		return (
			<>
				<td className="text-nowrap" style={{ width: '20%' }}>
					<div className="d-flex align-items-center">
						<CIcon name="cis-grip-dots-vertical" className="mr-1" />
						<div className="c-avatar mr-1">
							<Avatar
								imgUrl={instrument.iconUrl ? `${instrument.iconUrl}?${cacheOverrideDate}` : null}
								name={instrument.symbol}
							/>
						</div>
						<Link to={`/instruments/${encodeURIComponent(instrument.symbol)}`}>{instrument.symbol}</Link>
					</div>
				</td>
				<td style={{ width: '20%' }}>{instrument.currency}</td>
				<td style={{ width: '20%' }}>{instrument.contractSize}</td>
				<td style={{ width: '20%' }}>{instrument.decimals}</td>
				<td style={{ minWidth: '100px' }}>{instrument.price ?? '-'}</td>
				<td style={{ width: '30%' }}>
					<CBadge color={statusClassNames[instrument.status]}>{instrument.status}</CBadge>
				</td>
				<td className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={() => onSymbolEditClicked(instrument)}
						title="Edit instrument"
						size="sm"
					>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={() => onSymbolCloneClicked(instrument)}
						title="Clone instrument"
						size="sm"
					>
						<CIcon name="cil-clone" size="sm" />
					</CButton>
					<CButton className="mr-2" size="sm" color="danger" onClick={() => openDeleteInstrumentModal(instrument)}>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</td>
			</>
		);
	};

	const renderDraggableRow = (instrument: IInstrument, index: number) => {
		return (
			<Draggable key={instrument.symbol} draggableId={instrument.symbol} index={index}>
				{(provided, snapshot) => (
					<tr
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
					>
						{renderInstrumentRow(instrument)}
					</tr>
				)}
			</Draggable>
		);
	};

	const handleDragEnd = (result: any) => {
		if (result.source && result.destination && result.source.index !== result.destination.index) {
			const newReorderedInstruments = reorder(
				reorderedInstruments ?? instrumentsQuery.data!,
				result.source.index,
				result.destination.index
			);
			setReorderedInstruments(newReorderedInstruments);
		}
	};

	const handleActiveCategoryChanged = (category: InstrumentCategory) => {
		setReorderedInstruments(null);
		setActiveCategory(category);
		updateInstrumentOrderMutation.reset();
	};

	const renderTable = (instruments: Array<IInstrument>) => {
		return (
			<div className="position-relative table-responsive" key={activeCategory}>
				<table className="table" style={{ tableLayout: 'auto' }}>
					<thead>
						<tr>
							<th style={{ width: '20%' }}>{_t('instruments.form.symbol')}</th>
							<th style={{ width: '20%' }}>{_t('instruments.form.currency')}</th>
							<th style={{ width: '20%' }}>{_t('instruments.form.contract-size')}</th>
							<th style={{ width: '20%' }}>{_t('instruments.form.decimals')}</th>
							<th style={{ width: '100px', minWidth: '100px' }}>{_t('instruments.price')}</th>
							{/* $5000.14124 */}
							<th style={{ width: '30%' }}>{_t('instruments.status')}</th>
							<th style={{ width: '100%' }}>{_t('global.actions')}</th>
						</tr>
					</thead>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<tbody {...provided.droppableProps} ref={provided.innerRef}>
									{instruments.map(renderDraggableRow)}
									{provided.placeholder}
								</tbody>
							)}
						</Droppable>
					</DragDropContext>
				</table>
			</div>
		);
	};

	return (
		<PageLayout title={_t('instruments.title')}>
			<CRow>
				<CCol>
					<CTabs activeTab={activeCategory} onActiveTabChange={handleActiveCategoryChanged}>
						<CNav variant="tabs">
							<CNavItem>
								<CNavLink data-tab={InstrumentCategory.CURRENCIES}>
									{_t.translate('instruments.category.currencies')}
								</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={InstrumentCategory.INDICES}>
									{_t.translate('instruments.category.indices')}
								</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={InstrumentCategory.EQUITIES}>
									{_t.translate('instruments.category.equities')}
								</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={InstrumentCategory.COMMODITIES}>
									{_t.translate('instruments.category.commodities')}
								</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={InstrumentCategory.CRYPTOS}>
									{_t.translate('instruments.category.cryptos')}
								</CNavLink>
							</CNavItem>
						</CNav>
					</CTabs>
					<CCard className="card--with-top-tabs">
						<CCardBody>
							<div className="my-2 d-flex justify-content-between align-items-center">
								<div className={styles.updateInstrumentsFeedbackContainer}>
									{updateInstrumentOrderMutation.isError && (
										<NetworkError error={updateInstrumentOrderMutation.error as AxiosError} noMargin />
									)}
									{updateInstrumentOrderMutation.isLoading && <InlineSpinner />}
									{(updateInstrumentOrderMutation.isSuccess || updateInstrumentOrderMutation.isIdle) && (
										<span className="text-muted">{_t.translate('instruments.dnd-to-reorder')}</span>
									)}
								</div>
								<div className="d-flex justify-content-between">
									<ExportXLSXButton downloadLink={getExportLink()} defaultFilename="instruments.xlsx" />
									<Link to="instruments_form">
										<CButton className="header-button-add ml-2" />
									</Link>
								</div>
							</div>
							{instrumentsQuery.isLoading && instrumentsQuery.isFetching && <Loading />}
							{instrumentsQuery.isError && <NetworkError error={instrumentsQuery.error as AxiosError} />}
							{instrumentsQuery.isSuccess && renderTable(reorderedInstruments ?? instrumentsQuery.data!)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={toDeleteInstrument?.symbol!}
				show={toDeleteInstrument !== null}
				hidePanel={onDeletedInstrument}
				onConfirm={deleteIstrument}
				title={_t('instruments.delete-instrument')}
				error=""
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default InstrumentsPage;
