import React, { FormEvent, useState } from 'react';
import {
	CButton,
	CModal,
	CModalBody,
	CModalHeader,
	CModalTitle,
	CModalFooter,
	CInput,
	CLabel,
	CFormGroup,
} from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import { rejectPayoutRequest } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IProps {
	id: string;
	show: boolean;
	hidePanel: (refetch?: boolean) => void;
}

interface IParams {
	requestId: string;
	rejectReasonValue: string | null;
}

const RejectPayoutRequestModal = ({ id, show, hidePanel }: IProps) => {
	const [rejectReason, setRejectReason] = useState<string>('');

	const onRejectReasonChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		setRejectReason(target.value);
	};

	const rejectRequestMutation = useMutation(
		({ requestId, rejectReasonValue }: IParams) => rejectPayoutRequest(requestId, rejectReasonValue),
		{
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
			onSuccess: () => {
				hidePanel(true);
				toast.success(_t('commissions.payout-request-is-rejected'));
			},
		}
	);

	const invokeMutation = () => {
		const rejectReasonValue = rejectReason === '' ? null : rejectReason;
		rejectRequestMutation.mutate({ requestId: id, rejectReasonValue });
	};

	return (
		<CModal show={show} onClose={hidePanel} size="sm">
			<CModalHeader className="d-flex flex-column">
				<CModalTitle>{_t('commissions.reject-payout-request')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel>{_t('commissions.reject-reason')}</CLabel>
					<CInput onChange={onRejectReasonChange} value={rejectReason} />
				</CFormGroup>
			</CModalBody>
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={rejectRequestMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="danger"
					spinnerColor="secondary"
					title={_t('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={hidePanel} disabled={rejectRequestMutation.isLoading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default RejectPayoutRequestModal;
