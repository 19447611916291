import React from 'react';
import { StatusType } from './types';
import { CBadge } from '@coreui/react';

interface IColors {
	[key: string]: 'success' | 'danger' | 'primary' | 'warning' | 'secondary';
}

const OrderStatusBadge = ({ status }: { status: StatusType }) => {
	const colors: IColors = {
		new: 'success',
		rejected: 'danger',
		filled: 'primary',
		timeout: 'secondary',
		pending: 'warning',
		cancelled: 'danger',
		accepted: 'success',
		executing: 'warning',
	};

	return <CBadge color={colors[status]}>{status.toUpperCase()}</CBadge>;
};

export default OrderStatusBadge;
