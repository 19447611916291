import React, { useState } from 'react';
import { CBadge, CButton, CCard, CCardBody, CCol, CDataTable, CLink, CRow } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useQuery } from 'react-query';
import { deleteBanner, loadBanners } from '../../services/BackendService';
import { BannerDTO } from '../../dtos/BannerDTOs';
import { formatDateTime, isSet } from '../../helpers';
import CreateUpdateBannerModal from './CreateUpdateBannerModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

function StatusBadge(props: { banner: BannerDTO }) {
	const { banner } = props;

	let color = 'success';
	let status = 'active';

	if (new Date(banner.endDate) < new Date()) {
		color = 'danger';
		status = 'expired';
	}

	if (new Date(banner.startDate) > new Date()) {
		color = 'primary';
		status = 'upcoming';
	}

	return <CBadge color={color}>{status}</CBadge>;
}

const BannersPage = () => {
	const [tableFilter, setTableFilter] = useState<string>('');
	const [editingBanner, setEditingBanner] = useState<BannerDTO | null>(null);
	const [deletingBannerId, setDeletingBannerId] = useState<string | null>(null);
	const [showAddModal, setShowAddModal] = useState<boolean>(false);

	const bannersQuery = useQuery(['banners'], () => loadBanners());

	const hideAddUpdateModal = () => {
		setEditingBanner(null);
		setShowAddModal(false);
	};

	const hideDeleteModal = () => {
		setDeletingBannerId(null);
	};

	const onErrorRetry = () => {
		bannersQuery.refetch();
	};

	if (bannersQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('sidebar.banners')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<div className="my-2 float-sm-right">
								<CButton className="header-button-add" onClick={() => setShowAddModal(true)} />
							</div>
							<CDataTable
								fields={[
									{ key: 'filename', label: _t.translate('banners.file'), sorter: false },
									{ key: 'name', label: _t.translate('global.name'), sorter: true },
									{ key: 'startDate', label: _t.translate('banners.start-date'), sorter: false },
									{ key: 'endDate', label: _t.translate('banners.end-date'), sorter: false },
									{ key: 'link', label: _t.translate('banners.link'), sorter: false },
									{ key: 'status', label: _t.translate('global.status'), sorter: false },
									{ key: 'actions', label: _t.translate('global.actions'), sorter: false },
								]}
								scopedSlots={{
									filename: (banner: BannerDTO) => (
										<td>
											<CLink href={banner.fileLink} target="_blank">
												<img
													src={banner.fileLink}
													className="rounded"
													style={{ objectFit: 'contain', width: 30, height: 30 }}
													alt={banner.filename}
												/>
											</CLink>
										</td>
									),
									startDate: (banner: BannerDTO) => <td>{formatDateTime(banner.startDate)}</td>,
									endDate: (banner: BannerDTO) => <td>{formatDateTime(banner.endDate)}</td>,
									link: (banner: BannerDTO) => (
										<td>
											{!isSet(banner.link) ? (
												'-'
											) : (
												<CLink href={banner.link} target="_blank">
													{banner.link}
												</CLink>
											)}
										</td>
									),
									status: (banner: BannerDTO) => (
										<td>
											<StatusBadge banner={banner} />
										</td>
									),
									actions: (banner: BannerDTO) => (
										<td>
											<CButton className="mr-2 my-1" color="primary" onClick={() => setEditingBanner(banner)}>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											<CButton className="my-1" color="danger" onClick={() => setDeletingBannerId(banner.id)}>
												<CIcon name="cil-trash" size="sm" />
											</CButton>
										</td>
									),
								}}
								items={bannersQuery.data}
								loading={bannersQuery.isLoading}
								sorterValue={{ column: 'name', asc: true }}
								tableFilter
								tableFilterValue={tableFilter}
								onTableFilterChange={setTableFilter}
								pagination
								itemsPerPage={10}
								sorter
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{(showAddModal || Boolean(editingBanner)) && (
				<CreateUpdateBannerModal
					show={showAddModal || Boolean(editingBanner)}
					hideModal={hideAddUpdateModal}
					editingBanner={editingBanner}
				/>
			)}

			<ConfirmationModal
				id={deletingBannerId || ''}
				show={Boolean(deletingBannerId)}
				hidePanel={hideDeleteModal}
				onConfirm={deleteBanner}
				queryCacheKeyToInvalidate="banners"
				title={_t('banners.delete-banner')}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default BannersPage;
