import React, { FormEvent, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { useHistory } from 'react-router';
import _t from 'counterpart';
import { GetAdapterFromUrl } from './hooks';
import {
	CButton,
	CCard,
	CCardBody,
	CCol,
	CForm,
	CFormGroup,
	CInput,
	CInputGroup,
	CLabel,
	CRow,
	CSwitch,
} from '@coreui/react';
import { AdapterType, FixProvider, IAdapter, IConfigurationProps, IDemoQuoterAdapterConfig, IFIXAdapterConfig, IFIXConfigurationProps, defaultConfigs } from './types';
import { useMutation, useQuery } from 'react-query';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { createAdapter, fetchAdapter, updateAdapter } from '../../services/BackendService';
import CustomSelect from '../../components/CustomSelect';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import toast from 'react-hot-toast';

const DemoquoterConfigurationForm = ({ configuration, setConfiguration, findError }: IConfigurationProps) => {
	const onIntervalMsChange = (e: FormEvent) => {
		const value = (e.target as HTMLInputElement).value;

		// ignore empty field to prevent it from being set to 0 when the user deletes the value
		if (value.length > 0) {
			setConfiguration({ ...configuration, intervalMs: Number(value) });
		} else {
			setConfiguration({ ...configuration, intervalMs: null });
		}
	}

	return (
		<>
			<CLabel htmlFor="sizes" className="instrument-section-label">
				{_t('adapters.configuration')}
			</CLabel>
			<CFormGroup>
				<CLabel htmlFor="intervalMs">{_t('adapters.demo-quoter.interval-ms')}</CLabel>
				<CInputGroup>
					<CInput
						type="number"
						min="10"
						id="intervalMs"
						name="intervalMs"
						placeholder="500"
						value={configuration?.intervalMs ?? ''}
						onChange={onIntervalMsChange}
					/>
				</CInputGroup>
				{findError('intervalMs') && <CLabel className="text-danger">{findError('intervalMs')}</CLabel>}
			</CFormGroup>
		</>
	);
};

const FIXConfigurationForm = ({ configuration, isFix, setConfiguration, findError }: IFIXConfigurationProps) => {
	const fixProviders = Object.entries(FixProvider).map((fixProviderEnum) => {
		return {
			label: fixProviderEnum[0],
			value: fixProviderEnum[1],
		};
	});

	const providerType = fixProviders.find((t) => t.value === configuration?.provider);

	const toggleReverseSSL = () => {
		setConfiguration({ ...configuration, ssl: !configuration!.ssl });
	};

	const onSelectFixProviderChange = ({ value }: any) => {
		setConfiguration({ ...configuration, provider: value });
	};

	const resetFixProvider = () => {
		setConfiguration({ ...configuration, provider: undefined });
	};

	const toggleReverseResetOnLogon = () => {
		setConfiguration({ ...configuration, resetOnLogon: !configuration.resetOnLogon });
	};

	const setConfigFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const type: string = target.getAttribute('type')!;
		let value = target.value! as string | number;
		if (type === 'number') {
			value = Number(value);
		}

		setConfiguration({ ...configuration!, [name]: value });
	};

	return (
		<>
			<CLabel htmlFor="sizes" className="instrument-section-label">
				{_t('adapters.configuration')}
			</CLabel>
			<CFormGroup>
				<CLabel>SSL</CLabel>
				<br></br>
				<CSwitch color="primary" checked={configuration?.ssl} onChange={toggleReverseSSL} />
				<br></br>
				{findError('ssl') && <CLabel className="text-danger">{findError('ssl')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="host">{_t('adapters.host')}</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="host"
						name="host"
						placeholder="www.host.com"
						value={configuration?.host || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('host') && <CLabel className="text-danger">{findError('host')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="port">{_t('adapters.port')}</CLabel>
				<CInputGroup>
					<CInput
						type="number"
						id="port"
						name="port"
						placeholder="30100"
						value={configuration?.port || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('port') && <CLabel className="text-danger">{findError('port')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="session">{_t('adapters.session')}</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="session"
						name="session"
						placeholder="Friday:00:00:00-Friday:00:00:00"
						value={configuration?.session || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('session') && <CLabel className="text-danger">{findError('session')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="password">{_t('login.password')}</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="password"
						name="password"
						placeholder=""
						value={configuration?.password || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('password') && <CLabel className="text-danger">{findError('password')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="username">{_t('adapters.username')}</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="username"
						name="username"
						placeholder="Username"
						value={configuration?.username || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('username') && <CLabel className="text-danger">{findError('username')}</CLabel>}
			</CFormGroup>
			<CFormGroup>
				<CLabel>{_t(`adapters.reset-on-logon`)}</CLabel>
				<br></br>
				<CSwitch
					color="primary"
					checked={configuration?.resetOnLogon}
					onChange={toggleReverseResetOnLogon}
				/>
				<br></br>
				{findError('resetOnLogon') && (
					<CLabel className="text-danger">{findError('resetOnLogon')}</CLabel>
				)}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="senderCompId">SenderCompID</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="senderCompId"
						name="senderCompId"
						placeholder="Test"
						value={configuration?.senderCompId || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('senderCompId') && (
					<CLabel className="text-danger">{findError('senderCompId')}</CLabel>
				)}
			</CFormGroup>
			<CFormGroup>
				<CLabel htmlFor="targetCompId">TargetCompID</CLabel>
				<CInputGroup>
					<CInput
						type="text"
						id="targetCompId"
						name="targetCompId"
						placeholder="Test"
						value={configuration?.targetCompId || ''}
						onChange={setConfigFormValue}
					/>
				</CInputGroup>
				{findError('targetCompId') && (
					<CLabel className="text-danger">{findError('targetCompId')}</CLabel>
				)}
			</CFormGroup>
			{isFix && (
				<>
					<CFormGroup>
						<CLabel htmlFor="account">{_t('adapters.account')}</CLabel>
						<CInputGroup>
							<CInput
								type="text"
								id="account"
								name="account"
								placeholder="Test"
								value={configuration?.account || ''}
								onChange={setConfigFormValue}
							/>
						</CInputGroup>
						{findError('account') && <CLabel className="text-danger">{findError('account')}</CLabel>}
					</CFormGroup>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between trading-select">
							<CLabel className="m-0" htmlFor="regdate">
								FIX {_t('adapters.provider')}
							</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFixProvider}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CInputGroup>
							<CustomSelect
								className="trading-select"
								options={fixProviders}
								name="provider"
								value={providerType ?? null}
								whiteBackground
								onChange={onSelectFixProviderChange}
							/>
						</CInputGroup>
						{findError('provider') && <CLabel className="text-danger">{findError('provider')}</CLabel>}
					</CFormGroup>
				</>
			)}
		</>
	);
};

const CreateUpdateAdapter: React.FC = () => {
	const [name, setName] = useState('');
	const [enabled, setEnabled] = useState(false);
	const [type, setType] = useState<AdapterType>(AdapterType.Warehouse);
	const [configuration, setConfiguration] = useState<IDemoQuoterAdapterConfig | IFIXAdapterConfig | {}>(defaultConfigs[type]);

	const history = useHistory();

	const adapterFromUrl = GetAdapterFromUrl();
	const title = adapterFromUrl ? _t('adapters.update-adapter') : _t('adapters.create-adapter');

	const onError = (e: any) => {
		if (e.response?.status !== 422) {
			toast.error(extractErrorMessage(e));
		}
	};

	const adapterQuery = useQuery(['adapter', adapterFromUrl], () => fetchAdapter(adapterFromUrl!), {
		enabled: adapterFromUrl !== null,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (adapter: IAdapter) => {
			setName(adapter.name);
			setEnabled(adapter.enabled);
			setType(adapter.type);
			setConfiguration(adapter.configuration);
		},
	});

	const updateAdapterMutation = useMutation(
		({ name, enabled, type, configuration }: IAdapter) => updateAdapter(name, enabled, type, configuration),
		{
			onSuccess: () => {
				toast.success(_t('adapters.adapter-updated-successfully'));
				history.push('/adapters');
			},
			onError,
		}
	);

	const createAdapterMutation = useMutation(
		({ name, enabled, type, configuration }: IAdapter) => createAdapter(name, enabled, type, configuration),
		{
			onSuccess: () => {
				toast.success(_t('adapters.adapter-created-successfully'));
				history.push('/adapters');
			},
			onError,
		}
	);

	const onNameChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		setName(target.value);
	};

	const toggleReverse = () => {
		setEnabled(!enabled);
	};

	const onTypeChange = ({ value }: any) => {
		setConfiguration(defaultConfigs[value as AdapterType] || {});
		setType(value);
	};

	const types = Object.values(AdapterType).map((value: string) => {
		return {
			value: value,
			label: value,
		};
	});

	const callMutation = (e: React.FormEvent) => {
		e.preventDefault();

		const mutationParams = { name, enabled, type, configuration };

		if (adapterFromUrl !== null) {
			updateAdapterMutation.mutate(mutationParams as any);
		} else {
			createAdapterMutation.mutate(mutationParams as any);
		}
	};

	if (adapterFromUrl !== null) {
		if (adapterQuery.isLoading || adapterQuery.isIdle) {
			return <Loading />;
		}

		if (adapterQuery.isError) {
			return <Error onRetry={adapterQuery.refetch} />;
		}
	}

	const error = updateAdapterMutation.error ?? createAdapterMutation.error;

	const findError = (paramName: string) => {
		return findErrorFromValidation(error, paramName);
	};

	const stateType = types.find((t) => t.value === type);

	const hasFixConfig = type === AdapterType.Fix || type === AdapterType.Fixquoter;
	const isFix = type === AdapterType.Fix;
	const isDemoQuoter = type === AdapterType.Demoquoter;

	return (
		<PageLayout title={title}>
			<CForm onSubmit={callMutation}>
				<CRow>
					<CCol>
						<CCard className="card-overflow-visible">
							<CCardBody>
								<CFormGroup>
									<CLabel>{_t(`global.name`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											placeholder="warehouse"
											value={name}
											onChange={onNameChange}
											disabled={adapterFromUrl !== null}
										/>
									</CInputGroup>
									{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t(`adapters.enabled`)}</CLabel>
									<br></br>
									<CSwitch color="primary" checked={enabled} onChange={toggleReverse} />
									<br></br>
									{findError('enabled') && <CLabel className="text-danger">{findError('enabled')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t('global.type')}</CLabel>
									<CInputGroup>
										<CustomSelect
											className="trading-select"
											options={types}
											name="type"
											value={stateType}
											whiteBackground
											onChange={onTypeChange}
										/>
									</CInputGroup>
									{findError('type') && <CLabel className="text-danger">{findError('type')}</CLabel>}
								</CFormGroup>

								{isDemoQuoter &&
									<DemoquoterConfigurationForm
										configuration={configuration as IDemoQuoterAdapterConfig}
										setConfiguration={setConfiguration}
										findError={findError}
									/>
								}

								{hasFixConfig &&
									<FIXConfigurationForm
										configuration={configuration as IFIXAdapterConfig}
										isFix={isFix}
										setConfiguration={setConfiguration}
										findError={findError}
									/>
								}
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={updateAdapterMutation.isLoading || createAdapterMutation.isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={adapterFromUrl !== null ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default React.memo(CreateUpdateAdapter);
