import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import userReducer, { IUserState } from './userReducer';
import filtersReducer, { IFiltersState } from './filtersReducer';
import loginReducer, { LoginState } from './loginReducer';
import coreReducer, { ICoreState } from './coreReducer';

export interface AppState {
	router: RouterState;
	user: IUserState;
	login: LoginState;
	filters: IFiltersState;
	core: ICoreState;
}

const createRootReducer = (history: any) =>
	combineReducers<AppState>({
		router: connectRouter(history),
		user: userReducer,
		login: loginReducer,
		filters: filtersReducer,
		core: coreReducer,
	});

export default createRootReducer;
