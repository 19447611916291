import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CCard, CCardBody, CRow, CCol, CButton } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useQuery, useMutation } from 'react-query';
import { ICompetition } from './types';
import { formatDateTime } from '../../helpers';
import { loadCompetitions, deleteCompetition } from '../../services/BackendService';
import { CompetitionStatusBadge } from './CompetitionStatusBadge';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import ConfirmationModal from '../../components/ConfirmationModal';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

interface IState {
	search: string;
	page: number;
	sortBy: string;
}

const CompetitionsPage = () => {
	const [toDeleteId, setToDeleteId] = useState<string | null>(null);

	const history = useHistory();

	const [state, setState] = useState<IState>({
		search: '',
		page: 1,
		sortBy: 'start|DESC',
	});

	const { search, page, sortBy } = state;

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const loadCompetitionsQuery = useQuery(['competitions', state], () =>
		loadCompetitions(search, limit, offset, sortBy)
	);

	const deleteCompetitionMutation = useMutation(['delete-competition'], (id: string) => deleteCompetition(id), {
		onSuccess: () => {
			loadCompetitionsQuery.refetch();
			setToDeleteId(null);
		},
	});

	const setPage = (page: number) => {
		setState({ ...state, page });
	};

	const setTableFilterValue = (search: string) => {
		setState({ ...state, page: 1, search });
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const order = asc ? 'ASC' : 'DESC';
		setState({ ...state, sortBy: `${column}|${order}`, page: 1 });
	};

	const showDeleteCompetitionModal = (competitionId: string) => {
		setToDeleteId(competitionId);
	};

	const hideDeleteCompetition = () => {
		setToDeleteId(null);
	};

	const tryDeleteCompetition = () => {
		if (toDeleteId) {
			deleteCompetitionMutation.mutate(toDeleteId);
		}
	};

	const [column, isAsc] = sortBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		loadCompetitionsQuery.refetch();
	};

	if (loadCompetitionsQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const { data } = loadCompetitionsQuery;

	const onCompetitionEditClicked = (competition: ICompetition) => {
		history.push(`/competitions_form?edit=${encodeURIComponent(competition.id)}`);
	};

	return (
		<PageLayout title={_t.translate('competitions.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody className="simulated-overflow-y">
							<div className="my-2 float-sm-right d-flex justify-content-end">
								<Link to="competitions_form">
									<CButton className="header-button-add ml-2" />
								</Link>
							</div>
							<PaginationTable
								showFilter
								data={data?.competitions || []}
								tableFields={[
									{ key: 'description', label: _t('competitions.columns.description') },
									{ key: 'start', label: _t('competitions.columns.start') },
									{ key: 'end', label: _t('competitions.columns.end') },
									{
										key: 'status',
										label: _t('global.status'),
										sorter: false,
									},
									{
										key: 'type',
										label: _t('global.type'),
										sorter: false,
									},
									{ key: 'promoted', label: _t('competitions.promoted'), sorter: false },
									{
										key: 'actions',
										label: _t('global.actions'),
										sorter: false,
										filter: false,
									},
								]}
								scopedSlots={{
									description: (competition: any) => (
										<td className="text-nowrap">
											<Link to={`/competitions/${competition.id}`}>{competition.description}</Link>
										</td>
									),
									start: (competition: any) => <td className="text-nowrap">{formatDateTime(competition.start)}</td>,
									end: (competition: any) => <td className="text-nowrap">{formatDateTime(competition.end)}</td>,
									status: (competition: any) => (
										<td>
											<CompetitionStatusBadge competition={competition} />
										</td>
									),
									type: (competition: any) => <td>{_t(`competitions.${competition.type}`)}</td>,
									promoted: (competition: any) => (
										<td>
											<CIcon
												className={competition.promoted ? 'text-success' : 'text-danger'}
												name={competition.promoted ? 'cil-check-circle' : 'cil-x-circle'}
											/>
										</td>
									),
									actions: (competition: any) => (
										<td className="table-action">
											<CButton
												onClick={() => onCompetitionEditClicked(competition)}
												size="sm"
												className="mr-1"
												color="primary"
											>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											{new Date(competition.start) > new Date() && (
												<CButton color="danger" size="sm" onClick={() => showDeleteCompetitionModal(competition.id)}>
													<CIcon name="cil-trash" size="sm" />
												</CButton>
											)}
										</td>
									),
								}}
								loading={loadCompetitionsQuery.isLoading}
								pages={0}
								onPageChanged={setPage}
								onSearchValueChanged={setTableFilterValue}
								onSorterChanged={onSorterChanged}
								pagination
								activePage={page}
								sorter={{ column, asc }}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<ConfirmationModal
				id={toDeleteId!}
				show={Boolean(toDeleteId)}
				onConfirm={tryDeleteCompetition}
				hidePanel={hideDeleteCompetition}
				buttonColor="danger"
				title={_t('competitions.delete-competition')}
				withMutation={false}
				error={null}
			/>
		</PageLayout>
	);
};

export default CompetitionsPage;
