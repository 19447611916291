import React, { useCallback, useMemo, useState } from 'react';
import { CButton, CForm, CFormGroup, CCol, CRow, CLabel, CInput } from '@coreui/react';
import _t from 'counterpart';
import { useAppSelector, useAppDispatch } from '../../helpers/customHooks';
import { shallowEqual } from 'react-redux';
import { IOrdersFilters } from '../../reducers/filtersReducer';
import { setOrdersFilters } from '../../actions';
import { OrderType, StatusType } from './types';
import CustomSelect from '../../components/CustomSelect';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { getAllSymbols } from '../../services/BackendService';

interface IProps {
	show: boolean;
	onFilterChanged: () => void;
}

const OrdersFilter = ({ show, onFilterChanged }: IProps) => {
	const [accountId, setAccountId] = useState<string | null>(null);
	const activeFilters = useAppSelector((state) => state.filters.ordersFilters, shallowEqual);
	const dispatch = useAppDispatch();

	const { data } = useQuery(['get-orders-filter-symbols'], () => getAllSymbols());

	const orderSymbols = useMemo(() => data?.symbols?.map((s: string) => ({ label: s, value: s })), [data]);

	const setFilters = useCallback(
		(filters: Partial<IOrdersFilters>) => {
			dispatch(setOrdersFilters(filters));
			onFilterChanged();
		},
		[onFilterChanged, dispatch]
	);

	const orderTypes = Object.keys(OrderType).map((key) => {
		return { value: key, label: key };
	});

	const types = Object.keys(StatusType).map((key) => {
		return { value: key, label: key };
	});

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses,
		});
	};

	const resetStatuses = () => {
		setFilters({
			statuses: undefined,
		});
	};

	const handleOrderTypeChanged = (orderTypes: any) => {
		setFilters({
			orderTypes,
		});
	};

	const resetOrderTypes = () => {
		setFilters({
			orderTypes: undefined,
		});
	};

	const handleOrderSymbolChanged = (symbol: any) => {
		setFilters({
			symbol,
		});
	};

	const resetSymbols = () => {
		setFilters({
			symbol: undefined,
		});
	};

	const debouncedAccountIdFilterUpdate = useMemo(
		() =>
			debounce((accountId: string) => {
				setFilters({
					accountId,
				});
			}, 300),
		[setFilters]
	);

	const handleAccountIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setAccountId(value);
		debouncedAccountIdFilterUpdate(value);
	};

	const resetAccountId = () => {
		setFilters({
			accountId: undefined,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={3}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t.translate('global.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatuses}>
								{_t.translate('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.statuses ?? null}
							options={types}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={3}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t.translate('global.type')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetOrderTypes}>
								{_t.translate('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.orderTypes ?? null}
							options={orderTypes}
							onChange={handleOrderTypeChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={3}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t.translate('global.symbol')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSymbols}>
								{_t.translate('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.symbol ?? null}
							options={orderSymbols}
							onChange={handleOrderSymbolChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={3}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0">{_t('positions.account-id')}</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetAccountId}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>

						<CInput value={accountId || ''} onChange={handleAccountIdChange} />
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default OrdersFilter;
