import React, { useCallback, useMemo, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCol, CRow, CBadge } from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import PaginationTable from '../../components/PaginationTable';
import { deleteEmail, getEmails } from '../../services/BackendService';
import { IEmailTemplate } from './types';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import ConfirmationModal from '../../components/ConfirmationModal';
import { extractErrorMessage } from '../../helpers';
import { useGetSetting } from '../generalSettings/hooks';
import toast from 'react-hot-toast';

const EmailsPage = () => {
	const [search, setSearch] = useState<string | any>();
	const [toDelete, setToDelete] = useState<{ title: string; id: string } | null>(null);
	const {
		data: emails,
		refetch,
		isLoading,
	} = useQuery<Array<IEmailTemplate>>(['emails', search], () => getEmails(search));

	const emailProivderQuery = useGetSetting('EMAIL_PROVIDER', 'email-page-provider', {
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			toast.error(msg);
		},
	});

	const history = useHistory();
	const handleEditEmail = useCallback(
		(id: string) => {
			history.push(`/email_form?edit=${id}`);
		},
		[history]
	);

	const onSearch = (value: string) => {
		setSearch(value);
	};

	const onErrorRetry = () => {
		refetch();
	};

	const openCreateEmail = () => {
		history.push('/email_form');
	};

	const onEmailCloneClicked = useCallback(
		(id: string) => {
			history.push(`/email_form?clone=${id}`);
		},
		[history]
	);

	const deleteEmailTemplateMutation = useMutation(['delete-email-template'], (id: string) => deleteEmail(id), {
		onSuccess: () => {
			toast.success(_t('emails.email-template-delete-successfully'));
			setToDelete(null);
			refetch();
		},
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			}
		},
	});

	const onDeleteConfirm = () => {
		deleteEmailTemplateMutation.mutate(toDelete?.id || '');
	};

	const hideEmailModal = () => {
		setToDelete(null);
	};

	const scopedSlots = useMemo(
		() => ({
			subject: (email: IEmailTemplate) => <td>{email.subject}</td>,
			enabled: (email: IEmailTemplate) => (
				<td>
					{email.enabled && <CBadge color="success">{_t('emails.enabled')}</CBadge>}
					{!email.enabled && <CBadge color="danger">{_t('emails.disabled')}</CBadge>}
				</td>
			),
			actions: (email: IEmailTemplate) => (
				<td className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
					<CButton color="primary" size="sm" title="Edit email" onClick={() => handleEditEmail(email.id)}>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton
						className="ml-2"
						color="primary"
						type="button"
						size="sm"
						onClick={() => onEmailCloneClicked(email.id)}
						title="Clone email"
					>
						<CIcon name="cil-clone" size="sm" />
					</CButton>
					<CButton
						color="danger"
						className="ml-2"
						size="sm"
						title="Delete email"
						onClick={() =>
							setToDelete({
								id: email.id,
								title: email.title,
							})
						}
					>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</td>
			),
		}),
		[onEmailCloneClicked, handleEditEmail]
	);

	const tableFields = useMemo(
		() => [
			{ key: 'title', label: _t('emails.title'), sorter: true },
			{ key: 'subject', label: _t('emails.subject'), sorter: false },
			{ key: 'trigger', label: _t('emails.trigger'), sorter: false },
			{ key: 'enabled', label: _t('emails.enabled'), sorter: false },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const error = Boolean(emailProivderQuery.error);

	if (error) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t('sidebar.emails')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<div className="my-2 float-sm-right d-flex justify-content-end">
								<CButton onClick={openCreateEmail} className="header-button-add" />
							</div>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={emails || []}
								loading={isLoading}
								showFilter
								pages={0}
								search={search}
								onSearchValueChanged={onSearch}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={toDelete?.id || ''}
				show={Boolean(toDelete)}
				onConfirm={onDeleteConfirm}
				hidePanel={hideEmailModal}
				title={_t('emails.delete-email', { name: toDelete?.title })}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default EmailsPage;
