import {
	CRow,
	CCol,
	CCard,
	CCardHeader,
	CCardBody,
	CButton,
	CModal,
	CModalBody,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCustomerRiskEvents } from '../../../services/BackendService';
import PaginationTable from '../../../components/PaginationTable';
import { IRiskEvent } from '../types';
import { formatDateTime } from '../../../helpers';
import Error from '../../../components/Error';

interface IProps {
	customerId: string;
}

const RiskEventsTable = ({ customerId }: IProps) => {
	const [riskEventMessage, showRiskEventMessage] = useState<string | null>(null);
	const [page, setPage] = useState(1);
	const limit = 10;

	const onPageChanged = (page: number) => {
		setPage(page);
	};

	const offset = page * limit - limit;

	const riskEventsQuery = useQuery(['risk-events', customerId, offset], () =>
		fetchCustomerRiskEvents(customerId, limit, offset)
	);

	const fields = useMemo(
		() => [
			{
				key: 'eventType',
				label: _t('customer.risk-event.event-type'),
				sorter: false,
			},
			{
				key: 'createdAt',
				label: _t('customer.risk-event.created-at'),
				sorter: false,
			},
			{
				key: 'detail',
				label: _t('global.details'),
				sorter: false,
			},
		],
		[]
	);

	const slots = useMemo(
		() => ({
			createdAt: (riskEvent: IRiskEvent) => <td>{formatDateTime(riskEvent.createdAt)}</td>,
			detail: (riskEvent: IRiskEvent) => (
				<td>
					<CButton onClick={() => showRiskEventMessage(riskEvent.message)} color="primary">
						{_t('customer.risk-event.show-details')}
					</CButton>
				</td>
			),
		}),
		[]
	);

	const hideRiskEventModal = () => {
		showRiskEventMessage(null);
	};

	const count = riskEventsQuery.data?.count || null;
	const pages = count === null ? 0 : Math.ceil(count / limit);

	if (riskEventsQuery.isError) {
		return (
			<CRow>
				<CCol md={12}>
					<CCard className="card card--listing">
						<CCardBody className="card__holder">
							<Error onRetry={riskEventsQuery.refetch} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		);
	}

	return (
		<CRow>
			<CCol md={12}>
				<CCard className="card card--listing">
					<CCardBody className="card__holder">
						<CCardHeader className="card__header">
							<div className="card__info">
								<h4 className="card__title">{_t('customer.risk-event.title')}</h4>
							</div>
						</CCardHeader>
						<div className="table-overflow">
							<PaginationTable
								tableFields={fields}
								scopedSlots={slots}
								data={riskEventsQuery.data?.riskEvents || []}
								loading={riskEventsQuery.isLoading}
								pages={pages}
								pagination
								activePage={page}
								onPageChanged={onPageChanged}
							/>
						</div>
					</CCardBody>
				</CCard>
			</CCol>

			<CModal show={Boolean(riskEventMessage)} onClose={hideRiskEventModal} size="lg">
				<CModalHeader>
					<CModalTitle>{_t('global.details')}</CModalTitle>
				</CModalHeader>
				<CModalBody style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
					<p>{riskEventMessage}</p>
				</CModalBody>
			</CModal>
		</CRow>
	);
};

export default React.memo(RiskEventsTable);
