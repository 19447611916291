import React, { FormEvent, useState } from 'react';
import {
	CCard,
	CCardBody,
	CCol,
	CFormGroup,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CLabel,
	CLink,
	CNav,
	CNavItem,
	CNavLink,
	CRow,
	CSwitch,
	CTabs,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import DOMPurify from 'dompurify';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { convertMjmlToHtml, errorWithCode, extractErrorMessage, findErrorFromValidation, isSet } from '../../helpers';
import { createEmail, getEmail, getTriggers, sendTestEmail, updateEmail } from '../../services/BackendService';
import { EditType, IEditMjml, IEmailTemplate, IEmailTemplateCreate, IEmailTemplateUpdate, IMJMLError } from './types';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { EmailEditButtons, EmailSaveButton, WarningBox } from './EmailEditButtons';
import Loading from '../../components/Loading';
import PageLayout from '../../components/PageLayout';
import CustomSelect from '../../components/CustomSelect';
import { StringParam, useQueryParam } from 'use-query-params';
import styles from './EmailEditCreatePage.module.scss';
import classNames from 'classnames';
import toast from 'react-hot-toast';

const EmailEditCreatePage = () => {
	const history = useHistory();
	const [editParam] = useQueryParam('edit', StringParam);
	const [cloneParam] = useQueryParam('clone', StringParam);
	const idFromUrl = (editParam || cloneParam) ?? null;

	const isEditing = isSet(editParam);
	const isCloning = isSet(cloneParam);
	const [activeEditType, setEditType] = useState<EditType>(EditType.GENERAL);
	const [editMjml, setEditMjml] = useState<IEditMjml | null>(null);
	const [editSubject, setEditSubject] = useState<string>('');
	const [editTxt, setEditTxt] = useState<string>('');
	const [editTrigger, setEditTrigger] = useState<string>('');
	const [editTitle, setEditTitle] = useState<string>('');
	const [editConditions, setEditConditions] = useState<{
		communicationLanguage: string | undefined;
		country: string | undefined;
	}>({ communicationLanguage: undefined, country: undefined });
	const [triggerOptions, setTriggerOptions] = useState<Array<{ label: string; value: string }>>([]);
	const [mjmlWarnings, setMjmlWarnings] = useState<Array<IMJMLError>>([]);
	const [mjmlError, setMjmlError] = useState<string | null>(null);
	const [enabled, setEnabled] = useState<boolean>(false);
	const [activeFilters, setActiveFilters] = useState<any>({ excludeZero: false });

	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [mjmlIsNull, setMjmlIsNull] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);

	const onError = (error: any) => {
		if (error) {
			const { message, code } = errorWithCode(error);
			if (code === 500) {
				toast.error(message);
			}
		}
	};

	const setFilters = (filters: any) => {
		setActiveFilters({ ...activeFilters, ...filters });
	};

	const handleTypeChanged = (type: any) => {
		setFilters({ type });
		setIsChanged(true);
		updateEditTrigger(type.value);
	};

	const {
		isLoading: loadingEmail,
		data: emailTemplate,
		refetch,
	} = useQuery<IEmailTemplate>(['email', idFromUrl], async () => getEmail(idFromUrl as string), {
		onSuccess: ({ mjml, txt, subject, enabled: isEnabled, conditions, trigger, title }: any) => {
			if (mjml) {
				const mjmlObject = convertMjmlToHtml(mjml);
				setMjmlWarnings(mjmlObject.warnings || []);
				setEditMjml({ mjml, html: mjmlObject?.html || '' });
				if (mjmlObject.error) {
					setMjmlError('Unknown parse error, please check MJML');
				}
				setEnabled(isEnabled);
			} else {
				setMjmlIsNull(true);
			}
			setEditSubject(subject);
			setEditTxt(txt);
			setEditConditions(conditions);
			if (isEditing) {
				setEditTitle(title);
			}
			if (isCloning) {
				setEditTitle(title + '(copy)');
			}
			setEditTrigger(trigger);
			setActiveFilters({ type: { value: trigger, label: trigger } });
		},
		onError,
		refetchOnWindowFocus: false,
		enabled: idFromUrl !== null,
	});

	useQuery(['triggers'], () => getTriggers(), {
		onSuccess: (triggers) => {
			const newTriggerArray: Array<{ label: string; value: string }> = [];
			triggers.forEach((trigger) => {
				const newTriggerOption = { label: trigger, value: trigger };
				newTriggerArray.push(newTriggerOption);
			});
			setTriggerOptions(newTriggerArray);
		},
		onError: (error: any) => {
			toast.error(extractErrorMessage(error));
		},
	});

	const sendTestEmailMutation = useMutation((id: string) => sendTestEmail(id), {
		onSuccess: () => {
			toast.success(_t('emails.test-email-sent', { email: user.email }));
		},
		onError,
	});

	const updateEmailMutation = useMutation(
		['update-email'],
		async (template: IEmailTemplateUpdate) => {
			if (idFromUrl) {
				await updateEmail(idFromUrl, template);
				return;
			}
			return;
		},
		{
			onSuccess: () => {
				toast.success(_t('emails.email-updated'));
				refetch();
				setIsChanged(false);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					toast.error(extractErrorMessage(error));
				}
			},
		}
	);

	const checkForExactValue = (input: string, currentSavedValue: string) => {
		setIsChanged(true);
		if (input.length === currentSavedValue.length) {
			if (input === currentSavedValue) {
				setIsChanged(false);
			}
		}
	};

	const updateMjml = (e: any) => {
		setEditMjml({ mjml: e.target.value, html: editMjml?.html || '' });
		setMjmlError(null);
		const mjmlObject = convertMjmlToHtml(e.target.value);
		setMjmlWarnings(mjmlObject.warnings || []);
		if (mjmlObject.error && e.target.value) {
			setMjmlError('Unknown parse error, please check MJML');
		} else {
			setEditMjml({ mjml: e.target.value, html: mjmlObject.html });
		}
		checkForExactValue(e.target.value, emailTemplate?.mjml || '');
		if (mjmlIsNull && e.target.value) {
			setMjmlIsNull(false);
		}
	};

	const updateSubject = (e: any) => {
		setEditSubject(e.target.value);
		checkForExactValue(e.target.value, emailTemplate?.subject || '');
	};

	const updateEditTxt = (e: any) => {
		setEditTxt(e.target.value);
		checkForExactValue(e.target.value, emailTemplate?.txt || '');
	};

	const updateEditTrigger = (value: any) => {
		setEditTrigger(value);
		checkForExactValue(value, emailTemplate?.trigger || '');
	};

	const updateEditTitle = (e: any) => {
		setEditTitle(e.target.value);
		checkForExactValue(e.target.value, emailTemplate?.trigger || '');
	};

	const handleActiveCategoryChanged = (type: any) => {
		setEditType(type);
	};

	const renderHTML = () => (
		<div className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(editMjml?.html) }} />
	);

	const handleUpdateEmail = () => {
		updateEmailMutation.mutate({
			mjml: editMjml?.mjml,
			subject: editSubject,
			txt: editTxt,
			trigger: editTrigger,
			title: editTitle,
			enabled: enabled,
			conditions: editConditions,
		});
	};

	const toggleEnabled = () => {
		const enabledValue = !enabled;
		setEnabled(enabledValue);
		if (isEditing) {
			updateEmailMutation.mutate({ enabled: enabledValue });
		}
	};

	const handleTestMail = () => {
		if (idFromUrl) {
			sendTestEmailMutation.mutate(idFromUrl);
		}
	};

	const goToEmailsListPage = () => {
		history.push(`/emails`);
	};

	const createEmailMutation = useMutation(['create-email'], (template: IEmailTemplateCreate) => createEmail(template), {
		onSuccess: () => {
			toast.success(_t('emails.email-created'));
			goToEmailsListPage();
		},
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				toast.error(extractErrorMessage(error));
			}
		},
	});

	const handleCreateEmailTemplate = () => {
		createEmailMutation.mutate({
			mjml: editMjml?.mjml,
			subject: editSubject,
			txt: editTxt,
			trigger: editTrigger,
			title: editTitle,
			enabled: enabled,
			conditions: editConditions,
		});
	};

	const updatingEmail = updateEmailMutation.isLoading || sendTestEmailMutation.isLoading;

	if (loadingEmail) {
		return <Loading />;
	}

	const updateValue = (key: string, e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		const updatedConditions: any = { ...editConditions };
		updatedConditions![key] = value;
		setEditConditions(updatedConditions);
		setIsChanged(true);
	};

	const createEmailOrUpdateConditions = () => {
		if (isEditing) {
			handleUpdateEmail();
		} else {
			handleCreateEmailTemplate();
		}
	};

	const getTitle = () => {
		if (!idFromUrl) {
			return _t.translate('emails.create-email');
		}
		if (isEditing) {
			return _t.translate('emails.edit-email');
		}
		return _t.translate('emails.clone-email');
	};

	const title = getTitle();

	const isSaveDisabled = isEditing ? !isChanged : false;

	const findError = (name: string) => {
		return findErrorFromValidation(isEditing ? updateEmailMutation.error : createEmailMutation.error, name);
	};

	return (
		<PageLayout
			title={title}
			titleAppend={
				<CRow className="float-right">
					<EmailSaveButton render={!updatingEmail} disabled={isSaveDisabled} onClick={createEmailOrUpdateConditions} />
					<EmailEditButtons updatingEmail={updatingEmail} isChanged={isChanged} sendEmail={handleTestMail} />
				</CRow>
			}
		>
			<CRow>
				<CCol>
					<CTabs activeTab={activeEditType} onActiveTabChange={handleActiveCategoryChanged}>
						<CNav variant="tabs">
							<CNavItem>
								<CNavLink data-tab={EditType.GENERAL}>{_t.translate('emails.general')}</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={EditType.MJML}>MJML</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab={EditType.TXT}>{_t.translate('emails.text')}</CNavLink>
							</CNavItem>
						</CNav>
					</CTabs>
					<CCard className="card--with-top-tabs">
						<CCardBody>
							<div className="p-3 email-edit">
								<div className="d-flex align-items-center">
									<CSwitch color="primary" checked={enabled} onChange={toggleEnabled} />
									<span className="ml-2">
										{enabled ? _t.translate('emails.email-will-sent') : _t.translate('emails.email-wont-be-sent')}
									</span>
								</div>
								<div className="my-2 d-flex w-100 justify-content-between align-items-center">
									{activeEditType === EditType.GENERAL && (
										<div className="w-100 mb-2">
											<CFormGroup>
												<CLabel htmlFor="title">{_t.translate('emails.title')}</CLabel>
												<CInput
													type="text"
													name="title"
													placeholder={_t.translate('emails.title')}
													value={editTitle}
													onChange={updateEditTitle}
												/>
												{findError('title') && <CLabel className="text-danger">{findError('title')}</CLabel>}
											</CFormGroup>

											<CFormGroup>
												<CLabel htmlFor="subject">{_t.translate('emails.subject')}</CLabel>
												<CInput
													type="text"
													name="subject"
													placeholder={_t.translate('emails.subject')}
													value={editSubject}
													onChange={updateSubject}
												/>
												{findError('subject') && <CLabel className="text-danger">{findError('subject')}</CLabel>}
											</CFormGroup>

											<CFormGroup>
												<CLabel>{_t.translate('emails.conditions')}</CLabel>
												<CInputGroup>
													<CInputGroupPrepend>
														<CInputGroupText
															className={classNames('bg-info', 'text-white', styles.conditionsPrependText)}
														>
															{_t.translate('global.language')}
														</CInputGroupText>
													</CInputGroupPrepend>
													<CInput
														name="language"
														value={editConditions?.communicationLanguage || ''}
														onChange={(e) => updateValue('communicationLanguage', e)}
														placeholder="ES, EN, DE or !ES, !EN, !DE for default"
													/>
												</CInputGroup>
												<CInputGroup>
													<CInputGroupPrepend>
														<CInputGroupText
															className={classNames('bg-info', 'text-white', styles.conditionsPrependText)}
														>
															{_t.translate('global.country')}
														</CInputGroupText>
													</CInputGroupPrepend>
													<CInput
														name="country"
														value={editConditions?.country || ''}
														onChange={(e) => updateValue('country', e)}
														placeholder="ES, EN, DE or !ES, !EN, !DE for default"
													/>
												</CInputGroup>
												{findError('conditions') && <CLabel className="text-danger">{findError('conditions')}</CLabel>}
											</CFormGroup>

											<CFormGroup>
												<CLabel htmlFor="trigger">{_t.translate('emails.trigger')}</CLabel>
												<CustomSelect
													name="trigger"
													value={activeFilters.type ?? editTrigger}
													onChange={handleTypeChanged}
													options={triggerOptions}
													whiteBackground
													menuPortalTarget={document.body}
												/>
												{findError('trigger') && <CLabel className="text-danger">{findError('trigger')}</CLabel>}
											</CFormGroup>
										</div>
									)}
									{activeEditType === EditType.MJML && (
										<div className="w-100 mb-2">
											{mjmlError && (
												<div className="email-edit-error bg-danger mb-2">
													<CIcon name="cil-warning" size="xl" className="mx-2" />
													{mjmlError}
												</div>
											)}
											<div className="mb-2">
												<CLink href="https://documentation.mjml.io/" target="_blank">
													{_t.translate('emails.how-to-mjml')}
												</CLink>
											</div>
											{mjmlWarnings.length > 0 && <WarningBox warnings={mjmlWarnings} />}
											<div className="d-flex justify-content-center w-100 ">
												<div className="w-50 mr-1 email-edit-mjml-wrapper">
													<textarea
														className="w-100 h-100 rounded border-0 p-2 email-edit-mjml"
														onChange={updateMjml}
														value={editMjml?.mjml}
													/>
												</div>
												<div className="w-50 ml-1 email-edit-border">{!mjmlError && renderHTML()}</div>
											</div>
										</div>
									)}
									{activeEditType === EditType.TXT && (
										<div className="w-100 mb-2">
											<textarea
												className="w-100 h-100 email-edit-txt email-edit-border p-2 "
												onChange={updateEditTxt}
												value={editTxt}
											/>
										</div>
									)}
								</div>
							</div>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default EmailEditCreatePage;
