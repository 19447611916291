import React, { useState } from 'react';
import { CModal, CModalBody } from '@coreui/react';
import { IKYCDocument } from '../pages/customers/types';

interface KYCPreviewProps {
	documents: Array<IKYCDocument>;
}

const KYCPreview = (props: KYCPreviewProps) => {
	const [kycEnlargedUrl, setEnlargedUrl] = useState<string | null>(null);

	const showEnlargedKyc = (url: string) => {
		setEnlargedUrl(url);
	};

	const closeEnlargedKyc = () => {
		setEnlargedUrl(null);
	};

	const { documents } = props;

	const docs = documents.map((doc) => {
		const type = doc.url?.split('.').pop();
		const url = `${process.env.REACT_APP_API_ROOT}/api/admin/documents/file/${doc.token}`;

		if (type === 'pdf') {
			return (
				<a href={url} key={`doc-${doc.url}`} download>
					<figure className="figure mt-3">
						<svg
							className="bd-placeholder-img img-thumbnail figure-img rounded"
							width="250"
							height="150"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="xMidYMid slice"
							role="img"
						>
							<title>PDF</title>
							<rect width="100%" height="100%" fill="#868e96" />
							<text x="50%" y="50%" fill="#dee2e6" dy=".3em">
								PDF
							</text>
						</svg>

						<figcaption className="figure-caption" />
					</figure>
				</a>
			);
		}

		return (
			<a
				href={url}
				key={`doc-${doc.url}`}
				onClick={(e) => {
					showEnlargedKyc(doc.token);
					e.preventDefault();
				}}
			>
				<figure className="figure mt-3">
					<img
						src={url}
						className="figure-img img-thumbnail rounded"
						style={{ objectFit: 'contain', width: 250, height: 150 }}
						alt="KYC"
					/>
					<figcaption className="figure-caption" />
				</figure>
			</a>
		);
	});

	return (
		<div className="kyc-preview">
			{docs}
			<CModal show={Boolean(kycEnlargedUrl)} onClose={closeEnlargedKyc} centered>
				<CModalBody className="modal-dialog-centered">
					{Boolean(kycEnlargedUrl) && (
						<img
							src={`${process.env.REACT_APP_API_ROOT}/api/admin/documents/file/${kycEnlargedUrl}`}
							className="kyc-enlarged"
							alt="KYC document"
						/>
					)}
				</CModalBody>
			</CModal>
		</div>
	);
};

export default KYCPreview;
