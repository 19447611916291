import React, { useState } from 'react';
import {
	CButton,
	CFormGroup,
	CFormText,
	CInput,
	CInputCheckbox,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CSelect,
} from '@coreui/react';
import _t from 'counterpart';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { createBanner, updateBanner } from '../../services/BackendService';
import { useMutation, useQueryClient } from 'react-query';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { BannerDTO, CreateUpdateBannerDTO } from '../../dtos/BannerDTOs';
import FileUploader from '../../components/FileUploader';
import toast from 'react-hot-toast';

const DEFAULT_LINK_TARGET = '_self';

interface IProps {
	show: boolean;
	hideModal: Function;
	editingBanner: BannerDTO | null;
}

const CreateUpdateBannerModal = ({ show, hideModal, editingBanner }: IProps) => {
	const initialStartDate = moment().minutes(0).seconds(0).millisecond(0).toDate();
	const initialEndDate = moment().add(1, 'month').minutes(0).seconds(0).toDate();

	const initalState: CreateUpdateBannerDTO = {
		id: editingBanner?.id,
		name: editingBanner ? editingBanner.name : '',
		startDate: editingBanner ? new Date(editingBanner.startDate) : initialStartDate,
		endDate: editingBanner ? new Date(editingBanner.endDate) : initialEndDate,
		link: editingBanner ? editingBanner.link : null,
		linkTarget: editingBanner ? editingBanner.linkTarget : DEFAULT_LINK_TARGET,
		file: null,
	};

	const [banner, setBanner] = useState<CreateUpdateBannerDTO>(initalState);
	const [useLink, setUseLink] = useState<boolean>(editingBanner ? Boolean(editingBanner.link) : false);
	const [dropzoneError, setDropzoneError] = useState<string | null>(null);
	const queryClient = useQueryClient();

	const createUpdateBannerMutation = useMutation(
		['create-update-banner'],
		() => {
			if (editingBanner) {
				return updateBanner(banner);
			}
			return createBanner(banner);
		},
		{
			onSuccess: () => {
				if (editingBanner) {
					toast.success(_t('banners.successfully-updated'));
				} else {
					toast.success(_t('banners.successfully-added'));
				}
				queryClient.invalidateQueries('banners');
				hideModal();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	const hideSelf = () => {
		createUpdateBannerMutation.reset();
		hideModal();
	};

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		const value: string | null = target.value.trim().length > 0 ? target.value : null;

		if (name) {
			setBanner({ ...banner, [name]: value });
		}
	};

	const handleStartDateChange = (startDate: Moment | string) => {
		if (typeof startDate !== 'string') {
			setBanner({ ...banner, startDate: startDate.toDate() });
		}
	};

	const handleEndDateChange = (endDate: Moment | string) => {
		if (typeof endDate !== 'string') {
			setBanner({ ...banner, endDate: endDate.toDate() });
		}
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUseLink(e.target.checked);
		setBanner({ ...banner, link: null, linkTarget: DEFAULT_LINK_TARGET });
	};

	const handleDropzoneError = (error: string) => {
		setDropzoneError(error);
	};

	const tryAddFile = (files: any) => {
		setDropzoneError(null);
		setBanner({ ...banner, file: files[0] });
	};

	const removeFile = () => {
		setBanner({ ...banner, file: null });
	};

	const addUpdateBannerErrors = createUpdateBannerMutation.error;

	const findError = (name: string) => {
		return findErrorFromValidation(addUpdateBannerErrors, name);
	};

	return (
		<CModal show={show} onClose={hideSelf} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>
					{editingBanner ? _t.translate('banners.update-banner') : _t.translate('banners.add-banner')}
				</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="name">{_t.translate('global.name')}</CLabel>
					<CInput
						type="text"
						name="name"
						placeholder={_t.translate('global.name')}
						value={banner.name}
						onChange={handleInputChange}
					/>
					{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel>{_t('banners.start-date')}</CLabel>
					<Datetime
						value={banner.startDate}
						onChange={handleStartDateChange}
						dateFormat="DD/MM/YYYY"
						timeFormat="HH:mm"
						timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
						inputProps={{ readOnly: true }}
					/>
					{findError('startDate') && <CLabel className="text-danger">{findError('startDate')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel>{_t('banners.end-date')}</CLabel>
					<Datetime
						value={banner.endDate}
						onChange={handleEndDateChange}
						dateFormat="DD/MM/YYYY"
						timeFormat="HH:mm"
						timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
						inputProps={{ readOnly: true }}
					/>
					{findError('endDate') && <CLabel className="text-danger">{findError('endDate')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CLabel>{_t('banners.file')}</CLabel>
					<FileUploader
						allowMultipleFiles={false}
						addFiles={tryAddFile}
						removeFile={removeFile}
						maxFilesSizeInBytes={1000000}
						validFileTypes={['png', 'jpg', 'jpeg', 'gif', 'svg']}
						onError={handleDropzoneError}
						externalFile={editingBanner?.fileLink}
						externalFileName={editingBanner?.filename}
					/>
					<CFormText className="help-block">{_t('banners.file-recommendations')}</CFormText>
					{dropzoneError && <CLabel className="text-danger">{dropzoneError}</CLabel>}
					{findError('file') && <CLabel className="text-danger">{findError('file')}</CLabel>}
				</CFormGroup>
				<CFormGroup variant="checkbox" inline>
					<CInputCheckbox id="useLink" name="useLink" checked={useLink} onChange={handleCheckboxChange} />
					<CLabel variant="checkbox" htmlFor="useLink">
						{_t('banners.use-link')}
					</CLabel>
				</CFormGroup>
				{useLink && (
					<>
						<CFormGroup>
							<CLabel htmlFor="name">{_t.translate('banners.link')}</CLabel>
							<CInput
								type="text"
								name="link"
								placeholder={_t.translate('banners.link')}
								value={banner.link ?? ''}
								onChange={handleInputChange}
							/>
							{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-type">{_t('banners.link-behaviour')}</CLabel>
							<CSelect id="nf-type" name="linkTarget" onChange={handleInputChange} value={banner.linkTarget}>
								<option value="_self">{_t('banners.open-in-current-tab')}</option>
								<option value="_blank">{_t('banners.open-in-new-tab')}</option>
							</CSelect>
							{findError('linkTarget') && <CLabel className="text-danger">{findError('linkTarget')}</CLabel>}
						</CFormGroup>
					</>
				)}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={createUpdateBannerMutation.isLoading}
					onClick={createUpdateBannerMutation.mutate}
					buttonColor="primary"
					spinnerColor="secondary"
					title={editingBanner ? _t.translate('action.update') : _t.translate('action.add')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={hideSelf} disabled={createUpdateBannerMutation.isLoading}>
					{_t.translate('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default CreateUpdateBannerModal;
