import React from 'react';
import { CContainer, CRow, CCol, CLink } from '@coreui/react';

interface IProps {
	title: string;
	linkTitleTo?: string;
	children: React.ReactNode;
	titleAppend?: JSX.Element;
	subtitle?: string;
}

const PageLayout = ({ title, linkTitleTo, subtitle, titleAppend, children }: IProps) => {
	return (
		<CContainer fluid className="c-main main-holder">
			<CRow>
				<CCol>
					<div className="w-100">
						{!linkTitleTo && <h2 className="float-left c-main__title">{title}</h2>}
						{linkTitleTo && (
							<CLink href={linkTitleTo}>
								<h2>{title}</h2>
							</CLink>
						)}
						{titleAppend}
					</div>
					{subtitle && (
						<div className="pb-2">
							<span className="font-weight-lighter text-black-50">{subtitle}</span>
						</div>
					)}
				</CCol>
			</CRow>
			{children}
		</CContainer>
	);
};

export default PageLayout;
