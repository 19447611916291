import React from 'react';
import { CButton, CLabel } from '@coreui/react';
import _t from 'counterpart';

interface IInputProps {
	labelText: string;
	resetFilter: () => void;
}

const FilterInputHeader = ({ labelText, resetFilter }: IInputProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
			<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFilter}>
				{_t('action.reset').toUpperCase()}
			</CButton>
		</div>
	);
};

export default React.memo(FilterInputHeader);
