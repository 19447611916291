import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import Error from './Error';

interface IProps {
	onClose: () => void;
	onRetry: () => void;
	title: string;
}

const ErrorModal = ({ onClose, title, onRetry }: IProps) => {
	return (
		<CModal show onClose={onClose}>
			<CModalHeader closeButton className="d-flex align-items-center flex-column">
				<CModalTitle>{title}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<Error onRetry={onRetry} />
			</CModalBody>
		</CModal>
	);
};

export default React.memo(ErrorModal);
