import { CLabel, CSelect } from '@coreui/react';
import React from 'react';
import _t from 'counterpart';
import { itemsPerPageOptions } from './types';

interface IProps {
	limit: number;
	onLimitChanged: (value: number) => void;
}
const LimitDropdown = ({ limit, onLimitChanged }: IProps) => {
	const handleLimitChange = (e: any) => {
		onLimitChanged(parseInt(e.target.value));
	};

	return (
		<div className="d-flex align-items-center justify-content-end" style={{ paddingBottom: '7px', flex: '1' }}>
			<CLabel htmlFor="itemsPerPageDropdown" style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
				{_t(`global.items-per-page-label`)}
			</CLabel>
			<div style={{ paddingBottom: '10px' }}>
				<CSelect style={{ height: '35px' }} value={limit} id="itemsPerPageDropdown" onChange={handleLimitChange}>
					{itemsPerPageOptions.map((limit) => (
						<option key={limit} value={limit}>
							{limit}
						</option>
					))}
				</CSelect>
			</div>
		</div>
	);
};

export default LimitDropdown;
