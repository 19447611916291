import React from 'react';
import { CBadge } from '@coreui/react';
import _t from 'counterpart';
import { TransactionStatus } from './types';

interface IProps {
	status: TransactionStatus;
}

const statusToColor = {
	[TransactionStatus.Created]: 'info',
	[TransactionStatus.Successful]: 'success',
	[TransactionStatus.Error]: 'danger',
	[TransactionStatus.Failed]: 'danger',
	[TransactionStatus.Canceled]: 'secondary',
	[TransactionStatus.Pending]: 'warning',
};

export const TransactionStatusBadge: React.FC<IProps> = React.memo(({ status }) => {
	const color = statusToColor[status];
	return <CBadge color={color}>{_t(`global.transaction-status.${status}`).toUpperCase()}</CBadge>;
});
