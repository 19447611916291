import React, { useState } from 'react';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CCardTitle, CFormGroup, CSelect } from '@coreui/react';
import _t from 'counterpart';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation, useQuery } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import { loadGroups, updateWalletConfig } from '../../services/BackendService';
import toast from 'react-hot-toast';
import { IGroup } from '../groups/types';

interface IParams {
	group: string;
}

const WalletConfiguration = () => {
	const [groupId, setGroupId] = useState<string>('');

	const onError = (e: any) => {
		toast.error(extractErrorMessage(e));
	};

	const walletSettingsMutation = useMutation(({ group }: IParams) => updateWalletConfig(group), {
		onError,
		onSuccess: () => {
			toast.success(_t('onboarding.wallet-settings-configured'));
		},
	});

	const loadGroupsQuery = useQuery(['config-order-groups'], () => loadGroups(), {
		onError,
		onSuccess: (data: Array<IGroup>) => {
			if (data.length > 0) {
				setGroupId(data[0].name);
			}
		},
	});

	const callMutation = () => {
		walletSettingsMutation.mutate({ group: groupId });
	};

	const onGroupChanged = (e: any) => {
		const value = e.target.value;
		setGroupId(value);
	};

	return (
		<CCol>
			<CCard>
				<CCardHeader>
					<CCardTitle>{_t('onboarding.wallet-configuration')}</CCardTitle>
					<p>{_t('onboarding.wallet-config-description')}</p>
				</CCardHeader>
				<CCardBody className="simulated-overflow-y">
					<CRow>
						<CCol md={12}>
							<CFormGroup>
								<InputHeaderNoButton labelText={_t('onboarding.group')} />
								<CSelect id="nf-type" name="nf-type" onChange={onGroupChanged} value={groupId}>
									{loadGroupsQuery.data?.map((o: any) => (
										<option value={o.name} key={`group-${o.id}`}>
											{o.name}
										</option>
									))}
								</CSelect>
							</CFormGroup>
						</CCol>
					</CRow>
					<CRow>
						<CCol md={12} className="d-flex justify-content-end">
							<ButtonWithLoader
								isLoading={walletSettingsMutation.isLoading}
								onClick={callMutation}
								buttonColor="primary"
								spinnerColor="secondary"
								title={_t('action.save')}
								className="btn-md"
								showText={false}
							/>
						</CCol>
					</CRow>
				</CCardBody>
			</CCard>
		</CCol>
	);
};

export default WalletConfiguration;
