import React from 'react';
import _t from 'counterpart';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CForm,
	CFormGroup,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CLabel,
	CTextarea,
	CAlert,
	CFormText,
} from '@coreui/react';
import PageLayout from '../../components/PageLayout';
import { useLegacyState } from '../../helpers/customHooks';
import { useMutation } from 'react-query';
import { createMassBonus } from '../../services/BackendService';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import toast from 'react-hot-toast';

interface IState {
	error: string | null;
	amount: number | null;
	name: string | null;
	accounts: string;
	addToCredit: boolean | null;
}

const MassBonusesPage = () => {
	const initialState = {
		error: null,
		amount: null,
		name: '',
		accounts: '',
		addToCredit: false,
	};
	const [state, setState] = useLegacyState<IState>(initialState);

	const { accounts, error, amount, name, addToCredit } = state;

	const resetState = () => {
		setState(initialState);
	};

	const handleAmountChanged = (event: any) => {
		setState({
			amount: event.target.value,
		});
	};

	const handleNameChanged = (event: any) => {
		setState({
			name: event.target.value,
		});
	};

	const handleAccountsChanged = (event: any) => {
		setState({
			accounts: event.target.value,
		});
	};

	const handleAddToCredit = (event: any) => {
		const target = event.target as HTMLInputElement;
		const { checked } = target;
		setState({ addToCredit: checked });
	};

	const addBonusMutation = useMutation(
		['add-mass-bonus'],
		() => createMassBonus(accounts, name, amount!, 'USD', addToCredit),
		{
			onSuccess: async () => {
				resetState();
				toast.success(_t('mass-bonuses.success-toast'));
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					toast.error(msg);
				}
			},
		}
	);

	const addMassBonus = async (e: React.FormEvent) => {
		e.preventDefault();
		addBonusMutation.mutate();
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(addBonusMutation.error, paramName);
	};

	const loading = addBonusMutation.isLoading;

	return (
		<PageLayout title={_t.translate('mass-bonuses.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							{!loading && error && <CAlert color="danger">{error}</CAlert>}
							<CForm onSubmit={addMassBonus}>
								<CFormGroup>
									<CLabel htmlFor="nf-name">{_t('mass-bonuses.name')}</CLabel>
									<CInputGroup>
										<CInput
											id="nf-name"
											name="nf-name"
											placeholder="Bonus name"
											value={name || ''}
											onChange={handleNameChanged}
										/>
									</CInputGroup>
									<CFormText className="help-block">{_t('messaging.user-ids-comment')}</CFormText>
									{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
								</CFormGroup>

								<CFormGroup>
									<CLabel htmlFor="nf-amount">{_t('mass-bonuses.amount')}</CLabel>
									<CInputGroup>
										<CInputGroupPrepend>
											<CInputGroupText>$</CInputGroupText>
										</CInputGroupPrepend>
										<CInput
											type="number"
											id="nf-amount"
											name="nf-amount"
											placeholder="12.64"
											value={amount || ''}
											onChange={handleAmountChanged}
										/>
									</CInputGroup>
									{findError('amount') && <CLabel className="text-danger">{findError('amount')}</CLabel>}
								</CFormGroup>
								<CFormGroup className="d-flex align-items-center">
									<input
										className="mr-2"
										type="checkbox"
										id="addToCredit"
										value={addToCredit}
										onChange={handleAddToCredit}
										checked={addToCredit}
									/>
									<span>{_t.translate('mass-bonuses.added-as-credit')}</span>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-accounts">{_t('mass-bonuses.accounts')}</CLabel>
									<CTextarea
										id="nf-accounts"
										name="nf-accounts"
										value={accounts || ''}
										onChange={handleAccountsChanged}
									/>
									<CFormText className="help-block">{_t('mass-bonuses.accounts-comment')}</CFormText>
									{findError('accounts') && <CLabel className="text-danger">{findError('accounts')}</CLabel>}
								</CFormGroup>

								<ButtonWithLoader
									isLoading={loading}
									type="submit"
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t.translate('action.add')}
									className="mr-2"
								/>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default MassBonusesPage;
