import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CAlert } from '@coreui/react';
import _t from 'counterpart';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { extractErrorMessage } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { updateCustomer } from '../../services/BackendService';
import { CustomerStatus } from './types';

interface IProps {
	id: string;
	show: boolean;
	hidePanel: Function;
	onConfirm: Function;
	title: string;
	error: string | null;
	buttonColor: string;
	isLoading?: boolean;
	terminationRequested?: boolean;
}

const DisableCustomerModal = ({
	id,
	show,
	hidePanel,
	onConfirm,
	title,
	error,
	buttonColor,
	terminationRequested = false,
}: IProps) => {
	const rejectTerminationRequestMutation = useMutation(
		['reject-termination-request-mutation'],
		() => updateCustomer(id, { rejectTerminationRequest: true }),
		{
			onSuccess: () => {
				hidePanel(true);
				toast.success(_t('customer.successfully-rejected-termination-request'));
			},
			onError: (e: any) => {
				toast.error(extractErrorMessage(e));
			},
		}
	);

	const confirm = () => {
		hidePanel(true);
		onConfirm(CustomerStatus.Disabled);
	};

	const reject = () => {
		rejectTerminationRequestMutation.mutate();
	};

	return (
		<CModal show={show} onClose={hidePanel} size="sm">
			<CModalHeader className="d-flex flex-column">
				{error && <CAlert color="danger">{error}</CAlert>}
				<CModalTitle> {title}</CModalTitle>
			</CModalHeader>
			<CModalBody />
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={false}
					onClick={confirm}
					buttonColor={buttonColor}
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
				/>
				{terminationRequested && (
					<ButtonWithLoader
						isLoading={false}
						onClick={reject}
						buttonColor={'light'}
						spinnerColor="secondary"
						title={_t.translate('action.reject')}
					/>
				)}
				<CButton color="light" variant="outline" style={{ marginTop: '5%' }} onClick={hidePanel} disabled={false}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default DisableCustomerModal;
