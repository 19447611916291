import React, { useCallback, useMemo, useState } from 'react';
import { CButton, CForm, CFormGroup, CCol, CLabel, CRow } from '@coreui/react';
import _t from 'counterpart';
import { IDateRange } from '../../reducers/filtersReducer';
import CustomSelect from '../../components/CustomSelect';
import DateRangePicker from '../../components/DateRangePicker';
import { useAppSelector } from '../../helpers/customHooks';
import { stringify } from 'qs';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import * as Sentry from '@sentry/react';

interface IProps {
	onRequest: (filters: any) => void;
	amountOfRecords: number;
}

interface InputProps {
	labelText: string;
	resetFilter: Function;
}

const InputHeader = ({ labelText, resetFilter }: InputProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
			<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFilter}>
				{_t('action.reset').toUpperCase()}
			</CButton>
		</div>
	);
};

const RiskReportsConfigurator = ({ onRequest, amountOfRecords }: IProps) => {
	const [activeFilters, setActiveFilters] = useState<any>({ excludeZero: false });
	const [isDownloading, setIsDownloading] = useState(false);
	const setFilters = (filters: any) => {
		setActiveFilters({ ...activeFilters, ...filters });
	};

	const token = useAppSelector((state: any) => state.user.token);

	const handleTypeChanged = (type: any) => {
		setFilters({ type });
	};

	const handleRangeChanged = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({ range });
		}
	};

	const resetType = () => {
		setFilters({
			type: undefined,
		});
	};

	const resetRange = () => {
		setFilters({
			range: undefined,
		});
	};

	const reportOptions = [
		{ label: 'Profit/Loss', value: 'profitloss' },
		{ label: 'Account Balances', value: 'balance' },
		{ label: 'Account Coin Balances', value: 'coins' },
		{ label: 'Affiliation Performance', value: 'affiliation' },
	];

	const showDateFilter = ['profitloss', 'balance'].includes(activeFilters?.type?.value || '');

	const params: any = useMemo(
		() => ({
			token,
			type: activeFilters?.type?.value || null,
			start: activeFilters?.range?.startDate || null,
			end: activeFilters?.range?.endDate || null,
		}),
		[activeFilters?.range?.endDate, activeFilters?.range?.startDate, activeFilters?.type?.value, token]
	);

	const toggleExcludeZero = (e: any) => {
		setFilters({ excludeZero: e.target.checked });
	};

	const xlsxLink = useMemo(
		() => `${process.env.REACT_APP_API_ROOT}/api/admin/reports/export/xlsx?${stringify(params)}`,
		[params]
	);

	const handleDownloadReport = useCallback(async () => {
		try {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href = xlsxLink;
			a.click();
			a.remove();
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			setIsDownloading(false);
		}
	}, [xlsxLink]);

	return (
		<CForm className="filters">
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('reports.filters.type')} resetFilter={resetType} />
						<CustomSelect
							value={activeFilters.type ?? null}
							onChange={handleTypeChanged}
							options={reportOptions}
							whiteBackground
							menuPortalTarget={document.body}
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					{showDateFilter && (
						<CFormGroup>
							<InputHeader labelText={_t('reports.filters.range')} resetFilter={resetRange} />
							<DateRangePicker
								initialStartDate={activeFilters.range?.startDate ?? null}
								initialEndDate={activeFilters.range?.endDate ?? null}
								onRangeChanged={handleRangeChanged}
							/>
						</CFormGroup>
					)}
				</CCol>
			</CRow>
			<CRow>
				<CCol className="d-flex align-items-center justify-content-start">
					<CButton color="primary" onClick={() => onRequest(activeFilters)}>
						{_t('reports.request')}
					</CButton>
					{amountOfRecords > 0 && (
						<ButtonWithLoader
							isLoading={isDownloading}
							onClick={handleDownloadReport}
							buttonColor="success"
							title={_t('reports.export-xlsx')}
							className="ml-2"
						/>
					)}
					{activeFilters?.type?.value === 'balance' && (
						<div className="ml-3 p-3">
							<input
								className="form-check-input"
								type="checkbox"
								id="id"
								value="id"
								onChange={toggleExcludeZero}
								checked={activeFilters?.excludeZero}
							/>
							<span>{_t.translate('reports.exclude-zero')}</span>
						</div>
					)}
				</CCol>
			</CRow>
		</CForm>
	);
};

export default RiskReportsConfigurator;
