import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { CButton, CCard, CCardBody, CCol, CFormGroup, CInput, CInputCheckbox, CLabel, CRow } from '@coreui/react';
import { useMutation, useQuery } from 'react-query';
import { createCommissionModel, getCommissionModel, updateCommissionModel } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import {
	ICommissionModel,
	ICommissionModelLevel,
	IUpdateCommissionModelParams,
	initialCommissionModelLevel,
	initialCommissionModelState,
} from './types';
import CommissionLevelForm from './CommissionLevelForm';
import { useAppDispatch } from '../../helpers/customHooks';
import SubCommissionSelect from './SubCommissionSelect';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

const CreateUpdateCommissionModel = () => {
	const [state, setState] = useState<ICommissionModel>(initialCommissionModelState);

	const dispatch = useAppDispatch();
	const history = useHistory();

	const searchParams = new URLSearchParams(history.location.search);
	const editParam = searchParams.get('edit');

	useQuery(['commission-model-edit', editParam], () => getCommissionModel(editParam ?? ''), {
		enabled: editParam !== null,
		onError: (e) => {
			dispatch(extractErrorMessage(e));
		},
		onSuccess: (data: ICommissionModel) => {
			setState({
				greyLabelingEnabled: data.greyLabelingEnabled,
				name: data.name,
				subCommissionModelId: data.subCommissionModelId,
				subCommissionModel: null,
				default: false,
				commissionModelLevels: data.commissionModelLevels.map((level: ICommissionModelLevel) => {
					return {
						cpaAmount: level.cpaAmount ? Number(level.cpaAmount) : null,
						cpaCurrency: level.cpaCurrency,
						level: level.level,
						profitSharePercentage: level.profitSharePercentage,
						volumeCommissionAmount: level.volumeCommissionAmount ? Number(level.volumeCommissionAmount) : null,
						volumeCommissionCurrency: level.volumeCommissionCurrency,
					};
				}),
			});
		},
		refetchOnWindowFocus: false,
	});

	const createCommissionMutation = useMutation(
		(model: Omit<ICommissionModel, 'subCommissionModel' | 'default'>) => createCommissionModel(model),
		{
			onSuccess: () => {
				toast.success(_t('commissions.commission-model-created-successfully'));
				history.push('/commission_models');
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	const updateCommissionMutation = useMutation((model: IUpdateCommissionModelParams) => updateCommissionModel(model), {
		onSuccess: () => {
			toast.success(_t('commissions.commission-model-updated-successfully'));
			history.push('/commission_models');
		},
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			}
		},
	});

	const invokeMutation = () => {
		const { subCommissionModel, ...rest } = state;
		const commissionModel = {
			id: rest.id,
			name: rest.name,
			greyLabelingEnabled: rest.greyLabelingEnabled,
			subCommissionModelId: rest.subCommissionModelId,
			commissionModelLevels: rest.commissionModelLevels,
		};

		if (editParam === null) {
			createCommissionMutation.mutate(commissionModel);
		} else {
			updateCommissionMutation.mutate({
				commissionModel: commissionModel,
				id: Number(editParam),
			});
		}
	};

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		const value = target.value;

		if (name) {
			setState({ ...state, [name]: value });
		}
	};

	const onLevelInputChange = (levelIndex: number, updatedLevel: ICommissionModelLevel) => {
		const target = state.commissionModelLevels.find((_, index) => index === levelIndex);
		if (target) {
			const updatedLevels = [...state.commissionModelLevels];
			updatedLevels[levelIndex] = updatedLevel;
			setState({ ...state, commissionModelLevels: updatedLevels });
		}
	};

	const onLevelChanged = (levelIndex: number) => {
		const commissionModelLevels = [...state.commissionModelLevels];
		commissionModelLevels.splice(levelIndex, 1);
		setState({ ...state, commissionModelLevels });
	};

	const addCommissionLevel = () => {
		const levels = [...state.commissionModelLevels];
		const newLevel = { ...initialCommissionModelLevel };

		if (levels.length > 0) {
			const maxLevel = levels.reduce((prev, current) => (prev.level! > current.level! ? prev : current));
			if (maxLevel && maxLevel.level) {
				newLevel.level = maxLevel.level + 1;
			}
		}

		levels.push(newLevel);
		setState({ ...state, commissionModelLevels: levels });
	};

	const toggleGreyLabelingEnabled = () => {
		const greyLabelingEnabled = !state.greyLabelingEnabled;
		setState({ ...state, greyLabelingEnabled });
	};

	const onSubCommissionModelChange = (id: number) => {
		setState({ ...state, subCommissionModelId: id });
	};

	const onSubCommissionModelReset = () => {
		setState({ ...state, subCommissionModelId: null });
	};

	const findError = findErrorFromValidation;
	const errors = createCommissionMutation.error || updateCommissionMutation.error;

	return (
		<PageLayout
			title={editParam === null ? _t('commission-schemes.add-modal-title') : _t('commission-schemes.edit-modal-title')}
		>
			<CRow>
				<CCol>
					<CCard className="pt-2 pb-2">
						<CCardBody>
							<CFormGroup>
								<CLabel htmlFor="name">{_t('global.name')}</CLabel>
								<CInput
									type="text"
									name="name"
									placeholder="Commission model name"
									value={state.name}
									onChange={handleInputChange}
								/>
								{findError(errors, 'name') && <CLabel className="text-danger">{findError(errors, 'name')}</CLabel>}
							</CFormGroup>
							<SubCommissionSelect
								id={null}
								subCommissionModelId={state.subCommissionModelId}
								onChange={onSubCommissionModelChange}
								onReset={onSubCommissionModelReset}
							/>
							<CFormGroup>
								<div className="form-check form-check-inline">
									<CInputCheckbox
										className="form-check-input"
										type="checkbox"
										id="greyLabelingEnabled"
										onChange={toggleGreyLabelingEnabled}
										checked={state.greyLabelingEnabled}
									/>
									<span>{_t('commissions.grey-labeling-enabled')}</span>
								</div>
								{findError(errors, 'greyLabelingEnabled') && (
									<CLabel className="text-danger">{findError(errors, 'greyLabelingEnabled')}</CLabel>
								)}
							</CFormGroup>
							<CFormGroup className="d-flex flex-column">
								<CLabel htmlFor="trading-hours" className="instrument-section-label">
									{_t('commissions.levels')}
								</CLabel>
								{state.commissionModelLevels.map((level, index) => {
									return (
										<CommissionLevelForm
											key={index}
											index={index}
											commissionLevel={level}
											handleInputChange={onLevelInputChange}
											onLevelRemoved={onLevelChanged}
										/>
									);
								})}

								<CButton onClick={addCommissionLevel} className="instrument-buttons mt-2" color="success">
									{_t('commissions.add-level')}
								</CButton>
							</CFormGroup>
							<ButtonWithLoader
								onClick={invokeMutation}
								isLoading={createCommissionMutation.isLoading}
								type="submit"
								buttonColor="primary"
								spinnerColor="secondary"
								title={editParam === null ? _t('action.create') : _t('action.update')}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default CreateUpdateCommissionModel;
