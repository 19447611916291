import React, { useEffect, useRef, useState } from 'react';
import _t from 'counterpart';
import PageLayout from '../../components/PageLayout';
import { Tree } from 'react-tree-graph';
import 'react-tree-graph/dist/style.css';
import { StringParam, useQueryParam } from 'use-query-params';
import { useQuery } from 'react-query';
import { getReferralTree } from '../../services/BackendService';
import { IReferralNode } from './types';
import { useCallback } from 'react';
import { CButton, CLink } from '@coreui/react';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { isSet } from '../../helpers';

interface INodeSelected {
	name: string;
	id: string;
}

const useHideRefOutsideClick = (ref: any) => {
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target)) {
				ref.current.style.visibility = 'hidden';
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};

const ReferralTreeViewPage = () => {
	const [id] = useQueryParam('id', StringParam);
	const [initialData, setInitialData] = useState<IReferralNode | null>(null);
	const [currentData, setCurrentData] = useState<IReferralNode | null>(null);
	const [nodeSelected, setNodeSelected] = useState<INodeSelected | null>(null);

	const tooltipRef = useRef<any>(null);
	useHideRefOutsideClick(tooltipRef);

	const referralTreeQuery = useQuery(['referral-tree', id], () => getReferralTree(id as string), {
		enabled: isSet(id),
	});

	const handleClick = (event: any, id: any) => {
		const name = event.target.getAttribute('name');
		const rect = event.target.getBoundingClientRect();
		const left = rect.left;
		const top = rect.top;
		tooltipRef.current.style.left = Math.round(left) + 'px';
		tooltipRef.current.style.top = Math.round(top) - 110 + window.pageYOffset + 'px';
		tooltipRef.current.style.visibility = 'visible';

		setNodeSelected({ name, id });
	};

	const recursiveHandler = useCallback((node: IReferralNode) => {
		node.nodeProps = {
			name: node.name,
		};
		node.textProps = {
			name: node.name,
			x: -15,
			y: 15,
		};

		for (const child of node.children) {
			recursiveHandler(child);
		}
	}, []);

	useEffect(() => {
		if (referralTreeQuery.isSuccess && referralTreeQuery.data) {
			const data = { ...referralTreeQuery.data };
			recursiveHandler(data);
			setInitialData(data);
			setCurrentData(data);
		}
	}, [referralTreeQuery.isSuccess, referralTreeQuery.data, recursiveHandler]);

	const setNodeRecursively = (node: IReferralNode) => {
		if (nodeSelected === null) {
			return;
		}

		if (node.id === nodeSelected.id) {
			const newNode = { ...node };
			recursiveHandler(newNode);
			setCurrentData(newNode);
			tooltipRef.current.style.visibility = 'hidden';
			return;
		}

		for (const child of node.children) {
			setNodeRecursively(child);
		}
	};

	const cutDownline = () => {
		if (currentData) {
			setNodeRecursively(currentData);
		}
	};

	const resetDownline = () => {
		setCurrentData(initialData);
	};

	if (referralTreeQuery.isLoading || referralTreeQuery.isIdle) {
		return <Loading />;
	}

	if (referralTreeQuery.isError) {
		return <Error onRetry={referralTreeQuery.refetch} />;
	}

	return (
		<PageLayout title={_t('customers.referral-tree-view')}>
			<div className="d-flex justify-content-between d-flex align-items-center">
				<span>{currentData?.name}</span>
				{currentData?.id !== initialData?.id && (
					<CButton color="primary" onClick={resetDownline} className="float-right">
						{_t('action.reset')}
					</CButton>
				)}
			</div>
			<div className="node-tooltip" ref={tooltipRef} style={{ visibility: 'hidden' }}>
				<div className="d-flex justify-content-center mt-2">
					<CLink href={`customers/${nodeSelected?.id}`}>{nodeSelected?.name}</CLink>
				</div>
				<div className="d-flex justify-content-center mt-2">
					<CButton color="primary" onClick={cutDownline}>
						{_t('action.cut')}
					</CButton>
				</div>
			</div>
			<Tree
				data={currentData || {}}
				height={700}
				width={700}
				margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
				keyProp="id"
				gProps={{
					className: 'node',
					onClick: handleClick,
				}}
			/>
		</PageLayout>
	);
};

export default React.memo(ReferralTreeViewPage);
