import React from 'react';

const Detail = ({ title, children }: any) => {
	return (
		<>
			<dt>{title}:</dt>
			<dd>{children}</dd>
		</>
	);
};

export default Detail;
