import { put, takeLatest, select } from 'redux-saga/effects';
import {
	REQUEST_CHANGE_LANGUAGE,
	requestChangeLanguage,
	setLanguage,
	setSidebarShown,
	TOGGLE_SIDEBAR,
	TOGGLE_SIDEBAR_MOBILE,
} from '../actions';
import { AppState } from '../reducers';
import { AnyAction } from 'redux';
import _t from 'counterpart';
import moment from 'moment';
import { correctedMomentLocales } from '../locales';
import { REHYDRATE } from 'redux-persist/es/constants';

function* toggleDesktopSidebar() {
	// @ts-ignore
	const sidebarShown = yield select((state: AppState) => state.core.sidebarShown);

	yield put(setSidebarShown([true, 'responsive'].includes(sidebarShown) ? false : 'responsive'));
}

function* toggleMobileSidebar() {
	// @ts-ignore
	const sidebarShown = yield select((state) => state.core.sidebarShown);

	yield put(setSidebarShown([false, 'responsive'].includes(sidebarShown) ? true : 'responsive'));
}

function* setLanguageSaga(action: AnyAction) {
	const { language, skipUserUpdate } = action;
	if (_t.getLocale() !== language) {
		_t.setLocale(language);
	}

	if (Object.keys(correctedMomentLocales).includes(language)) {
		moment.locale(correctedMomentLocales[language]);
	} else {
		moment.locale(language);
	}

	yield put(setLanguage(language, skipUserUpdate));
}

function* setLanguageFromPersistance(action: AnyAction) {
	const { communicationLanguage } = action.payload.user;
	yield put(requestChangeLanguage(communicationLanguage, true));
}

function* dashboardSaga() {
	yield takeLatest(TOGGLE_SIDEBAR, toggleDesktopSidebar);
	yield takeLatest(TOGGLE_SIDEBAR_MOBILE, toggleMobileSidebar);
	yield takeLatest(REQUEST_CHANGE_LANGUAGE, setLanguageSaga);
	yield takeLatest(REHYDRATE, setLanguageFromPersistance);
}

export default dashboardSaga;
