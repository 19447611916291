import { CCol } from '@coreui/react';
import React from 'react';

interface IProps {
	title: string;
	children: React.ReactNode;
}

const Description = ({ title, children }: IProps) => {
	return (
		<CCol md={6}>
			<dl>
				<dt>{title}:</dt>
				<dd>{children}</dd>
			</dl>
		</CCol>
	);
};

export default React.memo(Description);
