import { IPosition } from '../pages/positions/position';
import { isSet } from '../helpers';

interface IPositionMessage {
	messageType: 'PositionStatus' | 'PositionOpen' | 'PositionClose' | 'PositionClosed' | 'PositionOpened';
	positionId: number;
}

interface IPositionStatus extends IPositionMessage {
	symbol: string;
	profitLoss: string;
	margin: string;
}

export interface IPositionOpen extends IPositionMessage {
	accountId: number;
	type: string;
	symbol: string;
	status: string;
	invest: number;
	openedAt: number;
	platform: string;
	size: number;
	side: string;
	closePositionAfterSeconds: number;
	openPrice: string;
	openingOrderExternalId: string;
}

export interface IPositionClose extends IPositionMessage {
	symbol: string;
	status: string;
	invest: number;
	closedAt: number;
	platform: string;
	size: number;
	side: string;
	closedPLInAccountCurrency: string;
	duration: number;
	openPrice: string;
	closePrice: string;
	profitLoss: string;
	closeReason: string;
}

const updatePositionFromPositionStatus = (position: IPosition, message: IPositionStatus): IPosition => {
	if (position.id !== message.positionId || !isSet(message.profitLoss)) {
		return position;
	}
	return {
		...position,
		profitLoss: message.profitLoss,
	};
};

const updatePositionFromPositionClose = (position: IPosition, message: IPositionClose): IPosition => {
	if (position.id !== message.positionId) {
		return position;
	}

	const { messageType, ...rest } = message;
	return {
		...position,
		...rest,
	};
};

export const updatePositionsFromPositionStatus = (
	positions: Array<IPosition>,
	message: IPositionStatus
): Array<IPosition> => {
	return positions.map((position) => updatePositionFromPositionStatus(position, message));
};

export const updatePositionsFromPositionClose = (
	positions: Array<IPosition>,
	message: IPositionClose
): Array<IPosition> => {
	return positions.filter((position) => position.id !== message.positionId);
};

const updatePositionsFromPositionOpen = (positions: Array<IPosition>, message: IPositionOpen): Array<IPosition> => {
	const { messageType, positionId, ...newPosition } = message;
	return [
		{
			...newPosition,
			id: positionId,
			closeReason: null,
			closedPLInAccountCurrency: null,
			closedPL: null,
			closedAt: null,
			closePrice: null,
			profitLoss: null,
			amount: null,
			swap: null,
			takeProfit: null,
			stopLoss: null,
			commission: null,
			trailingStopLossPct: null
		},
		...positions,
	];
};

export const updatePositionsCacheFromWSMessage = (oldData: any, message: IPositionMessage) => {
	if (!isSet(oldData) || !isSet(oldData.positions)) {
		return oldData;
	}

	let positions = [];

	switch (message.messageType) {
		case 'PositionStatus':
			positions = updatePositionsFromPositionStatus(oldData.positions, message as IPositionStatus);
			break;
		case 'PositionClose':
			positions = updatePositionsFromPositionClose(oldData.positions, message as IPositionClose);
			break;
		case 'PositionOpen':
			positions = updatePositionsFromPositionOpen(oldData.positions, message as IPositionOpen);
			break;
		default:
			throw Error(`Invalid message type ${message.messageType}`);
	}

	return {
		...oldData,
		positions,
	};
};

export const updatePositionCacheFromWSMessage = (oldData: IPosition, message: IPositionMessage) => {
	if (!isSet(oldData)) {
		return oldData;
	}

	switch (message.messageType) {
		case 'PositionStatus':
			return updatePositionFromPositionStatus(oldData, message as IPositionStatus);
		case 'PositionClose':
			return updatePositionFromPositionClose(oldData, message as IPositionClose);
		default:
			return oldData;
	}
};
