import {
	cibSkype,
	cibFacebook,
	cibTwitter,
	cibLinkedin,
	cibFlickr,
	cibTumblr,
	cibXing,
	cibGithub,
	cibYoutube,
	cibDribbble,
	cibInstagram,
	cibPinterest,
	cibVk,
	cibYahoo,
	cibBehance,
	cibReddit,
	cibVimeo,
	cibCcMastercard,
	cibCcVisa,
	cibStripe,
	cibPaypal,
	cibGooglePay,
	cibCcAmex,
	cifAd,
	cifAe,
	cifAf,
	cifAg,
	cifAl,
	cifAm,
	cifAo,
	cifAr,
	cifAt,
	cifAu,
	cifAz,
	cifBa,
	cifBb,
	cifBd,
	cifBe,
	cifBf,
	cifBg,
	cifBh,
	cifBi,
	cifBj,
	cifBn,
	cifBo,
	cifBr,
	cifBs,
	cifBt,
	cifBw,
	cifBy,
	cifBz,
	cifCa,
	cifCd,
	cifCf,
	cifCg,
	cifCh,
	cifCi,
	cifCl,
	cifCm,
	cifCn,
	cifCo,
	cifCr,
	cifCu,
	cifCv,
	cifCy,
	cifCz,
	cifDe,
	cifDj,
	cifDk,
	cifDm,
	cifDo,
	cifDz,
	cifEc,
	cifEe,
	cifEg,
	cifEr,
	cifEs,
	cifEt,
	cifFi,
	cifFj,
	cifFm,
	cifFr,
	cifGa,
	cifGb,
	cifGd,
	cifGe,
	cifGh,
	cifGm,
	cifGn,
	cifGq,
	cifGr,
	cifGt,
	cifGw,
	cifGy,
	cifHk,
	cifHn,
	cifHr,
	cifHt,
	cifHu,
	cifId,
	cifIe,
	cifIl,
	cifIn,
	cifIq,
	cifIr,
	cifIs,
	cifIt,
	cifJm,
	cifJo,
	cifJp,
	cifKe,
	cifKg,
	cifKh,
	cifKi,
	cifKm,
	cifKn,
	cifKp,
	cifKr,
	cifKw,
	cifKz,
	cifLa,
	cifLb,
	cifLc,
	cifLi,
	cifLk,
	cifLr,
	cifLs,
	cifLt,
	cifLu,
	cifLv,
	cifLy,
	cifMa,
	cifMc,
	cifMd,
	cifMe,
	cifMg,
	cifMh,
	cifMk,
	cifMl,
	cifMm,
	cifMn,
	cifMr,
	cifMt,
	cifMu,
	cifMv,
	cifMw,
	cifMx,
	cifMy,
	cifMz,
	cifNa,
	cifNe,
	cifNg,
	cifNi,
	cifNl,
	cifNo,
	cifNp,
	cifNr,
	cifNu,
	cifNz,
	cifOm,
	cifPa,
	cifPe,
	cifPg,
	cifPh,
	cifPk,
	cifPl,
	cifPt,
	cifPw,
	cifPy,
	cifQa,
	cifRo,
	cifRs,
	cifRu,
	cifRw,
	cifSa,
	cifSb,
	cifSc,
	cifSd,
	cifSe,
	cifSg,
	cifSi,
	cifSk,
	cifSl,
	cifSm,
	cifSn,
	cifSo,
	cifSr,
	cifSs,
	cifSt,
	cifSv,
	cifSy,
	cifSz,
	cifTd,
	cifTg,
	cifTh,
	cifTj,
	cifTl,
	cifTm,
	cifTn,
	cifTo,
	cifTr,
	cifTt,
	cifTv,
	cifTw,
	cifTz,
	cifUa,
	cifUg,
	cifUs,
	cifUy,
	cifUz,
	cifVa,
	cifVc,
	cifVe,
	cifVn,
	cifWs,
	cifXk,
	cifYe,
	cifZa,
	cifZm,
	cifZw,
	cilAccountLogout,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilCheckAlt,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFullscreen,
	cilFullscreenExit,
	cilGraph,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPrint,
	cilPuzzle,
	cilSave,
	cilScrubber,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilTags,
	cilTask,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilX,
	cilXCircle,
	cilWarning,
	cilFlagAlt,
	cilMoney,
	cilBook,
	cilBalanceScale,
	cilPlus,
	cilClone,
	cilEqualizer,
	cilCash,
	cibHockeyapp,
	cilBriefcase,
	cilCopy,
	cilFindInPage,
} from '@coreui/icons';
import {
	cisChevronLeft,
	cisSettings,
	cisSettingsAlt,
	cisGripDotsVertical,
	cilBoxAlt,
	cisFilePdf,
	cisFile,
	cilMinusCircle,
	cilMail,
	cisBookmark,
	cisGradient,
	cisStar,
	cilKey,
	cilNote,
} from '@coreui/icons-pro';
import { analytics } from './analytics';
import { marketing } from './marketing';
import { messages } from './messages';
import { overview } from './overview';
import { payments } from './payments';
import { referrals } from './referrals';
import { currency } from './currency';
import { support } from './support';
import { hourglass } from './hourglass';
import { info } from './info';
import { trophy } from './trophy';
import { positions } from './positions';
import { competition } from './competition';
import { gift } from './gift';
import { coins } from './coins';
import { onboarding } from './onboarding';
import { bonuses } from './bonuses';
import { map } from './map';
import { downtime } from './downtime';
import { warning } from './warning';
import { crown } from './crown';
import {instrument} from './instrument';
import {cilSettings2} from "./cil-settings"
import {voip_provider} from './voip-provider';
import {groups} from './groups';
import { email2 } from './email2';
import { cilClipboard } from './cil-clipboard';
import { cilGift1 } from './cil-gift-1';
import { cilGlobe1 } from './cil-globe-1';
import { cilWallpaper1 } from './cil-wallpaper-1';
import { cilClock1 } from './cil-clock-1';
import { cilCash1 } from './cil-cash-1';
import { cilChartline1 } from './cil-chartline-1';
import { cilBalancescale1 } from './cil-balancescale-1';
import { cilExchangeRates1 } from './cil-exchange-rates-1';
import { cilCurrencyConversion1 } from './cil-currency-conversion-1';
import { cilSwap1 } from './cil-swap-1';

export const icons = {
	analytics,
	marketing,
	messages,
	overview,
	payments,
	referrals,
	support,
	currency,
	hourglass,
	gift,
	info,
	trophy,
	competition,
	positions,
	coins,
	onboarding,
	bonuses,
	map,
	downtime,
	warning,
	crown,
	cilMoney,
	cilFlagAlt,
	cilAccountLogout,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilBoxAlt,
	cisGripDotsVertical,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDrop,
	cilDollar,
	cilBalanceScale,
	cilPlus,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFullscreen,
	cilFullscreenExit,
	cilGraph,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPrint,
	cilPuzzle,
	cilSave,
	cilScrubber,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilTags,
	cilTask,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilX,
	cilXCircle,
	cilWarning,
	cilBook,
	cilCheckAlt,
	cilMinusCircle,
	cilClone,
	cilEqualizer,
	cilMail,
	cilCash,
	cilBriefcase,
	cibHockeyapp,
	cilCopy,
	cilNote,
	cilFindInPage,
  instrument,
	cifAd,
	cifAe,
	cifAf,
	cifAg,
	cifAl,
	cifAm,
	cifAo,
	cifAr,
	cifAt,
	cifAu,
	cifAz,
	cifBa,
	cifBb,
	cifBd,
	cifBe,
	cifBf,
	cifBg,
	cifBh,
	cifBi,
	cifBj,
	cifBn,
	cifBo,
	cifBr,
	cifBs,
	cifBt,
	cifBw,
	cifBy,
	cifBz,
	cifCa,
	cifCd,
	cifCf,
	cifCg,
	cifCh,
	cifCi,
	cifCl,
	cifCm,
	cifCn,
	cifCo,
	cifCr,
	cifCu,
	cifCv,
	cifCy,
	cifCz,
	cifDe,
	cifDj,
	cifDk,
	cifDm,
	cifDo,
	cifDz,
	cifEc,
	cifEe,
	cifEg,
	cifEr,
	cifEs,
	cifEt,
	cifFi,
	cifFj,
	cifFm,
	cifFr,
	cifGa,
	cifGb,
	cifGd,
	cifGe,
	cifGh,
	cifGm,
	cifGn,
	cifGq,
	cifGr,
	cifGt,
	cifGw,
	cifGy,
	cifHk,
	cifHn,
	cifHr,
	cifHt,
	cifHu,
	cifId,
	cifIe,
	cifIl,
	cifIn,
	cifIq,
	cifIr,
	cifIs,
	cifIt,
	cifJm,
	cifJo,
	cifJp,
	cifKe,
	cifKg,
	cifKh,
	cifKi,
	cifKm,
	cifKn,
	cifKp,
	cifKr,
	cifKw,
	cifKz,
	cifLa,
	cifLb,
	cifLc,
	cifLi,
	cifLk,
	cifLr,
	cifLs,
	cifLt,
	cifLu,
	cifLv,
	cifLy,
	cifMa,
	cifMc,
	cifMd,
	cifMe,
	cifMg,
	cifMh,
	cifMk,
	cifMl,
	cifMm,
	cifMn,
	cifMr,
	cifMt,
	cifMu,
	cifMv,
	cifMw,
	cifMx,
	cifMy,
	cifMz,
	cifNa,
	cifNe,
	cifNg,
	cifNi,
	cifNl,
	cifNo,
	cifNp,
	cifNr,
	cifNu,
	cifNz,
	cifOm,
	cifPa,
	cifPe,
	cifPg,
	cifPh,
	cifPk,
	cifPl,
	cifPt,
	cifPw,
	cifPy,
	cifQa,
	cifRo,
	cifRs,
	cifRu,
	cifRw,
	cifSa,
	cifSb,
	cifSc,
	cifSd,
	cifSe,
	cifSg,
	cifSi,
	cifSk,
	cifSl,
	cifSm,
	cifSn,
	cifSo,
	cifSr,
	cifSs,
	cifSt,
	cifSv,
	cifSy,
	cifSz,
	cifTd,
	cifTg,
	cifTh,
	cifTj,
	cifTl,
	cifTm,
	cifTn,
	cifTo,
	cifTr,
	cifTt,
	cifTv,
	cifTw,
	cifTz,
	cifUa,
	cifUg,
	cifUs,
	cifUy,
	cifUz,
	cifVa,
	cifVc,
	cifVe,
	cifVn,
	cifWs,
	cifXk,
	cifYe,
	cifZa,
	cifZm,
	cifZw,
	cibSkype,
	cibFacebook,
	cibTwitter,
	cibLinkedin,
	cibFlickr,
	cibTumblr,
	cibXing,
	cibGithub,
	cibYoutube,
	cibDribbble,
	cibInstagram,
	cibPinterest,
	cibVk,
	cibYahoo,
	cibBehance,
	cibReddit,
	cibVimeo,
	cibCcMastercard,
	cibCcVisa,
	cibStripe,
	cibPaypal,
	cibGooglePay,
	cibCcAmex,
	cisChevronLeft,
	cisSettings,
	cisSettingsAlt,
	cisFilePdf,
	cisFile,
	cisBookmark,
	cisGradient,
	cisStar,
	cilKey,
  cilSettings2,
  voip_provider,
  groups,
  email2,
  cilClipboard,
  cilGift1,
  cilGlobe1,
  cilWallpaper1,
  cilClock1,
  cilCash1,
  cilChartline1,
  cilBalancescale1,
  cilExchangeRates1,
  cilCurrencyConversion1,
  cilSwap1
};
