import React, { ChangeEvent } from 'react';
import { CButton, CInput, CLabel, CSwitch } from '@coreui/react';
import _t from 'counterpart';
import { IFormGroupPathsProps } from './types';
import CIcon from '@coreui/icons-react';

export const FormGroupPathInput = (props: IFormGroupPathsProps) => {
	const { index, onRemovePath, onChange, path } = props;

	const onInputSymbolChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(index, {
			symbol: e.target.value,
			reverse: path.reverse,
		});
	};

	const toggleReverse = () => {
		const reverseValue = !path.reverse;
		onChange(index, {
			symbol: path.symbol,
			reverse: reverseValue,
		});
	};

	return (
		<div className="d-flex flex-row align-items-end mt-2">
			<div className="mr-2">
				{index === 0 && <CLabel htmlFor={`path-symbol-${index}`}>{_t('currencyConversions.symbol')}</CLabel>}
				<CInput
					type="text"
					id={`path-symbol-${index}`}
					name={`path-symbol-${index}`}
					placeholder="EURUSD"
					label="test"
					value={path.symbol}
					onChange={onInputSymbolChange}
					className="mr-2 border-fix"
				/>
			</div>
			<div className="mr-2 flex-grow-1">
				{index === 0 && <CLabel className="mb-3">{_t('currencyConversions.reverse')}</CLabel>}
				<br></br>
				<CSwitch color="primary" checked={path.reverse} onChange={toggleReverse} />
			</div>
			<CButton className="instrument-button" color="danger" onClick={() => onRemovePath(index)}>
				<CIcon name="cil-trash" />
			</CButton>
		</div>
	);
};
