import { stringify } from 'qs';
import { IMarketDataResponse } from './ImportTypes';
import client from '../apiClient';

export async function loadMarketData(
	symbol: string,
	start: string,
	end: string,
	resolution: string,
	countback: string | undefined
): Promise<IMarketDataResponse> {
  const qs = stringify({ start, end, resolution, countback });
	const response = await client.get(`${process.env.REACT_APP_HISTORY_ROOT}/candles/${symbol}?${qs}`);
	return response.data;
}
