import React, { useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CModalTitle,
	CButton,
} from '@coreui/react';
import _t from 'counterpart';
import { findErrorFromValidation } from '../helpers';
import ButtonWithLoader from './ButtonWithLoader';
import Textarea from 'react-expanding-textarea';

interface IProps {
	subject: string;
	setSubject: (s: string) => void;
	body: string;
	setBody: (s: string) => void;
	show: boolean;
	onClose: () => void;
	onCloseDiscardChanges: () => void;
	customer: any;
	errors: any;
	isLoading: boolean;
	onSend: () => void;
}

const EmailCustomerModal = ({
	show,
	onClose,
	onCloseDiscardChanges,
	customer,
	errors,
	isLoading,
	subject,
	setSubject,
	body,
	setBody,
	onSend,
}: IProps) => {
	const [discardChangesModalShow, setDiscardChangesModalShow] = useState<boolean>(false);

	const close = () => {
		setDiscardChangesModalShow(false);
		setSubject('');
		setBody('');
		onClose();
	};

	const handleClose = () => {
		if (subject !== '' || body !== '') {
			setDiscardChangesModalShow(true);
		}
		onClose();
	};

	const handleCloseDiscardChanges = () => {
		setDiscardChangesModalShow(false);
		onCloseDiscardChanges();
	};

	const handleSubjectChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setSubject(target.value);
	};

	const handleBodyChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setBody(target.value);
	};

	const sendEmail = (e: React.FormEvent) => {
		e.preventDefault();
		onSend();
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(errors, paramName);
	};

	return (
		<>
			<CModal show={show} onClose={handleClose}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('customer.send-email-to', { customer: customer.name })}</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={sendEmail}>
					<CModalBody>
						<CFormGroup>
							<CLabel htmlFor="subject">{_t('emails.subject')}</CLabel>
							<CInput type="text" id="subject" name="subject" value={subject} onChange={handleSubjectChange} />
						</CFormGroup>
						{findError('subject') && <CLabel className="text-danger">{findError('subject')}</CLabel>}
						<CFormGroup>
							<CLabel htmlFor="body">{_t('emails.body')}</CLabel>
							<Textarea
								className="w-100 mt-2 comments-input"
								onChange={handleBodyChange}
								value={body}
								placeholder={_t('customer.email-placeholder')}
								rows={3}
							/>
						</CFormGroup>
						{findError('body') && <CLabel className="text-danger">{findError('body')}</CLabel>}
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('action.send')}
							className="mr-2"
						/>
						<CButton color="light" variant="outline" onClick={handleClose} disabled={isLoading}>
							{_t('global.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
			<CModal show={discardChangesModalShow} size="sm" closeOnBackdrop={false}>
				<CModalHeader className="d-flex flex-column">
					<CModalTitle>{_t('customer.discard-changes')}</CModalTitle>
				</CModalHeader>
				<CModalBody />
				<CModalFooter className="modal-footer--alternative">
					<CButton color="primary" onClick={close}>
						{_t('global.confirm')}
					</CButton>
					<CButton color="light" variant="outline" onClick={handleCloseDiscardChanges}>
						{_t('global.cancel')}
					</CButton>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default React.memo(EmailCustomerModal);
