import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { getAffiliateCommissionLedger } from '../../services/BackendService';
import { useQuery } from 'react-query';
import { extractErrorMessage, formatCurrency, formatDate } from '../../helpers';
import { useParams } from 'react-router';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { CRow, CCol, CCard, CCardBody, CLink, CButton } from '@coreui/react';
import Description from '../../components/Description';
import toast from 'react-hot-toast';
import EditCommissionLedgerModal from './EditCommissionLedgerModal';

const CommissionLedgerPage = () => {
	const { id } = useParams<{ id: string }>();
	const [showEditLedger, setShowEditLedger] = useState<boolean>(false);

	const ledgerQuery = useQuery(['commission-ledger-page', id], () => getAffiliateCommissionLedger(id), {
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			toast.error(msg);
		},
	});
	const { isLoading, isError, data, refetch } = ledgerQuery;

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const {
		introducer,
		referral,
		commissionType,
		actionType,
		actionDetails,
		commissionAmount,
		correctedCommissionAmount,
		currency,
		payoutAt,
		adminComments,
	} = data;

	const hideEditLedgerModal = () => {
		setShowEditLedger(false);
	};

	const showEditLedgerModal = () => {
		setShowEditLedger(true);
	};

	return (
		<PageLayout
			title={_t('commissions.affiliate-commission-ledger')}
			titleAppend={
				<div className="d-flex justify-content-between float-right mb-2">
					{!payoutAt && (
						<CButton
							color="primary"
							type="button"
							onClick={showEditLedgerModal}
							title="Edit commission ledger"
							size="md"
						>
							{_t('action.edit')}
						</CButton>
					)}
				</div>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('commissions.introducer')}>
									<CLink href={`/customers/${introducer.id}`}>{introducer.name}</CLink>
								</Description>
								<Description title={_t('commissions.referral')}>
									<CLink href={`/customers/${referral.id}`}>{referral.name}</CLink>
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.commission-type')}>{commissionType}</Description>
								<Description title={_t('commissions.action-type')}>{actionType}</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.action-details')}>{actionDetails}</Description>
								<Description title={_t('commissions.commission-amount')}>
									{formatCurrency(commissionAmount, currency)}
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.corrected-amount')}>
									{correctedCommissionAmount ? formatCurrency(correctedCommissionAmount, currency) : '-'}
								</Description>
								<Description title={_t('commissions.cpa-currency')}>{currency}</Description>
							</CRow>
							<CRow>
								<Description title={_t('commissions.payout-at')}>
									{payoutAt ? formatDate(new Date(payoutAt)) : '-'}
								</Description>
								<Description title={_t('commissions.admin-comments')}>{adminComments}</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<EditCommissionLedgerModal ledger={data} show={showEditLedger} hidePanel={hideEditLedgerModal} />
		</PageLayout>
	);
};

export default CommissionLedgerPage;
