import React, { useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CDataTable, CLink, CRow } from '@coreui/react';
import { useHistory, useParams } from 'react-router';
import { deleteCommissionModel, getCommissionModel } from '../../services/BackendService';
import { useMutation, useQuery } from 'react-query';
import BooleanBadge from '../../components/BooleanBadge';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { ICommissionModel, ICommissionModelLevel } from './types';
import ConfirmationModal from '../../components/ConfirmationModal';
import { extractErrorMessage } from '../../helpers';
import toast from 'react-hot-toast';

const CommissionModelPage = () => {
	const { id } = useParams<{ id: string }>();
	const [toDeleteModel, setToDeleteModel] = useState<ICommissionModel | null>(null);

	const history = useHistory();

	const commissionModelQuery = useQuery(['commission-model', id], () => getCommissionModel(id));
	const { isLoading, isError, data } = commissionModelQuery;

	const linkToUpdatePage = () => {
		history.push(`/commission_model_edit?edit=${id}`);
	};

	const deleteCommissionModelMutation = useMutation((id: string) => deleteCommissionModel(id), {
		onError: (e) => {
			toast.error(extractErrorMessage(e));
		},
		onSuccess: () => {
			toast.success(_t('commissions.commission-model-deleted-successfully'));
			history.push('/commission_models');
		},
	});

	const invokeDeleteMutation = () => {
		if (toDeleteModel?.id) {
			deleteCommissionModelMutation.mutate(toDeleteModel.id?.toString());
		}
	};

	const hideDeleteModel = () => {
		setToDeleteModel(null);
	};

	const fields = useMemo(
		() => [
			{ key: 'level', label: _t('global.level') },
			{ key: 'cpaAmount', label: _t('commissions.cost-per-action') },
			{ key: 'cpaCurrency', label: _t('commissions.cpa-currency') },
			{ key: 'volumeCommissionAmount', label: _t('commissions.volume-commission-amount') },
			{ key: 'volumeCommissionCurrency', label: _t('commissions.volume-commission-currency') },
			{ key: 'profitSharePercentage', label: _t('commissions.profit-share-percentage') },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			cpaAmount: ({ cpaAmount }: ICommissionModelLevel) => <td>{cpaAmount ? cpaAmount : '-'}</td>,
			cpaCurrency: ({ cpaCurrency }: ICommissionModelLevel) => <td>{cpaCurrency ? cpaCurrency : '-'}</td>,
			volumeCommissionAmount: ({ volumeCommissionAmount }: ICommissionModelLevel) => (
				<td>{volumeCommissionAmount ? volumeCommissionAmount : '-'}</td>
			),
			volumeCommissionCurrency: ({ volumeCommissionCurrency }: ICommissionModelLevel) => (
				<td>{volumeCommissionCurrency ? volumeCommissionCurrency : '-'}</td>
			),
			profitSharePercentage: ({ profitSharePercentage }: ICommissionModelLevel) => (
				<td>{profitSharePercentage ? profitSharePercentage : '-'}</td>
			),
		}),
		[]
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={commissionModelQuery.refetch} />;
	}

	const { name, subCommissionModel, greyLabelingEnabled, commissionModelLevels } = commissionModelQuery.data;

	return (
		<PageLayout
			title={_t('commissions.commission-model')}
			titleAppend={
				<div className="float-right">
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={linkToUpdatePage}
						title="Edit instrument"
						size="sm"
					>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton
						className="mr-2"
						size="sm"
						color="danger"
						onClick={() => setToDeleteModel(commissionModelQuery.data)}
						disabled={!commissionModelQuery.isSuccess}
					>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</div>
			}
		>
			<CRow>
				<CCol md={6}>
					<CCard className="card-overflow-visible">
						<CCardHeader>
							<CCardTitle>{name}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('commissions.sub-commission-scheme')}:</dt>
										<dd>
											{subCommissionModel ? (
												<CLink href={`/commissions_models/${subCommissionModel.id}`}>{subCommissionModel.name}</CLink>
											) : (
												'-'
											)}
										</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('commissions.grey-labeling-enabled')}:</dt>
										<dd>
											<BooleanBadge value={greyLabelingEnabled} />
										</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('commissions.levels')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CDataTable items={commissionModelLevels} fields={fields} scopedSlots={scopedSlots} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={toDeleteModel?.id?.toString() || ''}
				show={Boolean(toDeleteModel)}
				onConfirm={invokeDeleteMutation}
				title={_t('commissions.delete-commission-model')}
				error={null}
				hidePanel={hideDeleteModel}
				buttonColor="danger"
				withMutation={false}
			/>
		</PageLayout>
	);
};

export default CommissionModelPage;
