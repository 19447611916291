import React, { useCallback, useMemo, useState } from 'react';
import { clearOpenPositionsFilters, setOpenPositionsFilters } from '../../actions';
import { useAppDispatch, useAppSelector, useLiveOpenPositionUpdates } from '../../helpers/customHooks';
import {
	IOpenPositionsFilters,
	IOpenPositionsFilters as Filters,
	IPositionsFilters,
} from '../../reducers/filtersReducer';
import { positionsFiltersToDTO } from './AllPositionsPage';
import { StringParam, useQueryParam } from 'use-query-params';
import { useQuery } from 'react-query';
import { loadPositions } from '../../services/BackendService';
import { IPosition } from './position';
import { getFiltersCount } from '../../helpers';
import _t from 'counterpart';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import SearchFilter from '../../components/SearchFilter';
import PositionsFilter from './PositionsFilter';
import OpenPositionRow from '../../components/OpenPositionRow';
import CenteredSpinner from '../../components/CenteredSpinner';
import { cilBan } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

export const openPositionsFiltersToDTO = (filters: Filters) => {
	return {
		...positionsFiltersToDTO({ ...filters, statuses: [] }),
		statuses: ['open'],
	};
};

const OpenPositionsPage = React.memo(() => {
	const dispatch = useAppDispatch();
	useLiveOpenPositionUpdates();
	const openPositionsFilters = useAppSelector((state) => state.filters.openPositionsFilters);

	const clearFilters = useCallback(() => {
		dispatch(clearOpenPositionsFilters());
	}, [dispatch]);

	const filtersDTO = useMemo(() => openPositionsFiltersToDTO(openPositionsFilters), [openPositionsFilters]);

	const onFiltersUpdated = useCallback(
		(filters: Partial<IOpenPositionsFilters>) => {
			dispatch(setOpenPositionsFilters(filters));
		},
		[dispatch]
	);

	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);

	const { data, isLoading, refetch, isError } = useQuery<any>(['open-positions', filter, filtersDTO], () =>
		loadPositions(null, null, filter || '', filtersDTO)
	);

	const setTableFilterValue = (search: string) => {
		setFilter(search, 'replaceIn');
	};

	const onFiltersChange = (filters: Partial<IPositionsFilters | IOpenPositionsFilters>) => {
		onFiltersUpdated(filters);
	};

	const filtersCount = getFiltersCount(openPositionsFilters);

	const tableFields = useMemo(
		() => [
			{
				key: 'id',
				label: 'ID',
				sorter: false,
			},
			{
				key: 'side',
				label: _t('positions.side'),
			},
			{
				key: 'symbol',
				label: _t('positions.symbol'),
			},
			{
				key: 'accountId',
				label: _t('positions.account-id'),
				width: 80,
			},
			{
				key: 'openedAt',
				label: _t('positions.opened-at'),
			},
			{
				key: 'lots',
				label: _t('positions.lots'),
			},
			{
				key: 'profit',
				label: _t('positions.profit'),
				width: 150,
			},
			{
				key: 'swap',
				label: _t('positions.swap'),
			},
			{
				key: 'status',
				label: _t('positions.status'),
			},
		],
		[]
	);

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('positions.open-title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={setTableFilterValue} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={clearFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
									</div>
								</div>
								<PositionsFilter show={showFilters} onFiltersChange={onFiltersChange} filters={openPositionsFilters} />
							</div>
						</CCardHeader>
						<CCardBody>
							<div className="position-relative table-responsive">
								<table className="table table-striped table-hover table-clickable">
									<thead>
										<tr>
											{tableFields.map((field) => (
												<th
													style={field.width ? { minWidth: `${field.width}px` } : {}}
													key={field.key}
													className="position-relative pr-4"
												>
													{field.label}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{isLoading && (
											<tr>
												<td colSpan={9}>
													<div className="my-5">
														<CenteredSpinner grow size="lg" color="primary" />
													</div>
												</td>
											</tr>
										)}
										{(data?.positions ?? []).length === 0 && !isLoading && (
											<tr>
												<td colSpan={9}>
													<div className="text-center my-5">
														<h2>
															No items &nbsp;
															<CIcon width="30" name="cilBan" content={cilBan} className="text-danger mb-2" />
														</h2>
													</div>
												</td>
											</tr>
										)}
										{(data?.positions ?? []).map((position: IPosition) => (
											<OpenPositionRow key={position.id} position={position} />
										))}
									</tbody>
								</table>
							</div>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
});

OpenPositionsPage.displayName = 'OpenPositionsPage';

export default OpenPositionsPage;
