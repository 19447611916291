import { CButton, CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import React, { useCallback } from 'react';
import CustomSelect from '../../components/CustomSelect';
import _t from 'counterpart';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { shallowEqual } from 'react-redux';
import { setAuditLogsFilters } from '../../actions';
import { IAuditLogsFilters, IDateRange } from '../../reducers/filtersReducer';
import { AdminActivityAction, AffectedObjectType } from './types';
import DateRangePicker from '../../components/DateRangePicker';
import { UserRole } from '../../reducers/userReducer';

interface IProps {
	show: boolean;
	onFiltersChanged: () => void;
}

const AuditLogsFilter = ({ show, onFiltersChanged }: IProps) => {
	const activeFilters = useAppSelector((state) => state.filters.auditLogsFilters, shallowEqual);
	const dispatch = useAppDispatch();
	const setFilters = useCallback(
		(filters: Partial<IAuditLogsFilters>) => {
			dispatch(setAuditLogsFilters(filters));
			onFiltersChanged();
		},
		[dispatch, onFiltersChanged]
	);

	const actionsOptions = Object.values(AdminActivityAction).map((s) => {
		return { value: s, label: s };
	});

	const userRolesOption = [UserRole.Admin, UserRole.Sales].map((u) => {
		return { value: u, label: u };
	});

	const actionTypesOption = Object.values(AffectedObjectType).map((o) => {
		return { value: o, label: o };
	});

	const handleActionChanged = (value: any) => {
		setFilters({
			selectedActions: value,
		});
	};

	const resetActions = () => {
		setFilters({
			selectedActions: [],
		});
	};

	const handleCreatedAtRange = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({ createdAt: range });
		}
	};

	const resetCreatedAtRange = () => {
		setFilters({ createdAt: undefined });
	};

	const handleActedAsChange = (value: any) => {
		setFilters({ actedAs: value });
	};

	const resetActedAs = () => {
		setFilters({ actedAs: undefined });
	};

	const handleTypeChanged = (value: any) => {
		setFilters({
			selectedTypes: value,
		});
	};

	const resetTypes = () => {
		setFilters({
			selectedTypes: [],
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('action.action')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetActions}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedActions ?? null}
							options={actionsOptions}
							onChange={handleActionChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<CLabel className="m-0">{_t('global.created-at')}</CLabel>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetCreatedAtRange}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<DateRangePicker
							initialStartDate={activeFilters.createdAt?.startDate ?? null}
							initialEndDate={activeFilters.createdAt?.endDate ?? null}
							onRangeChanged={handleCreatedAtRange}
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('audit-logs.acted-as')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetActedAs}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.actedAs ?? null}
							options={userRolesOption}
							onChange={handleActedAsChange}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('audit-logs.affected-type')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetTypes}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.selectedTypes ?? null}
							options={actionTypesOption}
							onChange={handleTypeChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default React.memo(AuditLogsFilter);
