import { extractErrorMessage } from '../../helpers';
import { useQuery } from 'react-query';
import { loadCustomerWallets } from '../../services/BackendService';
import toast from 'react-hot-toast';

export const useCustomerWallets = (customerId: string) => {
	const walletsQuery = useQuery(
		['customer-wallets', customerId],
		async () => {
			const data = await loadCustomerWallets(customerId);
			return data;
		},
		{
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			},
		}
	);
	return walletsQuery;
};
