import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	CButton,
	CModalHeader,
	CTextarea,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CInput,
	CModalTitle,
	CAlert,
	CFormGroup,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { formatCurrency } from '../../helpers';
import { useQuery, useMutation } from 'react-query';
import _t from 'counterpart';
import {
	loadCustomerTradingBalance,
	loadHasOpenPositions,
	requestApproveWithdrawal,
	requestRejectWithdrawal,
	getWithdrawalMethods,
} from '../../services/BackendService';
import TransferModal from './TransferModal';
import toast from 'react-hot-toast';
import { ITransaction } from './types';

interface IProps {
	show: boolean;
	onClose: (refetchData: boolean) => void;
	withdrawal: ITransaction;
	actionType: string;
}

const UpdateWithdrawalStateModal = ({ show, onClose, actionType, withdrawal }: IProps) => {
	const [amount, setAmount] = useState<string>(withdrawal.amount.toFixed(2));
	const [comment, setComment] = useState<string>('');
	const [transferModalShow, transferModalShowToggle] = useState<boolean>(false);

	const dispatch = useDispatch();

	const openUserHasPositionsRequest = useQuery<any, Error>(['user-has-open-positions'], async () => {
		const data = await loadHasOpenPositions(withdrawal.wallet.toString());
		return data;
	});

	const userBalanceRequest = useQuery<any, Error>(['wallet-balance', withdrawal.customer.id], async () => {
		const data = await loadCustomerTradingBalance(withdrawal.customer.id);
		return data;
	});

	const countryWithdrawalMethodsRequest = useQuery<any, Error>(
		['country-withdrawal-methods', withdrawal.customer.id],
		async () => {
			const data = await getWithdrawalMethods(withdrawal.customer.country);
			return data;
		}
	);

	let hasMethods = false;
	if (countryWithdrawalMethodsRequest.data) {
		const { methods } = countryWithdrawalMethodsRequest.data;
		if (methods && methods.length > 0) {
			hasMethods = true;
		}
	}

	const approveWithdrawalStateMutation = useMutation(
		() => requestApproveWithdrawal(withdrawal.id, comment, Number(amount!)),
		{
			onSuccess: () => {
				onClose(true);
			},
		}
	);

	const rejectWithdrawalStateMutation = useMutation(() => requestRejectWithdrawal(withdrawal.id, comment), {
		onSuccess: () => {
			onClose(true);
		},
	});

	const withdrawalStatusChangeAction = () => {
		if (actionType === 'approve') {
			approveWithdrawalStateMutation.mutate();
		}
		if (actionType === 'reject') {
			rejectWithdrawalStateMutation.mutate();
		}
	};

	const onTransferSuccess = () => {
		toast.success(_t('transactions.successful-transfer'));
		transferModalShowToggle(false);
		onClose(true);
	};

	const kycBadgeState: any = {
		APPROVED: {
			name: 'cil-check-circle',
			class: 'text-success',
		},
		REVOKED: {
			name: 'cil-x-circle',
			class: 'text-danger',
		},
		PENDING: {
			name: 'cil-x-circle',
			class: 'text-danger',
		},
	};

	const buttonMessage: any = {
		approve: _t('action.approve'),
		reject: _t('action.reject'),
	};

	const buttonStyle: any = {
		approve: 'success',
		reject: 'danger',
	};

	const hasOpenPositions = openUserHasPositionsRequest.isError ? false : openUserHasPositionsRequest.data;

	return (
		<>
			<div className={transferModalShow ? 'd-none' : ''}>
				<CModal show={show} onClose={() => onClose(false)} closeOnBackdrop={false}>
					<CModalHeader closeButton>
						<CModalTitle>
							{actionType === 'approve' ? _t('withdrawals-table.popup.approve') : _t('withdrawals-table.popup.reject')}
						</CModalTitle>
					</CModalHeader>
					<CModalBody>
						{hasOpenPositions > 0 && <CAlert color="danger">{_t('withdrawals-table.popup.has-open-positions')}</CAlert>}
						<dl>
							<dt>{_t('global.customer')}</dt>
							<dd>
								{withdrawal.customer.name}&nbsp;
								{withdrawal?.customer?.kycStatus && (
									<CIcon
										className={kycBadgeState[withdrawal.customer.kycStatus].class}
										name={kycBadgeState[withdrawal.customer.kycStatus].name}
									/>
								)}
							</dd>
							<dt>{_t('customers.columns.accountId')}</dt>
							<dd>{withdrawal?.wallet}</dd>
							<dt>{_t('global.customer-balance')}</dt>
							<dd>
								{userBalanceRequest.isLoading && _t.translate('global.loading')}
								{!userBalanceRequest.isLoading &&
									userBalanceRequest.isSuccess &&
									formatCurrency(userBalanceRequest.data.balance, userBalanceRequest.data.currency)}
								{userBalanceRequest.isError && (
									<CLabel className="text-danger">{_t('transactions.failed-to-fetch-balance')}</CLabel>
								)}
							</dd>
							<hr />
							<CFormGroup>
								<CLabel htmlFor="nf-amount">{_t('add-transaction-modal.amount-in-usd')}</CLabel>
								<CInputGroup>
									<CInputGroupPrepend>
										<CInputGroupText>$</CInputGroupText>
									</CInputGroupPrepend>
									<CInput
										type="number"
										id="nf-amount"
										name="nf-amount"
										max={userBalanceRequest.data}
										placeholder={withdrawal.amount.toString()}
										value={amount}
										onChange={(e: any) => setAmount(e.target.value)}
									/>
								</CInputGroup>
							</CFormGroup>
							<CFormGroup>
								<CLabel htmlFor="nf-comment">{_t('global.comment')}</CLabel>
								<CTextarea
									className="custom-text-area"
									value={comment}
									onChange={(e: any) => setComment(e.target.value)}
								/>
							</CFormGroup>
						</dl>
					</CModalBody>
					<CModalFooter className="justify-content-center">
						{actionType === 'approve' && hasMethods && (
							<CButton
								onClick={() => transferModalShowToggle(true)}
								size="md"
								className="mr-1"
								color="primary"
								disabled={!hasMethods}
								title={!hasMethods ? _t('transactions.transfer-disabled-no-methods') : undefined}
							>
								{_t('action.transfer')}
							</CButton>
						)}
						<CButton onClick={withdrawalStatusChangeAction} size="md" className="mr-1" color={buttonStyle[actionType]}>
							{buttonMessage[actionType]}
						</CButton>
					</CModalFooter>
				</CModal>
			</div>
			{transferModalShow && (
				<TransferModal
					methods={countryWithdrawalMethodsRequest?.data?.methods}
					show={transferModalShow}
					onClose={() => transferModalShowToggle(false)}
					withdrawal={withdrawal}
					initialAmount={Number(amount!)}
					onSuccessClose={onTransferSuccess}
				/>
			)}
		</>
	);
};

export default UpdateWithdrawalStateModal;
