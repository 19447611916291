import React, { useState } from 'react';
import { CRow, CCol, CInput, CLabel, CFormGroup, CInputGroup } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { useGetSetting } from './hooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updateSendgrid } from '../../services/BackendService';
import toast from 'react-hot-toast';

interface IParams {
	sender: string;
	staffEmail: string;
	apiKey: string;
}

interface IProps {
	onChange: () => void;
}

const SendgridIntegration = ({ onChange }: IProps) => {
	const [apiKey, setApiKey] = useState<string>('');
	const [emailSender, setEmailSender] = useState<string>('');
	const [staffEmail, setStaffEmail] = useState<string>('');

	const onError = (e: any) => {
		toast.error(extractErrorMessage(e));
	};

	const apiKeyQuery = useGetSetting('SENDGRID_API_KEY', 'sengrid-api-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setApiKey(value);
		},
		onError,
	});

	const emailSenderQuery = useGetSetting('EMAIL_TRANSACTIONAL_SENDER', 'email-sender', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setEmailSender(value);
		},
		onError,
	});

	const staffEmailQuery = useGetSetting('STAFF_NOTIFICATION_EMAIL', 'staff-email', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setStaffEmail(value);
		},
		onError,
	});

	const updateSendgridMutation = useMutation(
		({ sender, staffEmail, apiKey }: IParams) => updateSendgrid(sender, staffEmail, apiKey),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(extractErrorMessage(msg));
				}
			},
			onSuccess: () => {
				toast.success(_t('integrations.sendgrid-configured'));
				onChange();
			},
		}
	);

	const setApiKeyFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setApiKey(e.target.value);
	};

	const setSenderFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmailSender(e.target.value);
	};

	const setStaffFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStaffEmail(e.target.value);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateSendgridMutation.error, paramName);
		return error;
	};

	const callUpdateSendgridMutation = () => {
		updateSendgridMutation.mutate({ apiKey, sender: emailSender, staffEmail });
	};

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="apiKey">{_t(`integrations.sendgrid-api-key`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="apiKey"
							name="apiKey"
							value={apiKey}
							onChange={setApiKeyFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{apiKeyQuery.error && <CLabel className="text-danger">{_t('integrations.sendgrid-api-key-failed')}</CLabel>}
					{findError('apiKey') && <CLabel className="text-danger">{findError('apiKey')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.email-sender`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="sender"
							name="sender"
							value={emailSender}
							onChange={setSenderFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{emailSenderQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('sender') && <CLabel className="text-danger">{findError('sender')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.staff-email`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="staffEmail"
							name="staffEmail"
							value={staffEmail}
							onChange={setStaffFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{staffEmailQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('staffEmail') && <CLabel className="text-danger">{findError('staffEmail')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={updateSendgridMutation.isLoading}
					onClick={callUpdateSendgridMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={apiKey === '' || emailSender === ''}
				/>
			</CCol>
		</CRow>
	);
};

export default SendgridIntegration;
