import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CDataTable, CButton, CCardHeader } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useMutation, useQuery } from 'react-query';
import { deleteGroup, loadGroups } from '../../services/BackendService';
import { IGroup } from './types';
import CreateGroupModal from './CreateGroupModal';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import { useHistory } from 'react-router';
import { extractErrorMessage } from '../../helpers';
import ConfirmationModal from '../../components/ConfirmationModal';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../helpers/customHooks';
import { UserRole } from '../../reducers/userReducer';

const GroupsPage = () => {
	const [addGroupShow, setAddGroupShow] = useState<boolean>(false);
	const [toDeleteGroup, setToDeleteGroup] = useState<IGroup | null>(null);

	const loadGroupsQuery = useQuery(['groups'], () => loadGroups());
	const history = useHistory();

	const deleteGroupMutation = useMutation((id: number) => deleteGroup(id), {
		onSuccess: () => {
			toast.success(_t('groups.group-successfully-deleted'));
			setToDeleteGroup(null);
			loadGroupsQuery.refetch();
		},
		onError: (e: any) => {
			toast.error(extractErrorMessage(e));
		},
	});

	const closeAddGroup = (refetchOnClose?: boolean) => {
		setAddGroupShow(false);
		if (refetchOnClose) {
			loadGroupsQuery.refetch();
		}
	};

	const onGroupEditClicked = (group: IGroup) => {
		history.push(`/groups/${encodeURIComponent(group.id)}`);
	};

	const onErrorRetry = () => {
		loadGroupsQuery.refetch();
	};

	const openDeleteInstrumentModal = (group: IGroup) => {
		setToDeleteGroup(group);
	};

	const closeDeleteInstrumentModal = () => {
		setToDeleteGroup(null);
	};

	const invokeDeleteMutation = () => {
		deleteGroupMutation.mutate(toDeleteGroup!.id);
	};

	const state = useAppSelector((state) => state.user);

	if (loadGroupsQuery.isLoading || loadGroupsQuery.isIdle) {
		return <Loading />;
	}

	if (loadGroupsQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('groups.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								{state.roles.includes(UserRole.Admin) && (
									<CButton className="mr-2 header-button-add" onClick={() => setAddGroupShow(true)} />
								)}
							</div>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								fields={[
									{ key: 'name', label: _t('groups.name'), sorter: true },
									{ key: 'leverage', label: _t('groups.leverage'), sorter: true },
									{ key: 'actions', label: _t('global.actions'), sorter: false },
								]}
								scopedSlots={{
									name: (group: IGroup) => <td>{group.name}</td>,
									leverage: (group: IGroup) => <td>{group.leverage}</td>,
									actions: (group: IGroup) => (
										<td>
											<CButton size="sm" className="mr-1" color="primary" onClick={() => onGroupEditClicked(group)}>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											{state.roles.includes(UserRole.Admin) && (
												<CButton
													className="mr-2"
													size="sm"
													color="danger"
													onClick={() => openDeleteInstrumentModal(group)}
												>
													<CIcon name="cil-trash" size="sm" />
												</CButton>
											)}
										</td>
									),
								}}
								items={loadGroupsQuery.data || []}
								loading={loadGroupsQuery.isLoading}
								sorterValue={{ column: 'name', asc: false }}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CreateGroupModal show={addGroupShow} hideAddGroup={closeAddGroup} />
			<ConfirmationModal
				id={toDeleteGroup?.id.toString() ?? ''}
				show={toDeleteGroup !== null}
				onConfirm={invokeDeleteMutation}
				title={_t('groups.delete-group')}
				error=""
				buttonColor="danger"
				hidePanel={closeDeleteInstrumentModal}
				withMutation={false}
				isLoading={deleteGroupMutation.isLoading}
			/>
		</PageLayout>
	);
};

export default GroupsPage;
