import React from 'react';
import _t from 'counterpart';
import moment from 'moment';
import { CTooltip } from '@coreui/react';
import styles from './ActvityDot.module.scss';

interface IProps {
	lastActivity: string | null;
}

const ActivityDot = ({ lastActivity }: IProps) => {
	const locale = _t.getLocale();

	let online = false;

	if (lastActivity !== null) {
		const lastActive = Date.parse(lastActivity);
		const now = Date.parse(new Date().toString());
		const diff = (now - lastActive) / (1000 * 60);

		if (diff < 2) {
			online = true;
		}
	}

	return (
		<CTooltip
			content={lastActivity ? <span>{moment(lastActivity).locale(locale).fromNow()}</span> : _t('global.no-activity')}
			placement="right"
		>
			<div className={online ? styles.dotActive : styles.dotInactive} />
		</CTooltip>
	);
};

export default ActivityDot;
