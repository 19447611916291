import React from 'react';
import { CButton, CSpinner } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';

interface Props {
	updatingEmail: boolean;
	isChanged: boolean;
	sendEmail: Function;
}

export const EmailEditButtons = ({ updatingEmail, isChanged, sendEmail }: Props) => {
	return (
		<>
			{updatingEmail && (
				<div className="d-flex align-items-center">
					<CSpinner color="primary" className="mr-2" />
					{_t('global.loading')}
				</div>
			)}
			{!updatingEmail && (
				<CButton
					className="send-test-email ml-2"
					color="light"
					variant="outline"
					onClick={sendEmail}
					disabled={isChanged}
				>
					{_t.translate('emails.send-test-email')}
				</CButton>
			)}
		</>
	);
};

interface SaveButtonProps {
	render: boolean;
	disabled: boolean;
	onClick: Function;
}

export const EmailSaveButton = ({ render, disabled, onClick }: SaveButtonProps) => {
	return (
		<>
			{render && (
				<CButton color="primary" onClick={onClick} disabled={disabled}>
					{_t.translate('action.save')}
				</CButton>
			)}
		</>
	);
};

export const WarningBox = ({ warnings }: { warnings: Array<any> }) => {
	return (
		<>
			{warnings.length > 0 && (
				<div className="email-edit-error bg-warning mb-2 d-flex justify-content-between align-center">
					<div className="d-flex align-items-center">
						<CIcon name="cil-warning" size="xl" className="mx-2" />
						{`[${warnings[0].line}] ${warnings[0].message}`}
					</div>
					<div>
						{warnings.length > 1 && (
							<div className="email-edit-warnings-counter">
								<span>+{warnings.length}</span>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
