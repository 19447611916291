import React, { useState } from 'react';
import _t from 'counterpart';
import { CCard, CCardHeader, CCardTitle, CCardBody, CFormGroup, CCol, CRow } from '@coreui/react';
import CustomSelect from '../../components/CustomSelect';
import { useGetSetting, useSetSetting } from './hooks';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import SumSubConfiguration from './SumSubConfiguration';
import toast from 'react-hot-toast';

const SumSubIntegration = () => {
	const providersOptions = [
		{ label: _t('global.disabled'), value: '' },
		{ label: _t('global.enabled'), value: 'enabled' },
	];

	const [provider, setProvider] = useState<{ label: string; value: string }>(providersOptions[0]);

	const settingsMutation = useSetSetting();

	const sumsubEnabled = useGetSetting('SUMSUB_ENABLED', 'sumsub-enabled', {
		refetchOnWindowFocus: false,
		onSuccess: (value: boolean) => {
			if (value === true) {
				setProvider(providersOptions[1]);
			} else {
				setProvider(providersOptions[0]);
			}
		},
	});

	const handleProviderChanged = (provider: any) => {
		setProvider(provider);
	};

	const disableSumsubMutationCall = () => {
		settingsMutation.mutate(
			{ key: 'SUMSUB_ENABLED', value: '' },
			{
				onSuccess: () => {
					toast.success(_t('integrations.sumsub-disabled'));
					sumsubEnabled.refetch();
				},
			}
		);
	};

	const isLoading = settingsMutation.isLoading || sumsubEnabled.isFetching;
	const isError = Boolean(sumsubEnabled.error) && !isLoading;

	return (
		<CCol md={12}>
			<CCard>
				<CCardHeader>
					<CCardTitle>{'Sumsub'}</CCardTitle>
					<p>{_t('integrations.sumsub-config-description')}</p>
				</CCardHeader>
				<CCardBody>
					<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={sumsubEnabled.refetch}>
						<CFormGroup>
							<CustomSelect
								value={provider}
								onChange={handleProviderChanged}
								options={providersOptions}
								menuPortalTarget={document.body}
							/>
						</CFormGroup>
						{provider.value === 'enabled' && <SumSubConfiguration />}
						{provider.value === '' && (
							<CRow>
								<CCol md={12} className="d-flex justify-content-end">
									<ButtonWithLoader
										isLoading={settingsMutation.isLoading}
										onClick={disableSumsubMutationCall}
										buttonColor="primary"
										spinnerColor="secondary"
										title={_t('action.save')}
										className="btn-md"
										showText={false}
									/>
								</CCol>
							</CRow>
						)}
					</QueryDataRenderer>
				</CCardBody>
			</CCard>
		</CCol>
	);
};

export default SumSubIntegration;
