/*
 * action types
 */

import { VisibleTableKeys } from './reducers/userReducer';

export const REQUEST_OAUTH_TOKENS = 'REQUEST_OAUTH_TOKENS';

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const TOKEN_LOGIN_REQUESTED = 'TOKEN_LOGIN_REQUESTED';

export const UPDATE_REFKEY_REQUEST = 'UPDATE_REFKEY_REQUEST';
export const UPDATE_REFKEY_SUCCESS = 'UPDATE_REFKEY_SUCCESS';
export const UPDATE_REFKEY_FAILURE = 'UPDATE_REFKEY_FAILURE';

export const REQUEST_CHANGE_LANGUAGE = 'REQUEST_CHANGE_LANGUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const UPDATE_USER_REFRESH_TOKEN = 'UPDATE_USER_REFRESH_TOKEN';

export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';

export const OAUTH_LOGOUT_REQUESTED = 'OAUTH_LOGOUT_REQUESTED';
export const LOGOUT = 'LOGOUT';

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER';

export const LOAD_USER_REQUESTED = 'LOAD_USER_REQUESTED';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

export const SET_SIDEBAR_SHOWN = 'SET_SIDEBAR_SHOWN';
export const TOGGLE_SIDEBAR_MOBILE = 'TOGGLE_SIDEBAR_MOBILE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const IMPERSONATION_REQUEST = 'IMPERSONATION_REQUEST';
export const IMPERSONATION_SUCCESS = 'IMPERSONATION_SUCCESS';
export const IMPERSONATION_FAILURE = 'IMPERSONATION_FAILURE';
export const IMPERSONATION_STOP = 'IMPERSONATION_STOP';

export const SHOW_CLOSE_POSITION_MODAL = 'SHOW_CLOSE_POSITION_MODAL';
export const HIDE_CLOSE_POSITION_MODAL = 'HIDE_CLOSE_POSITION_MODAL';

export const SET_CUSTOMERS_FILTERS = 'SET_CUSTOMERS_FILTERS';
export const CLEAR_CUSTOMERS_FILTERS = 'CLEAR_CUSTOMERS_FILTERS';

export const SET_ORDERS_FILTERS = 'SET_ORDERS_FILTERS';
export const CLEAR_ORDER_FILTERS = 'CLEAR_ORDER_FILTERS';

export const SET_DEPOSIT_FILTERS = 'SET_DEPOSIT_FILTERS';
export const CLEAR_DEPOSIT_FILTERS = 'CLEAR_DEPOSIT_FILTERS';

export const SET_WITHDRAWAL_FILTERS = 'SET_WITHDRAWAL_FILTERS';
export const CLEAR_WITHDRAWAL_FILTERS = 'CLEAR_WITHDRAWAL_FILTERS';

export const SET_TRANSACTIONS_FILTERS = 'SET_TRANSACTIONS_FILTERS';
export const CLEAR_TRANSACTIONS_FILTERS = 'CLEAR_TRANSACTIONS_FILTERS';

export const SET_POSITIONS_FILTERS = 'SET_POSITIONS_FILTERS';
export const CLEAR_POSITIONS_FILTERS = 'CLEAR_POSITIONS_FILTERS';

export const SET_OPEN_POSITIONS_FILTERS = 'SET_OPEN_POSITIONS_FILTERS';
export const CLEAR_OPEN_POSITIONS_FILTERS = 'CLEAR_OPEN_POSITIONS_FILTERS';

export const SET_COINS_FILTERS = 'SET_COINS_FILTERS';
export const CLEAR_COINS_FILTERS = 'CLEAR_COINS_FILTERS';

export const SET_COINS_CONVERSION_FILTERS = 'SET_COINS_CONVERSION_FILTERS';
export const CLEAR_COINS_CONVERSION_FILTERS = 'CLEAR_COINS_CONVERSION_FILTERS';

export const SET_WALLETS_FILTERS = 'SET_WALLETS_FILTERS';
export const CLEAR_WALLETS_FILTERS = 'CLEAR_WALLETS_FILTERS';

export const SET_AFFILIATE_FILTERS = 'SET_AFFILIATE_FILTERS';
export const CLEAR_AFFILIATE_FILTERS = 'CLEAR_AFFILIATE_FILTERS';

export const SET_LEADS_FILTERS = 'SET_LEADS_FILTERS';
export const CLEAR_LEADS_FILTERS = 'CLEAR_LEADS_FILTERS';

export const SET_AUDIT_LOGS_FILTERS = 'SET_AUDIT_LOGS_FILTERS';
export const CLEAR_AUDIT_LOGS_FILTERS = 'CLEAR_AUDIT_LOGS_FILTERS';

export const SET_RISK_MANAGEMENT_FILTERS = 'SET_RISK_MANAGEMENT_FILTERS';
export const CLEAR_RISK_MANAGEMENT_FILTERS = 'CLEAR_RISK_MAGAMENT_FILTERS';

export const SET_PAYOUT_REQUEST_FILTERS = 'SET_PAYOUT_REQUEST_FILTERS';
export const CLEAR_PAYOUT_REQUEST_FILTERS = 'CLEAR_PAYOUT_REQUEST_FILTERS';

export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED';

export const SET_TABLE_VISIBLE_COLUMNS = 'SET_TABLE_VISIBLE_COLUMNS';

export const TOGGLE_PUSH_NOTIFICATION_TOAST = 'TOGGLE_PUSH_NOTIFICATION_TOAST';

/*
 * action creators
 */

export function requestOAuthTokens(code: string) {
	return { type: REQUEST_OAUTH_TOKENS, code };
}

export function requestLogin(username: string, password: string) {
	return { type: LOGIN_REQUESTED, username, password };
}

export function requestTokenLogin(token: string) {
	return { type: TOKEN_LOGIN_REQUESTED, token };
}

export function requestUpdateRefkey(refKey: string) {
	return { type: UPDATE_REFKEY_REQUEST, refKey };
}

export function successUpdateRefkey() {
	return { type: UPDATE_REFKEY_SUCCESS };
}

export function failureUpdateRefkey(message: string) {
	return { type: UPDATE_REFKEY_FAILURE, message };
}

export function requestChangeLanguage(language: string, skipUserUpdate = false) {
	return { type: REQUEST_CHANGE_LANGUAGE, language, skipUserUpdate };
}

export function setLanguage(language: string, skipUserUpdate: boolean) {
	return { type: SET_LANGUAGE, language, skipUserUpdate };
}

export function updateUserToken(token: string) {
	return { type: UPDATE_USER_TOKEN, token };
}

export function updateUserRefreshToken(refreshToken: string) {
	return { type: UPDATE_USER_REFRESH_TOKEN, refreshToken };
}

export function setLoginErrorMessage(message: string) {
	return { type: SET_LOGIN_ERROR_MESSAGE, message };
}

export function successLogin(user: any, token: string, refreshToken: string) {
	return { type: LOGIN_SUCCEEDED, user, token, refreshToken };
}

export function failureLogin(message: string) {
	return { type: LOGIN_FAILED, message };
}

export function logout() {
	return { type: LOGOUT };
}

export function oAuthLogoutRequested() {
	return { type: OAUTH_LOGOUT_REQUESTED };
}

export function showGlobalLoader() {
	return { type: SHOW_GLOBAL_LOADER };
}

export function hideGlobalLoader() {
	return { type: HIDE_GLOBAL_LOADER };
}

export function requestLoadUser() {
	return { type: LOAD_USER_REQUESTED };
}

export function failureLoadUser(message: string) {
	return { type: LOAD_USER_FAILED, message };
}

export function successLoadUser(user: any) {
	return { type: LOAD_USER_SUCCESS, user };
}

export function setSidebarShown(shown: boolean | string) {
	return { type: SET_SIDEBAR_SHOWN, payload: { shown } };
}

export function toggleSidebarMobile() {
	return { type: TOGGLE_SIDEBAR_MOBILE };
}

export function toggleSidebar() {
	return { type: TOGGLE_SIDEBAR };
}

export function requestImpersonation(customerId: string) {
	return { type: IMPERSONATION_REQUEST, customerId };
}

export function successImpersonation(user: any, token: string) {
	return { type: IMPERSONATION_SUCCESS, user, token };
}

export function failureImpersonation(message: string) {
	return { type: IMPERSONATION_FAILURE, message };
}

export function requestStopImpersonation() {
	return { type: IMPERSONATION_STOP };
}

export function showPositionCloseModal() {
	return { type: SHOW_CLOSE_POSITION_MODAL };
}

export function hidePositionCloseModal() {
	return { type: HIDE_CLOSE_POSITION_MODAL };
}

export function setCustomersFilters(filters: any) {
	return {
		type: SET_CUSTOMERS_FILTERS,
		payload: { filters },
	};
}

export function clearCustomersFilters() {
	return { type: CLEAR_CUSTOMERS_FILTERS };
}

export function setDepositsFilters(filters: any) {
	return {
		type: SET_DEPOSIT_FILTERS,
		payload: { filters },
	};
}

export function clearDepositsFilters() {
	return { type: CLEAR_DEPOSIT_FILTERS };
}

export function setWithdrawalFilters(filters: any) {
	return {
		type: SET_WITHDRAWAL_FILTERS,
		payload: { filters },
	};
}

export function setTransactionsFilters(filters: any) {
	return {
		type: SET_TRANSACTIONS_FILTERS,
		payload: { filters },
	};
}

export function clearWithdrawalFilters() {
	return { type: CLEAR_WITHDRAWAL_FILTERS };
}

export function clearTransactionsFilters() {
	return { type: CLEAR_TRANSACTIONS_FILTERS };
}

export function setPositionsFilters(filters: any) {
	return {
		type: SET_POSITIONS_FILTERS,
		payload: { filters },
	};
}

export function setOpenPositionsFilters(filters: any) {
	return {
		type: SET_OPEN_POSITIONS_FILTERS,
		payload: { filters },
	};
}

export function clearOpenPositionsFilters() {
	return { type: CLEAR_OPEN_POSITIONS_FILTERS };
}

export function clearPositionsFilters() {
	return { type: CLEAR_POSITIONS_FILTERS };
}

export function setCoinsFilters(filters: any) {
	return {
		type: SET_COINS_FILTERS,
		payload: { filters },
	};
}

export function clearCoinsFilters() {
	return { type: CLEAR_COINS_FILTERS };
}

export function setCoinsConversionsFilters(filters: any) {
	return {
		type: SET_COINS_CONVERSION_FILTERS,
		payload: { filters },
	};
}

export function clearCoinsConversionsFilters() {
	return { type: CLEAR_COINS_CONVERSION_FILTERS };
}

export function setOrdersFilters(filters: any) {
	return {
		type: SET_ORDERS_FILTERS,
		payload: { filters },
	};
}

export function clearOrdersFilters() {
	return { type: CLEAR_ORDER_FILTERS };
}

export function setWalletsFilters(filters: any) {
	return {
		type: SET_WALLETS_FILTERS,
		payload: { filters },
	};
}

export function setAffiliateFilters(filters: any) {
	return {
		type: SET_AFFILIATE_FILTERS,
		payload: { filters },
	};
}

export function clearWalletsFilters() {
	return { type: CLEAR_WALLETS_FILTERS };
}

export function clearAffiliateFilters() {
	return { type: CLEAR_AFFILIATE_FILTERS };
}

export function setLeadsFilters(filters: any) {
	return {
		type: SET_LEADS_FILTERS,
		payload: { filters },
	};
}

export function clearLeadsFilters() {
	return {
		type: CLEAR_LEADS_FILTERS,
	};
}

export function setAuditLogsFilters(filters: any) {
	return {
		type: SET_AUDIT_LOGS_FILTERS,
		payload: { filters },
	};
}

export function clearAuditLogsFilters() {
	return {
		type: CLEAR_AUDIT_LOGS_FILTERS,
	};
}

export function setSocketConnected(connected: boolean) {
	return {
		type: SET_SOCKET_CONNECTED,
		payload: { connected },
	};
}

export function setRiskManagementFilters(filters: any) {
	return {
		type: SET_RISK_MANAGEMENT_FILTERS,
		payload: { filters },
	};
}

export function clearRiskManagementFilters() {
	return {
		type: CLEAR_RISK_MANAGEMENT_FILTERS,
	};
}

export function setTableVisibleColumns(cols: Array<string>, tableKey: VisibleTableKeys) {
	return {
		type: SET_TABLE_VISIBLE_COLUMNS,
		payload: { cols, tableKey },
	};
}

export function setPayoutRequestFilters(filters: any) {
	return {
		type: SET_PAYOUT_REQUEST_FILTERS,
		payload: { filters },
	};
}

export function clearPayoutRequestFilters() {
	return { type: CLEAR_PAYOUT_REQUEST_FILTERS };
}

export function toggleDismissPushNotificationsToast(state = false) {
	return {
		type: TOGGLE_PUSH_NOTIFICATION_TOAST,
		payload: state,
	};
}
