import React from 'react';
import { CBadge } from '@coreui/react';

interface IProps {
	status: string;
}

const UserStatusBadge: React.FC<IProps> = ({ status }) => {
	const colors: any = {
		ACTIVE: 'success',
		DISABLED: 'danger',
		PENDING: 'warning',
	};

	return <CBadge color={colors[status]}>{status.toUpperCase()}</CBadge>;
};

export default UserStatusBadge;
