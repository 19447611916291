import { CFormGroup } from '@coreui/react';
import React from 'react';
import { getCommissionModels } from '../../services/BackendService';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { ICommissionModel } from './types';
import CustomSelect from '../../components/CustomSelect';
import InputHeader from '../../components/InputHeader';
import _t from 'counterpart';

interface IProps {
	id: number | null;
	subCommissionModelId: number | null;
	onChange: (id: number) => void;
	onReset: () => void;
}

const SubCommissionSelect = ({ id, subCommissionModelId, onChange, onReset }: IProps) => {
	const commissionModelsQuery = useQuery(['commission-models-for-sub'], () => getCommissionModels());

	const { isLoading, isError, data, refetch } = commissionModelsQuery;

	const handleChange = (subCommissionModel: any) => {
		onChange(subCommissionModel.value);
	};

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const subCommissionOptions = [];
	subCommissionOptions.push({ label: 'inherit', value: null });
	data
		.filter((commissionModel: ICommissionModel) => commissionModel.id !== id)
		.forEach((commissionModel: ICommissionModel) => {
			subCommissionOptions.push({ value: commissionModel.id, label: commissionModel.name });
		});

	const activeCommissionScheme =
		subCommissionOptions.find((option) => Number(option.value) === Number(subCommissionModelId)) ?? null;

	return (
		<CFormGroup>
			<InputHeader labelText={_t('commissions.sub-commission-scheme')} resetFilter={onReset} />
			<CustomSelect
				value={activeCommissionScheme}
				onChange={handleChange}
				options={subCommissionOptions}
				whiteBackground
			/>
		</CFormGroup>
	);
};

export default SubCommissionSelect;
