import axios from 'axios';
import { stringify } from 'qs';
import { createTokenRefreshInterceptor, handleReject, handleRequest, handleSuccess } from '../helpers/interceptors';

export interface IBaseStats {
	openLots: string;
	openPositions: number;
	totalProfitLoss: string;
}

export interface ITrendingInstrument {
	symbol: string;
	sumVolumeTraded: string;
}

export interface IProfitLossByMonth {
	month: string;
	profitLoss: string;
}

export interface ILotsPerInstrument {
	symbol: string;
	lots: string;
}

export interface IExposure {
	symbol: string;
	exposure: string;
}

interface IRiskStats {
	baseStats: IBaseStats;
	lotsPerInstrument: Array<ILotsPerInstrument>;
	profitLossByMonth: Array<IProfitLossByMonth>;
	trendingInstruments: Array<ITrendingInstrument>;
}

interface IExposureStats {
	exposures: Array<IExposure>;
}

if (!process.env.REACT_APP_API_ROOT) {
	throw new Error('REACT_APP_API_ROOT not set');
}

const baseURL = `${process.env.REACT_APP_API_ROOT}/api/admin/risk`;

const client = axios.create({ baseURL });

createTokenRefreshInterceptor(client);
client.interceptors.response.use(handleSuccess, (error) => handleReject(error));
client.interceptors.request.use(handleRequest);

export async function loadRiskStats(group: string | undefined, isDemo: string | undefined): Promise<IRiskStats> {
	const qs = stringify({ group, isDemo });
	const response = await client.get(`/stats?${qs}`);
	return response.data;
}

export async function loadUserExposure(group: string | undefined, isDemo: string | undefined): Promise<IExposureStats> {
	const qs = stringify({ group, isDemo });
	const response = await client.get(`/exposures?${qs}`);
	return response.data;
}
