import React from 'react';
import { CAlert } from '@coreui/react';
import { extractErrorMessage } from '../helpers';
import { AxiosError } from 'axios';

interface IProps {
	error: AxiosError;
	noMargin?: boolean;
}

const NetworkError: React.FC<IProps> = ({ error, noMargin = false }) => {
	return (
		<CAlert className={noMargin ? 'mb-0' : ''} color="danger">
			{extractErrorMessage(error)}
		</CAlert>
	);
};

export default NetworkError;
