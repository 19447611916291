import React from 'react';
import { CBadge } from '@coreui/react';
import { ExchangeRateSource } from './types';

const SourceBadge = ({ source }: { source: ExchangeRateSource }) => {
	const colors: any = {
		Manual: 'success',
		OpenExchangeRates: 'primary',
	};

	return <CBadge color={colors[source]}>{source}</CBadge>;
};

export default SourceBadge;
