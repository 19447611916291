import React, { useState } from 'react';
import {
	CButton,
	CFormGroup,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import { extractErrorMessage } from '../../helpers';
import { useMutation, useQuery } from 'react-query';
import { getSalesStatuses, updateCustomersBulk, listSalesPeople } from '../../services/BackendService';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import { ISalesStatus } from '../salesStatus/types';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { IBulkEditParams, ICustomer } from './types';
import { ICustomersFiltersDTO } from '../../sagas/customersSaga';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	customersFilters: ICustomersFiltersDTO;
	search: string | null | undefined;
	count: number;
}

const BulkEditModal = ({ show, onClose, customersFilters, search, count }: IProps) => {
	const [selectedStatus, setSelectedStatus] = useState<ISelectOption | null>();
	const [conversionAgent, setConversionAgent] = useState<ISelectOption | null>();
	const [retentionAgent, setRetentionAgent] = useState<ISelectOption | null>();

	const permissions = useAppSelector((state) => state.user.permissions);

	const close = (refetch?: boolean) => {
		onClose(refetch);
		setSelectedStatus(null);
		setConversionAgent(null);
		setRetentionAgent(null);
	};

	const resetStatus = () => {
		setSelectedStatus(null);
	};

	const resetConversionAgent = () => {
		setConversionAgent(null);
	};

	const handleConversionAgentChange = (sp: any) => {
		setConversionAgent(sp);
	};

	const resetRetentionAgent = () => {
		setRetentionAgent(null);
	};

	const handleRetentionAgentChange = (sp: any) => {
		setRetentionAgent(sp);
	};

	const updateCustomersMutation = useMutation((params: IBulkEditParams) => updateCustomersBulk(params), {
		onSuccess: (count) => {
			toast.success(_t('customers.modified-x-customers', { count }));
			close(true);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const handleStatusChange = (status: any) => {
		setSelectedStatus(status);
	};

	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
	});

	const canViewSalesPeople = permissions?.includes(Permission.VIEW_SALES_PEOPLE);

	const salesPeopleQuery = useQuery('sales-people', () => listSalesPeople(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		enabled: canViewSalesPeople,
	});

	const isLoading = salesStatusesQuery.isLoading;
	const isError = salesStatusesQuery.isError;

	const handleOnRefetch = () => {
		salesStatusesQuery.refetch();
	};

	const statuses = salesStatusesQuery.data?.map((s: ISalesStatus) => {
		return {
			value: s.id,
			label: s.status,
		};
	});

	const salesPeople = salesPeopleQuery.data?.map((sp: ICustomer) => {
		return {
			value: sp.id,
			label: `${sp.firstname} ${sp.lastname}`,
		};
	});

	const invokeMutation = () => {
		updateCustomersMutation.mutate({
			...customersFilters,
			search: search ?? undefined,
			selectedStatus: selectedStatus ? selectedStatus.value : null,
			conversionAgent: conversionAgent ? conversionAgent.value : null,
			retentionAgent: retentionAgent ? retentionAgent.value : null,
		});
	};

	return (
		<CModal show={show} onClose={close}>
			<CModalHeader>
				<CModalTitle>{_t('customers.bulk-edit-customers')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CLabel>{_t('customers.only-selected-attr')}</CLabel>
				<QueryDataRenderer isLoading={isLoading} isError={isError} onRefetch={handleOnRefetch}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatus}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={selectedStatus ?? null}
							options={statuses}
							onChange={handleStatusChange}
							isClearable={false}
							whiteBackground
							placeholder={_t('customers.select-a-status')}
						/>
					</CFormGroup>
					{canViewSalesPeople && (
						<>
							<CFormGroup>
								<div className="d-flex align-items-center justify-content-between">
									<span>{_t('customer.conversion-agent')}</span>
									<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetConversionAgent}>
										{_t('action.reset').toUpperCase()}
									</CButton>
								</div>
								<CustomSelect
									value={conversionAgent ?? null}
									options={salesPeople}
									onChange={handleConversionAgentChange}
									isClearable={false}
									whiteBackground
									placeholder={_t('customers.actions.select-retention-agent')}
								/>
							</CFormGroup>
							<CFormGroup>
								<div className="d-flex align-items-center justify-content-between">
									<span>{_t('customer.retention-agent')}</span>
									<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetRetentionAgent}>
										{_t('action.reset').toUpperCase()}
									</CButton>
								</div>
								<CustomSelect
									value={retentionAgent ?? null}
									options={salesPeople}
									onChange={handleRetentionAgentChange}
									isClearable={false}
									whiteBackground
									placeholder={_t('customers.actions.select-retention-agent')}
								/>
							</CFormGroup>
						</>
					)}
				</QueryDataRenderer>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={updateCustomersMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('customers.modify-customers', { count })}
					className="mr-2"
					style={{ lineHeight: '1' }} // bootstrap class lh-1 doesn't work
					disabled={count === 0}
				/>
				<CButton color="light" variant="outline" onClick={close}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(BulkEditModal);
