import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CRow } from '@coreui/react';
import React from 'react';
import { useParams } from 'react-router';
import Detail from '../../components/micro/Detail';
import PageLayout from '../../components/PageLayout';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import _t from 'counterpart';
import { fetchAdapter } from '../../services/BackendService';
import { useQuery } from 'react-query';
import { AdapterType, IFIXAdapterStatus } from './types';
import BooleanBadge from '../../components/BooleanBadge';

const FIXStatusCard = ({ status }: { status: IFIXAdapterStatus }) => {
	return (
		<CCard>
			<CCardHeader>
				<CCardTitle>{_t('adapters.status')}</CCardTitle>
			</CCardHeader>
			<CCardBody>
				<CRow>
					<CCol>
						<dl>
							<Detail title={_t('adapters.connected')}>
								<BooleanBadge value={status.connected} />
							</Detail>
							<Detail title={_t('adapters.last-message-received')}>{status.lastMessageReceived}</Detail>
						</dl>
					</CCol>
					<CCol>
						<dl>
							<Detail title={_t('adapters.messages-received')}>{status.messagesReceived}</Detail>
							<Detail title={_t('adapters.messages-sent')}>{status.messagesSent}</Detail>
						</dl>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};

const AdapterPage = () => {
	const params = useParams();
	const { name } = params as any;

	const adapterQuery = useQuery(['adapter', name], () => fetchAdapter(name));

	if (adapterQuery.isLoading || adapterQuery.isIdle) {
		return <Loading />;
	}

	if (adapterQuery.isError) {
		return <Error onRetry={adapterQuery.refetch} />;
	}

	const { name: adapterName, enabled, type, configuration, status } = adapterQuery.data;

	return (
		<PageLayout title={name}>
			<CRow>
				<CCol md={4}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('adapters.adapter-basic-info')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol>
									<dl>
										<Detail title={_t('global.name')}>{adapterName}</Detail>
										<Detail title={_t('adapters.enabled')}>
											<BooleanBadge value={enabled} />
										</Detail>
										<Detail title={_t('global.type')}>{type}</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				{(type === AdapterType.Fix || type === AdapterType.Fixquoter) && (
					<CCol md={8}>
						<FIXStatusCard status={status!} />
					</CCol>
				)}
			</CRow>
			{(type === AdapterType.Fix || type === AdapterType.Fixquoter) && (
				<CRow>
					<CCol md={8}>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('adapters.configuration')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<CRow>
									<CCol>
										<dl>
											<Detail title="SSL">
												<BooleanBadge value={configuration.ssl} />
											</Detail>
											<Detail title={_t('adapters.host')}>{configuration.host}</Detail>
											<Detail title={_t('adapters.port')}>{configuration.port}</Detail>
										</dl>
									</CCol>
									<CCol>
										<dl>
											<Detail title={_t('adapters.session')}>{configuration.session}</Detail>
											<Detail title={_t('login.password')}>{configuration.password}</Detail>
											<Detail title={_t('adapters.username')}>{configuration.username}</Detail>
										</dl>
									</CCol>
									<CCol>
										<dl>
											<Detail title={_t('adapters.reset-on-logon')}>
												<BooleanBadge value={configuration.resetOnLogon} />
											</Detail>
											<Detail title="SenderCompID">{configuration.senderCompId}</Detail>
											<Detail title="TargetCompID">{configuration.targetCompId}</Detail>
										</dl>
									</CCol>
								</CRow>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}
		</PageLayout>
	);
};

export default React.memo(AdapterPage);
