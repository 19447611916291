import React from 'react';
import { clearPositionsFilters, setPositionsFilters } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { IPositionsFilters } from '../../reducers/filtersReducer';
import BasePositionsPage from './BasePositionsPage';

export const positionsFiltersToDTO = (filters: IPositionsFilters) => {
	return {
		types: filters.types.map((type) => type.value),
		symbols: filters.symbols.map((symbol) => symbol.value),
		statuses: filters.statuses.map((status) => status.value),
		openedAt: {
			startDate: filters.openedAt?.startDate?.toISOString(),
			endDate: filters.openedAt?.endDate?.toISOString(),
		},
		accountId: filters.accountId,
	};
};

const AllPositionsPage = () => {
	const dispatch = useAppDispatch();
	const positionsFilters = useAppSelector((state) => state.filters.positionsFilters);

	const clearFilters = () => {
		dispatch(clearPositionsFilters());
	};

	const filtersDTO = positionsFiltersToDTO(positionsFilters);

	const onFiltersUpdated = (filters: Partial<IPositionsFilters>) => {
		dispatch(setPositionsFilters(filters));
	};

	return (
		<BasePositionsPage
			titleKey="positions.title"
			filters={positionsFilters}
			clearFilters={clearFilters}
			filtersDTO={filtersDTO}
			onFiltersUpdated={onFiltersUpdated}
			showExportButton
			queryKey="all-positions"
		/>
	);
};

export default AllPositionsPage;
