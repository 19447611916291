import posthog from 'posthog-js';

class Analytics {
	private initialized = false;

	init(key: string, config: posthog.Config) {
		posthog.init(key, config);
		this.initialized = true;
	}

	identifyUser(userId: string, email?: string) {
		if (this.initialized) {
			posthog.identify(userId);
			if (email) {
				posthog.people.set({ email });
			}
		}
	}

	resetUserData() {
		if (this.initialized) {
			posthog.reset();
		}
	}

	captureEvent(eventName: string, additionalData?: Record<string, any>) {
		if (this.initialized) {
			posthog.capture(eventName, additionalData);
		}
	}
}

const analytics = new Analytics();
export default analytics;
