export const calculateYAxisWidth = <T, K extends keyof T>(data: Array<T>, key: K): number => {
	const values = data.map((item) => item[key]);

	const longestNum = values.reduce((prevLongest, current) => {
		const currentLength = Number(current).toString().length;
		return currentLength > Number(prevLongest).toString().length ? current : prevLongest;
	}, values[0]);

	const numDigits = parseInt(String(longestNum)).toString().length;
	const result = numDigits * 15;

	return result;
};
