import React from 'react';
import { IPosition } from '../pages/positions/position';
import { CBadge } from '@coreui/react';

const PositionStatusBadge = (props: { position: IPosition }) => {
	const { position } = props;
	const positionStatus = position.status.toLowerCase();
	let badgeColor;
	if (positionStatus === 'open') {
		badgeColor = 'blue';
	} else if (['error-on-opening', 'error-on-closing'].includes(positionStatus)) {
		badgeColor = 'danger';
	} else if (['in-closing'].includes(positionStatus)) {
		badgeColor = 'warning';
	} else {
		badgeColor = 'closed';
	}
	return (
		<CBadge color={badgeColor} title={position.status}>
			{position.status}
		</CBadge>
	);
};

export default PositionStatusBadge;
