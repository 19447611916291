import React from 'react';
import _t from 'counterpart';
import { CBadge } from '@coreui/react';
import { calcTextColor } from '../pages/leads/helpers';
import { getSalesStatuses } from '../services/BackendService';
import { useQuery } from 'react-query';
import { extractErrorMessage } from '../helpers';
import { toast } from 'react-hot-toast';

interface IProps {
	status: string;
}

const SalesStatusBadge = ({ status }: IProps) => {
	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(`${_t('sales-status.failed-to-fetch-sales-statuses')}: ${error}`);
		},
	});

	if (salesStatusesQuery.isLoading) {
		return null;
	}

	const color = salesStatusesQuery.data?.find((ss) => ss.status === status)?.color || '#000000';

	return <CBadge style={{ backgroundColor: color, color: calcTextColor(color) }}>{status?.toUpperCase()}</CBadge>;
};

export default SalesStatusBadge;
