import React, { useMemo, useState } from 'react';
import _t from 'counterpart';
import { CCard, CCardBody, CCol, CRow, CCardHeader, CButton } from '@coreui/react';
import { capitalize, formatCurrency, formatDateTime } from '../../helpers';
import { TransactionStatusBadge } from '../transactions/TransactionStatusBadge';
import AddTransactionModal from './AddTransactionModal';
import { ITransaction } from './types';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { loadCustomerTransactions } from '../../services/BackendService';
import { useAppSelector } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';
import PaginationTable from '../../components/PaginationTable';
import Error from '../../components/Error';

const CustomerTransactionsTable = ({ customerId }: { customerId: string }) => {
	const [showAddTransaction, setShowAddTrasaction] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);

	const limit = 10;
	const offset = page * limit - limit;

	const permissions = useAppSelector((state) => state.user.permissions);

	const history = useHistory();

	const customerTransactionsQuery = useQuery(['customer-transactions', customerId, offset], () =>
		loadCustomerTransactions(customerId, limit, offset)
	);

	const handleTransactionClicked = (transaction: ITransaction) => {
		if (permissions?.includes(Permission.MANAGE_TRANSACTIONS)) {
			history.push(`/transactions/${transaction.id}`);
		}
	};

	const fields = useMemo(
		() => [
			{ key: 'date', label: capitalize(_t('global.date')) },
			{ key: 'type', label: capitalize(_t('global.type')) },
			{ key: 'wallet', label: _t('global.wallet') },
			{ key: 'amount', label: capitalize(_t('global.amount')) },
			{ key: 'status', label: capitalize(_t('global.status')) },
		],
		[]
	);

	const slots = useMemo(
		() => ({
			date: (transaction: ITransaction) => <td>{formatDateTime(transaction.createdAt)}</td>,
			amount: (transaction: ITransaction) => <td>{formatCurrency(transaction.amount, transaction.currency)}</td>,
			status: (transaction: ITransaction) => (
				<td>
					<TransactionStatusBadge status={transaction.status} />
				</td>
			),
			wallet: (transaction: ITransaction) => <td>{transaction.wallet.id}</td>,
		}),
		[]
	);

	if (customerTransactionsQuery.isError) {
		return (
			<CRow>
				<CCol md={12}>
					<CCard className="card card--listing">
						<CCardBody className="card__holder">
							<Error onRetry={customerTransactionsQuery.refetch} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		);
	}

	const showAddTransactionModal = () => {
		setShowAddTrasaction(true);
	};

	const count = customerTransactionsQuery.data?.count || 0;
	const pages = count === 0 ? 0 : Math.ceil(count / limit);

	const renderTransactions = () => {
		return (
			<PaginationTable
				data={customerTransactionsQuery.data?.transactions || []}
				clickableRows={permissions?.includes(Permission.MANAGE_TRANSACTIONS) ? true : undefined}
				onRowClicked={handleTransactionClicked}
				tableFields={fields}
				scopedSlots={slots}
				loading={customerTransactionsQuery.isLoading}
				pages={pages}
				activePage={page}
				onPageChanged={setPage}
				pagination
			/>
		);
	};

	return (
		<>
			<CRow>
				<CCol md={12}>
					<CCard className="card card--listing">
						<CCardBody className="card__holder">
							<CCardHeader className="card__header">
								<div className="card__info">
									<h4 className="card__title">{_t('customer.transactions')}</h4>
									<div className="small text-muted">{_t('customer.deposit-withdrawals')}</div>
								</div>
								{permissions?.includes(Permission.MANAGE_TRANSACTIONS) && (
									<CButton color="primary" className="mr-2 header-button-add" onClick={showAddTransactionModal} />
								)}
							</CCardHeader>
							{renderTransactions()}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<AddTransactionModal
				show={showAddTransaction}
				customerId={customerId}
				onClose={() => {
					setShowAddTrasaction(false);
					customerTransactionsQuery.refetch();
				}}
			/>
		</>
	);
};

export default CustomerTransactionsTable;
