import React from 'react';
import Loading from './Loading';
import Error from './Error';

interface IProps {
	children: React.ReactNode;
	isError: boolean;
	isLoading: boolean;
	onRefetch: () => void;
}

const QueryDataRenderer = ({ children, isError, isLoading, onRefetch }: IProps) => {
	return (
		<>
			{isError && <Error onRetry={onRefetch} />}
			{isLoading && <Loading />}
			{!isLoading && !isError && <div>{children}</div>}
		</>
	);
};

export default React.memo(QueryDataRenderer);
