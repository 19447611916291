import React from 'react';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CForm,
	CFormGroup,
	CLabel,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CInput,
	CFormText,
	CTextarea,
	CModalFooter,
	CButton,
	CAlert,
	CSelect,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import { createTransaction, loadCustomerWallets } from '../../services/BackendService';
import { findErrorFromValidation, extractErrorMessage } from '../../helpers';
import { useLegacyState } from '../../helpers/customHooks';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IWallet } from '../wallets/types';
import toast from 'react-hot-toast';

interface IProps {
	show: boolean;
	customerId: string;
	onClose: () => void;
}

enum TransactionType {
	Correction = 'CORRECTION',
	Bonus = 'BONUS',
	Deposit = 'DEPOSIT',
	Withdrawal = 'WITHDRAWAL',
}

interface IState {
	error: string | null;
	amount: number | undefined;
	comment: string | undefined;
	method: string | undefined;
	type: TransactionType;
	walletId: string | undefined;
	addToCredit: boolean;
}

const AddTransactionModal = ({ show, onClose, customerId }: IProps) => {
	const queryClient = useQueryClient();
	const [state, setState] = useLegacyState<IState>({
		error: null,
		amount: undefined,
		comment: undefined,
		method: undefined,
		type: TransactionType.Correction,
		walletId: undefined,
		addToCredit: false,
	});

	const { method, walletId, error, amount, comment, type, addToCredit } = state;

	const addTransactionMutation = useMutation(
		['add-transaction'],
		() => createTransaction(Number(walletId), type, amount, 'USD', comment, method, addToCredit),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(['wallet-balance']);
				await queryClient.invalidateQueries(['wallet-info']);
				onClose();
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					toast.error(extractErrorMessage(e));
				}
			},
		}
	);

	const addTransaction = async (e: React.FormEvent) => {
		e.preventDefault();
		addTransactionMutation.mutate();
	};

	const clearState = () => {
		addTransactionMutation.reset();
		setState({
			validationErrors: null,
			amount: undefined,
			comment: undefined,
			type: 'CORRECTION',
			method: undefined,
			addToCredit: false,
		});
	};

	const setTransactionType = (event: any) => {
		const { value } = event.target;
		setState({
			type: value,
			method: value === 'DEPOSIT' || value === 'WITHDRAWAL' ? 'Wire Transfer' : undefined,
			addToCredit: value === 'BONUS',
		});
	};

	const setWalletId = (event: any) => {
		const { value } = event.target;
		setState({
			walletId: value,
		});
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(addTransactionMutation.error, paramName);
	};

	const handleMethodChanged = (event: any) => {
		setState({
			method: event.target.method,
		});
	};

	const handleAmountChanged = (event: any) => {
		setState({
			amount: event.target.value,
		});
	};

	const handleCommentChanged = (event: any) => {
		let comment = event.target.value;
		if (comment.trim().length < 1) {
			comment = undefined;
		}
		setState({
			comment,
		});
	};

	const handleAddToCredit = (event: any) => {
		const target = event.target as HTMLInputElement;
		const { checked } = target;
		setState({ addToCredit: checked });
	};

	const { data } = useQuery<Array<IWallet>, Error>(['customer-wallets', customerId], async () => {
		const data = await loadCustomerWallets(customerId);
		return data;
	});

	const loading = addTransactionMutation.isLoading;

	if (!state.walletId && data && data.length > 0) {
		setState({ walletId: data[0].id });
	}

	return (
		<>
			<CModal show={show} onClose={onClose} onClosed={clearState}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('add-transaction-modal.add-transaction')}</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={addTransaction}>
					<CModalBody>
						{!loading && error && <CAlert color="danger">{error}</CAlert>}
						<CFormGroup>
							<CLabel htmlFor="nf-type">{_t('add-transaction-modal.transaction-type')}</CLabel>
							<CSelect id="nf-type" name="nf-type" onChange={setTransactionType} value={type}>
								<option value={TransactionType.Correction}>{_t('add-transaction-modal.correction')}</option>
								<option value={TransactionType.Deposit}>{_t('add-transaction-modal.deposit')}</option>
								<option value={TransactionType.Bonus}>{_t('add-transaction-modal.bonus')}</option>
								<option value={TransactionType.Withdrawal}>{_t('add-transaction-modal.withdrawal')}</option>
							</CSelect>
							<CFormText className="help-block">{_t('add-transaction-modal.select-transaction-type')}</CFormText>
							{findError('type') && <CLabel className="text-danger">{findError('type')}</CLabel>}
						</CFormGroup>
						{(type === TransactionType.Deposit || type === TransactionType.Withdrawal) && (
							<CFormGroup>
								<CLabel htmlFor="nf-type">{_t('add-transaction-modal.transaction-method')}</CLabel>
								<CSelect id="nf-method" name="nf-method" onChange={handleMethodChanged}>
									<option value="Wire Transfer">{_t('add-transaction-modal.wire-transfer')}</option>
								</CSelect>
							</CFormGroup>
						)}
						<CFormGroup>
							<CLabel htmlFor="nf-walletId">{_t('add-transaction-modal.wallet')}</CLabel>
							<CSelect id="nf-walletId" name="nf-walletId" onChange={setWalletId} value={state.walletId}>
								{data?.map((wallet: IWallet) => {
									return (
										<option key={`wallet-${wallet.id}`} value={wallet.id}>{`${wallet.id} ${
											wallet.primary ? '(primary)' : ''
										}`}</option>
									);
								})}
							</CSelect>
							{findError('walletId') && <CLabel className="text-danger">{findError('walletId')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-amount">{_t('add-transaction-modal.amount-in-usd')}</CLabel>
							<CInputGroup>
								<CInputGroupPrepend>
									<CInputGroupText>$</CInputGroupText>
								</CInputGroupPrepend>
								<CInput
									type="number"
									id="nf-amount"
									name="nf-amount"
									placeholder="12.64"
									value={amount || ''}
									onChange={handleAmountChanged}
								/>
							</CInputGroup>
							{type !== TransactionType.Withdrawal && (
								<CFormText className="help-block">{_t('add-transaction-modal.positive-amount-add-money')}</CFormText>
							)}
							{type === TransactionType.Withdrawal && (
								<CFormText className="help-block">{_t('add-transaction-modal.only-positive')}</CFormText>
							)}
							{findError('amount') && <CLabel className="text-danger">{findError('amount')}</CLabel>}
						</CFormGroup>
						{type === 'BONUS' && (
							<CFormGroup className="d-flex align-items-center">
								<input
									className="mr-2"
									type="checkbox"
									id="addToCredit"
									value={addToCredit}
									onChange={handleAddToCredit}
									checked={addToCredit}
								/>
								<span>{_t.translate('add-transaction-modal.added-as-credit')}</span>
							</CFormGroup>
						)}
						<CFormGroup>
							<CLabel htmlFor="nf-comment">{_t('global.comment')}</CLabel>
							<CTextarea id="nf-comment" name="nf-comment" value={comment || ''} onChange={handleCommentChanged} />
							<CFormText className="help-block">{_t('add-transaction-modal.enter-comment')}</CFormText>
							{findError('comment') && <CLabel className="text-danger">{findError('comment')}</CLabel>}
						</CFormGroup>
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('action.add')}
							className="mr-2"
						/>
						<CButton type="button" color="secondary" onClick={onClose} disabled={loading}>
							{_t('action.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
		</>
	);
};

export default AddTransactionModal;
