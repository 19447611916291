import React, { useEffect } from 'react';
import { CButton, CForm, CImg, CSpinner } from '@coreui/react';
import paperPlane from '../icons/paper_plane.svg';
import _t from 'counterpart';
import Textarea from 'react-expanding-textarea';
import Loading from './Loading';
import CustomerComment from '../pages/customers/CustomerComment';

interface CommentingCustomer {
	id: string;
	name: string;
	avatarUrl: string;
}

export interface Comment {
	comment: string;
	createdAt: string;
	id: number;
	commentingCustomer: CommentingCustomer;
}

interface IProps {
	comments: Array<any>;
	isCreatingComment: boolean;
	isLoadingData: boolean;
	onCommentCreated: () => void;
	comment: string;
	onCommentChanged: (comment: string) => void;
}

const CustomerCommentsList = ({
	comments,
	isCreatingComment,
	isLoadingData,
	onCommentCreated,
	comment,
	onCommentChanged,
}: IProps) => {
	const scrollerRef = React.useRef<HTMLDivElement>(null);

	const setCommentState = (e: any) => {
		onCommentChanged(e.target.value);
	};

	const scrollToBottom = () => {
		if (scrollerRef && scrollerRef?.current) {
			const offsetBottom = scrollerRef.current.scrollHeight;
			scrollerRef.current.scrollTop = offsetBottom;
		}
	};

	useEffect(() => {
		scrollToBottom();
	});

	const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
			onCommentCreated();
		}
	};

	const createComment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		onCommentCreated();
	};

	if (isLoadingData) {
		return <Loading />;
	}

	return (
		<div className="comments">
			<div className="comments-list" ref={scrollerRef}>
				{comments.length > 0 &&
					comments.map((c: Comment) => {
						const { id, name, avatarUrl } = c.commentingCustomer;
						return (
							<CustomerComment
								accountId={id}
								displayName={name}
								comment={c.comment}
								image={avatarUrl}
								createdAt={c.createdAt}
								key={c.id}
							/>
						);
					})}
				{comments.length === 0 && (
					<span className="no-comments-placeholder text-muted">{_t('customer.no-comments-placeholder')}</span>
				)}
			</div>
			<div className="comments-add">
				<CForm onSubmit={createComment}>
					<Textarea
						className="w-100 mt-2 comments-input"
						onChange={setCommentState}
						value={comment}
						placeholder={_t('customer.comment-placeholder')}
						onKeyDown={handleKeydown}
						rows={3}
					/>
					<CButton type="submit" className="comments-button" disabled={isCreatingComment}>
						{!isCreatingComment && <CImg src={paperPlane} />}
						{isCreatingComment && (
							<div>
								<CSpinner size="sm" className="p-0 m-0" color="light" />
							</div>
						)}
					</CButton>
				</CForm>
			</div>
		</div>
	);
};

export default React.memo(CustomerCommentsList);
