import { ICustomersFiltersDTO } from 'src/sagas/customersSaga';
import { UserRole } from '../../reducers/userReducer';
import { IPosition } from '../positions/position';
import { TransactionStatus } from '../transactions/types';
import { IWallet } from '../wallets/types';
import { SalesAgentRoles } from './AssignableCustomers';
import { IGroup, ITradeCommission } from '../groups/types';


export enum TradingType {
	Netting = 'NETTING',
	Hedging = 'HEDGING',
}
export interface ICreateWallet {
	customerId: string;
	groupId: number | undefined;
	leverage: number;
	isDemo: boolean;
	tradingType: TradingType;
}

export interface ICustomerModalsShow {
	affiliatesModalShow: boolean;
	salesPersonModalShow: boolean;
	changeCommissionSchemeShow: boolean;
	updatePasswordShow: boolean;
	confirmEmailShow: boolean;
	confirmBarShow: boolean;
	callCustomerModalShow: boolean;
	emailCustomerModalShow: boolean;
	assignSalesStatusShow: boolean;
	removeRiskStatusShow: boolean;
	messageModalShow: boolean;
}

export const initialModalsState: ICustomerModalsShow = {
	affiliatesModalShow: false,
	salesPersonModalShow: false,
	changeCommissionSchemeShow: false,
	updatePasswordShow: false,
	confirmEmailShow: false,
	confirmBarShow: false,
	callCustomerModalShow: false,
	emailCustomerModalShow: false,
	assignSalesStatusShow: false,
	removeRiskStatusShow: false,
	messageModalShow: false,
};

export interface IMarkup {
	groupId: string;
	instrumentSymbol: string;
	bid: number;
	ask: number;
}

export interface ICommission {
	groupId: string;
	symbol: string;
	open: string;
	close: string;
}

export interface IGroupWithExtraData extends IGroup {
	markups: Array<IMarkup>;
	commissions: Array<ITradeCommission>;
	stopOutLevel: number;
	marginCallLevel: number;
}

export interface IKYCDocument {
	url: string;
	token: string;
}

export interface ITransaction {
	id: string;
	type: string;
	amount: number;
	currency: string;
	method: string;
	status: TransactionStatus;
	createdAt: Date;
	creditedAt: Date;
	netAmountInUSD: number;
	wallet: IWallet;
}

export interface ICustomerTransactionsQuery {
	transactions: Array<ITransaction>;
	count: number;
}

export enum MessageType {
	SMS = 'SMS',
	Push = 'PUSH',
}

interface ICommentingCustomer {
	id: string;
	name: string;
	avatarUrl: string;
}

export interface IComment {
	comment: string;
	createdAt: string;
	id: number;
	commentingCustomer: ICommentingCustomer;
}

export interface ICommissionScheme {
	name: string;
}

interface ISalesDesk {
	id: string | null;
	name: string | null;
}

interface ISalesPerson {
	id: string | null;
	name: string | null;
}

export interface ICustomer {
	affiliateId: string | null;
	availableContactMethods: Array<string>;
	communicationLanguage: string;
	referrer: string;
	confirmedAt: string | null;
	country: string;
	countryName: string;
	createdAt: string | null;
	depositTotal: number;
	documentCount: number;
	email: string;
	firstname: string;
	id: string;
	isBookmarked: boolean;
	kycReviewedById: string | null;
	kycReviewedByName: string;
	kycStatus: string | null;
	lastTradingActivityDate: string | null;
	lastname: string | null;
	level: number;
	mobilenumber: string;
	name: string;
	notes: string;
	roles: Array<UserRole>;
	status: string;
	totalLogins: null | number;
	totalReferrals: number;
	appInstalledAt: Date | string | null;
	lastLoginDate: Date | string | null;
	commissionScheme: ICommissionScheme | null;
	totalCoinsEarned: number;
	totalVolumeTraded: number;
	percent: number | undefined;
	tradeVolumeOpen: number;
	tradeVolumeLastWeek: number;
	conversionAgent: ISalesPerson | null;
	retentionAgent: ISalesPerson | null;
	salesDesk: ISalesDesk | null;
	salesStatusName: string | null;
	hasFTD: boolean;
	lastActive: string | null;
	riskStatus: 'NONE' | 'REVIEW';
	withdrawalTotal: number;
	source: string | null;
	commissionsGranted?: number;
	commissionsEarned?: number;
	terminationRequestedAt: string | null;
	groups: Array<number>;
}

export interface ICustomersQuery {
	customers: [];
	pages: number;
}

export interface IAffiliateQueryParams {
	search: string | null;
	country: string | null;
	selectedStatuses: Array<string> | null;
	limit: number;
	offset: number;
	sortBy: string | null;
}

export const customerStatuses = [
	{ value: 'ACTIVE', label: 'Active' },
	{ value: 'downloaded', label: 'Downloaded' },
	{ value: 'push', label: 'Push' },
	{ value: 'PENDING', label: 'Pending' },
	{ value: 'DISABLED', label: 'Disabled' },
];

export interface ISalesPersonCustomer {
	id: string;
	level: string;
	name: string;
	totalDeposit: string;
	tradingVolume: string;
}

export interface ISalesPersonDetailsQuery {
	customer: {
		id: string;
		name: string;
	};
	customers: Array<ISalesPersonCustomer>;
	pages: number;
}

export interface ISalesPeopleRow {
	id: string;
	name: string;
	totalDeposit: string | number;
	totalTradingVolume: number;
	usersUnder: string | number;
}

export interface ISalesPeopleQuery {
	pages: number;
	salesPeople: Array<ISalesPeopleRow>;
}

export interface ICallDetail {
	duration: number;
	answered: boolean;
	recordingFileLink: string;
}

export interface IMessageDetail {
	readAt: Date;
	viaPush: boolean;
	subject: string;
	text: string;
}

export interface IEmailDetail {
	subject: string;
	body: string;
}

export interface ICommunication {
	date: string;
	detail: IEmailDetail | IMessageDetail | ICallDetail;
	from: string;
	fromName: string;
	toName: string;
	id: string;
	type: 'CALL' | 'MESSAGE' | 'EMAIL';
	userType: 'customer' | 'lead';
}

export interface ITableField {
	key: string;
	label: string;
	sorter?: boolean;
}

export interface ICallServiceAvailable {
	available: boolean;
	provider: string;
}

export interface ISalesPersonCommunicationQuery {
	communications: Array<ICommunication>;
	count: number;
}

export interface IUpdateCustomerParams {
	fullname?: string;
	email?: string;
	mobilenumber?: string;
	country?: string;
	roles?: Array<string>;
	primaryWalletId?: number | null;
	newPassword?: string | number;
	repeatPassword?: string | number;
	commissionSchemeId?: number;
	salesStatusId?: number;
	salesDeskId?: string;
	rejectTerminationRequest?: boolean;
	salesPersonId?: string;
	salesAgentRole?: SalesAgentRoles;
	removeRiskStatus?: boolean;
	newReferrerId?: string;
	customerStatus?: CustomerStatus;
	confirmEmail?: boolean;
	groups?: Array<number>;
}

export interface ILoyaltyLevelDetailsQuery {
	awardedAt: string;
	coinsRequiredToRetain: number;
	/** coins required to reach the next level. this will be null if the user is on the topmost level. */
	coinsUntilNextLevel: number | null;
	currentLevel: number;
	currentLevelName: string;
	totalCoinsLifeTime: number;
	totalCoinsMonth: number;
	validUntil: string;
}

export interface ICustomerPositionsQuery {
	positions: Array<IPosition>;
	count: number;
}

export interface IReferralNode {
	id: string;
	name: string;
	children: Array<IReferralNode>;
	nodeProps: {
		name: string;
	};
	textProps: {
		name: string;
		x: number;
		y: number;
	};
}

export interface IRiskEvent {
	createdAt: string;
	eventType: string;
	message: string;
}

export interface ICustomerOnboardingStep {
	completionDate: string | null;
	stage: string;
	step: string;
}
export interface IAffiliateInfo {
	name: string;
	totalReferrals: number;
	affiliateBalance: number;
	depositTotal: number;
	totalVolumeTraded: number;
}

export interface IBulkEditParams extends ICustomersFiltersDTO {
	selectedStatus: string | null;
	conversionAgent: string | null;
	retentionAgent: string | null;
	search: string | undefined;
}

export enum CustomerStatus {
	Active = 'ACTIVE',
	Disabled = 'DISABLED',
}
