import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, LabelList, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _t from 'counterpart';
import { formatCurrency } from '../helpers';
import { timeFormat } from 'd3-time-format';
import { IProfitLossByMonth } from '../services/RiskService';
import { calculateYAxisWidth } from '../helpers/charts';

interface IProps {
	profitLossByMonth: Array<IProfitLossByMonth>;
}

const monthDateFormatter = timeFormat('%B %Y');
const barLabelFormatter = (value: any) => formatCurrency(value);

const ProfitLossByMonthChart: React.FC<IProps> = ({ profitLossByMonth }) => {
	const parsedProfitLossByMonth = useMemo(() => {
		return profitLossByMonth.map((item) => ({
			...item,
			profitLoss: Number(item.profitLoss),
		}));
	}, [profitLossByMonth]);

	return (
		<ResponsiveContainer height={300}>
			<BarChart
				data={parsedProfitLossByMonth}
				margin={{
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}
			>
				<YAxis
					type="number"
					tickFormatter={(value) => formatCurrency(value!, 'USD', 0)}
					padding={{ top: 15, bottom: 15 }}
					width={calculateYAxisWidth(parsedProfitLossByMonth, 'profitLoss')}
				/>

				<XAxis type="category" dataKey="month" tickFormatter={(value) => monthDateFormatter(new Date(value))} />

				<Tooltip
					formatter={(value: any) => [formatCurrency(value), _t('risk-management.total-profit-loss')]}
					labelFormatter={(value: any) => monthDateFormatter(new Date(value))}
				/>

				<ReferenceLine y={0} stroke="#000" />

				<Bar stackId="a" dataKey="profitLoss">
					{parsedProfitLossByMonth.map((entry) => (
						<Cell key={entry.month} fill={Number(entry.profitLoss) > 0 ? 'var(--success)' : 'var(--danger)'} />
					))}
					<LabelList dataKey="profitLoss" position="top" formatter={barLabelFormatter} />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default ProfitLossByMonthChart;
