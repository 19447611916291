export interface ICompetition {
	id: string;
	description: string;
	start: Date;
	end: Date;
	finished: boolean;
	weekNumber: number;
	firstPrizeInUSD: number;
	secondPrizeInUSD: number | null;
	thirdPrizeInUSD: number | null;
	type: CompetitionType;
	tcLink: string;
	promoted: boolean;
}

export interface ICompetitionsQuery {
	competitions: Array<ICompetition>;
	pages: number;
}

export enum CompetitionType {
	PROFITLOSS = 'PROFITLOSS',
	PROFIT = 'PROFIT',
}

export interface ICompetitionQueryResponse {
	competition: ICompetition;
	leaderboard: Array<{
		fixedProfitLoss: number;
		fixedPoints: number;
		floatingProfitLoss: number;
		floatingPoints: number;
		points: number;
		participationId: string;
		nickname: string;
		mtLogin: string;
		volume: number;
	}>;
}
