/* eslint-disable react-hooks/exhaustive-deps */
import _t from 'counterpart';
import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';

interface IProps {
	options: Array<ISelectOption>;
	onOptionsChanged: (options: Array<ISelectOption>) => void;
	inputValue: string;
	onInputValueChanged: (value: string) => void;
	selectAll: boolean;
	onSelectChanged: (value: boolean) => void;
	className?: string;
	classNamePrefix?: string;
	placeholder?: string;
}

export interface ISelectOption {
	value: string;
	label: string;
	enabled: boolean;
	disabled?: boolean;
	sorter?: boolean;
}

const MenuList = React.memo(
	({
		children,
		selectAll = false,
		toggleAllValues,
		...rest
	}: {
		children: React.ReactNode;
		selectAll: boolean;
		toggleAllValues: (selectAll: boolean) => void;
	}) => {
		const [isSelectedAll, setIsSelectedAll] = useState<boolean>(selectAll);

		useEffect(() => {
			// @ts-expect-error options exists
			const selectAll = rest.options.filter((i) => !i.enabled).length === 0;
			setIsSelectedAll(selectAll);
			// @ts-expect-error options exists
		}, [rest.options]);

		return (
			<components.MenuList {...(rest as any)}>
				<div
					className="d-flex align-items-center justify-content-between p-2 border-bottom"
					onClick={() => {
						setIsSelectedAll(!isSelectedAll);
						toggleAllValues(isSelectedAll);
					}}
					onKeyDown={() => {
						setIsSelectedAll(!isSelectedAll);
						toggleAllValues(isSelectedAll);
					}}
					role="button"
					tabIndex={0}
				>
					<span>{_t('global.all')}</span>
					<input className="float-right" type="checkbox" checked={isSelectedAll} readOnly />
				</div>
				{children}
			</components.MenuList>
		);
	}
);

MenuList.displayName = 'MenuList';

const ToggleSelect = ({
	options,
	onOptionsChanged,
	inputValue,
	onInputValueChanged,
	selectAll,
	onSelectChanged,
	className,
	classNamePrefix,
	placeholder,
}: IProps) => {
	const handleInputValue = (input: any, reason: any) => {
		const { action } = reason;
		const returnFields = ['set-value', 'input-blur', 'menu-close'];
		if (!returnFields.includes(action)) {
			onInputValueChanged(input);
		}
	};

	const toggleAllValues = (selectAll: boolean) => {
		const newArray = options.map((c: any) => {
			return {
				...c,
				enabled: c.disabled ? c.enabled : !selectAll,
			};
		});

		onSelectChanged(!selectAll);
		onOptionsChanged(newArray);
	};

	const setValue = (countryCode: string, checked: boolean) => {
		const newArray = [...options];
		const country = newArray.find((c) => c.value === countryCode);
		country!.enabled = checked;
		onOptionsChanged(newArray);
		if (!checked) {
			onSelectChanged(false);
		}
	};

	const customOption = (all: any) => {
		const currentValue = options.find((c) => c.value === all.data.value);
		const { value, enabled, disabled = false } = currentValue!;

		return (
			<div
				{...all.innerProps}
				onClick={() => !disabled && setValue(value, !enabled)}
				onKeyDown={() => !disabled && setValue(value, !enabled)}
				className={`p-2 custom-select-row ${disabled ? 'custom-select-row--disabled' : ''}`}
				role="button"
				tabIndex={value}
			>
				<span>{all.data.label}</span>
				<input className="float-right" type="checkbox" name={all.data.value} checked={all.data.enabled} readOnly />
			</div>
		);
	};

	return (
		<Select
			options={options}
			components={{
				Option: customOption,
				MenuList: useCallback(
					({ children, ...rest }) => (
						<MenuList {...rest} toggleAllValues={toggleAllValues} selectAll={selectAll}>
							{children}
						</MenuList>
					),
					[]
				),
			}}
			isMulti
			closeMenuOnSelect={false}
			value={null}
			placeholder={placeholder}
			inputValue={inputValue}
			onInputChange={handleInputValue}
			className={className}
			classNamePrefix={classNamePrefix}
		/>
	);
};

export default ToggleSelect;
