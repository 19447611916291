import React from 'react';
import { IPosition } from '../pages/positions/position';
import { CBadge } from '@coreui/react';

const PositionTypeBadge = (props: { position: IPosition }) => {
	const { position } = props;
	const sideColorBadge = position.side === 'buy' ? 'success' : 'danger';
	return (
		<CBadge color={sideColorBadge} title={position.side}>
			{position.side.toUpperCase()}
		</CBadge>
	);
};

export default PositionTypeBadge;
