import { ILeadsFilters } from '../../reducers/filtersReducer';

export const leadsFiltersToDTO = (filters: ILeadsFilters) => {
	return {
		statuses: filters.statuses.map((status) => status.value),
		salesPeople: filters.salesPeople.filter((s) => s.enabled).map((s) => s.value),
		sources: filters.sources.filter((s) => s.enabled).map((s) => s.value),
		onlyAssignedToMe: filters.onlyAssignedToMe,
		country: filters.country?.value,
		modifiedBefore: filters.modifiedBefore?.toISOString(),
		modifiedAfter: filters.modifiedAfter?.toISOString(),
	};
};

export function getFiltersCount(selector: any) {
	const objectKeys = Object.keys(selector);
	return Object.values(selector).reduce<number>((count, filterValue, currentIndex) => {
		const filterKey = objectKeys[currentIndex];
		const notToggleSelect = filterKey === 'salesPeople' || filterKey === 'sources';
		if (
			((filterValue && !Array.isArray(filterValue)) || (Array.isArray(filterValue) && filterValue.length > 0)) &&
			!notToggleSelect
		) {
			return count + 1;
		}
		if (notToggleSelect) {
			if (filterKey === 'salesPeople') {
				const foundEnabled = selector.salesPeople.find((s: any) => s.enabled);
				if (foundEnabled) {
					return count + 1;
				}
			}
			if (filterKey === 'sources') {
				const foundEnabled = selector.sources.find((s: any) => s.enabled);
				if (foundEnabled) {
					return count + 1;
				}
			}
		}
		return count;
	}, 0);
}

export function calcTextColor(hexcolor: string) {
	if (hexcolor) {
		const r = parseInt(hexcolor.substring(1, 3), 16);
		const g = parseInt(hexcolor.substring(3, 5), 16);
		const b = parseInt(hexcolor.substring(5, 7), 16);
		const yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq >= 148 ? 'black' : 'white';
	} else {
		return 'white';
	}
}
