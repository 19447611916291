import React, { useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CButton,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CModal,
	CFormGroup,
	CForm,
	CLabel,
	CInput,
	CModalTitle,
	CLink,
} from '@coreui/react';
import { ISalesDesk, ISalesDeskEdit, IUpdateSalesDeskParams } from './types';
import { formatCurrency, findErrorFromValidation, extractErrorMessage } from '../../helpers';
import { useQuery, useMutation } from 'react-query';
import { listSalesDesks, createSalesDesk, updateSalesDesk, deleteSalesDesk } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useAppSelector } from '../../helpers/customHooks';
import CIcon from '@coreui/icons-react';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Permission } from '../../reducers/userReducer';
import { useQueryParam, NumberParam } from 'use-query-params';
import PaginationTable from '../../components/PaginationTable';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import toast from 'react-hot-toast';

const SalesDesksPage = () => {
	const [currentSalesDesk, setCurrentSalesDesk] = useState<ISalesDeskEdit | null>(null);
	const [toDelete, setToDelete] = useState<ISalesDeskEdit | null>(null);
	const [page, setPage] = useQueryParam('page', NumberParam);

	const permissions = useAppSelector((state) => state.user.permissions);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const hasPermission = (permission: Permission) => {
		return permissions?.includes(permission);
	};

	const listSalesDesksQuery = useQuery(['sales-desks', offset], () => listSalesDesks(limit, offset));

	const createUpdateSalesDeskMutation = useMutation(
		['create-update-sales-desk'],
		({ id, name }: IUpdateSalesDeskParams) => {
			if (id) {
				return updateSalesDesk(id, name);
			}
			return createSalesDesk(name);
		},
		{
			onSuccess: () => {
				if (currentSalesDesk!.id) {
					toast.success(_t('sales-desks.sales-desk-updated-successfully'));
				} else {
					toast.success(_t('sales-desks.sales-desk-created-successfully'));
				}

				setCurrentSalesDesk(null);
				listSalesDesksQuery.refetch();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					toast.error(msg);
				}
			},
		}
	);

	const deleteSalesDeskMutation = useMutation(['delete-sales-desk'], (id: string) => deleteSalesDesk(id), {
		onSuccess: () => {
			toast.success(_t('sales-desks.sales-desk-delete-successfully'));
			setToDelete(null);
			listSalesDesksQuery.refetch();
		},
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				const msg = extractErrorMessage(error);
				toast.error(msg);
			}
		},
	});

	const showSalesDeskModal = () => {
		setCurrentSalesDesk({
			id: null,
			name: '',
		});
	};

	const editSalesDesk = (salesDesk: ISalesDeskEdit) => {
		setCurrentSalesDesk(salesDesk);
	};

	const hideSalesDeskModal = () => {
		setCurrentSalesDesk(null);
	};

	const onDeleteConfirm = () => {
		deleteSalesDeskMutation.mutate(toDelete?.id || '');
	};

	const fields = useMemo(
		() => [
			{ key: 'name', label: _t('sales-desks.name'), sorter: false },
			{ key: 'numberOfSalesAgents', label: _t('sales-desks.number-of-agents'), sorter: false },
			{ key: 'grossDeposits', label: _t('sales-desks.gross-deposit'), sorter: false },
			{ key: 'netDeposits', label: _t('sales-desks.net-deposit'), sorter: false },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			name: ({ id, name }: ISalesDesk) => (
				<td>
					<CLink href={`/sales-desks/${id}`}>{name}</CLink>
				</td>
			),
			grossDeposits: (salesDesk: ISalesDesk) => <td>{formatCurrency(salesDesk.grossDeposits)}</td>,
			netDeposits: (salesDesk: ISalesDesk) => <td>{formatCurrency(salesDesk.netDeposits)}</td>,
			actions: ({ id, name }: ISalesDesk) => (
				<td>
					{permissions.includes(Permission.MANAGE_SALES_DESK) && (
						<>
							<CButton color="primary" size="sm" onClick={() => editSalesDesk({ id, name })}>
								<CIcon name="cil-pencil" size="sm" />
							</CButton>
							<CButton className="ml-2" color="danger" size="sm" onClick={() => setToDelete({ id, name })}>
								<CIcon name="cil-trash" size="sm" />
							</CButton>
						</>
					)}
				</td>
			),
		};
	}, [permissions]);

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		if (name) {
			setCurrentSalesDesk({ ...currentSalesDesk!, name: target.value });
		}
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(createUpdateSalesDeskMutation.error, paramName);
	};

	const handleSalesDeskMutations = (e: React.FormEvent) => {
		e.preventDefault();
		const { id, name } = currentSalesDesk!;
		createUpdateSalesDeskMutation.mutate({ id, name });
	};

	const hideDeleteSalesDeskModal = () => {
		setToDelete(null);
	};

	const resetErrorState = () => {
		createUpdateSalesDeskMutation.reset();
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	if (listSalesDesksQuery.isLoading || listSalesDesksQuery.isIdle) {
		return <Loading />;
	}

	if (listSalesDesksQuery.isError) {
		return <Error onRetry={listSalesDesksQuery.refetch} />;
	}

	const isUpdating = currentSalesDesk?.id !== null;
	const { isLoading } = createUpdateSalesDeskMutation;

	return (
		<PageLayout title={_t('sidebar.sales-desks')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody className="simulated-overflow-y">
							<div className="my-2 float-sm-right d-flex justify-content-end">
								{hasPermission(Permission.MANAGE_SALES_DESK) && (
									<CButton onClick={showSalesDeskModal} className="header-button-add" />
								)}
							</div>
							<PaginationTable
								data={listSalesDesksQuery.data?.salesDesks || []}
								pages={listSalesDesksQuery.data?.pages || 0}
								scopedSlots={scopedSlots}
								tableFields={fields}
								pagination
								loading={isLoading}
								activePage={page || 1}
								onPageChanged={onPageChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CModal show={Boolean(currentSalesDesk)} onClose={hideSalesDeskModal} closeOnBackdrop onOpened={resetErrorState}>
				<CModalHeader>
					<CModalTitle>
						{isUpdating && _t('sales-desks.update-sales-desk')}
						{!isUpdating && _t('sales-desks.create-sales-desk')}
					</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={handleSalesDeskMutations}>
					<CModalBody>
						<CFormGroup>
							<CLabel htmlFor="salesDeskName">{_t('global.name')}</CLabel>
							<CInput
								type="text"
								id="categoryName"
								name="categoryName"
								value={currentSalesDesk?.name || ''}
								onChange={handleInputChange}
							/>
							{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
						</CFormGroup>
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={isUpdating ? _t.translate('global.update') : _t.translate('action.create')}
							className="mr-2"
						/>
						<CButton color="light" variant="outline" onClick={hideSalesDeskModal} disabled={isLoading}>
							{_t('global.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
			<ConfirmationModal
				id={toDelete?.id || ''}
				show={Boolean(toDelete)}
				onConfirm={onDeleteConfirm}
				hidePanel={hideDeleteSalesDeskModal}
				title={_t('sales-desks.delete-sales-desk', { name: toDelete?.name })}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default React.memo(SalesDesksPage);
