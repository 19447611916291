import _t from 'counterpart';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { customersTableColumns } from './CustomersTable';
import ToggleSelect, { ISelectOption as IToggleSelectOption } from '../../components/ToggleSelect';
import { setTableVisibleColumns } from '../../actions';

interface IProps {
	className?: string;
	classNamePrefix?: string;
	placeholder?: string;
}

const CustomersTableColumnSelect = (props: IProps) => {
	const {
		className = 'filters-header-buttons-dropdown',
		classNamePrefix = 'customers-table',
		placeholder = _t('customers.select.placeholder'),
	} = props;

	const customerTableVisibleColumns = useAppSelector((state) => state.user.visibleTableColumns['CUSTOMERS_TABLE']);
	const dispatch = useAppDispatch();

	const initialSelectAll =
		customersTableColumns.filter((column) => customerTableVisibleColumns.includes(column.key)).length ===
		customersTableColumns.length;

	const [selectAll, setSelectAll] = useState<boolean>(initialSelectAll);
	const [inputValue, setInputValue] = useState<string>('');

	const tableHiddenColumns = customersTableColumns.map(({ key, options }) => {
		const { sorter = false, disabled = false } = options || {};
		return {
			value: key,
			label: _t(`customers.columns.${key}`),
			enabled: disabled ? true : customerTableVisibleColumns.includes(key),
			sorter,
			disabled,
		};
	});

	const onOptionsChanged = (options: Array<IToggleSelectOption>) => {
		const mappedOptions = options.map((option) => {
			const { value, enabled, disabled, sorter } = option;
			return {
				key: value,
				options: {
					enabled,
					disabled,
					sorter,
				},
			};
		});
		const disabledIndex = options.findIndex((o) => !o.enabled);
		const visibleIds = mappedOptions.filter((i) => i.options.enabled).map((i) => i.key);

		setSelectAll(disabledIndex === -1);
		dispatch(setTableVisibleColumns(visibleIds, 'CUSTOMERS_TABLE'));
	};

	return (
		<ToggleSelect
			options={tableHiddenColumns}
			inputValue={inputValue}
			onInputValueChanged={setInputValue}
			onOptionsChanged={onOptionsChanged}
			selectAll={selectAll}
			onSelectChanged={setSelectAll}
			className={className}
			classNamePrefix={classNamePrefix}
			placeholder={placeholder}
		/>
	);
};

export default CustomersTableColumnSelect;
