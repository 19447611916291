import React from 'react';
import { CLabel, CButton } from '@coreui/react';
import _t from 'counterpart';

interface InputHeaderProps {
	labelText: string;
	resetFilter: Function;
}

const InputHeader = ({ labelText, resetFilter }: InputHeaderProps) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<CLabel className="m-0" htmlFor="regdate">
				{labelText}
			</CLabel>
			<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetFilter}>
				{_t.translate('action.reset').toUpperCase()}
			</CButton>
		</div>
	);
};

export default React.memo(InputHeader);
