import React from 'react';
import _t from 'counterpart';
import { useGetSetting } from '../pages/generalSettings/hooks';
import { CLink } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { extractErrorMessage } from '../helpers';
import { Permission } from '../reducers/userReducer';

const NotFound = () => {
	const permissions = useAppSelector((state) => state.user.permissions);
	const hasPermission = permissions?.includes(Permission.VIEW_EMAILS);

	const dispatch = useAppDispatch();
	const staffEmailQuery = useGetSetting('STAFF_NOTIFICATION_EMAIL', 'staff-email-not-found', {
		onError: (e: any) => {
			dispatch(extractErrorMessage(e));
		},
		enabled: hasPermission,
	});
	const email = staffEmailQuery.data;
	const showContact = email !== '' && !staffEmailQuery.isLoading && !staffEmailQuery.isError && hasPermission;

	return (
		<div className="d-flex justify-content-center align-items-center flex-column my-auto">
			<h2>{_t('global.not-found-title')}</h2>
			{staffEmailQuery.isLoading && (
				<CIcon name="hourglass" style={{ stroke: 'black' }} className="animation-rotate-center" />
			)}
			{showContact && <p>{_t('global.contact', { email })}</p>}
			{email === '' && <CLink href="/general_settings#email">{_t('global.contact-email-not-configured')}</CLink>}
		</div>
	);
};

export default NotFound;
